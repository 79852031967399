/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC } from 'react';
import clsx from 'clsx';
import { CarouselControlsProps } from './CarouselControls.types';
import { StyledCarouselControls } from './CarouselControls.styles';

// Use named rather than default exports.
export const CarouselControls: FC<CarouselControlsProps> = ({ customClasses, dots }) => {
  const dotsArray = Array(dots)?.fill(null);

  return (
    <StyledCarouselControls>
      <div
        data-component-name="m-account-CarouselControls"
        data-testid="account-CarouselControls"
        className={clsx('d-flex align-items-center justify-content-center controls_wrapper pb-2', customClasses)}
      >
        <div className="carouselControlType3b">
          <div className="glide__arrows" data-glide-el="controls">
            <button
              className="glide__arrow--left left-arrow"
              data-glide-dir="<"
              aria-label="Previous"
              data-content="Previous"
              id="left_arrow"
            >
              <span className="icon-arrow-left"></span>
            </button>
          </div>
          <div data-glide-el="controls[nav]">
            {dotsArray?.map((_: any, index: number) => {
              return (
                <button
                  data-glide-dir={`=${index}`}
                  key={index}
                  aria-label={`glide-bullet-${index}`}
                  data-testid="glide-dot"
                ></button>
              );
            })}
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button
              className="glide__arrow--right right-arrow"
              data-glide-dir=">"
              aria-label="Next"
              id="right_arrow"
              data-content="Next"
            >
              <span className="icon-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>
    </StyledCarouselControls>
  );
};
