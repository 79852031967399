// Styles for TotalPoints go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const StyledTotalPoints = styled.div`
  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: justify;
      .m-link-tertiary-button{
        margin-left: ${toRem(27)} !important;
      }
      .m-link-tertiary-button::after {
        transform: rotate(-180deg) !important;
      }
    }
    background-color: ${baseVariables.color['alert10']} !important;
    @media ${baseVariables.mediaQuery.sm} {
      border-radius: ${toRem(14)};
      box-sizing: border-box;
    }

    .card {
      &__header {
        @media ${baseVariables.mediaQuery.sm} {
          min-height: ${toRem(50)};
          border-bottom: ${toRem(2)} solid ${baseVariables.color['neutral20']};
          width: 100%;
        }
      }
    }
  `}
`;
