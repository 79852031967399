import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../../modules/utils/constants/constants';
export const StyledPrintableMissingStayRequestForm = styled.div<{ isOpen: boolean }>`
  ${rtl`
      @media print {
         body,
         html,
         div,
         #printable-form-wrapper {
           overflow: visible;
        }
      }
      .modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        overflow-y: auto;
        height: 100vh;
        background-color: ${baseVariables.color['base20']};
        &__content {
          height: 100%;
          width: 100%;
          @media print {
            height: 100%;
          }
        &__header {
          height: ${toRem(52)};
          width: 100%;
          border-bottom: ${toRem(1)} dashed ${constants.BORDER10};
          overflow: hidden;
          @media ${baseVariables.mediaQuery.md} {
            height: ${toRem(88)};
          }
          @media print {
            height: ${toRem(88)};
          }
          &__print-button {
            cursor: pointer;
          }
          &__clear-button {
            border-radius: ${toRem(4)};
            background-color: ${baseVariables.color['light-gray-3']};
            height: ${toRem(32)};
            width: ${toRem(32)};
          }
          &__logo {
            position: relative;
            top: ${toRem(14)};
            left: ${toRem(52)};
            @media ${baseVariables.mediaQuery.md} {
              left: 0;
            }
            @media print {
              left: 0;
            }
          }
        }
        &__body {
          padding: ${toRem(40)} ${toRem(16)} ${toRem(86)} ${toRem(16)};
          @media ${baseVariables.mediaQuery.md} {
            padding: ${toRem(40)} ${toRem(32)} ${toRem(70)} ${toRem(32)};
          }
          @media ${baseVariables.mediaQuery.lg} {
            padding: ${toRem(56)} ${toRem(158)} ${toRem(86)} ${toRem(158)};
          }
          &__section-label {
            width: ${toRem(158)};
            @media ${baseVariables.mediaQuery.md} {
              width: ${toRem(224)};
            }
            @media ${baseVariables.mediaQuery.lg} {
              width: ${toRem(174)};
            }
          }
          &__section-content {
            width: ${toRem(158)};
            @media ${baseVariables.mediaQuery.md} {
              width: ${toRem(224)};
            }
            @media ${baseVariables.mediaQuery.lg} {
              width: ${toRem(269)};
            }
          }
          &__description {
            @media ${baseVariables.mediaQuery.lg} {
              max-width: ${toRem(744)};
            }
          }
        }
        &__body:is(${constants.RTL_LANG_LIST}){
          text-align: right !important;
        }
      }
    }
  `}
`;
