// Styles for OnboardingComponent go here.
import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledOnboardingComponent = styled.div`
  .horizontal-card {
    .new-member-icon {
      font-size: ${toRem(42)};
    }
  }
`;
