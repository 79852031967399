/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useContext, useRef, useState } from 'react';
import { inspect } from 'util';

import { useLazyQuery } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Messages, RichText, getCurrentDateObject } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

import {
  apiLogger,
  generateApolloClientHeaders,
  getMemberLevel,
  getMemberStatusDescription,
  constants,
  checkChinaLocale,
} from '../../modules/utils';

import { PageContext } from '../../modules/context/PageContext';
import { AccountPersistentState, useAccountPersistentStore } from '../../modules/store/accountPersistentStore';

import { MemberProfileDetails } from './MemberProfileDetails/MemberProfileDetails';
import { MemberStayDetails } from './MemberStayDetails/MemberStayDetails';

import { phoenixAccountGetMemberStatusDetails } from '../../modules/graph';

import { OverviewMainContainer } from './MemberStatus.styles';

import mockMemberData from './__mock__/memberStatus.json';

export const MemberStatus = (props: any) => {
  const { IS_LOCAL_DEV } = process.env;
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { FETCH_MEMBER_DETAILS_FOR_PATHS: fetchMemberDetailsPaths, ACCEPT_LANGUAGE: currentLocale } = clientEnvVars;
  const pageContext = useContext(PageContext);
  const dataLoaded = useRef<boolean>(false);
  const {
    memberLevelCode,
    memberLevelTypeCode,
    memberName,
    currentPointBalance,
    totalNightCount,
    memberData,
    memberLevel,
    memberLocalizedName,
    setMemberDetails,
  } = useAccountPersistentStore(state => state);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [memberStatusError, setMemberStatusError] = useState<boolean>(false);
  const [memberDataState, setMemberDataState] = useState<AccountPersistentState>();
  const aemLabel = props?.model;
  const isChinaLocale = checkChinaLocale(currentLocale); //check if chinalocale is true

  const isAuthorMode = props?.isAuthorMode;
  const sessionData = pageContext?.sessionData?.cacheData?.data;

  useEffect(() => {
    if (isAuthorMode) {
      setMemberDetails?.({ ...mockMemberData });
    } else {
      const referrerPath = (typeof window !== 'undefined' && window.document?.referrer) || '';
      const isExpectedPage = referrerPath?.includes(constants.LOYALTY_PAGE_PATH);
      const allowedPaths = fetchMemberDetailsPaths?.split(',');
      const pathIncluded = allowedPaths?.some(path => referrerPath?.includes(path));

      if (pathIncluded || !referrerPath || !isExpectedPage || !memberData) {
        generateMembersData();
      }
    }
    /**
     * update the header path once page is loaded
     */
  }, []);

  useEffect(() => {
    setMemberDataState({
      memberLevelCode: memberLevelCode,
      memberLevelTypeCode: memberLevelTypeCode,
      memberName: memberName,
      currentPointBalance: currentPointBalance,
      totalNightCount: totalNightCount,
      memberData: memberData,
      memberLevel: memberLevel,
      memberLocalizedName: memberLocalizedName,
    });
  }, [
    memberLevelCode,
    memberLevelTypeCode,
    memberName,
    currentPointBalance,
    totalNightCount,
    memberData,
    memberLevel,
    memberLocalizedName,
  ]);

  const dateObj = getCurrentDateObject();

  // *************** UXL ****************************

  const [generateMembersData] = useLazyQuery(phoenixAccountGetMemberStatusDetails, {
    variables: {
      customerId: sessionData?.consumerID,
      startYear: dateObj?.year(),
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      dataLoaded.current = true;
      setMemberDetails?.(data);
      apiLogger(`[MemberStatus] getMemberStatusDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`);
    },
    onError: error => {
      dataLoaded.current = true;
      setMemberStatusError(true);
      apiLogger(
        `[MemberStatus] getMemberStatusDetails - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    },
  });

  if (memberStatusError) {
    return (
      <div data-testid="uxl-error-msg-wrapper">
        <Messages messageType="warning" className="my-4">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      </div>
    );
  }

  return (
    <OverviewMainContainer className="py-5" data-testid="memberStatus" data-component-name="o-account-memberStatus">
      <div
        className="container d-flex flex-column flex-lg-row justify-content-lg-between pt-3 pb-2 member-status-container"
        data-testid="member-status"
        id="memberStatusContainer"
      >
        <div className="container__right d-flex d-md-block d-lg-flex justify-content-center align-items-center mb-sm-4 mb-md-5 mb-lg-0 offset-md-1 offset-lg-0 pl-md-4 pl-lg-0">
          <MemberProfileDetails
            color={memberDataState?.memberLevel}
            givenName={
              isChinaLocale
                ? memberDataState?.memberLocalizedName || memberDataState?.memberName
                : sessionData?.firstName || memberDataState?.memberName
            }
            description={getMemberStatusDescription(
              getMemberLevel(memberDataState?.memberLevelCode ?? '', memberDataState?.memberLevelTypeCode ?? '') ?? '',
              pageContext?.memberStatusList
            )}
            // TODO - removing this member image for now - it will be enable in future
            // eliteMember={memberRibbon?.loyaltyInformation?.rewards?.eliteLifetimeLevel}
          ></MemberProfileDetails>
        </div>
        <div>
          <MemberStayDetails
            rewardDetails={memberDataState?.memberData?.customer?.loyaltyInformation}
            aemResponse={aemLabel}
            currentPointBalance={memberDataState?.currentPointBalance}
            totalNightCount={memberDataState?.totalNightCount}
          />
        </div>
      </div>
    </OverviewMainContainer>
  );
};

export const MemberStatusConfig = {
  emptyLabel: 'memberStatus',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/memberStatus`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MemberStatusEditable = (props: any) => {
  return props.cqPath.includes('datapagecomponent') ? (
    <MemberStatus {...props} />
  ) : (
    <EditableComponent config={MemberStatusConfig} {...props}>
      <MemberStatus {...props} />
    </EditableComponent>
  );
};

// export default MemberStatusEditable;
