import React, { useState, useEffect, useContext, FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import clsx from 'clsx';
import { inspect } from 'util';
import { useLazyQuery } from '@apollo/client';
import { Types, Heading, RichText, Button, Link, Messages, InputTextField } from '@marriott/mi-ui-library';
import { phoenixAccountLookupCustomerProfiles } from '../../modules/graph';
import { apiLogger, getCountryAndLangCode, generateApolloClientHeaders } from '../../modules/utils';
import { PageContext } from '../../modules/context/PageContext';
import { Dropdown } from '../../molecules/Dropdown';
import { constants } from '../../modules/utils/constants/constants';
import { maskEmail } from '../../modules/utils/helper';
import { FormData, CountryList, CountryOption, errorType, LookUpMemberNumberProps } from './LookUpMemberNumber.types';
import { StyledLookUpMemberNumber } from './LookUpMemberNumber.styles';

// Use named rather than default exports.
export const LookUpMemberNumber: FC<LookUpMemberNumberProps> = props => {
  const {
    header,
    subHeader,
    countries,
    firstNameLabel,
    lastNameLabel,
    countryRegionLabel,
    emailLabel,
    firstNameError,
    lastNameError,
    emailErrorLabel,
    errorMessageHeader,
    submitButtonLabel,
    cancelButtonLabel,
    apiFailureErrMsg,
    subHeaderEmailSent,
    subHeaderEmailDescription,
    forgotPwrdLabel,
    forgotPwrdCtaPath,
  } = props;

  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { ACCEPT_LANGUAGE: currentLocale } = clientEnvVars;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode?.toUpperCase() || constants.USA_COUNTRY_CODE;

  const initialFormData: FormData = {
    email: '',
    firstName: '',
    lastName: '',
    country: {
      id: '',
      value: '',
      countryDialCode: '',
    },
  };

  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    emailErrorLabel: '',
  });
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [reverseNamefield, setReverseNamefield] = useState(false);
  const [refererPath, setRefererPath] = useState('');
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [emailSent, setEmailSent] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getLookupCustomerProfiles] = useLazyQuery(phoenixAccountLookupCustomerProfiles);

  useEffect(() => {
    if (countries) {
      if (countries && countries.length) {
        const defCountry = localeCountryCode
          ? countries.find((country: CountryOption) => country?.id?.toUpperCase() === localeCountryCode?.toUpperCase())
          : countries[0];
        setFormData({ ...formData, country: defCountry });
      }
    }
  }, []);

  useEffect(() => {
    const allowedCountryCodes = [
      constants.KOREA_COUNTRY_CODE,
      constants.CHINA_COUNTRY_CODE,
      constants.JAPAN_COUNTRY_CODE,
    ];

    if (allowedCountryCodes.includes(localeCountryCode)) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }

    if (typeof window !== 'undefined' && window.document?.referrer) {
      setRefererPath(window.document?.referrer);
    }
  }, []);

  useEffect(() => {
    if (errorsList?.length || apiError) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [errorsList, apiError]);

  const handleCountryNameChange = (option: CountryList) => {
    if (
      option.id === constants.KOREA_COUNTRY_CODE ||
      option.id === constants.CHINA_COUNTRY_CODE ||
      option.id === constants.JAPAN_COUNTRY_CODE
    ) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }
    setFormData({ ...formData, country: option });
  };

  const resetForm = (fieldName: string) => {
    const newErrors: errorType = { ...errors };
    switch (fieldName) {
      case 'email':
        newErrors.emailErrorLabel = '';
        break;
      case 'firstName':
        newErrors.firstNameError = '';
        break;
      case 'lastName':
        newErrors.lastNameError = '';
        break;
      default:
    }
    setErrors(newErrors);
  };

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      handleSubmit(ev);
    }
  };

  const validateAllFields = () => {
    const newErrors: errorType = {
      firstNameError: '',
      lastNameError: '',
      emailErrorLabel: '',
    };
    let isValid = true;
    if (!formData?.email) {
      newErrors.emailErrorLabel = emailErrorLabel;
      isValid = false;
    }
    if (!formData?.firstName) {
      newErrors.firstNameError = firstNameError;
      isValid = false;
    }
    if (!formData?.lastName) {
      newErrors.lastNameError = lastNameError;
      isValid = false;
    }
    setErrors(newErrors);
    const filteredErrors = Object.values(newErrors).filter(value => value !== '');
    setErrorsList(filteredErrors);

    if (isValid) {
      validateLookupMember();
    }
    return isValid;
  };

  const handleSubmit = (ev: React.KeyboardEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setApiError(false);
    validateAllFields();
  };

  // UXL call
  const validateLookupMember = () => {
    setIsLoading(true);
    getLookupCustomerProfiles({
      variables: {
        input: {
          country: formData?.country?.id?.trim(),
          email: formData?.email?.trim(),
          firstName: formData?.firstName?.trim(),
          lastName: formData?.lastName?.trim(),
          sendEmail: true,
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: data => {
        if (data?.lookupCustomerProfiles?.code === 'Success') {
          setEmailSent(true);
        } else {
          setApiError(true);
        }
        setIsLoading(false);
        apiLogger(`[LookupCustomer] AccountLookupCustomer - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`);
      },
      onError: error => {
        setApiError(true);
        setIsLoading(false);
        apiLogger(
          `[LookupCustomer] AccountLookupCustomer - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
        );
      },
    });
  };

  return (
    <StyledLookUpMemberNumber data-component-name="o-account-lookupmembernumber" data-testid="lookupmembernumber">
      <div className="lookup-member-number container">
        <div className="row justify-content-center justify-content-lg-start pt-5 pb-2 pb-md-5">
          <div className="col-12 col-md-8 col-lg-5 pt-md-3">
            <Heading variation={Types.headingType.title} fontSize={Types.size.small} titleText={header} />

            {emailSent ? (
              <div>
                <Heading
                  variation={Types.headingType.subtitle}
                  fontSize={Types.size.large}
                  titleText={subHeaderEmailSent}
                  customClass={'pt-4'}
                />

                <RichText
                  text={subHeaderEmailDescription?.replace('{0}', maskEmail(formData?.email?.trim()))}
                  customClass={'px-0 pb-0'}
                  styleclass={'t-font-m'}
                  componentId="lookup-member-number-subHeader"
                />

                <Link
                  text={forgotPwrdLabel}
                  linkClassName="t-font-s mt-5 pt-md-3 link-underline"
                  linkHref={forgotPwrdCtaPath}
                  target="_self"
                  linkType="internal"
                />
              </div>
            ) : (
              <div>
                <RichText
                  text={subHeader}
                  customClass={'px-0 pb-0'}
                  styleclass={'t-font-m'}
                  componentId="lookup-member-number-subHeader"
                />

                {(errorsList?.length || apiError) && (
                  <div>
                    <Messages messageType="error-sev1" className="my-5 account-page-error-msg px-2">
                      {
                        <>
                          <span>{errorMessageHeader}</span>

                          <ul>
                            {apiError ? (
                              <li>
                                <RichText text={apiFailureErrMsg} componentId={apiFailureErrMsg} />
                              </li>
                            ) : (
                              errorsList.map((error, index) => (
                                <li key={index}>
                                  <RichText text={error} componentId={error} />
                                </li>
                              ))
                            )}
                          </ul>
                        </>
                      }
                    </Messages>
                  </div>
                )}

                <form method="post" onSubmit={handleSubmit} data-testid="lookup-form">
                  <InputTextField
                    label={emailLabel}
                    inputValue={formData?.email}
                    testId="email-label"
                    showErrorMessage={!!errors?.emailErrorLabel}
                    messageToShow={emailErrorLabel}
                    messageClass="error-label"
                    inputMaxLength={80}
                    infoLabel={emailLabel}
                    getInputProps={() => ({
                      autoComplete: 'off',
                      id: `input-Email`,
                      onKeyUp: handleKeyPress,
                    })}
                    getLabelProps={() => ({
                      htmlFor: `input-Email`,
                    })}
                    className={clsx(
                      'm-input-text-field mt-5',
                      errors?.emailErrorLabel ? 'is-error' : formData?.email ? 'is-active' : ''
                    )}
                    getInputValue={(val: string) => {
                      val && resetForm('email');
                      setFormData({ ...formData, email: val });
                    }}
                  />

                  {reverseNamefield ? (
                    <InputTextField
                      label={lastNameLabel}
                      inputValue={formData?.lastName}
                      showErrorMessage={!!errors?.lastNameError}
                      messageToShow={errors?.lastNameError ? lastNameError : ''}
                      messageClass="error-label"
                      inputMaxLength={25}
                      getInputProps={() => ({
                        autoComplete: 'off',
                        id: `input-lastName`,
                        onKeyUp: handleKeyPress,
                      })}
                      getLabelProps={() => ({
                        htmlFor: `input-lastName`,
                      })}
                      className={clsx(
                        'm-input-text-field mt-5',
                        errors?.lastNameError ? 'is-error' : formData?.lastName ? 'is-active' : ''
                      )}
                      getInputValue={(val: string) => {
                        val && resetForm('lastName');
                        setFormData({ ...formData, lastName: val });
                      }}
                    />
                  ) : (
                    <InputTextField
                      label={firstNameLabel}
                      inputValue={formData?.firstName}
                      showErrorMessage={!!errors?.firstNameError}
                      messageToShow={errors?.firstNameError ? firstNameError : ''}
                      messageClass="error-label"
                      inputMaxLength={20}
                      getInputProps={() => ({
                        autoComplete: 'off',
                        id: `input-firstName`,
                        onKeyUp: handleKeyPress,
                      })}
                      getLabelProps={() => ({
                        htmlFor: `input-firstName`,
                      })}
                      className={clsx(
                        'm-input-text-field mt-5',
                        errors?.firstNameError ? 'is-error' : formData?.firstName ? 'is-active' : ''
                      )}
                      getInputValue={(val: string) => {
                        val && resetForm('firstName');
                        setFormData({ ...formData, firstName: val });
                      }}
                    />
                  )}

                  {reverseNamefield ? (
                    <InputTextField
                      label={firstNameLabel}
                      inputValue={formData?.firstName}
                      showErrorMessage={!!errors?.firstNameError}
                      messageToShow={errors?.firstNameError ? firstNameError : ''}
                      messageClass="error-label"
                      inputMaxLength={20}
                      getInputProps={() => ({
                        autoComplete: 'off',
                        id: `input-firstName`,
                        onKeyUp: handleKeyPress,
                      })}
                      getLabelProps={() => ({
                        htmlFor: `input-firstName`,
                      })}
                      className={clsx(
                        'm-input-text-field mt-5',
                        errors?.firstNameError ? 'is-error' : formData?.firstName ? 'is-active' : ''
                      )}
                      getInputValue={(val: string) => {
                        val && resetForm('firstName');
                        setFormData({ ...formData, firstName: val });
                      }}
                    />
                  ) : (
                    <InputTextField
                      label={lastNameLabel}
                      inputValue={formData?.lastName}
                      showErrorMessage={!!errors?.lastNameError}
                      messageToShow={errors?.lastNameError ? lastNameError : ''}
                      messageClass="error-label"
                      inputMaxLength={25}
                      getInputProps={() => ({
                        autoComplete: 'off',
                        id: `input-lastName`,
                        onKeyUp: handleKeyPress,
                      })}
                      getLabelProps={() => ({
                        htmlFor: `input-lastName`,
                      })}
                      className={clsx(
                        'm-input-text-field mt-5',
                        errors?.lastNameError ? 'is-error' : formData?.lastName ? 'is-active' : ''
                      )}
                      getInputValue={(val: string) => {
                        val && resetForm('lastName');
                        setFormData({ ...formData, lastName: val });
                      }}
                    />
                  )}

                  <div className="m-input-text-field pb-2 mt-5">
                    <Dropdown
                      defaultOption={formData?.country?.value || ''}
                      dropdownOptions={countries || []}
                      onChange={handleCountryNameChange}
                      customClassName="lookup-countrydropdown is-active"
                      dropdownId="fp-country-code"
                      hasLabel={countryRegionLabel}
                    />
                  </div>

                  <div className="mx-auto d-flex align-items-center flex-column flex-md-row pt-4 px-0 space-bottom submit_btn-wrapper">
                    <Button
                      className={'m-button-m m-button-primary custom-btn submit_btn px-4 py-2 lookup-submit-mobile'}
                      type={Types.ButtonTypeVariation.Submit}
                      testId={'submitButtonLabel'}
                      ariaLabel={submitButtonLabel}
                    >
                      {isLoading ? (
                        <div className="m-spinner-s" data-testid="loading-spinner"></div>
                      ) : (
                        submitButtonLabel
                      )}
                    </Button>
                    <Link
                      text={cancelButtonLabel}
                      linkClassName="t-font-m mt-4 mt-md-0 mx-md-4 link-underline"
                      linkHref={refererPath ? refererPath : forgotPwrdCtaPath}
                      target="_self"
                      linkType="internal"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledLookUpMemberNumber>
  );
};

export const LookUpMemberNumberConfig = {
  emptyLabel: 'lookupmembernumber',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/lookupmembernumber`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LookUpMemberNumberEditable = (props: any) => {
  return (
    <EditableComponent config={LookUpMemberNumberConfig} {...props}>
      <LookUpMemberNumber {...props} />
    </EditableComponent>
  );
};
