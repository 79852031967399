// Styles for BannerMessages go here.
import styled from 'styled-components';

export const StyledBannerMessages = styled.div`
  .row {
    margin: 0;
    .m-message-content {
      p {
        margin: 0;
      }
    }
  }
`;
