import React, { FC, useEffect, useState } from 'react';
import { CheckBox, Text, Types } from '@marriott/mi-ui-library';
import { isArraysEqual } from '../../../modules/utils/helper';
import { ExperiencesSectionProps } from './ExperiencesSection.types';

export const ExperiencesSection: FC<ExperiencesSectionProps> = ({
  model,
  onHandleFlag,
  onSubmitFlag,
  customerExperienceData,
  setFlagforSaveBtn,
}) => {
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const checkedItemsList = model?.experienceList?.flatMap(section =>
    section?.items?.map(item => ({ ...item, checked: false, sectionID: section?.sectionId }))
  );
  const [checkedItems, setCheckedItems] = useState(checkedItemsList);

  const codes = customerExperienceData?.liveEvents?.music
    .concat(customerExperienceData?.liveEvents?.otherEvents, customerExperienceData?.liveEvents?.sports)
    .map((item: { code: string }) => item?.code);

  const getSelectedData = () => {
    const updatedCheckedItems = checkedItems?.map(item =>
      codes?.includes(item?.id) ? { ...item, checked: true } : { ...item, checked: false }
    );
    setCheckedItems(updatedCheckedItems);
  };
  const prevCheckedItems = customerExperienceData?.liveEvents?.music
    .concat(customerExperienceData?.liveEvents?.otherEvents, customerExperienceData?.liveEvents?.sports)
    .map((item: { code: string }) => item?.code);

  const currentCheckedItems = checkedItems?.filter(item => item.checked === true)?.map(item => item?.id);

  //sent flag to enable save btn
  useEffect(() => {
    if (isSaveButtonEnabled) {
      setFlagforSaveBtn(isSaveButtonEnabled);
    }
  }, [isSaveButtonEnabled, setFlagforSaveBtn]);

  useEffect(() => {
    if (onSubmitFlag) {
      const experiencePayload = {
        music: checkedItems
          ?.filter(item => item?.checked === true && item?.sectionID === 'MUSIC')
          ?.map(item => item?.id),
        otherEvents: checkedItems
          ?.filter(item => item?.checked === true && item?.sectionID === 'OTHER')
          ?.map(item => item?.id),
        sports: checkedItems
          ?.filter(item => item?.checked === true && item?.sectionID === 'SPORTS')
          ?.map(item => item?.id),
      };

      const experienceDataChanged = !isArraysEqual(currentCheckedItems, prevCheckedItems);
      onHandleFlag(experiencePayload, experienceDataChanged); // Call callback with data if flag is true
    }
  }, [onSubmitFlag]);

  useEffect(() => {
    getSelectedData();
  }, [customerExperienceData]);

  const handleCheckedItems = (checkedID: string, sectionID: string) => {
    const updatedCheckedItems = checkedItems?.map(item =>
      item?.id === checkedID && item?.sectionID === sectionID ? { ...item, checked: !item?.checked } : item
    );
    setCheckedItems(updatedCheckedItems);
  };

  return (
    <div className="ml-md-5 experience-section">
      <Text
        copyText={model?.experienceSubTitle}
        fontSize={Types.size.medium}
        customClass="t-font-m mt-5"
        element={Types.tags.span}
        data-testid="modal-heading"
      />
      <div className="row">
        {model?.experienceList?.map((expItem, expIndex) => (
          <div className="col-lg-4">
            <Text
              key={expIndex}
              copyText={expItem?.sectionTitle}
              fontSize={Types.size.large}
              customClass="t-subtitle-m mt-5 pb-2"
              element={Types.tags.paragraph}
              data-testid="modal-heading"
            />
            {checkedItems
              ?.filter(item => item?.sectionID === expItem?.sectionId)
              ?.map((checkboxitem, checkboxindex) => (
                <CheckBox
                  checked={checkboxitem?.checked}
                  onChange={() => {
                    handleCheckedItems(checkboxitem?.id, checkboxitem?.sectionID);
                    setIsSaveButtonEnabled(true);
                  }}
                  data-testId={`${checkboxitem?.id}-${checkboxindex}-${checkboxitem?.sectionID}`}
                  checkboxName={checkboxitem?.value}
                  checkboxId={`${checkboxitem?.id}-${checkboxindex}-${checkboxitem?.sectionID}`}
                  className="mt-3 pt-1"
                  checkboxLabel={checkboxitem?.value}
                  ariaLabel={`${checkboxitem?.id}-${checkboxindex}-${checkboxitem?.sectionID}`}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
