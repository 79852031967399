// Styles for PointsTransfer go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledPointsTransfer = styled.div`
  .points-transfer {
    margin-bottom: ${toRem(64)} !important;
    @media ${baseVariables.mediaQuery.md} {
      margin-top: ${toRem(56)} !important;
    }
  }
`;
