import clsx from 'clsx';
import { Types, Image, Button } from '@marriott/mi-ui-library';
import { addSubDirectoryPrefix, truncateText, useCheckBreakpoint, generateAEMImageRenditions } from '../../../modules';
import { PROMOTIONS_CONSTANT } from '../../../modules/utils/constants/constants';
import { StyledPromotionBannerContainer, StyledPromotionBannerImage } from './BannerContent.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BannerContent = (props: any) => {
  const { model, promotionDetail, registerPromotion, isLoggedIn } = props;
  const promotionInfo = isLoggedIn ? promotionDetail?.loyaltyPromotion : promotionDetail?.promotion;
  const isViewportL = useCheckBreakpoint('viewportL');
  const isViewportS = useCheckBreakpoint('viewportS');

  return (
    <StyledPromotionBannerContainer>
      <StyledPromotionBannerImage>
        {isViewportS ? (
          <Image
            customClass="pb__img img-desktop"
            dynamic={true}
            loading={'eager'}
            defaultImageURL={promotionInfo?.media?.images?.[1]?.url}
            renditions={generateAEMImageRenditions(promotionInfo?.media?.images?.[1]?.url)}
            altText={promotionInfo?.media?.images?.[1]?.alternateText}
          />
        ) : (
          <Image
            customClass="pb__img img-mobile"
            dynamic={true}
            loading={'eager'}
            defaultImageURL={promotionInfo?.media?.images?.[0]?.url}
            renditions={generateAEMImageRenditions(promotionInfo?.media?.images?.[0]?.url)}
            altText={promotionInfo?.media?.images?.[0]?.alternateText}
          />
        )}
      </StyledPromotionBannerImage>

      <div className="pb__cnt-sec pb__scrim">
        <div className="container pb__cnt-ctr hero-2x d-flex flex-column-reverse">
          <div>
            {isLoggedIn ? (
              PROMOTIONS_CONSTANT.NEW_PROMOTIONS_STATE_CODES.includes(promotionInfo?.state?.code) ? (
                <Button
                  type={Types.ButtonTypeVariation.Button}
                  buttonCopy={model?.registerCtaLabel}
                  callback={registerPromotion}
                  className={clsx(
                    isViewportL ? 'm-button-m' : 'm-button-s',
                    'm-button-primary color-scheme2 t-background-color pb__btn'
                  )}
                />
              ) : (
                <Button
                  type={Types.ButtonTypeVariation.Button}
                  buttonCopy={model?.bookNowCtaLabel}
                  className={clsx(
                    isViewportL ? 'm-button-m' : 'm-button-s',
                    'm-button-primary color-scheme2 t-background-color pb__btn text-center'
                  )}
                  isLink={true}
                  target={'_self'}
                  href={`${addSubDirectoryPrefix(model?.bookNowCtaPath)}`}
                  custom_click_track_value=""
                />
              )
            ) : (
              <>
                <Button
                  type={Types.ButtonTypeVariation.Button}
                  buttonCopy={model?.joinAndRegisterCtaLabel}
                  href={`${addSubDirectoryPrefix(model?.joinAndRegisterCtaPath)?.replace('{0}', promotionInfo?.id)}`}
                  isLink={true}
                  target={'_self'}
                  className={clsx(
                    isViewportL ? 'm-button-m' : 'm-button-s',
                    'm-button-primary color-scheme2 t-background-color pb__btn text-center'
                  )}
                  custom_click_track_value=""
                />
                <Button
                  type={Types.ButtonTypeVariation.Button}
                  buttonCopy={model?.signInLabel}
                  className={clsx(
                    isViewportL ? 'm-button-m' : 'm-button-s',
                    'm-button-primary ml-3 text-center pb__btn pb__signin'
                  )}
                  target={'_self'}
                  isLink={true}
                  href={`${addSubDirectoryPrefix(model?.signInCtaPath)?.replace('{0}', promotionInfo?.id)}`}
                  custom_click_track_value=""
                />
              </>
            )}
          </div>
          <div className="pb__img-header col-12 col-sm-7 col-xl-6">
            {truncateText(
              promotionInfo?.descriptions?.name || promotionInfo?.offers?.[0]?.descriptions?.name || '',
              PROMOTIONS_CONSTANT.BANNER_TITLE_CHARACTER_LIMIT
            )}
          </div>
        </div>
      </div>
    </StyledPromotionBannerContainer>
  );
};
