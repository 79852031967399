import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { Button } from '@marriott/mi-ui-library';
// TODO: adding types post implementation
// import { ReportMissingStayRequestConfirmationProps } from './ReportMissingStayRequestConfirmation.types';
import { StyledReportMissingStayRequestConfirmation } from './ReportMissingStayRequestConfirmation.styles';

// TODO: adding custom props types for the Component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReportMissingStayRequestConfirmation = ({ model }: any) => {
  return (
    <StyledReportMissingStayRequestConfirmation
      className="color-scheme3 pt-4 px-3 px-md-0"
      data-component-name="o-account-missingstayrequestconfirmation"
      data-testid="missingstayrequestconfirmation"
    >
      <div className="container color-scheme1 request-confirmation rounded p-0">
        <div className="request-confirmation__heading t-subtitle-xl px-5 py-4">{model?.header}</div>
        <hr className="request-confirmation__line m-0" />
        <div className="request-confirmation__notification mx-5 mt-4">
          <div className="m-message-inline success col-12">
            <div className="m-message-content-wrap">
              <div className="m-message-content">
                <span>{model?.successBanner}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="request-confirmation__body px-5 py-4"
          dangerouslySetInnerHTML={{ __html: model?.informationSection ?? '' }}
        />
        <div className="px-5 pb-5 next-link-container">
          <Button
            href={model?.ctaPath}
            className="request-confirmation__next-link m-link-tertiary-button"
            isLink={true}
          >
            {model?.ctaLabel}&nbsp;
          </Button>
        </div>
      </div>
      <div className="container request-confirmation-link next-link-container mt-5">
        <Button
          href={model.secondaryCtaPath}
          className="request-confirmation__next-link m-link-back-tertiary-button mx-0"
          isLink={true}
        >
          &nbsp;
          {model.secondaryCtaLabel}
        </Button>
      </div>
    </StyledReportMissingStayRequestConfirmation>
  );
};

export const ReportMissingStayRequestConfirmationConfig = {
  emptyLabel: 'reportMissingStayRequestConfirmation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/missingstayrequestconfirmation`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReportMissingStayRequestConfirmationEditable = (props: any) => (
  <EditableComponent config={ReportMissingStayRequestConfirmationConfig} {...props}>
    <ReportMissingStayRequestConfirmation {...props} />
  </EditableComponent>
);
