import { accountConstants } from './constants';
export interface RenditionProps {
  renditionPath?: string;
  damPath?: string;
  mediaValue?: string;
  mediaQuery?: string;
  width?: number;
  height?: number;
}

export type imageDimensionsType = {
  width: number;
  height: number;
};
const outputQuality = 90;
const outputInterpolation = 'progressive-bilinear';

export const imageDimensionsSize = [576, 768, 992, 1140, 1420];
export const imageDimensionsSizeMobile = [440, 570, 769, 992];
/**
 * Construct a string with all arguments for image processing.
 */
export const getImgUrlQueryStringWithWidth = (width: number) => {
  // get image according to width cropping ratio accordingly handle depend on width
  return `?output-quality=${outputQuality}&interpolation=${outputInterpolation}&downsize=` + width + 'px:*';
};

export const generateImageRenditions = (
  src: string,
  imageSizeList: { imageWidth?: number; imageHeight?: number; breakpoint: string; queryType: string }[]
) => {
  return imageSizeList.map(rendition => {
    return {
      renditionPath: `${src}${accountConstants.DOWNSIZE_PARAM}${
        rendition?.imageWidth ? rendition?.imageWidth * 2 + 'px' : '*'
      }:${rendition?.imageHeight ? rendition?.imageHeight * 2 + 'px' : '*'}`,
      mediaValue: rendition.breakpoint,
      width: 0,
      dynamic: false,
      damPath: src,
      mediaQuery: rendition.queryType,
      height: 0,
    };
  });
};

export const generateAEMImageRenditions = (
  src: string,
  imageSizeList: Array<{ imageWidth: number; breakpoint: string; queryType: string }> = [
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.desktop,
      queryType: 'min-width',
      imageWidth: 1440,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.largerTablet,
      queryType: 'min-width',
      imageWidth: 1024,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.tablet,
      queryType: 'min-width',
      imageWidth: 992,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
      queryType: 'min-width',
      imageWidth: 680,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
      queryType: 'max-width',
      imageWidth: 480,
    },
  ]
) => {
  if (src) {
    return imageSizeList.map(rendition => {
      return {
        renditionPath: `${src}${accountConstants.DOWNSIZE_PARAM}${rendition?.imageWidth}px:* 1x,${src}${
          accountConstants.DOWNSIZE_PARAM
        }${rendition?.imageWidth * 2}px:* 2x`,
        mediaValue: rendition.breakpoint,
        width: 0,
        dynamic: false,
        damPath: src,
        mediaQuery: rendition.queryType,
        height: 0,
      };
    });
  }
  return [];
};

export const getImageRenditionsWithCropQueryString = (
  croppingRatio: number,
  outputQuality: number,
  outputInterpolation: string,
  imageDimensions: imageDimensionsType
) => {
  return (
    `?output-quality=${outputQuality}&interpolation=${outputInterpolation}&crop=${
      imageDimensions.width * croppingRatio
    }:${imageDimensions.height * croppingRatio};*,*&downsize=` +
    imageDimensions.width +
    'px:*'
  );
};

export const genrateImageRenditionPathForAllMobileAndTablet = (src: string) => {
  return imageDimensionsSizeMobile.map(key => {
    return {
      renditionPath: `${src}${getImgUrlQueryStringWithWidth(key)}`,
      mediaValue: `${key}px`,
      width: 0,
      dynamic: false,
      damPath: src,
      mediaQuery: 'max-width',
      height: 0,
    };
  });
};

export const addParamToRenditions = (renditions?: RenditionProps[]) => {
  return (
    renditions &&
    renditions.map(rendition => {
      const temRendition = rendition?.renditionPath?.split(',').map(path => `${path}&fmt=png-alpha`);
      return {
        ...rendition,
        renditionPath: temRendition?.join(', '),
      };
    })
  );
};
