// Styles for FeaturePreferences go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const StyledInterestsAndHobbiesModal = styled.div`
  ${rtl`
  > div[class*='Modal'] {
    &:before {
      position: fixed;
      @media ${baseVariables.mediaQuery.md} {
        right: ${toRem(4)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(16)};
      }
    }
  }
  .uxl-error-msg{
    width:100%;
  }
  .modal__container {
    max-width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(584)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(902)} !important;
    }
    &-content {
      .checkbox {
        white-space: nowrap;
        @media ${baseVariables.mediaQuery.lg} {
          white-space: pre-wrap !important;
        }
          
        label {
          font-size: ${toRem(16)};
          font-weight: ${baseVariables.font.fontWeightMedium};
        }
        &-consent {
          label {
            font-weight: ${constants.BOLD_FONT_WEIGHT};

            :is(${constants.RTL_LANG_LIST}) {
              padding-right: ${toRem(24)};
              
              ::before {
                left: unset;
                right: 0;
              }
              ::after {
                left: ${toRem(45)};
                right: ${toRem(6)};
                transform: rotateZ(45deg) !important;
              }  
            }
          }
        }
      }
      :is(${constants.RTL_LANG_LIST}) {
        text-align: right;
      }
    }
    &-accordion-food {
      width: 100%;
      .d-contents {
        display: contents;
      }
    }

    &-footer {
      display: flex;
      background-color: ${baseVariables.color['merch50']};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};

      .apply-button {
        width:auto;
      }

      &_radio {
        width: 100%;
        label {
          font-size: ${toRem(16)};
          font-weight: ${baseVariables.font.fontWeightMedium};
        }
      }
      .clear {
        margin-left: ${toRem(28)};
        @media ${baseVariables.mediaQuery.md} {
          margin-left: ${toRem(0)};
        }
        &:is(${constants.RTL_LANG_LIST}) {
          margin-left: ${toRem(28)};

          @media ${baseVariables.mediaQuery.md} {
            margin-left:: ${toRem(0)};
          }
        }
      }
    }
  }
  .accordion__list {
    margin-top: 0 !important;
    :is(${constants.RTL_LANG_LIST}) {
      .accordion-checkbox{
        label {
          padding-right: ${toRem(24)};

          ::before {
            left: unset;
            right: 0;
          }

          ::after {
            left: unset;
            right: ${toRem(6)};
            transform: rotateZ(45deg) !important;
          }
        }
      }
    }
  }
  .spa-golf-title {
    padding-bottom: ${toRem(16)};
  }
  .modal__container_radio {
    white-space: nowrap;
    :is(${constants.RTL_LANG_LIST}) {
      margin-right: 0 !important;
      padding-right: 0 !important;

      label {
        ::before {
          left: unset;
        }
        ::after {
          left: ${toRem(45)};
          right: ${toRem(6)};
        }
        span {
          margin-right: ${toRem(28)};
        }
      }
    }
  }

  .accordion__container:is(${constants.RTL_LANG_LIST}) {
    .accordion__icon {
      margin-left: unset !important;
    }
    .accordion__title {
      .icon-decorative {
        margin-left: ${toRem(16)};
        margin-right: 0 !important;
      }
    }
  }
`}
`;
