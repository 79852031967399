/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useContext, useRef } from 'react';
import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery } from '@apollo/client';
import { inspect } from 'util';
import clsx from 'clsx';
import { Messages, RichText } from '@marriott/mi-ui-library';
import {
  apiLogger,
  defaultValuesetActivityFilter,
  defaultValuesetDurationFilter,
  DEFAULT_VALUE_SET_RECENT_DURATION_FILTER,
  defaultPageLimitActivity,
  ACTIVITY_COUNT,
  AllLocalesKeysListConstant,
  OverviewMovableComponents,
  getCalednarLocaleKeys,
  OVERVIEW_CONSTANT,
} from '../../modules/utils';
import { useCheckBreakpoint } from '../../modules/hooks';
import { PageContext } from '../../modules/context/PageContext';
import { generateApolloClientHeaders, convertDateToLocaleSpecific } from '../../modules/utils/helper';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { DropdownOption } from '../../molecules/Dropdown/Dropdown.types';
import { phoenixAccountGetMyActivityTable } from '../../modules/graph/index';
import { useOverviewStore } from '../../modules/store/overviewStore';
import { createRows } from './utils/MyActivityUtils';
import StyledMyActivity from './MyActivity.styles';
import { MyActivityProps, activityInformationDetails, Amenity, TimeLimit } from './MyActivity.types';
import activityMockdata from './__mock__/activity.json';

const ColumnTable = dynamic(() => import('../../molecules/ColumnTable').then(mod => mod.ColumnTable));
const OverviewSectionHeading = dynamic(() =>
  import('../../molecules/OverviewSectionHeading').then(mod => mod.OverviewSectionHeading)
);
const PaginationWithPageSize = dynamic(() =>
  import('../../molecules/PaginationWithPageSize').then(mod => mod.PaginationWithPageSize)
);
const FilterActivity = dynamic(() => import('./FilterActivity').then(mod => mod.FilterActivity));
const NoActivity = dynamic(() => import('./NoActivity').then(mod => mod.NoActivity));

export const MyActivity: React.FC<MyActivityProps> = (props: any) => {
  const { IS_LOCAL_DEV } = process.env;
  const [activityDetails, setActivityDetails] = useState<activityInformationDetails>();
  const durationFilter = props?.model?.durationFilter;
  const filterLabel = props?.model?.filterLabel;
  const activityFilter = props?.model?.activityFilter;
  const activityPagination = props?.model?.activityPagination;
  const pageContext = useContext(PageContext);
  const isOverViewPage = pageContext?.isOverViewPage;
  const { memberData } = useAccountPersistentStore(store => store);
  const memberEliteLevel = memberData?.customer?.loyaltyInformation?.rewards?.eliteLifetimeLevel?.code;

  const [selectedActivityValue, setSelectedActivityValue] = useState<DropdownOption>(() => {
    // Set default value for activityFilter
    const initialId = defaultValuesetActivityFilter;
    return { value: findLabelForValue(initialId, activityFilter)?.label, id: initialId };
  });
  const [selectedDurationValue, setSelectedDurationValue] = useState<DropdownOption>(() => {
    // Set default value for durationFilter
    const initialId = isOverViewPage ? DEFAULT_VALUE_SET_RECENT_DURATION_FILTER : defaultValuesetDurationFilter;
    return { value: findLabelForValue(initialId, durationFilter)?.label, id: initialId };
  });
  const [pageSize, setPageSize] = useState<DropdownOption>(() => {
    // default page size 10
    const initialId = defaultPageLimitActivity;
    return { value: findLabelForValue(initialId, activityPagination)?.value, id: initialId };
  });
  const isMobileViewPortAbove = useCheckBreakpoint('viewportS');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterLoader, setFilterLoader] = useState(true);
  const dataLoaded = useRef<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const isAuthorMode = props?.isAuthorMode;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const currentLocale = pageContext?.currentLocale ?? 'en-US';
  const validityIdItems = ['ALL_ADDITIONS', 'ALL_DEDUCTIONS', 'ALL_QUALIFYING'];
  const isEnLocale = currentLocale?.replace('_', '-') === AllLocalesKeysListConstant.en;
  const { componentMoved, setComponentHasData, setComponentHasError, setComponentIsLoading } = useOverviewStore(
    state => state
  );
  const activitiesMoved = componentMoved === OverviewMovableComponents.RECENT_ACTIVITIES;
  getCalednarLocaleKeys(props?.model); // to initialize moment with the locale date object

  //function for setting deafult value for filter activity
  function findLabelForValue(initialId: string, dataArray: any) {
    if (dataArray) {
      const foundObject = dataArray.find((item: any) => item?.id.toLowerCase() === initialId.toLowerCase());
      return foundObject;
    }
    return '';
  }
  const getSelectedActivityId = (activityOption: DropdownOption) => {
    return activityOption.id ?? '';
  };
  useEffect(() => {
    if (isAuthorMode) {
      setActivityDetails(activityMockdata.data);
      setFilterLoader(false);
      isOverViewPage &&
        setComponentHasData(
          OverviewMovableComponents.RECENT_ACTIVITIES,
          activityMockdata.data?.customer?.loyaltyInformation?.accountActivity?.edges?.length > 0
        );
    }
  }, []);

  useEffect(() => {
    if (!isAuthorMode && sessionData?.consumerID) {
      refetchActivity({
        variables: {
          customerId: sessionData?.consumerID,
          //As per Aries we are, we are displaying activity table data for selected month plus 1
          numberOfMonths: Number(selectedDurationValue?.id) + 1,
          types: getSelectedActivityId(selectedActivityValue),
          limit: isOverViewPage ? ACTIVITY_COUNT : Number(pageSize?.value),
          offset: Number(pageSize?.value) * (currentPage - 1),
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      });
    }
  }, [sessionData?.consumerID]);

  // ***********UXL***********
  const [refetchActivity, { loading: activityTableLoading, error: activityTableError, data: activityTableData }] =
    useLazyQuery(phoenixAccountGetMyActivityTable);

  useEffect(() => {
    if (activityTableLoading) {
      isOverViewPage && setComponentIsLoading(OverviewMovableComponents.RECENT_ACTIVITIES, true);
    }
  }, [activityTableLoading]);

  useEffect(() => {
    if (activityTableError) {
      isOverViewPage && setComponentHasError(OverviewMovableComponents.RECENT_ACTIVITIES, true);
    }
  }, [activityTableError]);

  useEffect(() => {
    if (activityTableData) {
      dataLoaded.current = true;
      setActivityDetails(activityTableData);
      setFilterLoader(false);
      isOverViewPage &&
        setComponentHasData(
          OverviewMovableComponents.RECENT_ACTIVITIES,
          activityTableData?.customer?.loyaltyInformation?.accountActivity?.edges?.length > 0
        );
      apiLogger(
        `[MyActivity] getSavedPropertiesData - sessionId: ${sessionData?.sessionToken}: ${inspect(activityTableData)}`
      );
    }
  }, [activityTableData]);

  // **********component handler***************
  const activityData = activityFilter?.map((amenity: Amenity) => ({
    value: amenity?.label,
    id: amenity?.id,
  }));
  const durationData = durationFilter?.map((timelimit: TimeLimit) => ({
    value: timelimit?.label,
    id: timelimit?.id,
  }));
  const onDurationFilterChange = (durationOption?: any) => {
    if (durationOption.id !== selectedDurationValue.id) {
      refetchActivity(buildQueryVariable(pageSize.value, 1, selectedActivityValue, durationOption.id));
      setSelectedDurationValue(durationOption);
      dataLoaded.current = false;
      scrollToActivityList();
    }
  };

  const onActvityFilterChange = (activityOption?: any) => {
    if (activityOption.id !== selectedActivityValue.id) {
      refetchActivity(buildQueryVariable(pageSize.value, 1, activityOption, selectedDurationValue.id));
      setSelectedActivityValue(activityOption);
      dataLoaded.current = false;
      scrollToActivityList();
    }
  };

  // ****************TableCondition********************
  // const getFilteredActivityData = (data: activityInformationDetails, activityData: DropdownOption) => {
  //TODO: will remove this code once activity is working fine
  //   if (data) {
  //     let edges = data?.customer?.loyaltyInformation?.accountActivity?.edges || [];
  //     if (activityData.id === 'types') return data;
  //     else if (activityData.id === 'qualifying') {
  //       edges = edges.filter((edge: any) => edge?.node?.isQualifyingActivity);
  //     } else if (activityData.id === 'additions') {
  //       edges = edges.filter((edge: any) => edge?.node?.totalEarning > 0);
  //     } else if (activityData.id === 'deductions') {
  //       edges = edges.filter((edge: any) => edge?.node?.totalEarning <= 0);
  //     }
  //     data.customer.loyaltyInformation.accountActivity.edges = edges;
  //   }
  //   return data;
  // };

  const buildQueryVariable = (
    size: string,
    currentPageNo: number,
    activityValue: DropdownOption,
    durationValue: string
  ) => {
    const activityFilterVal = activityValue.id;
    const isParamsRequiredFilterVal = validityIdItems.includes(activityFilterVal);
    return {
      variables: {
        customerId: sessionData?.consumerID,
        numberOfMonths: Number(durationValue) + 1,
        types: isParamsRequiredFilterVal ? defaultValuesetActivityFilter : activityFilterVal,
        limit: size?.toLowerCase() === 'all' ? null : Number(size),
        offset: size?.toLowerCase() === 'all' ? 0 : Number(size) * (currentPageNo - 1),
        filter: isParamsRequiredFilterVal
          ? {
              accountActivityType: activityFilterVal,
            }
          : null,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    };
  };

  // ************return statement*******************
  const qualifyingDateData = activityDetails?.customer?.loyaltyInformation?.rewards?.lastQualifyingActivityDate ?? '';
  const expiryDateData = activityDetails?.customer?.loyaltyInformation?.rewards?.datePointsExpire ?? '';
  const vistanaUserPointsExpired = activityDetails?.customer?.loyaltyInformation?.rewards?.vistanaPointsExpired;
  const isExempt = activityDetails?.customer?.loyaltyInformation?.rewards?.isExempt;
  const formatedexpiryDateData = convertDateToLocaleSpecific(expiryDateData, currentLocale);
  const formatedqualifyingDateData = convertDateToLocaleSpecific(qualifyingDateData, currentLocale);
  const isLifetimeTitaniumOrPlatinumMember =
    OVERVIEW_CONSTANT.PLATINUM_LIFETIME === memberEliteLevel ||
    OVERVIEW_CONSTANT.TITANIUM_LIFETIME === memberEliteLevel;

  const activityMessage = `${props?.model?.activityMessage
    ?.replace('{expiryDate}', formatedexpiryDateData)
    ?.replace('{postDate}', formatedqualifyingDateData)}`;
  const beforeExpiry = `${props?.model?.beforeExpiryTreatment
    ?.replace('{expiryDate}', formatedexpiryDateData)
    ?.replace('{postDate}', formatedqualifyingDateData)}`;
  const expiryMessage = `${props?.model?.pointsExpiredTreatment?.replace('{expiryDate}', formatedexpiryDateData)}`;
  const hasActivityData =
    activityDetails && activityDetails?.customer?.loyaltyInformation?.accountActivity?.edges?.length > 0;
  const vistanaMessage = `${props?.model?.activityMessageVistanaMembership?.replace(
    '{postDate}',
    formatedqualifyingDateData
  )}`;
  const totalActivitiesCount = activityDetails?.customer?.loyaltyInformation?.accountActivity?.total;

  const userTotalActivitiesCountPeriod = activityDetails?.customer?.loyaltyInformation?.accountActivity?.total;

  const scrollToActivityList = () => {
    const activityDiv = window?.document?.getElementById(OverviewMovableComponents.RECENT_ACTIVITIES)?.offsetTop;
    window?.scrollTo({ top: activityDiv ?? 100, behavior: 'smooth' });
  };
  // ************return statement*******************

  if (activityTableError) {
    apiLogger(
      `[MyActivity] getSavedPropertiesData - sessionId: ${sessionData?.sessionToken} - error: ${inspect(
        activityTableError
      )}`
    );
    return (
      <div id={OverviewMovableComponents.RECENT_ACTIVITIES}>
        <Messages messageType="warning" className="my-4">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      </div>
    );
  }

  return (
    <StyledMyActivity
      data-component-name="o-account-myActivity"
      data-testid="myActivity"
      id={OverviewMovableComponents.RECENT_ACTIVITIES}
      className={clsx(isOverViewPage && componentMoved && hasActivityData && 'pb-3')}
    >
      {!isOverViewPage ? (
        <>
          {' '}
          {filterLoader ? (
            <div className="container">
              <div className="skeleton-loader my-3 skeleton-style"></div>
              <div className="skeleton-loader my-3 skeleton-style"></div>
              <div className="skeleton-loader col-lg-4 col-12 skeleton-style"></div>
            </div>
          ) : (
            <div className="container filterSelect d-flex align-items-center">
              <FilterActivity
                currentLocale={currentLocale}
                expiryDate={expiryDateData}
                label={filterLabel}
                vistanaUserPointsExpired={vistanaUserPointsExpired}
                durationFilter={durationData}
                activityFilter={activityData}
                expiryMessage={expiryMessage}
                vistanaMessage={vistanaMessage}
                beforeExpiry={beforeExpiry}
                activityMessage={activityMessage}
                onDurationFilterChange={onDurationFilterChange}
                onActvityFilterChange={onActvityFilterChange}
                defaultDurationOption={selectedDurationValue.value}
                defaultActivityOption={selectedActivityValue.value}
                props={props?.model}
                lastQualifyingActivityDate={qualifyingDateData}
                isExempt={isExempt}
                isLifetimeTitaniumOrPlatinumMember={isLifetimeTitaniumOrPlatinumMember}
                disableDropdown={activityTableLoading ? true : false}
              />
            </div>
          )}
        </>
      ) : (
        hasActivityData &&
        ((componentMoved && !activitiesMoved) || !isMobileViewPortAbove) && (
          <OverviewSectionHeading
            title={props?.model?.recentActivitySectionHeader}
            ctaLabel={props?.model?.viewMyActivityCtaLabel}
            ctaPath={props?.model?.viewMyActivityCtaPath}
            sectionHeadingClass={'mb-3 pt-4 pt-md-5 mt-md-2 container'}
          />
        )
      )}
      {(!isOverViewPage || (isOverViewPage && componentMoved)) && (
        <div>
          {!activityDetails || activityTableLoading ? (
            <div>
              <StyledMyActivity>
                <div className="container">
                  <div className="skeleton-loader my-3 skeleton-style skeleton-style-table-loader"></div>
                </div>
              </StyledMyActivity>
            </div>
          ) : hasActivityData ? (
            <>
              <div className={clsx(activitiesMoved && 'container')}>
                <div className={clsx(activitiesMoved && 'table-responsive mt-4')}>
                  {isOverViewPage && activitiesMoved && isMobileViewPortAbove && (
                    <>
                      <OverviewSectionHeading
                        title={props?.model?.recentActivitySectionHeader}
                        ctaLabel={props?.model?.viewMyActivityCtaLabel}
                        ctaPath={props?.model?.viewMyActivityCtaPath}
                        sectionHeadingClass={'px-3 px-md-4 px-lg-5 py-3'}
                      />
                      <div className="border-line" />
                    </>
                  )}
                  <ColumnTable
                    columns={[
                      props?.model?.firstColumnHeader,
                      props?.model?.secondColumnHeader,
                      props?.model?.thirdColumnHeader,
                      props?.model?.fourthColumnHeader,
                      props?.model?.fifthColumnHeader,
                    ]}
                    rows={createRows(
                      activityDetails?.customer?.loyaltyInformation?.accountActivity?.edges,
                      currentLocale,
                      activityDetails?.customer?.loyaltyInformation?.rewards?.number,
                      props?.model,
                      activitiesMoved
                    )}
                    columnClasses={[
                      `${!activitiesMoved && 'col-lg-2'} col-6`,
                      `${!activitiesMoved && 'col-lg-2'} col-6`,
                      `${!activitiesMoved && 'col-lg-4'} col-8 pt-3 px-md-0`,
                      `${!activitiesMoved && 'col-lg-2'} col-4 pt-3`,
                      `${!activitiesMoved && 'col-lg-2'} col-md pt-3`,
                    ]}
                    showColumnHeader={!isOverViewPage ? true : false}
                    overviewComponentMoved={activitiesMoved}
                  />
                </div>
              </div>
              {!isOverViewPage && (
                <PaginationWithPageSize
                  customPaginationClass="activity-pagination"
                  viewPerPageLabel={props.viewPerPageLabel}
                  enteriesLabel={`${totalActivitiesCount} ${props.entriesLabel}`}
                  currentPage={currentPage}
                  totalCount={userTotalActivitiesCountPeriod ?? 0}
                  pageSize={pageSize}
                  onPageChange={(page: number): void => {
                    dataLoaded.current = false;
                    setCurrentPage(page);
                    setActivityDetails(undefined);
                    scrollToActivityList();
                    refetchActivity(
                      buildQueryVariable(pageSize.value, page, selectedActivityValue, selectedDurationValue.id)
                    );
                    tableRef?.current?.scrollIntoView &&
                      tableRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                  showViewPerPage={true}
                  showTotalEntries={true}
                  pageSizeOptions={activityPagination}
                  onPageSizeChange={size => {
                    dataLoaded.current = false;
                    setPageSize(size);
                    setCurrentPage(1);
                    setActivityDetails(undefined);
                    scrollToActivityList();
                    refetchActivity(buildQueryVariable(size.id, 1, selectedActivityValue, selectedDurationValue.id));
                    tableRef?.current?.scrollIntoView &&
                      tableRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                />
              )}
            </>
          ) : (
            !isOverViewPage && (
              <NoActivity
                text={props?.description}
                buttonLink={isEnLocale ? props?.noActivityCtaPath : props?.noActivityCtaPathOthers}
                buttonTitle={props?.noActivityCtaLabel}
              />
            )
          )}
        </div>
      )}
    </StyledMyActivity>
  );
};
export const MyActivityConfig = {
  emptyLabel: 'myActvity',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/myActvity`,
};
export const MyActivityEditable = (props: any) => {
  return (
    <EditableComponent config={MyActivityConfig} {...props}>
      <MyActivity {...props} />
    </EditableComponent>
  );
};
