/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

import { RootPromotions } from '../../organisms/NewPromotions/NewPromotions.types';
import { PromotionDetailsModel } from '../../organisms/PromotionDetails/PromotionDetails.types';
import { TripDetailsProps } from '../../organisms/UpcomingTrips/TripDetails/TripDetails.types';

export type uxlError = {
  component: string;
  showError: boolean;
};
interface memberLevelState {
  showOnboardingSection: boolean;
  setShowOnboardingSection: (show: boolean) => void;
  showFindReservationModal: boolean;
  setShowFindReservationModal: (show: boolean) => void;
  isUpcomingTripSelected: boolean;
  setTripData: (data: any) => void;
  isPromotionLoading: boolean;
  setIsPromotionLoading: (loading: boolean) => void;
  setPromotions: (data: RootPromotions) => void;
  promotions: RootPromotions | null;
  promotionDetails: PromotionDetailsModel | null;
  isPromotionDetailsLoading: boolean;
  setIsPromotionDetailsLoading: (loading: boolean) => void;
  setPromotionDetails: (data: PromotionDetailsModel) => void;
  uxlError?: uxlError;
  setUxlError: (error: uxlError) => void;
  isNightsModelOpen: boolean;
  setIsNightsModelOpen: (open: boolean) => void;
  cancelModal: {
    showCancelReservation: boolean;
    setShowCancelReservation: (show: boolean) => void;
    canceledReservations: TripDetailsProps | null;
    setCanceledReservations: (data: TripDetailsProps) => void;
    confirmationNumber: string | null;
    setConfirmationNumber: (value: string) => void;
    confirmationId: string | null;
    setConfirmationId: (value: string) => void;
  };
  isForgotPasswordSuccessEaa: boolean;
  setIsForgotPasswordSuccessEaa: (data: boolean) => void;
  showCustomMessage: boolean;
  setShowCustomMessage: (data: boolean) => void;
}
const initialState: memberLevelState = {
  showOnboardingSection: false,
  setShowOnboardingSection: () => {},
  showFindReservationModal: false,
  setShowFindReservationModal: () => {},
  isUpcomingTripSelected: true,
  setTripData: () => {},
  isPromotionLoading: false,
  promotions: null,
  promotionDetails: null,
  isPromotionDetailsLoading: true,
  setPromotionDetails: () => {},
  setIsPromotionDetailsLoading: () => {},
  setPromotions: () => {},
  setIsPromotionLoading: () => {},
  uxlError: {
    component: '',
    showError: false,
  },
  setUxlError: () => {},
  isNightsModelOpen: false,
  setIsNightsModelOpen: () => {},
  cancelModal: {
    showCancelReservation: false,
    setShowCancelReservation: () => {},
    canceledReservations: null,
    setCanceledReservations: () => {},
    confirmationNumber: null,
    setConfirmationNumber: () => {},
    confirmationId: null,
    setConfirmationId: () => {},
  },
  isForgotPasswordSuccessEaa: false,
  setIsForgotPasswordSuccessEaa: () => {},
  showCustomMessage: false,
  setShowCustomMessage: () => {},
};

const memberLevelStore: StateCreator<memberLevelState> = set => {
  return {
    ...initialState,
    setShowOnboardingSection: show => {
      set({ showOnboardingSection: show });
    },
    setShowFindReservationModal: show => {
      set({ showFindReservationModal: show });
    },
    setTripData: data => {
      set({ isUpcomingTripSelected: data });
    },
    setPromotions: (data: RootPromotions) => {
      set({ promotions: data });
    },
    setPromotionDetails: (data: PromotionDetailsModel) => {
      set({ promotionDetails: data });
    },
    setIsPromotionDetailsLoading: (loading: boolean) => {
      set({ isPromotionDetailsLoading: loading });
    },
    setIsPromotionLoading: (loading: boolean) => {
      set({ isPromotionLoading: loading });
    },
    setUxlError: (uxlError: uxlError) => {
      set({ uxlError: uxlError });
    },
    setIsNightsModelOpen: (open: boolean) => {
      set({ isNightsModelOpen: open });
    },
    cancelModal: {
      ...initialState.cancelModal,
      setShowCancelReservation: show => {
        set(state => ({ cancelModal: { ...state.cancelModal, showCancelReservation: show } }));
      },
      setCanceledReservations: (data: any) => {
        set(state => ({ cancelModal: { ...state.cancelModal, canceledReservations: data } }));
      },
      setConfirmationNumber: confirmationNumber => {
        set(state => ({ cancelModal: { ...state.cancelModal, confirmationNumber } }));
      },
      setConfirmationId: confirmationId => {
        set(state => ({ cancelModal: { ...state.cancelModal, confirmationId } }));
      },
    },
    setIsForgotPasswordSuccessEaa: (data: boolean) => {
      set({ isForgotPasswordSuccessEaa: data });
    },
    setShowCustomMessage: (data: boolean) => {
      set({ showCustomMessage: data });
    },
  };
};

export const useStore = createAppStore(memberLevelStore, {
  usePersistentStore: false,
});
