/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { NightsDetailProps } from './NightsDetail.types';
import { StyledNightsDetail } from './NightsDetail.styles';
import { useStore } from '../../modules/store/memberLevelStore';
import { AccountPersistentState, useAccountPersistentStore } from '../../modules/store/accountPersistentStore';

const NightDetailModal = dynamic(() => import('./NightDetailModal/NightDetailModal').then(mod => mod.NightDetailModal));

// Use named rather than default exports.
export const NightsDetail: FC<NightsDetailProps> = (pageProps: any) => {
  const model = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const { isNightsModelOpen, setIsNightsModelOpen } = useStore(store => store);
  const {
    memberLevelCode,
    memberLevelTypeCode,
    memberName,
    currentPointBalance,
    totalNightCount,
    memberData,
    memberLevel,
  } = useAccountPersistentStore(store => store);
  const [memberDataState, setMemberDataState] = useState<AccountPersistentState>();

  useEffect(() => {
    setMemberDataState({
      memberLevelCode: memberLevelCode,
      memberLevelTypeCode: memberLevelTypeCode,
      memberName: memberName,
      currentPointBalance: currentPointBalance,
      totalNightCount: totalNightCount,
      memberData: memberData,
      memberLevel: memberLevel,
    });
  }, [memberLevelCode, memberLevelTypeCode, memberName, currentPointBalance, totalNightCount, memberData, memberLevel]);
  return (
    <StyledNightsDetail data-component-name="o-account-nightsdetail" data-testid="nightsdetail">
      <NightDetailModal
        open={isNightsModelOpen}
        setNightModelOpen={setIsNightsModelOpen}
        isAuthorMode={isAuthorMode}
        model={model}
        customerData={memberDataState?.memberData?.customer}
        memberLevel={memberDataState?.memberLevel}
      />
    </StyledNightsDetail>
  );
};
export const NightsDetailConfig = {
  emptyLabel: 'NightsDetail',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/nightsdetail`,
};
export const NightsDetailEditable = (props: any) => {
  return (
    <EditableComponent config={NightsDetailConfig} {...props}>
      <NightsDetail {...props} />
    </EditableComponent>
  );
};
