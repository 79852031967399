// Styles for NightsDetail go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';

import { toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledNightsDetail = styled.div`
  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: justify;
      .m-link-tertiary-button-inverse, .m-link-tertiary-button{
        margin-left: ${toRem(27)} !important;
        margin-right: ${toRem(0)} !important;
      }
      .icon-lifetime {
        margin-left: ${toRem(8)} !important;
      }
      .icon-arrow-down,
      .icon-arrow-up {
        margin-right: ${toRem(4)}!important;
      }
    }
  `}
`;
