// Styles for CreateAccount go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';
const { lg, md } = { ...baseVariables.mediaQuery };

export const StyledEditPersonalInformation = styled.div`
  ${rtl`
  .label, .date-label {
    z-index: 1;
  }
  .dropdown {
    width: 100%;
    padding: ${toRem(12)} ${toRem(
    16
  )} !important; //important is given to override the dropdown css property to make it aligned with text box
    border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
    border-color: ${baseVariables.color['neutral40']} !important;
    height: ${toRem(44.67)};
    .icon-dropdown-down,
    .icon-dropdown-up {
      position: absolute;
      right: ${toRem(8)};
    }
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: ${toRem(224)};
    }
  }
  .datepicker-inner {
    padding-left: 0 !important;
    padding-right: 0;
  }
  .item {
    flex: 1;
  }
  .spacing {
    gap: ${toRem(32)};
    @media ${md} {
      gap: ${toRem(16)};
    }
  }
  .modal__container-footer .apply-button {
    width: auto;
  }
  @media ${md} {
    .submit-btn {
      width: auto !important;
    }
    .w-china {
      width: 50% !important;
    }
    .w-intl {
      width: 50% !important;
    }
  }
  @media ${lg} {
    .submit-btn {
      width: auto !important;
    }
  }
  .icon-calendar {
    position: absolute;
    right: ${toRem(16)};
    top: ${toRem(12)};
    cursor: pointer;
  }
  .validation-msg {
    color: ${baseVariables.color['alert50']};
  }
  .submit-btn {
    width: 100%;
  }
  .w-100 {
    width: 100%;
  }
  .icon-color {
    color: ${baseVariables.color['bvyAccent10']};
  }
  .icon-disabled {
    pointer-events: none;
  }
  .separator {
    width: 100vw;
    margin-left: calc(-54vw + 100%);
  }

  .modal__container-footer {
    background-color: ${baseVariables.color['merch50']};
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral00};
  }
  .w-china {
    width: 100%;
  }
  .w-intl {
    width: 100%;
  }
  .submit-btn-footer {
    text-decoration: underline;
     margin-left: ${toRem(28)};
     @media ${baseVariables.mediaQuery.md} {
          margin-left: ${toRem(0)};
      }
      &:is(${constants.RTL_LANG_LIST}){
        margin-right: ${toRem(28)};
        @media ${baseVariables.mediaQuery.md} {
          margin-right: ${toRem(0)};
        }
      }
  }
  .modal__container {
    max-width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(598)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(754)} !important;
    }

    &-footer {
      background-color: ${baseVariables.color['merch50']};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
    }
    &__fullname{
      text-transform: capitalize;
    }
  }
  .calendar-wrapper {
    border-radius: ${toRem(12)};
    box-shadow: ${baseVariables.shadows.shadowSubtle04};
    position: absolute;
    top: 100%;
    z-index: 2;
    background-color: ${baseVariables.color['merch50']};
  }
  .numberfieldwidth {
    max-width: 90%;
  }
  input[type='text'] {
    caret-color: ${baseVariables.color['base10']};
  }
  .text_modal-align:is(${constants.RTL_LANG_LIST}){
    text-align: right;
  }
  .icon-calendar:is(${constants.RTL_LANG_LIST}){
    right:unset;
    left: ${toRem(16)};
  }
  .m-input-field:is(${constants.RTL_LANG_LIST}) {
    label{
      left: unset;
      right:${toRem(16)};
    }
    input{
      padding-right: ${toRem(16)};
    }
    .dropdown{
      .icon-dropdown-down,
      .icon-dropdown-up{
        left: ${toRem(8)};
        right: unset; 
      }
    }
  }
  .personal-info-radiobtn:is(${constants.RTL_LANG_LIST}) {
    label{
      padding-right: ${toRem(24)} !important;
      ::before {
        left: unset;
        right: 0;
        display: flex;
      }
      ::after {
        left: unset;
        right: ${toRem(6)};
      }
      h6 {
        padding-right: ${toRem(32)};
      }
    }
  }
  .date-format-indicator {
    p {
      margin-bottom: 0; //to override p tag styles inside rte
    }
  }
`}
`;
