// Styles for FindReservationOverlay go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledFindReservationOverlay = styled.div`
  .modal-main-container {
    &__content {
      height: calc(100% - ${toRem(64)});
      overflow-y: auto;
      @media ${baseVariables.mediaQuery.md} {
        height: fit-content;
      }
    }
  }
  .modal-header {
    .popup-close {
      max-height: ${toRem(32)};
      max-width: ${toRem(32)};
    }
  }

  .modal-body {
    max-height: none;
  }
`;
