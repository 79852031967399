// Imports for external libraries go here.
import React, { FC, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { inspect } from 'util';
import { useLazyQuery } from '@apollo/client';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { usePointsTransferStore } from '../../modules/store/pointsTransferStore';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { phoenixAccountGetuserCurrentPointBalance } from '../../modules/graph';
import { PageContext } from '../../modules/context/PageContext';
import {
  apiLogger,
  ENCRYPTED_VERIFY_DATA,
  generateApolloClientHeaders,
  POINTS_TRANSFER_TYPE,
} from '../../modules/utils';
import { VerifyOrSuccessTransferPoints } from '../../molecules/VerifyOrSuccessTransferPoints';
import { PointsTransferProps, TransferPointsDetails } from './PointsTransfer.types';
import { StyledPointsTransfer } from './PointsTransfer.styles';
import { TransferPartnerPoints } from './TransferPartnerPoints/TransferPartnerPoints';
import { PointsToMiles } from './PointsToMiles';
import { MemberToMember } from './MemberToMember';

// Use named rather than default exports.
export const PointsTransfer: FC<PointsTransferProps> = pageProps => {
  const autherModel = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { FREQUENT_FLYER, CONVERT_POINTS, MEMBER_TO_MEMBER } = POINTS_TRANSFER_TYPE;
  const isPointsToMiles = autherModel?.typeOfPointsTransfer === FREQUENT_FLYER;
  const initialTransferPointsDetails = {
    partnerId: '',
    partnerName: '',
    partnerNumber: '',
    pointsToTransfer: '',
    isVerifyConvertPoints: false,
    partnerConfig: undefined,
  };
  const [transferPointsDetails, setTransferPointsDetails] =
    useState<TransferPointsDetails>(initialTransferPointsDetails);

  const initalVerificationStatus = {
    isVerificationPointsSuccess: pageContext?.isPointsTransferSuccess,
    isVerificationPointsFailed: false,
  };
  const [verificationStatus, setVerificationStatus] = useState(initalVerificationStatus);

  const {
    isVerificationPointsSuccess,
    isVerificationPointsFailed,
    isResetState,
    setVerificationPointsFailed,
    setVerificationPointsSuccess,
    setIsResetState,
  } = usePointsTransferStore(store => store);
  const { currentPointBalance, setCurrentPointsBalance } = useAccountPersistentStore(state => state);

  useEffect(() => {
    if (isVerificationPointsFailed) {
      setVerificationStatus({
        isVerificationPointsSuccess: isVerificationPointsSuccess,
        isVerificationPointsFailed: isVerificationPointsFailed,
      });
    }
  }, [isVerificationPointsFailed]); //dependency is required to hide the landing page, once we get error on submit, as there is no reload in non mfa flow for error scenario

  useEffect(() => {
    if (isResetState) {
      setVerificationStatus({
        isVerificationPointsSuccess: false,
        isVerificationPointsFailed: false,
      });
      setVerificationPointsSuccess(false);
      setVerificationPointsFailed(false);
      setIsResetState(false);
    }
  }, [isResetState]);

  useEffect(() => {
    if (!isAuthorMode) {
      if (!currentPointBalance) {
        getCurrentPointsBalance();
      }
    } else {
      // to remove else
      setCurrentPointsBalance && setCurrentPointsBalance(9999999);
    }
    window?.sessionStorage?.removeItem(ENCRYPTED_VERIFY_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // *************** UXL ****************************

  const [getCurrentPointsBalance] = useLazyQuery(phoenixAccountGetuserCurrentPointBalance, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setCurrentPointsBalance?.(data?.customer?.loyaltyInformation?.rewards?.currentPointBalance);
      apiLogger(`[PointsTransfer] getCurrentPointsBalance - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`);
    },
    onError: error => {
      apiLogger(
        `[PointsTransfer] getCurrentPointsBalance - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    },
  });

  return (
    <StyledPointsTransfer>
      {!verificationStatus?.isVerificationPointsFailed && (
        <div className={clsx('container points-transfer mt-5', isPointsToMiles && 'px-auto px-md-0')}>
          {autherModel?.typeOfPointsTransfer === CONVERT_POINTS ||
          autherModel?.typeOfPointsTransfer === FREQUENT_FLYER ? (
            transferPointsDetails?.isVerifyConvertPoints ? (
              <VerifyOrSuccessTransferPoints
                headerLabel={autherModel?.confirmHeader}
                subHeaderLabel={autherModel?.confirmSubHeader}
                sectionHeaderLabel={autherModel?.confirmSectionHeader}
                accountNameLabel={isPointsToMiles ? autherModel?.frequentFlyerProgram ?? '' : autherModel?.partnerLabel}
                accountNumberLabel={
                  isPointsToMiles ? autherModel?.frequentFlyerProgramNumber ?? '' : autherModel?.partnerAccountNumber
                }
                amountToTransferLabel={autherModel?.amountToTransfer}
                termsDescription={autherModel?.termsDescription}
                buttonLabel={autherModel?.transferPointsNow}
                linkLabel={autherModel?.goBack}
                returnToManagePointsCtaPath={autherModel?.returnToCtaPath}
                pointsLabel={autherModel?.points}
                transferPointsInfo={transferPointsDetails}
                setTransferPointsInfo={data => setTransferPointsDetails(data)}
                typeOfPointsTransfer={autherModel?.typeOfPointsTransfer}
                isVerifySuccess={false}
              />
            ) : verificationStatus?.isVerificationPointsSuccess ? (
              <VerifyOrSuccessTransferPoints
                headerLabel={autherModel?.successHeader}
                subHeaderLabel={autherModel?.successSubHeader}
                sectionHeaderLabel={autherModel?.confirmSectionHeader}
                accountNameLabel={isPointsToMiles ? autherModel?.frequentFlyerProgram ?? '' : autherModel?.partnerLabel}
                accountNumberLabel={
                  isPointsToMiles ? autherModel?.frequentFlyerProgramNumber ?? '' : autherModel?.partnerAccountNumber
                }
                amountToTransferLabel={autherModel?.amountToTransfer}
                buttonLabel={autherModel?.goToAccount}
                linkLabel={autherModel?.returnToCtaLabel}
                returnToManagePointsCtaPath={autherModel?.returnToCtaPath}
                pointsLabel={autherModel?.points}
                newBalanceLabel={autherModel?.newBalance}
                goToMyAccountCtaPath={autherModel?.goToMyAccountCtaPath}
                goToAccountLabel={autherModel?.goToAccount}
                transferPointsInfo={transferPointsDetails}
                setTransferPointsInfo={data => setTransferPointsDetails(data)}
                amountTransferred={autherModel?.amountTransferred}
                typeOfPointsTransfer={autherModel?.typeOfPointsTransfer}
                isVerifySuccess={true}
              />
            ) : isPointsToMiles ? (
              <PointsToMiles
                isAuthorMode={isAuthorMode}
                transferHeader={autherModel?.transferHeader}
                transferSubHeader={autherModel?.transferSubHeader}
                currentBalText={autherModel?.currentBalText}
                transferSectionHeader={autherModel?.transferSectionHeader}
                partnerLabel={autherModel?.frequentFlyerProgram ?? ''}
                frequentFlyersList={autherModel?.frequentFlyersList ?? []}
                partnerAccountNumberLabel={autherModel?.frequentFlyerProgramNumber ?? ''}
                pointsLabel={autherModel?.points}
                milesLabel={autherModel?.miles ?? ''}
                userInstruction={autherModel?.userInstruction}
                sectionSubHeader={autherModel?.sectionSubHeader}
                errorList={autherModel?.errorList}
                goToMyAccountCtaPath={autherModel?.goToMyAccountCtaPath}
                continueButtonLabel={autherModel?.continueButtonLabel}
                cancel={autherModel?.cancel}
                convertPointsMappingUrl={autherModel?.convertPointsMappingUrl}
                maxPointsTransfer={autherModel?.maxPointsTransfer}
                transferPointsDetails={transferPointsDetails}
                setTransferPointsDetails={data => setTransferPointsDetails(data)}
                airlineNumberRegex={autherModel?.airlineNumberRegex}
                cancelCtaPath={autherModel?.cancelCtaPath || ''}
              />
            ) : (
              <TransferPartnerPoints
                isAuthorMode={isAuthorMode}
                transferHeader={autherModel?.transferHeader}
                transferSubHeader={autherModel?.transferSubHeader}
                currentBalText={autherModel?.currentBalText}
                transferSectionHeader={autherModel?.transferSectionHeader}
                partnerLabel={autherModel?.partnerLabel}
                partnerSelectionList={autherModel?.partnerDropdownList}
                partnerAccountNumberLabel={autherModel?.partnerAccountNumber}
                pointsLabel={autherModel?.points}
                userInstruction={autherModel?.userInstruction}
                sectionSubHeader={autherModel?.sectionSubHeader}
                errorList={autherModel?.errorList}
                goToMyAccountCtaPath={autherModel?.goToMyAccountCtaPath}
                continueButtonLabel={autherModel?.continueButtonLabel}
                cancel={autherModel?.cancel}
                convertPointsMappingUrl={autherModel?.convertPointsMappingUrl}
                maxPointsTransfer={autherModel?.maxPointsTransfer}
                transferPointsDetails={transferPointsDetails}
                setTransferPointsDetails={data => setTransferPointsDetails(data)}
                cancelCtaPath={autherModel?.cancelCtaPath || ''}
                linkedMessage={autherModel?.linkedMessage}
                unLinkedMessage={autherModel?.unLinkedMessage}
                linkYourAccountsCtaLabel={autherModel?.linkYourAccountsCtaLabel}
                linkYourAccountsCtaPath={autherModel?.linkYourAccountsCtaPath}
                marriottBonvoyPoints={autherModel?.marriottBonvoyPoints}
                partnerRewards={autherModel?.partnerRewards}
              />
            )
          ) : autherModel?.typeOfPointsTransfer === MEMBER_TO_MEMBER ? (
            verificationStatus?.isVerificationPointsSuccess ? (
              <VerifyOrSuccessTransferPoints
                headerLabel={autherModel?.successHeader}
                subHeaderLabel={autherModel?.successSubHeader}
                sectionHeaderLabel={autherModel?.successSectionHeader}
                accountNameLabel={autherModel?.recipientName}
                accountNumberLabel={autherModel?.recipientMemberNumber || ''}
                amountToTransferLabel={autherModel?.amountToTransfer}
                buttonLabel={autherModel?.goToAccount}
                linkLabel={autherModel?.returnToCtaLabel}
                returnToManagePointsCtaPath={autherModel?.returnToCtaPath}
                pointsLabel={autherModel?.points}
                newBalanceLabel={autherModel?.newBalance}
                goToMyAccountCtaPath={autherModel?.goToMyAccountCtaPath}
                goToAccountLabel={autherModel?.goToAccount}
                transferPointsInfo={transferPointsDetails}
                setTransferPointsInfo={data => setTransferPointsDetails(data)}
                amountTransferred={autherModel?.amountTransferred}
                typeOfPointsTransfer={autherModel?.typeOfPointsTransfer}
                isVerifySuccess={true}
              />
            ) : (
              !verificationStatus?.isVerificationPointsFailed && (
                <MemberToMember
                  isAuthorMode={isAuthorMode}
                  typeOfPointsTransfer={autherModel?.typeOfPointsTransfer || ''}
                  transferHeader={autherModel?.transferHeader || ''}
                  transferSubHeader={autherModel?.transferSubHeader || ''}
                  currentBalText={autherModel?.currentBalText || ''}
                  pointsLabel={autherModel?.points || ''}
                  transferSectionHeader={autherModel?.transferSectionHeader || ''}
                  firstName={autherModel?.firstName}
                  lastName={autherModel?.lastName}
                  recipientMemberNumber={autherModel?.recipientMemberNumber}
                  memberNumber={autherModel?.memberNumber}
                  confirmMemberNumber={autherModel?.confirmMemberNumber}
                  amountToTransfer={autherModel?.amountToTransfer}
                  inlineMessage={autherModel?.inlineMessage}
                  consentDetails={autherModel?.consentDetails}
                  transferPointsNow={autherModel?.transferPointsNow}
                  cancel={autherModel?.cancel}
                  cancelCtaPath={autherModel?.cancelCtaPath || ''}
                  errorList={autherModel?.errorList || {}}
                  convertPointsMappingUrl={autherModel?.convertPointsMappingUrl || ''}
                  transferPointsInfo={transferPointsDetails}
                  setTransferPointsDetails={data => setTransferPointsDetails(data)}
                  transferPointsNowClickTrackValue={autherModel?.transferPointsNowClickTrackValue}
                />
              )
            )
          ) : null}
        </div>
      )}
    </StyledPointsTransfer>
  );
};

export const PointsTransferConfig = {
  emptyLabel: 'PointsTransfer',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/pointstransfer`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PointsTransferEditable = (props: any) => {
  return (
    <EditableComponent config={PointsTransferConfig} {...props}>
      <PointsTransfer {...props} />
    </EditableComponent>
  );
};
