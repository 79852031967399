// Imports for external libraries go here.
import React, { useState, useContext, useRef, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useMutation, useLazyQuery } from '@apollo/client';
import { inspect } from 'util';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Modal, Accordion, Text, Types, Icon, CheckBox, Heading, Messages, RichText } from '@marriott/mi-ui-library';
import { phoenixAccountAddCustomerPreferences, phoenixAccountGetCustomerPreferenceFeatures } from '../../modules/graph';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import {
  ALERTS_BEVTYPE,
  BANNER_MSGS_KEYS,
  OPTED_OUT_PERSONALIZATION_KEY,
  constants,
} from '../../modules/utils/constants/constants';
import {
  HYPHEN_SYMBOL,
  UNDERSCORE_SYMBOL,
  COLON_SYMBOL,
  FoodBevIconsType,
  SEMI_COLON_SYMBOL,
  FOOD_BEV_CLICK_TRACK,
} from '../../modules/utils/constants';
import {
  apiLogger,
  generateApolloClientHeaders,
  getCurrentUrlParams,
  checkAndSetConsentChecked,
  updateConsentFeatureOptOut,
  scrollToClass,
} from '../../modules/utils';
import { useProfileStore } from '../../modules/store/profileStore';
import { PageContext } from '../../modules/context/PageContext';
import FoodBevMocks from './__mock__/FoodBevPreferences.model.json';
import { StyledFoodBevPreferences } from './FoodBevPreferences.styles';
import {
  PreferencesFeatures,
  FoodItems,
  FoodTypeObj,
  UpdatedFoodItem,
  FoodBevDetailsProps,
  FoodBevItems,
  CustomerType,
  FoodBevProps,
  ConsentDetails,
} from './FoodBevPreferences.types';

// Use named rather than default exports.
export const FoodBevPreferences = (props: FoodBevProps) => {
  const isAuthorMode = props?.isAuthorMode;
  const dataLoaded = useRef<boolean>(false);
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [foodPreferences, setFoodPreferences] = useState<any | null>(null);
  const [foodItemschecked, setFoodItemschecked] = useState<FoodItems>({});
  const [hasError, setHasError] = useState<boolean>(false);
  const [consentChecked, setConsentChecked] = useState<boolean>(false);
  const [updateCustomerFoodPreferences] = useMutation(phoenixAccountAddCustomerPreferences);
  const [getCustomerFoodPreferences] = useLazyQuery(phoenixAccountGetCustomerPreferenceFeatures);
  const { profileModalId, setProfileModalId, setProfileDetails, profileDetails } = useProfileStore(state => state);
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const userProfileCountryCode = profileDetails?.profileAddress?.filter(address => address?.primary === true)?.[0];
  const userCountryCode = userProfileCountryCode?.address?.country?.code;
  const modalBodyClass = 'foodbevpreferences-modal-body';
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    if (isAuthorMode) {
      setFoodPreferences(FoodBevMocks?.customer?.featuresByGroup?.preferencesFeatures);
    }

    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isEditFoodPreferencesModal = param.get('editFoodBeveragePreferences');
    if (isEditFoodPreferencesModal === 'true') {
      setProfileModalId('food-beverage-preferences');
    }
  }, []);

  useEffect(() => {
    if (!isAuthorMode && profileModalId === 'food-beverage-preferences' && !foodPreferences) {
      fetchFoodPreferences();
    }
  }, [profileModalId, foodPreferences]);

  const resetData = () => {
    setFoodPreferences(null);
    setFoodItemschecked({});
    setHasError(false);
    setConsentChecked(false);
    setIsSaveButtonEnabled(false);
  };

  const fetchFoodPreferences = () => {
    getCustomerFoodPreferences({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: (data: CustomerType) => {
        dataLoaded.current = true;
        setFoodPreferences(data?.customer?.featuresByGroup?.preferencesFeatures);
        setProfileDetails({
          ...profileDetails,
          consentFeatures: data?.customer?.featuresByGroup?.preferencesFeatures,
        });
        loadCheckedItems(data?.customer?.featuresByGroup?.preferencesFeatures);
        setHasError(false);
        apiLogger(
          `[FoodBevPreferences] getFoodBevPreferencesData - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        setHasError(true);
        scrollToClass(modalBodyClass);
      },
    });
  };
  const handleCheckboxChange = () => {
    setConsentChecked(prevIsDefaultCard => !prevIsDefaultCard);
  };
  const featureCodes = ['food-lov', 'beverage-non-alcoholic-lov', 'beverage-alcoholic-lov', 'alerts'];
  const loadCheckedItems = (foodPreferences: [PreferencesFeatures]) => {
    const foodTypeObj: FoodTypeObj = {};
    featureCodes.forEach(code => {
      foodTypeObj[code] = [];
    });
    let selectedFoodItems = {};
    foodPreferences?.map((food: PreferencesFeatures) => {
      const { feature, value } = food;
      if (value !== null) {
        if (featureCodes.includes(feature.code)) {
          foodTypeObj[feature.code].push(
            ...value.split(feature.code === ALERTS_BEVTYPE ? SEMI_COLON_SYMBOL : COLON_SYMBOL)
          );
        }
      }
    });
    const optInConsent = checkAndSetConsentChecked(foodPreferences, OPTED_OUT_PERSONALIZATION_KEY, 'N');
    setConsentChecked(optInConsent);

    Object.keys(foodTypeObj).forEach(key => {
      foodTypeObj[key].forEach((value: string) => {
        selectedFoodItems = { ...selectedFoodItems, [key + UNDERSCORE_SYMBOL + value]: true };
      });
    });

    setFoodItemschecked(selectedFoodItems);
  };

  const updateFoodItem = (foodBevType: string, foodBevValue: string) => {
    const foodkey = foodBevType + UNDERSCORE_SYMBOL + foodBevValue;
    setFoodItemschecked((foodItems: FoodItems) => ({ ...foodItems, [foodkey]: !foodItems[foodkey] }));
  };

  const updatePayload = () => {
    const filteredChecked: FoodItems = {};
    Object.entries(foodItemschecked).forEach(([key, value]) => {
      if (value === true) {
        filteredChecked[key] = value;
      }
    });

    const groups: { [code: string]: string[] } = {};
    Object.keys(filteredChecked).forEach((key: string) => {
      const [code, suffix] = key.split(UNDERSCORE_SYMBOL);
      groups[code] = groups[code] || [];
      groups[code].push(suffix);
    });

    const foodBevPayload = Object.entries(groups).map(([code, suffixes]) => {
      return {
        code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase(),
        value: suffixes.join(code?.toLowerCase() === ALERTS_BEVTYPE ? SEMI_COLON_SYMBOL : COLON_SYMBOL),
      };
    });
    const foodItemDescList: UpdatedFoodItem[] = [];
    foodBevPayload?.forEach((foodItem: UpdatedFoodItem) => {
      if (foodItem.code?.includes('_LOV')) {
        const updatedFoodItem = {
          code: foodItem.code.replace('_LOV', '_DESC'),
          value: foodItem.value.replaceAll(COLON_SYMBOL, ':|') + COLON_SYMBOL,
        };
        foodItemDescList.push(updatedFoodItem);
      }
    });
    featureCodes.forEach(code => {
      if (!Object.prototype.hasOwnProperty.call(groups, code)) {
        if (!code?.includes('-lov')) {
          foodBevPayload.push({
            code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase(),
            value: '',
          });
        } else {
          foodBevPayload.push(
            {
              code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase(),
              value: '',
            },
            {
              code: code.replaceAll(HYPHEN_SYMBOL, UNDERSCORE_SYMBOL).toUpperCase().replace('_LOV', '_DESC'),
              value: '',
            }
          );
        }
      }
    });
    const consentItem =
      consentChecked === true
        ? { code: 'OPTED_OUT_PERSONALIZATION', value: 'N' }
        : { code: 'OPTED_OUT_PERSONALIZATION', value: 'Y' };
    foodBevPayload.push(...foodItemDescList);
    foodBevPayload.push(consentItem);

    updateCustomerFoodPreferences({
      variables: {
        input: {
          customerId: sessionData?.consumerID,
          customerFeatures: foodBevPayload,
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: (data: CustomerType) => {
        setProfileDetails({
          ...profileDetails,
          consentFeatures: updateConsentFeatureOptOut(profileDetails?.consentFeatures, consentChecked),
        });
        setFoodPreferences(null);
        setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
        setProfileModalId('');
        setHasError(false);
        setIsSaveButtonEnabled(false);
        apiLogger(
          `[FoodBevPreferences] updateCustomerFoodPreferences - sessionId value: ${
            sessionData?.sessionToken
          }: ${inspect(data)}`
        );
      },
      onError: error => {
        setHasError(true);
        scrollToClass(modalBodyClass);
        apiLogger(
          `[FoodBevPreferences] addCustomerFeatures - sessionId value: ${sessionData?.sessionToken} - error: ${inspect(
            error
          )}`
        );
      },
    });
    return foodBevPayload;
  };

  return (
    <StyledFoodBevPreferences data-testid="foodbevpreferences" data-component-name="o-account-foodbevpreferences">
      <Modal
        show={profileModalId === props?.model?.modelId}
        popupOpenState={profileModalId === props?.model?.modelId}
        setPopupOpenState={(): void => {
          setProfileModalId('');
          resetData();
        }}
        modalId={props?.model?.modelId + 'modal'}
        labelledBy={props?.model?.modelId}
        onClose={(): void => {
          setProfileModalId('');
          resetData();
        }}
        secondaryClassName="modal__container"
        disableScrollOnBody={true}
        aria-modal="true"
        handleBlur={true}
        role="dialog"
      >
        <Modal.Header
          className="food-bev-header t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
          customHeadingClass="t-subtitle-xl"
          labelText={props.model?.header}
          popupHeaderOnCLoseFunc={(): void => {
            setProfileModalId('');
            resetData();
          }}
          data-testid="food-bev-header"
        />
        <Modal.Body
          className={`modal__container-content custom-scrollbar px-4 px-md-5 py-4 mt-md-2 mb-3 mx-2 ${modalBodyClass}`}
        >
          <div className="row">
            {hasError && (
              <div data-testId="uxl-error-msg" className="uxl-error-msg">
                <Messages messageType="warning" className="mb-4">
                  <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                </Messages>
              </div>
            )}
            <div className={clsx('t-font-m col-12 m-modal-content mt-2 mt-md-0 mb-0 px-0')}>
              {props.model?.description}
            </div>
            <div className="accordion-food pb-2">
              {props.model?.details &&
                props.model.details.map((food: FoodBevDetailsProps, index: number) => {
                  const { sectionTitle, sectionSubTitle, items, sectionId } = food;
                  const enableAccordionSeparator = index === props.model.details.length - 1 ? false : true;
                  return (
                    <Accordion
                      id={sectionId}
                      enableAccordionSeparator={enableAccordionSeparator}
                      key={index}
                      isOpen={index === 0 ? true : false}
                      headerChildren={
                        <div>
                          <Icon iconClass={clsx(FoodBevIconsType[sectionId], 'icon-decorative mr-3')}></Icon>
                          <Heading
                            variation={Types.headingType.subtitle}
                            fontSize={Types.size.large}
                            titleText={sectionTitle}
                            customClass={clsx('d-inline food-item')}
                          />
                        </div>
                      }
                    >
                      <div className="ml-0 ml-md-5">
                        <Text
                          element={Types.tags.span}
                          fontSize={Types.size.medium}
                          copyText={sectionSubTitle}
                          customClass={clsx('')}
                        />
                        <div className="d-flex flex-wrap mt-5">
                          {items &&
                            items.map((foodItem: FoodBevItems) => {
                              const { id, value } = foodItem;
                              return (
                                <CheckBox
                                  onChange={() => {
                                    updateFoodItem(sectionId, id);
                                    setIsSaveButtonEnabled(true);
                                  }}
                                  checked={foodItemschecked[sectionId + UNDERSCORE_SYMBOL + id]}
                                  data-testid={id}
                                  checkboxName="foodItem_checkbox"
                                  checkboxLabel={value}
                                  checkboxId={id}
                                  key={sectionId + UNDERSCORE_SYMBOL + id}
                                  className="col-12 col-lg-4 t-font-m accordion-checkbox checkbox checkbox-food_items pb-2"
                                />
                              );
                            })}
                        </div>
                      </div>
                    </Accordion>
                  );
                })}
            </div>
            {profileModalId === props?.model?.modelId &&
              userCountryCode &&
              userCountryCode !== constants.KOREA_COUNTRY_CODE && (
                <div className={clsx('mt-5 pt-2')}>
                  {props?.model?.consentDetails &&
                    props?.model?.consentDetails.map((consent: ConsentDetails) => {
                      const { consentTitle, consentDescription } = consent;
                      return (
                        <>
                          <CheckBox
                            onChange={() => {
                              handleCheckboxChange();
                              setIsSaveButtonEnabled(true);
                            }}
                            checked={consentChecked}
                            data-testId="checkbox_consent"
                            checkboxName="checkbox_consent"
                            checkboxLabel={consentTitle}
                            checkboxId={'consent-foodBev'}
                            className="t-subtitle-m checkbox checkbox-consent"
                          />
                          <div className="t-font-m ml-5">{consentDescription}</div>
                        </>
                      );
                    })}
                </div>
              )}
          </div>
        </Modal.Body>
        <Modal.Footer
          className="modal__container-footer m-auto px-5 py-4 py-md-5 align-items-baseline justify-content-md-end"
          clearButtonLabel={props.model?.cancelButton}
          clearClickHandler={(): void => {
            setProfileModalId('');
            resetData();
          }}
          clearBtnClassname="clear m-link-action mr-md-4 p-0 px-auto px-md-0 custom_click_track clear-btn"
          applyLabel={props.model?.saveButton}
          data-testid="Save"
          applyBtnClickHandler={() => {
            updatePayload();
          }}
          applyButtonDisabled={foodPreferences === null || !isSaveButtonEnabled}
          applyBtnClickTrackValue={FOOD_BEV_CLICK_TRACK}
        ></Modal.Footer>
      </Modal>
    </StyledFoodBevPreferences>
  );
};

export const FoodBevPreferenceConfig = {
  emptyLabel: 'foodbevpreferences',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/foodbevpreferences`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FoodBevPreferenceEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <FoodBevPreferences {...props} />
  ) : (
    <EditableComponent config={FoodBevPreferenceConfig} {...props}>
      <FoodBevPreferences {...props} />
    </EditableComponent>
  );
};
