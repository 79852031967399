import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';
const { xl, md } = { ...baseVariables.mediaQuery };

export const StyledPromotionBanner = styled.div`
  ${rtl` .pb-item {
    position: relative;
    overflow: hidden;
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right;
    }
    & .pb-item {
      &__skeleton {
        &__img {
          width: 100%;
          height: ${toRem(364)};
          @media ${md} {
            height: ${toRem(256)};
          }
          @media ${xl} {
            height: ${toRem(480)};
          }
        }
      }
    }
  }
`}
`;
