/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { inspect } from 'util';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Button, Eyebrow, RichText, Text, Types } from '@marriott/mi-ui-library';
import { phoenixAccountGetMemberStatusDetails } from '../../modules/graph';
import { PROMOTIONS_CONSTANT, localeDateFormats, accountConstants } from '../../modules/utils/constants/constants';
import { useStore } from '../../modules/store/memberLevelStore';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import {
  PageContext,
  addSubDirectoryPrefix,
  apiLogger,
  convertDateToLocaleSpecific,
  generateApolloClientHeaders,
  useCheckBreakpoint,
} from '../../modules';
import PromotionBannerMockdata from '../../organisms/PromotionBanner/__mock__/PromotionBanner.model.json';
import { PromotionDetailsProps } from './PromotionDetails.types';
import { StyledPromotionDetails } from './PromotionDetails.styles';

export const PromotionSkeleton = () => (
  <div className="card__skeleton d-flex flex-column p-5">
    <div className="skeleton-loader card__skeleton__label mb-4"></div>
    {Array.from({ length: 8 }, (_, index) => (
      <div key={index} className={`skeleton-loader card__skeleton__desc mb-${index === 4 ? 5 : 2}`}></div>
    ))}
  </div>
);

export const PromotionDetails: FC<PromotionDetailsProps> = ({ model }) => {
  const { promotionDetails, isPromotionDetailsLoading, setPromotionDetails } = useStore(state => state);
  const { setMemberDetails } = useAccountPersistentStore(state => state);
  const pageContext = useContext(PageContext);
  const currentLocale = pageContext?.currentLocale ?? accountConstants?.DEFAULT_LANG;
  const { IS_LOCAL_DEV } = process.env;
  const isAuthorMode = model?.isAuthorMode;
  const data = promotionDetails?.promotion
    ? promotionDetails?.promotion?.offers
    : promotionDetails?.loyaltyPromotion?.offers;
  const descList = data?.[0]?.descriptions?.descriptions;
  const promoHurdle = promotionDetails?.promotion ? data?.[0] : data?.[0]?.hurdle;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const dataLoaded = useRef<boolean>(false);
  const isViewportS = useCheckBreakpoint('viewportS');
  const isViewportXl = useCheckBreakpoint('viewportXl');

  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode ||
    !sessionData?.consumerID;

  useQuery(phoenixAccountGetMemberStatusDetails, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    skip: skipQuery,
    onCompleted: (data: any) => {
      dataLoaded.current = true;
      setMemberDetails?.(data);
    },
    onError: error => {
      apiLogger(
        `[MemberStatus] getMemberStatusDetails - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    },
  });
  useEffect(() => {
    if (isAuthorMode) {
      setPromotionDetails(PromotionBannerMockdata.data);
    }
  }, [isAuthorMode]);

  const replaceDigitWithDates = (promoData: string) => {
    const promoContent = promoData;
    // replacing the placeholder with date in format of numeric dates with slash
    return promoContent
      .replace(
        '{0}',
        convertDateToLocaleSpecific(
          promoHurdle?.startDate ?? '',
          currentLocale,
          localeDateFormats[currentLocale?.replace('_', '-') ?? 'en-US']?.numericDate
        )
      )
      .replace(
        '{1}',
        convertDateToLocaleSpecific(
          promoHurdle?.endDate ?? '',
          currentLocale,
          localeDateFormats[currentLocale?.replace('_', '-') ?? 'en-US']?.numericDate
        )
      );
  };

  const filteredDescListData = useMemo(() => {
    return PROMOTIONS_CONSTANT.PROMOTION_DETAIL_KEYS.reduce((filteredData, keyData) => {
      const matchingDescListData = descList?.find(data => data.key === keyData);
      if (matchingDescListData) {
        if (PROMOTIONS_CONSTANT.PROMOTION_DETAIL_KEYS[0] === matchingDescListData.key) {
          filteredData.push(
            <RichText
              key={keyData}
              text={replaceDigitWithDates(matchingDescListData.value)}
              customClass="pb-3 m-0 t-subtitle-l"
              componentId={keyData}
            />
          );
        } else {
          filteredData.push(
            <Text
              customClass="card__promotion-detail"
              dangerouslySetInnerHTML={{
                __html:
                  matchingDescListData.key === PROMOTIONS_CONSTANT.PROMOTION_DETAIL_KEYS[1]
                    ? replaceDigitWithDates(matchingDescListData.value)
                    : matchingDescListData.value,
              }}
              fontSize={Types.size.medium}
              element={Types.tags.paragraph}
            />
          );
        }
      }
      return filteredData;
    }, [] as React.ReactNode[]);
  }, [descList]);

  return (
    <StyledPromotionDetails
      className="color-scheme3"
      data-testid="promotiondetails"
      data-component-name="o-account-promotiondetails"
    >
      <div className="container pb-4">
        <div
          className={clsx(
            'd-flex justify-content-between promo-detail-label',
            isViewportXl ? 'pt-6 pb-4' : isViewportS ? 'pt-6 pb-3' : 'pt-5 pb-3'
          )}
        >
          <span className="color-scheme1">
            <Eyebrow text={model?.promotionDetailsTitle} />
          </span>
          {sessionData?.consumerID && (
            <Button
              isLink={true}
              className="m-link-tertiary-button"
              href={addSubDirectoryPrefix(PROMOTIONS_CONSTANT.PROMOTIONS_CENTRAL_URL)}
            >
              {' '}
              {model?.seeMyPromotionsLabel}
            </Button>
          )}
        </div>
        <div className="card mb-3">
          {isPromotionDetailsLoading ? <PromotionSkeleton /> : <div className="p-5">{filteredDescListData} </div>}
        </div>
      </div>
    </StyledPromotionDetails>
  );
};

export const PromotionDetailsConfig = {
  emptyLabel: 'promotiondetails',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/promotiondetails`,
};
export const PromotionDetailsEditable = (props: any) => {
  return (
    <EditableComponent config={PromotionDetailsConfig} {...props}>
      <PromotionDetails {...props} />
    </EditableComponent>
  );
};
