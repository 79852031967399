import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';

export const StyledReportMissingStayLinkSection = styled.div`
  ${rtl`
    .container:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;

      .m-link-tertiary-button::after {
        transform: rotate(-180deg) !important;
      }

      .m-link-tertiary-button:is(${constants.RTL_LANG_LIST}) {
        margin-right: 0 !important;
      }
    }
  `}
`;
