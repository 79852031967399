import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { SkeletonLoaderDuration } from '../../../modules/utils/constants';

export const StayDetailsStyle = styled.div`
  .container__left {
    gap: 0;

    @media ${baseVariables.mediaQuery.md} {
      gap: ${toRem(176)};
    }

    @media ${baseVariables.mediaQuery.lg} {
      gap: ${toRem(56)};
    }

    .t-title-m-custom {
      font-weight: bold;
    }

    .rewards {
      .buy {
        color: ${baseVariables.color['alert40']};

        .text {
          color: ${baseVariables.color['alert40']};
        }
      }

      &__skeleton {
        &__label {
          width: ${toRem(150)};
          height: ${toRem(24)};
          &:after {
            animation-duration: ${SkeletonLoaderDuration};
          }
        }
      }
    }
  }
`;
