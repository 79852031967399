import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledSignOut = styled.div`
  ${rtl`

    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }
    .space-bottom {
      padding-bottom: ${toRem(120)};
    }
    .spacing-top-bottom {
      margin-bottom: ${toRem(104)};
      @media ${baseVariables.mediaQuery.md} {
        margin-bottom: ${toRem(120)};
      }
    }
    //overriding margin of rte label authored from aem
    .paragraphh {
      p {
        margin-bottom: ${toRem(8)};
      }
    }
  `}
`;
