/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Glide from '@glidejs/glide';
import clsx from 'clsx';
import moment from 'moment';
import { Label, RichText, Types } from '@marriott/mi-ui-library';
import { getDirection, membersnapshotribbonHelper } from '../../../../modules/utils/overviewHelper';
import { getTextColorByCode, PageContext } from '../../../../modules';
import { StyledMemberBenefits } from './MemberBenefits.styles';
import { MessageTemplate, MemberBenefitModelTypes } from './MemberBenefits.types';

export const MemberBenefits: React.FC<MemberBenefitModelTypes> = ({ model, customerData, isNotBasicMember }) => {
  const glideRef = useRef<HTMLDivElement | any>(null);
  const [glideCurrentSlide, setGlideCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(getDirection());
  const pageContext = useContext(PageContext);
  const levelTypeCode = customerData?.loyaltyInformation?.rewards?.levelType?.code;
  const MemberBenefitsPillMessages = membersnapshotribbonHelper(model, customerData, pageContext?.memberStatusList);
  const lessThanOnePillMsg = MemberBenefitsPillMessages?.length <= 1;
  //Date range for year end message
  const startDate = new Date(model?.yearEndStartDate ?? '');
  const endDate = new Date(model?.yearEndEndDate ?? '');
  const currentDate = new Date();
  //store if date is within the range
  const dateInRange = moment(currentDate).isBetween(startDate, endDate);
  const handleSlideChange = (event: any) => {
    setGlideCurrentSlide(event);
  };

  useEffect(() => {
    if (!direction) {
      setDirection(getDirection());
    }

    let glide: any;
    if (glideRef?.current) {
      glide = new Glide(glideRef.current, {
        type: 'slider',
        gap: 16,
        focusAt: 'center',
        autoplay: false,
        bound: false,
        direction: direction ?? getDirection(),
        dragThreshold: false,
        perView: 1.18,
        peek: {
          before: 0,
          after: 0,
        },
        breakpoints: {
          992: {
            perView: 1.3,
          },
        },
      });
    }
    glide?.mount();

    // Add event listener
    glide?.on('run.after', () => handleSlideChange(glide?.index));
  }, []);

  return (
    <StyledMemberBenefits textColor={getTextColorByCode(levelTypeCode)} isNotBasicMember={isNotBasicMember}>
      {dateInRange && model?.isYearEndEnabled ? (
        <div className={clsx('card__wrapper-message t-font-inverse-s px-5 pb-5', `${!isNotBasicMember ? 'mt-3' : ''}`)}>
          <div className="card__wrapper-annual pb-3"></div>
          <RichText text={model?.yearEndCardMessage ?? ''} componentId="yearmessage" customClass=" text-center" />
        </div>
      ) : (
        <div className="glide" ref={glideRef}>
          <div
            className={clsx(lessThanOnePillMsg ? 'pl-0 d-flex align-items-center' : '', 'glide__track mx-auto  pt-4')}
            data-glide-el="track"
          >
            <ul className={clsx('glide__slides d-flex m-0 p-0 h-auto', lessThanOnePillMsg ? 'pb-4 ' : '')}>
              {MemberBenefitsPillMessages?.map((data: MessageTemplate, index: number) => {
                return (
                  <li
                    className={clsx(
                      'glide__slide p-3 d-flex',
                      glideCurrentSlide === MemberBenefitsPillMessages.length - 1 &&
                        index === MemberBenefitsPillMessages.length - 1 &&
                        MemberBenefitsPillMessages.length > 1 &&
                        'ml-2'
                    )}
                    data-glide-index={index}
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <span
                        className={clsx(
                          direction === 'rtl' ? 'ml-3 ' : 'mr-3 ',
                          typeof data === 'string' ? '' : data?.icon
                        )}
                      ></span>
                      <Label
                        labelText={typeof data === 'string' ? data : data?.title}
                        size={Types.size.extraSmall}
                        customClass="glide__slide-msg"
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
            {MemberBenefitsPillMessages?.length > 1 ? (
              <div className="controls_wrapper pr-5 pr-lg-3">
                <div className="controls d-flex align-items-center justify-content-center">
                  <div
                    className={clsx('carouselControl carouselControlType3b', {
                      'flex-row-reverse': direction === 'rtl',
                    })}
                  >
                    <div className="glide__arrows" data-glide-el="controls" tabIndex={0}>
                      <button
                        className={clsx('glide__arrow glide__arrow--left left-arrow', {
                          'glide__arrow--disabled':
                            (direction === 'ltr' && glideCurrentSlide === 0) ||
                            (direction === 'rtl' && glideCurrentSlide === MemberBenefitsPillMessages.length - 1),
                        })}
                        data-glide-dir="<"
                        disabled={
                          (direction === 'ltr' && glideCurrentSlide === 0) ||
                          (direction === 'rtl' && glideCurrentSlide === MemberBenefitsPillMessages.length - 1)
                        }
                        aria-label="Previous"
                      >
                        <span
                          className={clsx({
                            'icon-arrow-left': direction === 'ltr',
                            'icon-arrow-right': direction === 'rtl',
                          })}
                        ></span>
                      </button>
                    </div>

                    <div data-glide-el="controls[nav]">
                      {MemberBenefitsPillMessages?.map((_: MessageTemplate, index: number) => {
                        return (
                          <button
                            className="bullets"
                            data-glide-dir={index}
                            aria-label={`control-button-${index}`}
                            key={index}
                            tabIndex={-1}
                          ></button>
                        );
                      })}
                    </div>

                    <div className="glide__arrows text" data-glide-el="controls" tabIndex={0}>
                      <button
                        className={clsx('glide__arrow glide__arrow--right right-arrow', {
                          'glide__arrow--disabled':
                            (direction === 'rtl' && glideCurrentSlide === 0) ||
                            (direction === 'ltr' && glideCurrentSlide === MemberBenefitsPillMessages.length - 1),
                        })}
                        data-glide-dir=">"
                        disabled={
                          (direction === 'rtl' && glideCurrentSlide === 0) ||
                          (direction === 'ltr' && glideCurrentSlide === MemberBenefitsPillMessages.length - 1)
                        }
                        aria-label="Next"
                      >
                        <span
                          className={clsx({
                            'icon-arrow-right': direction === 'ltr',
                            'icon-arrow-left': direction === 'rtl',
                          })}
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </StyledMemberBenefits>
  );
};
