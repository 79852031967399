/* eslint-disable */

// Queries
export const phoenixAccountDttGetOrdersByCustomerId = require('./queries/phoenixAccountDttGetOrdersByCustomerId.graphql');
export const phoenixAccountDttGetReservationDetails = require('./queries/phoenixAccountDttGetReservationDetails.graphql');
export const phoenixAccountDttGetCancelReservationDetails = require('./queries/phoenixAccountDttGetCancelReservationDetails.graphql');
export const phoenixAccountDttGetUpcomingCustomerTrips = require('./queries/phoenixAccountDttGetUpcomingCustomerTrips.graphql');
export const phoenixAccountDttAwardsRequestsCount = require('./queries/phoenixAccountDttAwardsRequestsCount.graphql');
export const phoenixAccountDttGetMyActivityRewardsEarned = require('./queries/phoenixAccountDttGetMyActivityRewardsEarned.graphql');
export const phoenixAccountDttGetNightlyUpgradeAwards = require('./queries/phoenixAccountDttGetNightlyUpgradeAwards.graphql');
