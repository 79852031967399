/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Button, Image, Icon, Text, Types } from '@marriott/mi-ui-library';
import moment from 'moment';
import axios from 'axios';
import { logger } from '../../modules/utils';

import {
  formatDateRange,
  localesWithLowerCaseSplCharacters,
  addSubDirectoryPrefix,
  PageContext,
  cancelReservationUrl,
  reviewCancelReservationUrl,
  tripCancellationUrl,
} from '../../modules';
import { formatPhoneNumber } from '../../modules/utils/numericalFormatsHelper';
import { fallbackImages, imageDomain } from '../../modules/utils/constants';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { useStore } from '../../modules/store/memberLevelStore';
import { CancelReservationDetailsProps, SuiteNightAwardsStatus } from './CancelReservationDetails.types';
import { StyledCancelReservationDetails } from './CancelReservationDetails.styles';
import { ContactProps } from '../../organisms/UpcomingTrips/TripDetails/TripDetails.types';
import { cancelStatus, cancelStatusGuaranteed, cancelStatusForfeited } from '../../modules';
// Use named rather than default exports.

const { log } = logger({})('Cancel Reservation');

export const getCurrentLocalPropertyTime = (gmtOffset: string): moment.Moment => {
  return moment().utcOffset(gmtOffset);
};

export const validateCurrentTime = (checkInDate: any, currentDate: any) => {
  let validWindowPrior = true;

  if (checkInDate === currentDate) {
    const hours = currentDate.getHours();
    if (hours > 14 || (hours === 0 && currentDate.getMinutes() > 0)) {
      validWindowPrior = false;
    }
  } else if (checkInDate < currentDate) {
    validWindowPrior = false;
  }

  return validWindowPrior;
};

export const checkRoomUpgradeWindowPrior = (checkInDate: any, gmtOffset: any) => {
  let validWindowPrior = true;

  const checkInDateCalendar = moment(checkInDate).subtract(1, 'day');
  const currentDate = gmtOffset ? getCurrentLocalPropertyTime(gmtOffset) : moment();

  if (currentDate) {
    validWindowPrior = validateCurrentTime(checkInDateCalendar, currentDate.toDate());
  } else {
    validWindowPrior = false;
  }
  return validWindowPrior;
};

export const CancelReservationDetails: FC<CancelReservationDetailsProps> = ({
  pointsReturnDescription,
  freeNightAwardReturnDescription,
  nightlyUpgradeAwardReturnDescription,
  bookingData,
  cancelButtonOverlayLabel,
  keepRoomButtonLabel,
  pointsDetails,
}) => {
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const currentLocale = pageContext?.currentLocale;
  const {
    cancelModal: { canceledReservations, setShowCancelReservation },
  } = useStore();
  const [points, setPoints] = useState(0);
  const [numberOfNights, setNumberOfNights] = useState(0);
  const [suiteNightAwardsStatus, setSuiteNightAwardsStatus] = useState<SuiteNightAwardsStatus>({
    suiteNightAwards: 0,
    showSNAReturned: false,
  });
  const [pointText, setPointText] = useState('');
  const [isFreeCancellationValid, setIsFreeCancellationValid] = useState<boolean>(false);

  const authorModelData = canceledReservations?.authorModelData;
  const propertyAddress = canceledReservations?.hotelData?.contactInformation?.address;
  const contactInfo = canceledReservations?.hotelData?.contactInformation?.contactNumbers;

  const phoneNumber =
    contactInfo?.find((cur: ContactProps) => cur?.type?.code === 'phone')?.phoneNumber?.display ||
    contactInfo?.[0]?.phoneNumber?.display ||
    '';

  const dateRangeFormatter = formatDateRange(
    bookingData?.[0]?.basicInformation?.startDate,
    bookingData?.[0]?.basicInformation?.endDate,
    currentLocale
  );

  const primaryImage = canceledReservations?.hotelData?.media?.primaryImage?.edges?.[0]?.node?.imageUrls.square;
  const galleryImages = canceledReservations?.hotelData?.media?.photos?.[0]?.content;
  // Check if primaryImage is available, if not, use the first image from photoGallery hotelview if available, or fallback image
  const primaryImageURL = primaryImage ? imageDomain + primaryImage : null;
  const galleryImageURL = galleryImages && galleryImages.length > 0 ? galleryImages?.[0]?.url : null;
  const imageSourceURL = primaryImageURL || galleryImageURL || fallbackImages.Classic;

  const updateUrlWithConfirmationNumber = (url: string, number: string) =>
    (addSubDirectoryPrefix(url) ?? '').replaceAll('{confirmationNumber}', number);

  const currentDate = new Date();
  const dateGiven = pointsDetails?.orderLineItem?.product?.basicInformation?.freeCancellationUntil;
  const freeCancellationDate = dateGiven ? new Date(dateGiven) : null;

  const suiteNightAwards = pointsDetails?.orderLineItem?.awardRequests?.[0]?.count;
  const suiteNightAwardsRequest = pointsDetails?.orderLineItem?.suiteNightAwardsRequest;
  const propertyBasicInfoWSResponseNode = pointsDetails?.orderLineItem?.property?.basicInformation;

  useEffect(() => {
    pointsDetails?.orderLineItem?.certificates.map(certificate => {
      if (certificate?.certificateDetails?.points === 0) {
        setNumberOfNights(prevNumberOfNights => prevNumberOfNights + 1);
      } else if (certificate?.certificateDetails?.points > 0) {
        setPoints(prevPoints => prevPoints + certificate?.certificateDetails?.points);
      }
    });

    const outdatedRules =
      pointsDetails?.orderLineItem?.totalPricing?.rules.map(rule => {
        if (rule?.type?.code === 'CP' && rule?.dateTime) {
          const ruleDate = new Date(rule.dateTime);
          return ruleDate > currentDate;
        }
        return false;
      }) ?? [];

    if (!!freeCancellationDate && freeCancellationDate > currentDate && outdatedRules.some(Boolean)) {
      setIsFreeCancellationValid(true);
    }
  }, [pointsDetails]);

  useEffect(() => {
    setSuiteNightAwardsStatus(prevState => ({ ...prevState, suiteNightAwards: suiteNightAwards }));

    if (!!suiteNightAwardsRequest && cancelStatus?.includes(suiteNightAwardsRequest?.status?.code)) {
      setSuiteNightAwardsStatus(prevState => ({ ...prevState, showSNAReturned: true }));
    } else if (suiteNightAwardsRequest?.status?.code === cancelStatusGuaranteed) {
      const isValidWindow = checkRoomUpgradeWindowPrior(
        suiteNightAwardsRequest?.startDate,
        propertyBasicInfoWSResponseNode?.gmtOffset
      );

      setSuiteNightAwardsStatus(prevState => ({
        ...prevState,
        checkInDate: suiteNightAwardsRequest.startDate,
        showSNAReturned: isValidWindow,
      }));
    } else if (suiteNightAwardsRequest?.status?.code === cancelStatusForfeited) {
      setSuiteNightAwardsStatus(prevState => ({ ...prevState, showSNAReturned: false }));
    } else {
      setSuiteNightAwardsStatus(prevState => ({
        ...prevState,
        count: 0,
        showMessage: false,
      }));
    }
  }, [pointsDetails, setSuiteNightAwardsStatus]);

  useEffect(() => {
    setPointText(generateCopyText());
  }, [numberOfNights, points, suiteNightAwardsStatus]);

  const generateCopyText = () => {
    let text = '';

    if (points) {
      text += ` ${Number(points).toLocaleString()} ${pointsReturnDescription} `;
    }
    if (numberOfNights) {
      text += `${numberOfNights} ${freeNightAwardReturnDescription} `;
    }

    if (suiteNightAwardsStatus?.suiteNightAwards) {
      text += `${suiteNightAwardsStatus?.suiteNightAwards} ${nightlyUpgradeAwardReturnDescription}`;
    }

    return text;
  };

  const cancelSubmit = () => {
    const payload = {
      itemId: bookingData?.[0]?.id?.split('.')[1],
      tripId: bookingData?.[0]?.basicInformation?.confirmationNumber,
      isCancelConfirmationRequired: !isFreeCancellationValid,
      brandCode: pointsDetails?.orderLineItem?.property?.basicInformation?.brand?.id,
      bookingLanguage: pointsDetails?.orderLineItem?.basicInformation?.bookingLanguage,
      hotelLoc:
        pointsDetails?.orderLineItem?.property?.contactInformation?.address?.city +
        ',' +
        pointsDetails?.orderLineItem?.property?.contactInformation?.address?.country?.code,
      mrshaCode: pointsDetails?.orderLineItem?.property?.id,
      checkInDate: pointsDetails?.orderLineItem?.product?.basicInformation?.startDate,
    };

    axios
      .post(tripCancellationUrl, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Cookie: 'sessionID=' + sessionData?.sessionToken,
        },
      })
      .then(response => {
        const cancellationNumber = response.data.cancellationNumber;

        if (response.status === 200 && !!cancellationNumber) {
          const confirmCancelationUrl = updateUrlWithConfirmationNumber(
            cancelReservationUrl,
            bookingData?.[0]?.basicInformation?.confirmationNumber
          );
          window.location.href = window.location.origin + confirmCancelationUrl;
        } else if (response.status === 200 && !cancellationNumber) {
          const confirmReviewUrl = updateUrlWithConfirmationNumber(
            reviewCancelReservationUrl,
            bookingData?.[0]?.basicInformation?.confirmationNumber
          );
          window.location.href = window.location.origin + confirmReviewUrl;
        }
      })
      .catch(error => {
        log.debug('Cancel Reservation for the booking', error);
      });
  };

  return (
    <StyledCancelReservationDetails
      data-component-name="m-account-CancelReservationDetails"
      data-testid="account-CancelReservationDetails"
      className="d-flex flex-column justify-content-end"
    >
      <div className="cancel-description px-4 px-md-5 py-3">
        <div className="card d-md-flex">
          <div className="card__image-section pr-md-4 pb-3 pb-md-0">
            <Image
              title=""
              defaultImageURL={imageSourceURL}
              altText="hotel"
              customClass="card__image-hotel"
              loading="lazy"
            />
          </div>
          <div className="card__details">
            <Text
              element={Types.tags.div}
              fontSize={Types.size.small}
              copyText={
                localesWithLowerCaseSplCharacters?.includes(currentLocale?.replace('_', '-'))
                  ? dateRangeFormatter
                  : dateRangeFormatter?.toUpperCase()
              }
              customClass="card__details-dates pb-2"
            />
            <div className="card__details-hotel pb-2 t-subtitle-m">
              {canceledReservations?.hotelData?.basicInformation?.name}
            </div>
            <div className="trip-details__confirmation pb-2">
              <span className="t-subtitle-m">{`${authorModelData?.confirmationNumberLabel}: `}</span>
              <span>{`${bookingData?.[0]?.basicInformation?.confirmationNumber}`}</span>
            </div>
            <div className={clsx('trip-details__contact pb-2 t-font-s')}>
              <Icon iconClass="icon-phone mi-icon pr-2"></Icon>
              <Text
                element={Types.tags.span}
                fontSize={Types.size.small}
                copyText={phoneNumber ? formatPhoneNumber(phoneNumber, true) : ''}
                customClass="trip-details__contact--number"
              />
            </div>
            <div className="trip-details__address pb-2">
              <Icon iconClass="icon-location mi-icon pr-2"></Icon>
              <Text
                element={Types.tags.span}
                fontSize={Types.size.medium}
                copyText={`${propertyAddress?.line1}, ${propertyAddress?.city} ${propertyAddress?.country?.code}`}
                customClass="trip-details__address-full"
              />
            </div>
          </div>
        </div>
        {!!pointText && (
          <div className="points-texts p-3 my-3">
            <Text element={Types.tags.div} fontSize={Types.size.medium} copyText={pointText} />
          </div>
        )}
      </div>
      <div className="overlay-footer d-flex justify-content-end align-items-center py-4 flex-md-row flex-column-reverse">
        <Button
          className="t-font-s cancel-button mr-0 p-0"
          callback={() => setShowCancelReservation(false)}
          testId={'Keep Room'}
        >
          {`${keepRoomButtonLabel}`}
        </Button>
        <Button
          className="m-button-primary mx-5 mb-4 mb-md-0 py-2 d-flex justify-content-center cancel-room-btn"
          callback={cancelSubmit}
          isLink={false}
          custom_click_track_value={''}
          testId={'Cancel Room'}
        >
          {`${cancelButtonOverlayLabel}`}
        </Button>
      </div>
    </StyledCancelReservationDetails>
  );
};
