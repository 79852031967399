// Styles for CombineAccount go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';

export const StyledCombineAccount = styled.div`
  ${rtl`
  .m-dropdown-container {
    .m-dropdown-children {
      max-height: ${toRem(224)};
    }
  }
  .phone-number {
    .dropdown-field {
      min-width: ${toRem(116)};
      @media ${baseVariables.mediaQuery.md} {
        width: ${toRem(142)};
      }
    }
    label {
      left: ${toRem(20)};
      &:is(${constants.RTL_LANG_LIST}) {
        right: ${toRem(22)} !important;
      }
    }
  }
  .form-bar {
    @media ${baseVariables.mediaQuery.lg} {
      left: 50%;
      border-left: ${toRem(1)} solid ${baseVariables.color.alert20};
    }
    left: 0;
    border-bottom: ${toRem(1)} solid ${baseVariables.color.alert20};
  }
  .left-container,
  .right-container {
    flex: 1;
    height: 100%;
    position: relative;
  }
  .hotelCountry-label {
    z-index: 1;
  }
  .dropdown {
    width: 100%;
    padding: ${toRem(12)} ${toRem(16)} !important; 
    //important is given to override the dropdown css property to make it aligned with text box
    border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
    border-color: ${baseVariables.color['neutral40']} !important;
    height: ${toRem(44)};
    .icon-dropdown-down,
    .icon-dropdown-up {
      position: absolute;
      right: ${toRem(8)};
    }
  }

  #qrCode {
    position: relative;
    cursor: pointer;
  }

  .validation-msg {
    color: ${baseVariables.color['alert50']};
  }

  //qr code css
  .qr-code {
    display: none;
    position: absolute;
    left: ${toRem(80)};
    top: ${toRem(32)};
    background-color: ${baseVariables.color['base20']};
    padding: ${toRem(8)} ${toRem(16)};
    border: ${toRem(1)} solid ${baseVariables.color.neutral30};
    transform: translateX(50%);
  }

  .span-hovered .qr-code {
    display: block;
    transform: translateX(-60%);
    border: ${toRem(1)} solid ${baseVariables.color.neutral30};
    border-radius: ${toRem(5)};
    z-index: 1;
  }
  .notesRte {
    position: relative;
  }
  .qr-code {
    width: ${toRem(200)};

    img {
      width: ${toRem(164)};
    }
  }

  .combine-account-qr-arrow {
    z-index: -1;
    background: ${baseVariables.color.base20};
    border: solid ${baseVariables.color.neutral30};
    border-width: 0 ${toRem(1)} ${toRem(1)} 0;
    display: inline-block;
    padding: ${toRem(4)};
    position: absolute;
    right: 50%;
    top: -${toRem(5)};
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  input {
    caret-color: ${baseVariables.color['base10']};
  }
  .password-valid-check {
    position: absolute;
    left: initial;
    right: ${toRem(16)};
    top: ${toRem(16)};
  }
  .red-clear {
    color: ${baseVariables.color.alert50} !important;
  }
  .combine-account-confirmation {
    margin-bottom: ${toRem(68)};
  }
  @media ${baseVariables.mediaQuery.md} {
    .combine-account-confirmation {
      margin-bottom: ${toRem(176)};
    }
  }
  .m-input-field:is(${constants.RTL_LANG_LIST}) {
    label {
      left: unset;
      right: ${toRem(16)};
    }
    input {
      padding-right: ${toRem(16)};
    }
  }
  .combine-account:is(${constants.RTL_LANG_LIST}) {
    .m-input-field {
      input[type='text'] {
        padding-right: ${toRem(16)} !important;
      }
      label {
        left: unset;
        right: ${toRem(8)};
      }
      .password-valid-check {
        left: ${toRem(16)} !important;
        right: unset !important;
      }
      .dropdown{
        .icon-dropdown-down,
        .icon-dropdown-up{
          left: ${toRem(8)};
          right: unset; 
        }
      }
    }
  }
`}
`;
