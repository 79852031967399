// Styles for PromotionNotAvailable go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';
export const StyledPromotionNotAvailable = styled.div`
  ${rtl`
&:is(${constants.RTL_LANG_LIST}) {
  text-align: right;
}
  margin-top: ${toRem(40)};
  ul {
    padding-left: ${toRem(16)};
  }
  li {
    padding-bottom: ${toRem(8)};
  }
  .divider {
    border-bottom: ${toRem(2)} solid ${baseVariables.color['neutral30']};
  }
  .btn {
    min-width: ${toRem(154)};
    @media ${baseVariables.mediaQuery.sm} {
      min-width: ${toRem(128)};
    }
  }
  .join-btn{
    margin-left: 0;
  }
  .join-btn:is(${constants.RTL_LANG_LIST}){
    margin-left: ${toRem(24)};
    @media ${baseVariables.mediaQuery.md} {
      margin-left: ${toRem(16)};
    }
  }
  .sign-in-btn:is(${constants.RTL_LANG_LIST}){
    margin-left: 0 !important;
  }`}
`;
