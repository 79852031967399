// Styles for ConfirmIdentity go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledConfirmIdentity = styled.div`
  ${rtl`
    .confirm-identity-container {
      &:is(${constants.RTL_LANG_LIST}){
        text-align: justify;
      }
      &__cta {
        &--cancel {
          font-size: ${toRem(14)};
        }
        &--sendcode {
          width: 100%;
          @media ${baseVariables.mediaQuery.md} {
            width: auto;
          }
          &:is(${constants.RTL_LANG_LIST}){
            margin-left: unset !important;
            margin-right: unset !important;
            @media ${baseVariables.mediaQuery.md} {
              margin-left: ${toRem(32)} !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
    .radio_btn {
      margin-bottom: ${toRem(12)};
    }
    .radio_btn:before {
      height: ${toRem(18)};
      width: ${toRem(18)};
      top: ${toRem(1)};
      left: ${toRem(1)};
    }
    .skeleton {
      &__title {
        width: ${toRem(250)};
        height: ${toRem(24)};
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(350)};
          height: ${toRem(30)};
        }
      }
      &__subtitle {
        width: ${toRem(300)};
        height: ${toRem(18)};
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(450)};
          height: ${toRem(24)};
        }
      }
    }
  `}
`;
