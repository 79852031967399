// Imports for external libraries go here.
import { FC } from 'react';
import clsx from 'clsx';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Link } from '@marriott/mi-ui-library';
import { OverviewSectionHeadingProps } from './OverviewSectionHeading.types';
import { StyledOverviewSectionHeading } from './OverviewSectionHeading.styles';
import { addSubDirectoryPrefix } from '../../modules';

// Use named rather than default exports.
export const OverviewSectionHeading: FC<OverviewSectionHeadingProps> = props => {
  const { title, ctaLabel, ctaPath, sectionHeadingClass, isComponentMoved, isLinkOpenNewTab } = props;

  return (
    <StyledOverviewSectionHeading
      data-component-name="m-account-OverviewSectionHeading"
      data-testid="account-OverviewSectionHeading"
      isComponentMoved={isComponentMoved}
      className={clsx('d-flex justify-content-between', sectionHeadingClass ?? '')}
    >
      <span className="color-scheme1 t-overline-normal">{title}</span>
      {ctaLabel && (
        <Link
          text={ctaLabel}
          linkClassName="m-link-tertiary-button"
          linkHref={`${addSubDirectoryPrefix(ctaPath ?? '/')}`}
          target={clsx(!isLinkOpenNewTab ? '_self' : '_external')}
          linkType="internal"
        />
      )}
    </StyledOverviewSectionHeading>
  );
};
