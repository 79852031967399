// Styles for CancelReservationDetails go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledCancelReservationDetails = styled.div`
  .cancel-description {
    height: calc(100vh - ${toRem(290)});
    overflow: auto;

    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(462)};
    }

    @media ${baseVariables.mediaQuery.xl} {
      height: ${toRem(338)};
    }
  }

  .overlay-footer {
    height: ${toRem(144)};
    width: 100%;

    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(80)};
    }
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }

  .points-texts {
    background-color: ${baseVariables.color.neutral20};
    border-radius: ${toRem(8)};
  }

  .card__image-hotel {
    width: 100%;
    height: ${toRem(144)};
    border-radius: ${toRem(8)};

    @media ${baseVariables.mediaQuery.md} {
      width: ${toRem(240)};
      height: ${toRem(160)};
    }
  }

  .cancel-button {
    background: none;
    border-bottom: ${toRem(1)} solid ${baseVariables.color.merch30};
    height: fit-content;
    &::after,
    &::before {
      display: none;
    }
  }

  .cancel-room-btn {
    width: calc(100% - ${toRem(48)});
    @media ${baseVariables.mediaQuery.md} {
      width: auto;
    }
  }
`;
