import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

// any custome style for step one would come up here
export const StyledStepOne = styled.div`
  ${rtl`
    .step-one__question--radiobutton:is(${constants.RTL_LANG_LIST}) {
      .m-radio-button-sm:is(${constants.RTL_LANG_LIST}) {
        margin: 0 0 0 ${toRem(16)} !important;
        padding: 0 0 0 ${toRem(32)} !important;

        // update the css for radio rtl styles
        & input[type=radio] {
          &+label {
            padding-left: 0;
            padding-right: ${toRem(25)};

            &:before {
              right: 0;
            }

            &:after {
              right: ${toRem(5)};
            }
          }
        }
      }
    }

    .icon-arrow-down:is(${constants.RTL_LANG_LIST}),
    .icon-arrow-up:is(${constants.RTL_LANG_LIST}),
    .m-message-content:is(${constants.RTL_LANG_LIST}) {
      padding-right: ${toRem(8)} !important;
    }

    .m-input-field input:is(${constants.RTL_LANG_LIST}) {
      padding-right: ${toRem(16)} !important;
      height: ${toRem(44)} !important;
    }

    .m-input-field label:is(${constants.RTL_LANG_LIST}) {
      left: auto !important;
      right: ${toRem(11)} !important;
    }
  `}
`;
