// Imports for external libraries go here.
import React, { FC, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { inspect } from 'util';
import { useQuery } from '@apollo/client';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Button, Heading, Icon, InputTextField, Link, Messages, RichText, Types } from '@marriott/mi-ui-library';
import { PageContext, useCheckBreakpoint, constants } from '../../../modules';
import { phoenixAccountGetAirlineTransactionDetail } from '../../../modules/graph';
import { useAccountPersistentStore } from '../../../modules/store/accountPersistentStore';
import { usePointsTransferStore } from '../../../modules/store/pointsTransferStore';
import { Dropdown } from '../../../molecules/Dropdown';
import {
  apiLogger,
  generateApolloClientHeaders,
  numberWithCommas,
  validateFrequentFlyerProgram,
} from '../../../modules/utils';
import { replaceText } from '../../../modules/utils/promotionHelper';
import {
  handlePartnerPoints,
  updatePointsTransferDataLayer,
  errorKeyHandler,
} from '../PointsTransferUtils/PointsTransferHelper';
import {
  FormDataErrors,
  PartnerDetails,
  PartnerList,
  PartnersList,
  FormData,
} from '../TransferPartnerPoints/TransferPartnerPoints.types';
import { CustomError, PointsToMilesProps } from './PointsToMiles.types';
import { StyledPointsToMiles } from './PointsToMiles.styles';
import frequentFlyerMock from './__mock__/FrequentFlyerMock.json';
import airlineTransationDetailMock from './__mock__/GetAirlineTransactionDetailMock.json';

// Use named rather than default exports.
export const PointsToMiles: FC<PointsToMilesProps> = props => {
  const { IS_LOCAL_DEV } = process.env;
  const isTabletViewPort = useCheckBreakpoint('viewportM');
  const defaultPartner = props?.frequentFlyersList?.[0];
  const defaultPartnerErrorList = defaultPartner ? props?.errorList[defaultPartner?.id]?.[0] : {};
  const defaultErrorsList = props?.errorList?.['default']?.[0];
  const [isUXLError, setIsUXLError] = useState(false);
  const { errorKey, setErrorKey, transferPointsDetails, setTransferPointsDetails } = usePointsTransferStore(
    store => store
  );
  const initailState = {
    partnerID: defaultPartner?.id || '',
    partnerName: defaultPartner?.value || '',
    partnerAccountNumber: '',
    points: '',
    convertedPoints: '',
  };

  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [partnersList, setPartnersList] = useState<PartnersList | undefined>();
  const [partnerDetails, setPartnerDetails] = useState<PartnerDetails | undefined>();
  const [formData, setFormData] = useState<FormData>(initailState);
  const [errorList, setErrorList] = useState(defaultPartnerErrorList);
  const [formErrors, setFormErrors] = useState<FormDataErrors>({});
  const [pointsError, setPointsError] = useState<string | undefined>('');
  const [accountNumberError, setAccountNumberError] = useState<string | undefined>('');
  const { currentPointBalance } = useAccountPersistentStore(store => store);
  const [pointsBalance, setPointsBalance] = useState<number | undefined>();

  const formErrorsList = Object.values(formErrors).filter((error: string) => error !== undefined);
  const isBtnDisabled =
    !!pointsError || !!accountNumberError || !formData?.convertedPoints || !formData?.partnerAccountNumber;

  useEffect(() => {
    if (currentPointBalance !== undefined) {
      setPointsBalance(currentPointBalance);
    }
  }, [currentPointBalance]);

  const { data: airLineTransactionDetail } = useQuery(phoenixAccountGetAirlineTransactionDetail, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    fetchPolicy: 'no-cache',
    onError: error => {
      apiLogger(
        `[PointsTransfer] getAirlineTransactionDetail - sessionId: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    },
  });

  useEffect(() => {
    if (errorKey) {
      errorKeyHandler(
        errorKey,
        setFormErrors,
        defaultErrorsList as { [key: string]: string },
        transferPointsDetails.partnerConfig,
        () => {
          setIsUXLError(true);
        }
      );
      updatePointsTransferDataLayer(transferPointsDetails?.partnerConfig?.dataLayerName ?? '');
      setTransferPointsDetails({
        partnerId: '',
        partnerName: '',
        partnerNumber: '',
        pointsToTransfer: '',
        typeOfPointsTransfer: '',
        partnerConfig: undefined,
      });
      setErrorKey('');
    }
  }, [errorKey]);

  useEffect(() => {
    const getPartnersDetails = async () => {
      try {
        const response = await axios.get(props?.convertPointsMappingUrl);
        const partnersListReponse = response.data.pointsTransferData;
        setPartnersList(partnersListReponse);
        setPartnerDetails(partnersListReponse?.default);
      } catch (error) {
        setIsUXLError(true);
        apiLogger(
          `[TransferPartnerPoints] getPartnersDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(error)}`
        );
      }
    };
    if (props?.isAuthorMode) {
      setPartnersList(frequentFlyerMock?.pointsTransferData);
      setPartnerDetails(frequentFlyerMock?.pointsTransferData.default);
    } else {
      getPartnersDetails();
    }
  }, []);

  const handlePartnerNameChange = (event: PartnerList) => {
    setErrorList(props.errorList?.['default']?.[0]);
    const partnerId = event.id;
    setPartnerDetails(partnersList?.[partnerId]);
    setFormErrors({}); // clear all error messages when airline changes
    setPointsError('');
    const preFilledAccountNumber = (
      props?.isAuthorMode
        ? airlineTransationDetailMock.airlines
        : airLineTransactionDetail?.customer?.loyaltyInformation?.programPartners?.airlines
    )?.find(
      (airlineTransaction: {
        account?: string;
        partner: {
          code?: string;
        };
      }) => airlineTransaction?.partner?.code === event?.id
    )?.account;
    setFormData({
      ...initailState,
      partnerID: event?.id,
      partnerName: event?.value,
      ...(preFilledAccountNumber && { partnerAccountNumber: preFilledAccountNumber }),
    });
  };

  const handlePartnerAccountNumberChange = (accountNumber: string) => {
    let inlineAccountError;
    if (accountNumber && !RegExp(props?.airlineNumberRegex || '')?.test(accountNumber)) {
      inlineAccountError = defaultErrorsList?.invalidFrequentFlyerErrorMessage;
    }
    setAccountNumberError(inlineAccountError);
    setFormData({ ...formData, partnerAccountNumber: accountNumber });
  };

  const handlePartnerPointsChange = (points: string) => {
    const partnerPointsUpdatedData = handlePartnerPoints(
      points,
      partnerDetails ?? {},
      formData,
      errorList ?? {},
      pointsBalance,
      defaultErrorsList
    );

    setPointsError(partnerPointsUpdatedData?.inlinePointsError);
    setFormData({
      ...formData,
      points: points,
      convertedPoints: !partnerPointsUpdatedData?.inlinePointsError ? partnerPointsUpdatedData?.convertedPoints : '',
    });
  };

  const validateAirlineAccountNumber = async () => {
    return axios.post(
      validateFrequentFlyerProgram,
      {
        airlineAccountNumber: formData?.partnerAccountNumber,
        airlineName: partnersList?.[formData?.partnerID]?.enumCode || '',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  };

  const handlePointsSubmission = async () => {
    setisLoading(true);
    setIsUXLError(false); // initializing UXL error to false on submit
    setFormErrors({});
    const errors: FormDataErrors = {};

    //validate frequent flyer number
    if (formData?.partnerAccountNumber) {
      try {
        const response = await validateAirlineAccountNumber();
        if (response.status === 200) {
          const pointsDetails = {
            partnerId: formData?.partnerID,
            partnerName: formData?.partnerName,
            partnerNumber: formData?.partnerAccountNumber,
            pointsToTransfer: formData?.points,
          };
          props?.setTransferPointsDetails({
            ...pointsDetails,
            isVerifyConvertPoints: true,
            partnerConfig: partnerDetails,
          });
        }
      } catch (error) {
        if ((error as CustomError)?.response?.data?.phoenixErrorMessages?.errorMessages?.[0]) {
          errors.invalidFrequentFlyerErrorMessage = errorList?.invalidFrequentFlyerErrorMessage;
          setFormErrors(errors);
        }
      } finally {
        setisLoading(false);
      }
    }
  };

  return (
    <StyledPointsToMiles>
      <Heading
        customClass={'mb-3'}
        titleText={props?.transferHeader}
        variation={Types.headingType.title}
        fontSize={Types.size.small}
      />

      {partnerDetails?.minPointsToTransfer ? (
        <Heading
          customClass="subheader mb-4 mb-md-5"
          titleText={replaceText(props?.transferSubHeader || '', [
            numberWithCommas(partnerDetails?.minPointsToTransfer || ''),
          ])}
          variation={Types.headingType.body}
          fontSize={Types.size.medium}
        />
      ) : (
        <div className="skeleton-loader mb-4 mb-md-5 text-loader" />
      )}
      {isUXLError ? (
        <Messages messageType="error-sev1" className="mt-2 mt-md-0 mb-5 px-2 points-page-error-msg">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      ) : (
        !!formErrorsList?.length && (
          <Messages messageType="error-sev1" className="mt-2 mt-md-0 mb-5 px-2 points-page-error-msg t-font-s">
            <RichText text={formErrorsList?.[0]} componentId="uxl-error-msg" />
          </Messages>
        )
      )}
      <div className="current-balance p-5 d-flex justify-content-start mb-4 mb-md-5">
        <Icon iconClass="icon-buy-points icon-l mi-icon icon-decorative mt-1 mb-auto mb-md-0"></Icon>
        {pointsBalance !== undefined ? (
          <span className={clsx('my-auto', isTabletViewPort ? 't-subtitle-xl' : 't-subtitle-l')}>
            {replaceText(props?.currentBalText || '', [numberWithCommas(pointsBalance)])}
          </span>
        ) : (
          <div className="skeleton-loader current-balance__loader" />
        )}
      </div>
      <div className="d-flex flex-column transfer-details-container">
        <div className="transfer-details-container__header p-4 p-md-5">
          <Heading
            element={Types.tags.span}
            titleText={props?.transferSectionHeader}
            variation={Types.headingType.subtitle}
            fontSize={Types.size.extraLarge}
          />
        </div>
        <div className="transfer-details-container__form px-4 px-md-5 py-4 py-md-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="m-input-field">
                <label
                  className="transfer-details-container__form__partnerlabel"
                  id="dropdown-label-fp-partner"
                  htmlFor="dropdown-label-fp-partner"
                  data-testid="partner-label"
                >
                  {props?.partnerLabel}
                </label>
                <div className="transfer-details-container__form__dropdown-container">
                  <Dropdown
                    defaultOption={formData?.partnerName}
                    dropdownOptions={props?.frequentFlyersList || []}
                    onChange={event => handlePartnerNameChange(event)}
                    customClassName="transfer-details-container__form__dropdown-container__dropdown is-active"
                    dropdownId="fp-partner"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <InputTextField
                testId="partner-account-number-field"
                iconClass="icon icon-clear icon-m input-valid-check"
                label={props?.partnerAccountNumberLabel}
                inputValue={formData?.partnerAccountNumber}
                showErrorMessage={!!formErrors?.invalidFrequentFlyerErrorMessage}
                showIcon={!!formErrors?.invalidFrequentFlyerErrorMessage}
                setErrorHtml={true}
                messageClass="error-label"
                inputMaxLength={20}
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'partneraccountnumber',
                })}
                getLabelProps={() => ({
                  htmlFor: 'partneraccountnumber',
                })}
                className="m-input-field mt-4 mt-md-0"
                getInputValue={(val: string) => {
                  handlePartnerAccountNumberChange(val);
                }}
              />
              {accountNumberError && (
                <div className="t-label-s pt-1 transfer-details-container__form__validation-msg">
                  {accountNumberError}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4 mt-md-5 mb-3 transfer-details-container__form__sectionSubHeader">
              <Heading
                element={Types.tags.span}
                titleText={props?.sectionSubHeader}
                variation={Types.headingType.subtitle}
                fontSize={Types.size.medium}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-start align-items-center">
            <div className="col-6 transfer-details-container__form__points">
              <InputTextField
                testId="points-field"
                label={props?.pointsLabel}
                inputValue={formData?.points}
                iconClass="icon icon-clear icon-m input-valid-check"
                showIcon={formErrors?.invalidPoints !== undefined || !!pointsError}
                showErrorMessage={formErrors?.invalidPoints !== undefined}
                setErrorHtml={true}
                inputMaxLength={20}
                messageClass="error-label"
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'points',
                })}
                getLabelProps={() => ({
                  htmlFor: 'points',
                })}
                className={clsx('m-input-field', { 'is-error': !!pointsError })}
                getInputValue={(val: string) => {
                  handlePartnerPointsChange(val);
                }}
              />
            </div>
            <span className=" transfer-details-container__form__separator t-subtitle-m">
              {constants.CONVERSION_POINTS_SYMBOL}
            </span>
            <div className="col-6">
              <InputTextField
                testId="convert-points-field"
                label={props?.milesLabel}
                inputValue={formData?.convertedPoints}
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'converted-points',
                  readOnly: true,
                })}
                getLabelProps={() => ({
                  htmlFor: 'converted-points',
                })}
                className="m-input-field t-body-m is-disabled"
                inputTextFieldClassName="transfer-details-container__form__converted-points"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {pointsError && (
                <div className="t-label-s pt-1 transfer-details-container__form__validation-msg">{pointsError}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {partnerDetails?.excludedBonusMiles ? (
        <RichText
          text={replaceText(props?.userInstruction || '', [numberWithCommas(partnerDetails?.excludedBonusMiles || '')])}
          customClass={'t-body-m user-instruction mt-4 mt-md-5'}
          componentId={'user-instruction'}
        />
      ) : (
        <div className="skeleton-loader mt-4 mt-md-5 description-loader" />
      )}
      <div className="col-12 d-flex align-items-center flex-column flex-md-row mt-4 mt-md-5 px-0 space-bottom">
        <Button
          ariaLabel="continuebtn"
          testId="continuebtn"
          isDisabled={isLoading || isBtnDisabled}
          className={clsx('m-button-m m-button-primary continue_btn', isBtnDisabled ? 'disabled' : '')}
          type={Types.ButtonTypeVariation.Button}
          callback={handlePointsSubmission}
        >
          {isLoading ? <div className="m-spinner-s"></div> : props?.continueButtonLabel}
        </Button>
        <Link
          text={props?.cancel}
          linkClassName="m-link-action mt-5 mt-md-0 ml-md-5 cancel"
          linkHref={props?.cancelCtaPath || ''}
          target="_self"
          linkType="internal"
        />
      </div>
    </StyledPointsToMiles>
  );
};
