import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables as theme, toRem } from '@marriott/mi-ui-library';
import { SkeletonLoaderDuration, constants } from '../../modules';

const StyledMyActivity = styled.div`
  ${rtl`
      .table-responsive {
        border: ${toRem(2)} solid ${theme.color['neutral10']};
        border-radius: ${toRem(16)};
        background-color: ${theme.color['base20']};
      }
      .action-text:is(${constants.RTL_LANG_LIST}){
        text-align: left !important;
      }
      .fade-txt {
        color: ${theme.color['neutral40']};
      }

      .border-line {
        border-bottom: ${toRem(1)} solid ${theme.color['neutral10']};
      }

      .button-bg-color{
        background-color: ${theme.color['base20']};
      }
      .custom-hotel-name{
        &:hover{
          text-decoration:underline;
        }
      }

      .skeleton-style {
        width: 100%;
        height: ${toRem(20)};
        &:after {
          animation-duration: ${SkeletonLoaderDuration};
        }
        &-filter-loader{
          height: ${toRem(100)};
        }
        &-table-loader{
          height: ${toRem(400)};
        }
      }

      .activity-pagination {
        .dropdown {
          .m-custom-select-block {
            button {
              padding: ${toRem(8)};
            }
          }
        }
      }

      .icon-star:is(${constants.RTL_LANG_LIST}) {
        margin: 0 ${toRem(8)} 0 0 !important;
      }

      &:is(${constants.RTL_LANG_LIST}) {
        .m-link-tertiary-button{
          margin-left: ${toRem(27)} !important;
        }
        .m-link-tertiary-button::after {
          transform: rotate(-180deg) !important;
        }
      }

      .customized-icon {
        font-size: ${toRem(16)};
        line-height:${toRem(32)}
      }
  `}
`;
export default StyledMyActivity;
