import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledToggleSwitch = styled.div`
  ${rtl`
    .item-tabs {
      position: relative;
      padding-bottom: ${toRem(64)};

      &__button {
        right: 0;
        bottom: 0;
        float: right;
        position: absolute;

        @media ${baseVariables.mediaQuery.md} {
          top: ${toRem(22)};
        }
      }
      // custom class structure added for tabComponent not adaptable as a generic classes
      .tab-wrapper {
        padding-top: ${toRem(32)};
        padding-bottom: 0;
        box-shadow: none;
        .m-standard-tab-list {
          margin-left: 0 !important;
          width: 100%;
        }

        .tab-desc {
          margin-top: 0 !important;
        }
      }
    }

    .item-tabs__button:is(${constants.RTL_LANG_LIST}) {
      float: none !important;
      left: 0 !important;
      right: auto !important;
      padding-left: ${toRem(16)} !important;
    }

    .m-standard-tab-list-item:is(${constants.RTL_LANG_LIST}) {
      margin: 0 0 0 ${toRem(16)} !important;
    }

    .item-tabs__button:is(${constants.RTL_LANG_LIST})  {
      .m-button-secondary-icon [class*=icon]:before {
        padding: 0 0 0 ${toRem(8)} !important;
      }
    }
  `}
`;
