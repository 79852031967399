// Styles for EditFolioOptions go here.
import { toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import { constants } from '../../modules';

export const StyledFolioOptions = styled.div`
  .text_modal-align:is(${constants.RTL_LANG_LIST}) {
    text-align: right;
  }
  .folio-radiobtn:is(${constants.RTL_LANG_LIST}) {
    label {
      padding-right: ${toRem(32)};
      ::before {
        left: unset;
        right: 0;
      }
      ::after {
        left: ${toRem(45)};
        right: ${toRem(6)};
      }
      span {
        margin-right: ${toRem(28)};
      }
    }
  }
`;
