import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';
export const StyledGlobalEAAHeader = styled.div`
  .header-container {
    height: ${toRem(88)};
    overflow: hidden;
    border: ${toRem(1)} solid ${constants.BORDER10};
    background-color: ${baseVariables.color['base20']};
    box-shadow: 0 ${toRem(5)} ${toRem(14)} 0 ${constants.BORDER10};
  }
`;
