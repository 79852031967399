// Styles for MissingStayRequestConfirmation go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledReportMissingStayRequestConfirmation = styled.div`
  ${rtl`

     .next-link-container:is(${constants.RTL_LANG_LIST}){
      text-align: right !important;
     }
    .request-confirmation{
      border-radius: ${toRem(10)};
      &__body:is(${constants.RTL_LANG_LIST})
      &__heading:is(${constants.RTL_LANG_LIST}){
        text-align: right !important;
      }
      &__line {
        color: ${baseVariables.color['neutral30']}
      }
      &__next-link:is(${constants.RTL_LANG_LIST}){
        &:after{
          transform: rotate(-180deg) translate(${toRem(-6)}, 0px);
        }
      }
    }
    .request-confirmation-link{
      .m-link-back-tertiary-button{
        &:before{
          position:static;
          transform:rotate(-180deg) translate(${toRem(-6)},${toRem(-2)});
        }
      }
      .m-link-back-tertiary-button:is(${constants.RTL_LANG_LIST}){
        &:before{
          line-height:1;
          transform: none;
        }
      }
    }
  `}
`;
