// Styles for OffersCard go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const OverviewOffersStyles = styled.div`
  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      .m-link-tertiary-button{
        margin-left: ${toRem(27)} !important;
      }
      .m-link-tertiary-button::after {
        transform: rotate(-180deg) !important;
      }
      .glide__slides,
      .glide__track {
        overflow: hidden;
        .chevronContainer {
          margin: 0 auto;
          @media ${baseVariables.mediaQuery.md} {
            margin: 0px;
            &::before{
              right: ${toRem(232)} !important;
            }
            &.icon-arrow-right-external {
              &:hover::before {
                transform: rotate(-135deg); 
              }
            }
          }
        }
      }
    }
   
    .card-layered {
      &:is(${constants.RTL_LANG_LIST}) {
        text-align: justify;
      }
      picture img {
        height: ${toRem(332)};
        width: ${toRem(332)};
        @media ${baseVariables.mediaQuery.sm} {
          height: ${toRem(342)};
          width: ${toRem(342)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          height: ${toRem(269)};
          width: ${toRem(268)};
        }
      }
      &__content {
        background: ${baseVariables.gradient['baseGradient05']};
        .card-texts {
          &:is(${constants.RTL_LANG_LIST}) {
            padding-right: ${toRem(24)} !important;
          }
        }
      }
    }
    .list-unstyled {
      list-style-type: none;
    }
    .glide__slides,
    .glide__track {
      overflow: hidden;
      .chevronContainer {
        margin: 0 auto;
        @media ${baseVariables.mediaQuery.md} {
          margin: 0;
        }
      }
    }
  `}
`;
