/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { baseVariables, getEventKeyTypes, Icon } from '@marriott/mi-ui-library';
import { StepThreeContainer } from './ReportMissingStayStepThree.styles';

const FileUpload = dynamic(() => import('../../../molecules/FileUpload/FileUpload').then(mod => mod.FileUpload));
const Dropdown = dynamic(() => import('../../../molecules/Dropdown/').then(mod => mod.Dropdown));

export const StepThree = (props: any) => {
  const authorModelData = props?.authorModelData;
  const currentLocale = props?.currentLocale;
  const [toggleAccordian, setToggleAccordian] = useState(false);
  const [selectedState, setSelectedState] = useState(
    authorModelData?.states?.length ? authorModelData?.states[0] : null
  );
  const [selectedCountry, setSelectedCountry] = useState(
    authorModelData?.countries?.length ? authorModelData?.countries[0] : null
  );

  useEffect(() => {
    if (authorModelData?.countries?.length) {
      authorModelData?.countries.forEach((country: any) => {
        if (currentLocale?.split('-')?.[1] === country.id) {
          setSelectedCountry(country);
          props?.setFormFields({ ...props?.formFields, scanHotelCountry: country.value });
        }
      });
    }
  }, []);

  const handleCommentsChange = (e: any) => {
    props?.setFormFields({ ...props?.formFields, comments: e.target.value });
  };

  const handleAccoridanToggle = () => {
    setToggleAccordian(!toggleAccordian);
  };

  const handleStateNameChange = (option: any) => {
    setSelectedState(option);
    props?.setFormFields({ ...props?.formFields, scanHotelState: option.id });
  };

  const handleCountryNameChange = (option: any) => {
    setSelectedCountry(option);
    props?.setFormFields({ ...props?.formFields, scanHotelCountry: option.id });
  };

  const handleRadioButtonKeyDown = (e: any, value: string) => {
    if (getEventKeyTypes(e).isEnterKey) {
      e.preventDefault(); // Prevent form submission when using Enter on the radio button
      props?.setFormFields({
        ...props?.formFields,
        scannedCopySelection: value === 'attach',
        faxOrMailSelection: value === 'fax',
      });
    }
  };

  const handleAccordionKeyDown = (e: any) => {
    if (getEventKeyTypes(e)?.isEnterKey) {
      handleAccoridanToggle();
    }
  };

  return (
    <StepThreeContainer>
      <div
        className={clsx(
          'step-three',
          'mx-4 mx-md-5',
          'pt-2',
          props?.formFields?.thirdPartyBooking !== false ||
            (!props?.formFields?.paperBillSelection &&
              !props?.formFields?.noBillSelection &&
              !props?.formFields?.digitalBillSelection)
            ? 'blur'
            : ''
        )}
        data-testid="step-three-container"
      >
        <h2 className="step-three__heading t-subtitle-l mb-lg-0 mb-3" data-testid="step-3">
          {authorModelData?.stepThreeSectionTitle}
        </h2>

        {props?.formFields?.paperBillSelection && (
          <div className="step-three__submitrequest my-4">
            <div className=" t-font-m pb-4">{authorModelData?.stepThreeHeader}</div>
            <div className="step-three__question--radiobutton" role="radiogroup">
              <div className="m-radio-button-sm pb-2">
                <input
                  type="radio"
                  id="attach"
                  value="attach"
                  data-testid="attach"
                  checked={props?.formFields?.scannedCopySelection}
                  onChange={() => {
                    props?.setFormFields({
                      ...props?.formFields,
                      faxOrMailSelection: false,
                      scannedCopySelection: true,
                    });
                  }}
                  onKeyDown={e => handleRadioButtonKeyDown(e, 'attach')}
                  aria-checked={props?.formFields?.scannedCopySelection}
                />
                <label htmlFor="attach" className="t-font-s">
                  {authorModelData?.billRadioOptionOneLabel}
                </label>
              </div>
              <div className="m-radio-button-sm">
                <input
                  type="radio"
                  id="fax"
                  value="fax"
                  data-testid="fax"
                  checked={props?.formFields?.faxOrMailSelection}
                  onChange={() => {
                    props?.setFormFields({
                      ...props?.formFields,
                      faxOrMailSelection: true,
                      scannedCopySelection: false,
                    });
                  }}
                  onKeyDown={e => handleRadioButtonKeyDown(e, 'fax')}
                  aria-checked={props?.formFields?.faxOrMailSelection}
                />
                <label htmlFor="fax" className="t-font-s">
                  {authorModelData?.billRadioOptionTwoLabel}
                </label>
              </div>
            </div>
          </div>
        )}

        {props?.formFields?.noBillSelection && (
          <div className="step-three__nobill mt-3 t-font-m">
            <span dangerouslySetInnerHTML={{ __html: authorModelData?.statement }}></span>
          </div>
        )}

        {/* for one condion the UI starts from heer */}
        {!props?.formFields?.noBillSelection &&
          (props?.formFields?.digitalBillSelection || props?.formFields?.scannedCopySelection) && (
            <div className="step-three__attachbill mt-4">
              <div className="t-font-m mt-3">{authorModelData?.hotelBillSectionTitle}</div>
              <div
                role="button"
                className="step-three__attachbill--accordian t-font-s mt-2"
                tabIndex={0}
                data-testid="accordian"
                onClick={() => handleAccoridanToggle()}
                onKeyDown={e => handleAccordionKeyDown(e)}
              >
                <span className="attachbill-accordian">{authorModelData?.stepThreeAccordionTitle}</span>
                <Icon
                  iconClass={clsx('pl-2', !toggleAccordian ? 'icon-arrow-down' : 'icon-arrow-up')}
                  ariaLabel={!toggleAccordian ? 'icon-arrow-down' : 'icon-arrow-up'}
                />
              </div>
              {toggleAccordian && (
                <div
                  className="t-font-s pt-2"
                  dangerouslySetInnerHTML={{ __html: authorModelData?.stepThreeAccordionDescription }}
                />
              )}
            </div>
          )}

        {(props?.formFields?.scannedCopySelection || props?.formFields?.digitalBillSelection) && (
          <div
            className={clsx(
              'step-three__fields mt-3',
              props?.formFields?.thirdPartyBooking === true ? 'show-disabled' : ''
            )}
          >
            <FileUpload
              authorModelData={authorModelData}
              setFormFields={props?.setFormFields}
              formFields={props?.formFields}
              errorStates={props?.errorStates}
              setErrorStates={props?.setErrorStates}
            />
            <label className="step-three__fields--uploadformat py-4 t-font-s" htmlFor="upload" id="upload">
              {authorModelData?.formatsLabel}
            </label>
          </div>
        )}

        {/* for one condion the UI ends here */}

        {!props?.formFields?.noBillSelection &&
          (props?.formFields?.scannedCopySelection || props?.formFields?.faxOrMailSelection) && (
            <div className="step-three__faxRequest mt-3">
              <div className="step-three__faxRequest--firstrow row mb-0 mb-md-4">
                <div className="col-12 col-md-6 mb-5 mb-md-0">
                  <div
                    className={clsx(
                      'm-input-field',
                      props?.formFields?.scanHotelCity ? 'is-active' : '',
                      props?.errorStates?.scanHotelCity ? 'is-error' : ''
                    )}
                    data-testid="hotelcity-container"
                  >
                    <label htmlFor="scanHotelCity">{authorModelData?.hotelCityLabel}</label>
                    <input
                      type="text"
                      id="scanHotelCity"
                      data-testid="hotelcity"
                      maxLength={21}
                      value={props?.formFields?.scanHotelCity}
                      style={{ caretColor: baseVariables.color['base10'] }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props?.setFormFields({ ...props?.formFields, scanHotelCity: event?.target?.value });
                      }}
                    />
                    {props?.errorStates?.scanHotelCity && (
                      <div className="t-label-s validation-msg step-three__fields--error-state">
                        {authorModelData?.cityErrorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-5 mb-md-0">
                  <div
                    className={clsx(
                      'm-input-field',
                      props?.formFields?.scanHotelCountry ? 'is-active' : '',
                      props?.errorStates?.scanHotelCountry ? 'is-error' : ''
                    )}
                    data-testid="hotelcountry"
                  >
                    <label className="hotelCountry-label" htmlFor="scanHotelCountry" id="dropdown-label-hotel-country">
                      {authorModelData?.hotelCountryDropDownMenuLabel}
                    </label>
                    <Dropdown
                      defaultOption={selectedCountry?.value}
                      dropdownOptions={authorModelData?.countries ?? []}
                      onChange={handleCountryNameChange}
                      customClassName={clsx(
                        'step-three__row--countrydropdown',
                        props?.formFields?.scanHotelCountry ? 'is-active' : '',
                        props?.errorStates?.scanHotelCountry ? 'is-error' : ''
                      )}
                      dropdownId="hotel-country"
                    />
                  </div>
                </div>
              </div>

              <div className="step-three__faxRequest--secondrow row">
                <div className="col-12 col-md-6 mb-5 mb-md-0">
                  <div className="m-input-field is-active" data-testid="hotelstate">
                    <label className="hotelState-label" htmlFor="scanHotelState" id="dropdown-label-hotel-state">
                      {authorModelData?.hotelStateDropDownMenuLabel}
                    </label>
                    <Dropdown
                      defaultOption={selectedState?.value}
                      dropdownOptions={authorModelData?.states ?? []}
                      onChange={handleStateNameChange}
                      customClassName="step-three__row--statedropdown is-active"
                      dropdownId="hotel-state"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

        {!props?.formFields?.noBillSelection && (
          <div
            className={clsx(
              'step-three__comment mt-2 mt-md-4 m-input-field',
              props?.errorStates?.comments && 'is-error'
            )}
            data-testid="comment"
          >
            <label className="step-three__comment--label t-label-s px-1" htmlFor="comments">
              {props?.formFields?.folioStayAndPayApplicable && props?.formFields?.folioStayAndPay === 'No'
                ? authorModelData?.commentsTitle
                : authorModelData?.commentsTitleOptional}
            </label>
            <textarea
              className={clsx(
                'step-three__comment--commentsbox t-label-s pt-2',
                props?.errorStates?.comments
                  ? 'step-three__comment--commentsbox--error'
                  : 'step-three__comment--commentsbox--optional'
              )}
              id="comments"
              rows={4}
              cols={35}
              onChange={handleCommentsChange}
              disabled={
                (props?.formFields?.thirdPartyBooking ||
                  props?.pageLoadFlag ||
                  !props?.formFields?.thirdPartyBooking) &&
                !props?.formFields?.digitalBillSelection &&
                !props?.formFields?.paperBillSelection &&
                !props?.formFields?.noBillSelection
              }
              data-testid="commentinput"
            ></textarea>
            {props?.errorStates?.comments && (
              <div className="t-label-s validation-msg">{authorModelData?.commentsErrorMessage}</div>
            )}
          </div>
        )}

        {props?.formFields?.faxOrMailSelection && <div className="t-font-s">{authorModelData?.footerText}</div>}
      </div>
    </StepThreeContainer>
  );
};
