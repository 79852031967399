/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { baseVariables, Link, Icon } from '@marriott/mi-ui-library';
import { getColorByCode, numberWithCommas } from '../../../modules/utils';
import { StayDetailsStyle } from './MemberStayDetails.styles';

interface LabelProps {
  labelClassName: string;
  data: any;
  color: string;
}

export const LabelText: React.FC<LabelProps> = ({ labelClassName, data, color }) => {
  return (
    <div className="text-center">
      {!data && data !== 0 ? (
        /**
         * data can be null/undefined
         * it can be 0 in that case we have to display the value
         */
        <div className="skeleton-loader mb-2 rewards__skeleton__label"></div>
      ) : (
        <span className={labelClassName} data-testid="labels" style={{ color }}>
          {data}
        </span>
      )}
    </div>
  );
};
export const MemberStayDetails = (props: any) => {
  const [colorCode, setColorCode] = useState('');
  const aemResponse = props?.aemResponse;
  const rewardDetails = props?.rewardDetails;
  const memberCode = rewardDetails?.rewards?.level?.code;
  const memberLevelType = memberCode?.toLowerCase() === 'p' ? rewardDetails?.rewards?.levelType?.code : null;
  const totalNightCount = props?.totalNightCount;
  const currentPointBalance = props?.currentPointBalance;

  useEffect(() => {
    if (getColorByCode((memberLevelType ? memberLevelType : memberCode) ?? '', false)) {
      setColorCode(getColorByCode((memberLevelType ? memberLevelType : memberCode) ?? '', false)?.split(' !')?.[0]);
    }
  }, [memberCode, memberLevelType]);

  return (
    <StayDetailsStyle>
      <div className="container__left d-flex justify-content-around justify-content-lg-between my-0 mt-md-3">
        <div className="container__left--points rewards d-flex flex-column">
          <LabelText
            labelClassName="points t-title-m t-title-m-custom"
            data={currentPointBalance === undefined ? undefined : numberWithCommas(currentPointBalance)}
            color={colorCode}
          ></LabelText>
          <LabelText
            labelClassName="points text t-label-xs"
            data={aemResponse?.pointsLabel}
            color={baseVariables.color['neutral40']}
          ></LabelText>
          <div className="buy d-flex justify-content-center align-items-center">
            <Link
              custom_click_track_value="Night Details Tile|Purchase Points|external|event167,evar48=MER-web-nightDetails-buyPoints"
              linkHref={aemResponse?.ctaPath}
              linkClassName="text t-label-xs"
              text={aemResponse?.buyPointsLabel}
            >
              <Icon iconClass="icon-arrow-right ml-1"></Icon>
            </Link>
          </div>
        </div>
        <div className="container__left--nights rewards d-flex flex-column">
          <LabelText
            labelClassName="points night t-title-m t-title-m-custom"
            data={totalNightCount}
            color={colorCode}
          ></LabelText>
          <LabelText
            labelClassName="night text t-label-xs"
            data={aemResponse?.nightsThisYearLabel}
            color={baseVariables.color['neutral40']}
          ></LabelText>
          <div className="t-label-xs"></div>
        </div>
      </div>
    </StayDetailsStyle>
  );
};
