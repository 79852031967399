/* eslint-disable @typescript-eslint/no-explicit-any */
import { SpeedometerConstants } from '../../molecules/NightsProgress/NightsProgress.types';
import {
  UserDataTypes,
  UserLevelDataTypes,
  rewardsSummary,
  MessageTemplatesTypes,
} from '../../organisms/MemberSnapshotRibbon/MemberStatusCard/MemberBenefits/MemberBenefits.types';
import { ComponentDetails } from '../store/overviewStore';
import {
  OVERVIEW_CONSTANT,
  PLATINUM_PREMIER_ELITE_LEVELTYPE,
  PLATINUM_PREMIER_PFR_LEVELTYPE,
  CURRENT_YEAR,
  OverviewMovableComponents,
} from './constants';
import { numberWithCommas } from './numericalFormatsHelper';
import { getMemberStatusDescription, getMemberLevel, canUseDOM } from './helper';
import { replaceText } from './promotionHelper';
export const getSpeedoMeterConstants = (code: string | undefined): SpeedometerConstants => {
  switch (code) {
    case 'P':
    case 'PAC':
    case 'PLR':
    case 'PAR':
      return {
        maxValue: 75,
        breakPoints: [0.0, 0.13, 0.33, 0.66, 1.0],
      };

    case 'PPC':
    case 'PPR':
    case 'PL7':
    case 'PPE':
      return {
        maxValue: 100,
        breakPoints: [0.0, 0.1, 0.25, 0.5, 0.75, 1.0],
      };

    case 'G':
      return {
        maxValue: 50,
        breakPoints: [0.0, 0.2, 0.5, 1.0],
      };

    case 'S':
      return {
        maxValue: 25,
        breakPoints: [0.0, 0.4, 1.0],
      };

    case 'PFS':
    case 'PFR':
    case 'PPAE':
      return {
        maxValue: 100,
        breakPoints: [0.0, 0.1, 0.25, 0.5, 0.75, 1.0],
      };

    default:
      return {
        maxValue: 10,
        breakPoints: [0.0, 1.0],
      };
  }
};

export const getCoordsOnArc = (angle: number, offset = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
];

const {
  LIFEITME_ACHIEVER,
  LIFEITME_RENEWED,
  AMBASSADOR_REFERRAL,
  AMBASSADOR_ACHIEVER,
  AMBASSADOR_RENEWED,
  TITANIUM_ACHIEVER,
  TITANIUM_RENEWED,
  REFERRAL_ACCOUNT,
  ACHIEVER_ACCOUNT,
  RENEWED_ACCOUNT,
  BASIC_MEMBERSHIP,
  REFERRAL_CODE,
  TITANIUM_CODE,
  MEMBER_CODE,
  AMBASSADOR_CODE,
  TITANIUM_REFERRAL,
  TITANIUM_REFERRAL_CODE,
  SILVER_LIFETIME,
  GOLD_LIFETIME,
  PLATINUM_LIFETIME,
} = OVERVIEW_CONSTANT;

export function getLifeTimeCodes(lifeTimeCode: string) {
  let lifeTimeCodes;
  switch (lifeTimeCode) {
    case 'GLR':
      lifeTimeCodes = 'G';
      break;
    case 'PLR':
      lifeTimeCodes = 'P';
      break;
    case 'SLR':
      lifeTimeCodes = 'S';
      break;
    case 'PL7':
      lifeTimeCodes = PLATINUM_PREMIER_ELITE_LEVELTYPE;
      break;
  }
  return lifeTimeCodes;
}
export function getUserLoyaltyStatus(userData: UserDataTypes | undefined) {
  const rewards = userData?.loyaltyInformation?.rewards;
  const lifetimeCode = rewards?.eliteLifetimeLevel?.code;
  const stayCurrentLevelNights = rewards?.eliteNightsToRenewCurrent;
  const levelCode = rewards?.level?.code;
  const levelTypeCode = rewards?.levelType?.code ?? '';
  const coreLevelCode = getMemberLevel(levelCode, levelTypeCode);
  const lifetimeLevelCode = getLifeTimeCodes(lifetimeCode || '');
  const levelTypeCodeUpperCase = levelTypeCode?.toUpperCase();

  if (coreLevelCode === lifetimeLevelCode) {
    return stayCurrentLevelNights === 0 ? LIFEITME_ACHIEVER : LIFEITME_RENEWED;
  } else {
    if (AMBASSADOR_CODE.includes(levelTypeCode)) {
      if (levelTypeCodeUpperCase === PLATINUM_PREMIER_PFR_LEVELTYPE) {
        return AMBASSADOR_REFERRAL;
      } else {
        return stayCurrentLevelNights === 0 ? AMBASSADOR_ACHIEVER : AMBASSADOR_RENEWED;
      }
    } else if (TITANIUM_CODE.includes(levelTypeCode)) {
      if (levelTypeCodeUpperCase === TITANIUM_REFERRAL_CODE) {
        return TITANIUM_REFERRAL;
      } else {
        return stayCurrentLevelNights === 0 ? TITANIUM_ACHIEVER : TITANIUM_RENEWED;
      }
    } else if (MEMBER_CODE.includes(levelTypeCode)) {
      return BASIC_MEMBERSHIP;
    } else if (REFERRAL_CODE.includes(levelTypeCode)) {
      return REFERRAL_ACCOUNT;
    } else {
      return stayCurrentLevelNights === 0 ? ACHIEVER_ACCOUNT : RENEWED_ACCOUNT;
    }
  }
}

/**
 * Calculates the remaining amount based on rewards summary.
 *
 * @param {rewardsSummary} rewardsSummary - The rewards summary data.
 * @param {number} currentYear - The current year for which to calculate the remaining amount.
 * @param {number} achieveAmbassadorLimitAmount - The total amount needed to achieve the ambassador level.
 * @returns {number} The remaining amount as a formatted number.
 */
export function calculateRemainingAmount(
  rewardsSummary: rewardsSummary | undefined,
  currentYear: number,
  achieveAmbassadorLimitAmount: number
) {
  let totalRemainingAmount = 0;

  rewardsSummary?.yearly?.forEach(ele => {
    if (ele?.year === currentYear) {
      totalRemainingAmount = achieveAmbassadorLimitAmount - (ele?.totalRevenue || 0);
    }
  });

  return totalRemainingAmount;
}

/**
 * Generates a message object with title and icon properties.
 *
 * @param {string} title - The title of the message.
 * @param {string} icon - The icon associated with the message.
 * @returns {{ title: string, icon: string }} A message object with title and icon properties.
 */
export const generateMessageObject = (title: string, icon: string) => ({ title, icon });

export const getMovableComponentId = (overviewComponents: ComponentDetails) => {
  const movableComponentList = Object.values(OverviewMovableComponents);
  for (let i = 0; i < movableComponentList.length; i++) {
    if (movableComponentList[i] !== OverviewMovableComponents.NONE) {
      const component = overviewComponents[movableComponentList[i]];
      if (component.isLoading) {
        return '';
      }
      if (component.hasData || component.hasError) {
        return movableComponentList[i];
      }
    }
  }
  return OverviewMovableComponents.NONE;
};

export const membersnapshotribbonHelper = (
  model: any,
  customerData: UserDataTypes | undefined,
  memberStatusList: UserLevelDataTypes,
  isNightsModel = false
) => {
  let {
    otherUsersRenewMessage: renewMsg,
    otherUsersLevelUpMessage: levelUpMsg,
    successfullyRenewedMessage: successMsg,
    ambassadorRenewMessage,
    titaniumLevelUpMessage,
    titaniumOnlyMessage,
    ambassadorPersonalMessage,
    continueAs,
  } = model ?? {};
  const rewards = customerData?.loyaltyInformation?.rewards;
  const rewardsSummary = customerData?.loyaltyInformation?.rewardsSummary;
  const limitAmount = model?.achieveAmbassadorLimitAmount;

  let remainingAmount = calculateRemainingAmount(rewardsSummary, CURRENT_YEAR, Number(limitAmount)).toString();
  remainingAmount = numberWithCommas(remainingAmount);
  const levelTypeCode = rewards?.levelType?.code;
  const stayCurrentLevelNights = rewards?.eliteNightsToRenewCurrent;
  const nextLevelNights = rewards?.eliteNightsToAchieveNext;
  const levelLabel = getMemberStatusDescription(levelTypeCode, memberStatusList);
  const nextLevelCode = rewards?.nextLevelType?.code || '';
  const nextLevel = getMemberStatusDescription(nextLevelCode, memberStatusList);
  const remainingNights = stayCurrentLevelNights || nextLevelNights;

  continueAs = replaceText(continueAs, [levelLabel]);
  renewMsg = replaceText(
    isNightsModel ? renewMsg : model?.otherUsersPillRenewMessage,
    isNightsModel ? [remainingNights?.toString() ?? ''] : [remainingNights?.toString() ?? '', levelLabel]
  );
  renewMsg = isNightsModel ? `${continueAs} # ${renewMsg}` : `${renewMsg}`;
  ambassadorRenewMessage = isNightsModel ? `${continueAs} # ${ambassadorRenewMessage}` : `${renewMsg}`;
  successMsg = isNightsModel ? `${model?.congratulations} # ${successMsg}` : `${model?.congratulations} ${successMsg}`;
  titaniumLevelUpMessage = isNightsModel
    ? `${model?.unlockMoreBenefits} # ${titaniumLevelUpMessage}`
    : `${titaniumLevelUpMessage}`;

  levelUpMsg = isNightsModel ? `${model?.unlockMoreBenefits} # ${levelUpMsg}` : `${levelUpMsg}`;
  titaniumOnlyMessage = replaceText(titaniumOnlyMessage, [nextLevel]);
  ambassadorPersonalMessage = replaceText(ambassadorPersonalMessage, [levelLabel]);

  levelUpMsg = replaceText(levelUpMsg, [nextLevelNights?.toString() ?? '', nextLevel || '']);
  successMsg = replaceText(successMsg, [levelLabel, CURRENT_YEAR.toString()]);
  ambassadorRenewMessage = replaceText(ambassadorRenewMessage, [
    remainingNights?.toString() ?? '',
    remainingAmount?.toString(),
  ]);
  titaniumLevelUpMessage = replaceText(titaniumLevelUpMessage, [
    nextLevelNights?.toString() ?? '',
    remainingAmount?.toString(),
    nextLevel,
  ]);

  const { renew, successfully_renewed, level_up, nights_awards_level, titanium_only, ambassador_only } =
    OVERVIEW_CONSTANT.MEMBER_LEVEL_MSG_ICONS;

  function getIconForRemainingNights(remainingNights: any) {
    if (remainingNights >= 1 && remainingNights <= 7) {
      // If remaining nights are between 1 and 7, use the respective number in the icon
      return nights_awards_level.replace('{nightAward}', remainingNights);
    } else {
      // If remaining nights are more than 7, use the level_up icon
      return level_up;
    }
  }
  const icon_level = getIconForRemainingNights(remainingNights);
  const msgsArray = {
    renewMsgs: generateMessageObject(renewMsg, renew),
    successMsgs: generateMessageObject(successMsg, successfully_renewed),
    levelUpMsgs: generateMessageObject(levelUpMsg, icon_level),
    ambassadorRenewal: generateMessageObject(ambassadorRenewMessage, renew),
    ambassadorSuccess: generateMessageObject(successMsg, successfully_renewed),
    ambassadorOnly: generateMessageObject(ambassadorPersonalMessage, ambassador_only),
    titaniumLevelUp: generateMessageObject(titaniumLevelUpMessage, icon_level),
    titaniumOnly: generateMessageObject(titaniumOnlyMessage, titanium_only),
  };
  const userLoyaltyStatus = getUserLoyaltyStatus(customerData);

  const messageTemplates: MessageTemplatesTypes = {
    LifetimeAchiever: [msgsArray.successMsgs, msgsArray.levelUpMsgs],
    LifetimeRenewed: [msgsArray.levelUpMsgs],
    AmbassadorReferral: isNightsModel
      ? [msgsArray.ambassadorRenewal]
      : [msgsArray.ambassadorRenewal, msgsArray.ambassadorOnly],
    AmbassadorAchiever: isNightsModel ? [msgsArray.successMsgs] : [msgsArray.successMsgs, msgsArray.ambassadorOnly],
    AmbassadorRenewed: [msgsArray.ambassadorRenewal],
    TitaniumReferral: isNightsModel
      ? [msgsArray.renewMsgs, msgsArray.titaniumLevelUp]
      : [msgsArray.renewMsgs, msgsArray.titaniumLevelUp, msgsArray.titaniumOnly],
    TitaniumAchiever: isNightsModel
      ? [msgsArray.successMsgs, msgsArray.titaniumLevelUp]
      : [msgsArray.successMsgs, msgsArray.titaniumLevelUp, msgsArray.titaniumOnly],
    TitaniumRenewed: isNightsModel
      ? [msgsArray.titaniumLevelUp, msgsArray.renewMsgs]
      : [msgsArray.titaniumLevelUp, msgsArray.renewMsgs, msgsArray.titaniumOnly],
    ReferralAccount: [msgsArray.renewMsgs, msgsArray.levelUpMsgs],
    AchieverAccount: [msgsArray.successMsgs, msgsArray.levelUpMsgs],
    RenewedAccount: [msgsArray.levelUpMsgs, msgsArray.renewMsgs],
    BasicMembership: [msgsArray.levelUpMsgs],
  };
  return messageTemplates[userLoyaltyStatus];
};

export const getNextLifetimeLabel = (lifetimeCode: string, memberStatusList: UserLevelDataTypes) => {
  const defaultValue = getMemberStatusDescription(SILVER_LIFETIME, memberStatusList);
  if (lifetimeCode) {
    if (lifetimeCode === SILVER_LIFETIME) {
      return getMemberStatusDescription(GOLD_LIFETIME, memberStatusList);
    } else if (lifetimeCode === GOLD_LIFETIME) {
      return getMemberStatusDescription(PLATINUM_LIFETIME, memberStatusList);
    }
  } else {
    return defaultValue;
  }
  return defaultValue;
};

export const getDirection = () => {
  const directionValue = canUseDOM && document?.body?.getAttribute('dir');
  let direction: 'ltr' | 'rtl' | undefined;

  if (directionValue === 'ltr' || directionValue === 'rtl') {
    direction = directionValue;
  } else {
    direction = 'ltr';
  }
  return direction;
};
