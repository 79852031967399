import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Icon } from '@marriott/mi-ui-library';
import { useGetBreakpoint, BREAKPOINT_TABLET_TEXT, BREAKPOINT_DESKTOP_TEXT } from '../../../modules/utils';
import { PrintableMissingStayRequestFormProps } from './PrintableMissingStayRequestForm.types';
import { StyledPrintableMissingStayRequestForm } from './PrintableMissingStayRequestForm.styles';
export const PrintableMissingStayRequestForm: FC<PrintableMissingStayRequestFormProps> = ({
  isOpen,
  onClose,
  labels,
  values,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [isDesktopViewPort, setIsDesktopViewPort] = useState(false);
  const [isTabletViewPort, setIsTabletViewPort] = useState(false);

  function handleResize() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setIsDesktopViewPort(useGetBreakpoint() === BREAKPOINT_DESKTOP_TEXT);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    setIsTabletViewPort(useGetBreakpoint() === BREAKPOINT_TABLET_TEXT);
  }

  // Listen for changes to the 'isOpen' prop to enable/disable the modal
  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  //logic for breakpoint
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
    window.addEventListener('afterprint', onCloseHandler);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('afterprint', onCloseHandler);
    };
  }, []);

  function onCloseHandler() {
    onClose && onClose();
  }
  if (!isOpen) return null;

  return (
    <StyledPrintableMissingStayRequestForm isOpen={isVisible}>
      <div id="printable-form-wrapper" className="modal justify-content-between align-items-center d-block">
        <div className="modal__content">
          <div className="modal__content__header d-flex pr-5 justify-content-between align-items-center ">
            <div className="modal__content__header__logo">
              <div
                className={clsx(
                  `modal__content__header__logo__marriott-bonvoy ${
                    isDesktopViewPort || isTabletViewPort ? 't-program-logo-alt-color-m' : 't-program-logo-alt-color-s'
                  }`
                )}
                aria-label="Marriott Bonvoy"
              ></div>
            </div>
            <div className="d-flex">
              <div
                className="d-flex mr-5 modal__content__header__print-button"
                onClick={() => window.print()}
                aria-label="print"
                data-testid="modal__content__header__print-button"
              >
                <Icon iconClass="icon-print mt-1" aria-label="print icon"></Icon>
                <div className="ml-1 t-font-xs mt-2">{labels?.printLabel}</div>
              </div>
              <button
                onClick={onClose}
                className="modal__content__header__clear-button"
                aria-label="close-button"
                data-testid="close-button"
              >
                <Icon iconClass="icon-clear" ariaLabel="close icon"></Icon>
              </button>
            </div>
          </div>
          <div className="modal__content__body" aria-label="missing stay request print form">
            <h6 className="t-title-s">{labels?.header}</h6>

            <p className="t-font-s pt-4 modal__content__body__description">{labels?.lineOneText}</p>
            <p className="t-font-s modal__content__body__description">{labels?.lineTwoText}</p>
            <h6
              className={clsx(
                `modal__content__body__sub-heading mt-5 ${isDesktopViewPort ? 't-subtitle-xl' : 't-subtitle-m'}`
              )}
            >
              <span className="mt-md-3 mt-lg-3">{labels?.infoHeader}</span>
            </h6>
            <div className="mt-4" aria-label="user details">
              {labels?.firstNameFieldLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.firstNameFieldLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.firstName || '-'}
                  </section>
                </div>
              )}
              {labels?.lastNameFieldLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.lastNameFieldLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.lastName || '-'}
                  </section>
                </div>
              )}
              {labels?.number && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">{labels?.number}</section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.rewardsNumber || '-'}
                  </section>
                </div>
              )}
            </div>
            <div className="mt-4" aria-label="user contact details">
              {labels?.emailAddressFieldLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.emailAddressFieldLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.emailAddress || '-'}
                  </section>
                </div>
              )}
              {labels?.phoneNumberFieldLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.phoneNumberFieldLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.phoneNumber || '-'}
                  </section>
                </div>
              )}
            </div>
            <div className="mt-4" aria-label="Booking Details">
              {labels?.code && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">{labels?.code}</section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.propertyCode || '-'}
                  </section>
                </div>
              )}
              {labels?.hotelNameLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.hotelNameLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.propertyName || '-'}
                  </section>
                </div>
              )}
              {labels?.hotelCityLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.hotelCityLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.scanHotelCity || '-'}
                  </section>
                </div>
              )}
            </div>
            <div className="mt-4" aria-label="Booking Date details">
              {labels?.checkInDateLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.checkInDateLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.checkInDate || '-'}
                  </section>
                </div>
              )}
              {labels?.checkOutDateLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.checkOutDateLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.checkOutDate || '-'}
                  </section>
                </div>
              )}
              {labels?.confirmationNumberLabel && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.confirmationNumberLabel}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.confirmationNumber || '-'}
                  </section>
                </div>
              )}
            </div>
            <div className="mt-4">
              {labels?.folio && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">{labels?.folio}</section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.folioStayAndPay || '-'}
                  </section>
                </div>
              )}
            </div>
            <div className="mt-4">
              {labels?.commentsTitle && (
                <div className="d-flex">
                  <section className="modal__content__body__section-label mr-3 t-font-s">
                    {labels?.commentsTitle}
                  </section>
                  <section className="modal__content__body__section-content t-font-s">
                    {values?.comments || '-'}
                  </section>
                </div>
              )}
            </div>
            <div className="modal__content__body__address mt-5" aria-label="Guest Service Location Details">
              <h6
                className={`mb-3 mt-2 mt-lg-3 mt-md-3 ${
                  isDesktopViewPort ? 't-subtitle-xl' : isTabletViewPort ? 't-subtitle-m' : 't-subtitle-l'
                }`}
              >
                {labels?.servicesHeader}
              </h6>
              <div>
                {labels?.faxLabel && (
                  <section className="t-font-s">
                    {labels?.faxLabel} {labels?.faxNumber || '-'}
                  </section>
                )}
                {labels?.mailLabel && (
                  <section className="t-font-s">
                    {labels?.mailLabel} {labels?.postalAddress || '-'}
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledPrintableMissingStayRequestForm>
  );
};
