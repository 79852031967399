import { Icon, Text, Link, Types } from '@marriott/mi-ui-library';
import { AmbassadorInfoProps } from './AmbassadorInfo.types';
import { StyledAmbassadorInfo } from './AmbassadorInfo.styles';

export const AmbassadorInfo = ({ icon, text }: AmbassadorInfoProps) => {
  return (
    <StyledAmbassadorInfo>
      <div className="d-flex">
        <Icon iconClass={`personal-ambassador-container__icon ${icon} pb-2 pb-lg-0 pr-2 pr-lg-3`} />
        {icon === 'icon-mail' || icon === 'icon-phone' ? (
          <Link
            text={text}
            linkClassName="personal-ambassador-container__text pb-3 m-0 link-underline t-font-m"
            linkHref={icon === 'icon-phone' ? `tel:${text ?? ''}` : `mailto:${text ?? ''}`}
            target="_self"
            linkType="internal"
          />
        ) : (
          <Text
            element={Types.tags.paragraph}
            fontSize={Types.size.medium}
            copyText={text}
            customClass={'personal-ambassador-container__text pb-3 m-0'}
          />
        )}
      </div>
    </StyledAmbassadorInfo>
  );
};
