/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import { OverviewMovableComponents } from '../utils/constants/constants';

type awardDetailsType = {
  count: number;
  expirationDate: string;
};
export interface OverviewState {
  uxlTriggered: boolean;
  componentMoved: string;
  awardsCount: number;
  suiteNightAwardInfo?: Array<awardDetailsType>;
  components: ComponentDetails;
  setAwardsCount: (count: number, details: Array<awardDetailsType>) => void;
  setComponentMoved: (movedCompId: string) => void;
  setComponentHasData: (component: OverviewMovableComponents, hasData: boolean) => void;
  setComponentHasError: (component: OverviewMovableComponents, hasError: boolean) => void;
  setComponentIsLoading: (component: OverviewMovableComponents, isLoading: boolean) => void;
}

export type ComponentDetails = {
  [key: string]: ComponentProperties;
};

export type ComponentProperties = {
  hasData: boolean;
  hasError: boolean;
  isLoading: boolean;
};

const initialPropertyState: ComponentProperties = { hasData: false, hasError: false, isLoading: false };

const initialState: OverviewState = {
  uxlTriggered: false,
  componentMoved: '',
  awardsCount: 0,
  components: {
    [OverviewMovableComponents.UPCOMING_TRIPS]: { ...initialPropertyState },
    [OverviewMovableComponents.RECENT_ACTIVITIES]: { ...initialPropertyState },
    [OverviewMovableComponents.EARNED_REWARDS]: { ...initialPropertyState },
    [OverviewMovableComponents.PROMOTIONS]: { ...initialPropertyState },
  },
  setAwardsCount: () => {},
  setComponentMoved: () => {},
  setComponentHasData: () => {},
  setComponentHasError: () => {},
  setComponentIsLoading: () => {},
};

const overviewStore: StateCreator<OverviewState> = set => {
  return {
    ...initialState,
    setAwardsCount: (count: number, details?: Array<awardDetailsType>) => {
      set({ awardsCount: count, suiteNightAwardInfo: details });
    },
    setComponentMoved: (movedCompId: string) => {
      set({ componentMoved: movedCompId });
    },
    setComponentHasData: (component: OverviewMovableComponents, hasData: boolean) => {
      set((state: OverviewState) => ({
        ...state,
        components: {
          ...state.components,
          [component]: { hasData, hasError: false, isLoading: false },
        },
      }));
    },
    setComponentHasError: (component: OverviewMovableComponents, hasError: boolean) => {
      set((state: OverviewState) => ({
        ...state,
        components: {
          ...state.components,
          [component]: { hasError, hasData: false, isLoading: false },
        },
      }));
    },
    setComponentIsLoading: (component: OverviewMovableComponents, isLoading: boolean) => {
      set((state: OverviewState) => {
        let isUxlTriggered = state.uxlTriggered;
        // uxlTriggered is set to true only when setComponentIsLoading is called by 1st movable component from OverviewMovableComponents enum
        if (!state.uxlTriggered) {
          isUxlTriggered = component === Object.values(OverviewMovableComponents)[0];
        }
        return {
          ...state,
          uxlTriggered: isUxlTriggered,
          components: {
            ...state.components,
            [component]: { isLoading, hasError: false, hasData: false },
          },
        };
      });
    },
  };
};

export const useOverviewStore = createAppStore(overviewStore, {
  usePersistentStore: false,
});
