/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState, SyntheticEvent } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Button, Link, Text, Types } from '@marriott/mi-ui-library';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { useProfileStore } from '../../modules/store/profileStore';
import {
  CONSENT_METHOD_SMS,
  EDIT_PERSONAL_INFO_ID,
  SMS_TYPE_CODES,
  NOTIFICATION_BANNER_KEYS,
  PROFILE_CONSTANTS,
} from '../../modules/utils/constants/constants';
import { AccountOverviewNotificationProps, NotificationMessage } from './AccountOverviewNotification.types';
import { StyledAccountOverviewNotification } from './AccountOverviewNotification.styles';

export const AccountOverviewNotification: FC<AccountOverviewNotificationProps> = (pageProps: any) => {
  const model = pageProps?.model;
  const memberData = useAccountPersistentStore(state => state.memberData);
  const [isAboutToExpire, setIsAboutToExpire] = useState(false);
  const [updatedNotifcationMessages, setUpdatedNotifcationMessages] = useState(
    model?.fieldMessage?.map((item: NotificationMessage) => ({ ...item, showNotification: false }))
  );
  const { profileDetails, setProfileModalId } = useProfileStore(state => state);

  useEffect(() => {
    const pointExpiryDate = memberData?.customer?.loyaltyInformation?.rewards?.datePointsExpire;
    if (pointExpiryDate) {
      const today = new Date();
      const expiryDate = new Date(pointExpiryDate);
      const endDate = new Date();
      endDate.setDate(today.getDate() + 90);
      const pointsAboutToExpire = today <= expiryDate && endDate > expiryDate;
      setIsAboutToExpire(pointsAboutToExpire);
    }
  }, [memberData]);

  const getCommunicationOption = () => {
    const communicationOptions = profileDetails?.communicationOptions;
    if (communicationOptions) {
      return communicationOptions.some(
        item =>
          item.method.code === CONSENT_METHOD_SMS &&
          (item.type.code === SMS_TYPE_CODES.TRNS || item.type.code === SMS_TYPE_CODES.MRKT) &&
          item.subscribed === true
      );
    } else {
      return false;
    }
  };

  const getPhoneOption = () => {
    const phoneOptions = profileDetails?.phoneNumber;
    const mobileNumber = phoneOptions?.find(item => item.phoneType === PROFILE_CONSTANTS.PHONE_TYPE.DEFAULT);
    if (mobileNumber) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const notifcationMessages = updatedNotifcationMessages ? [...updatedNotifcationMessages] : [];
    if (notifcationMessages?.length > 0) {
      for (const item of notifcationMessages) {
        if (item.key === NOTIFICATION_BANNER_KEYS.MISSING_MOBILE_NUMBER) {
          item.showNotification = getCommunicationOption() && getPhoneOption();
        }
      }
      setUpdatedNotifcationMessages(notifcationMessages);
    }
  }, [profileDetails]);

  const showAccountNotification = updatedNotifcationMessages?.some(
    (msg: NotificationMessage) => msg.showNotification === true
  );

  return showAccountNotification ? (
    <div data-component-name="o-account-accountoverviewnotification">
      {updatedNotifcationMessages?.map((msg: NotificationMessage) => {
        const { warningMessage, ctaLabel, icon, theme, showNotification, key, ctaPath, isExternalLink } = msg;
        return showNotification ? (
          <StyledAccountOverviewNotification className="container mb-3" iconTheme={theme} data-testid="Notification">
            <div className="col-sm-12 p-sm-0">
              <div className="card d-flex align-items-start align-items-lg-center pt-sm-0 pt-md-3 pt-lg-0 flex-sm-row flex-lg-row mb-4 mb-md-0">
                <div className="d-flex outer ml-3 ml-md-4 mt-3 mt-md-0">
                  <div className="icon d-flex">
                    <span className={clsx(icon, 'icon-inverse mx-auto my-auto')}></span>
                  </div>
                </div>
                <div className="d-flex navheader">
                  <div className="ml-3 ml-lg-4 mt-3 mt-md-0 mr-3 mr-md-4 mr-lg-0">
                    <Text element={Types.tags.span} fontSize={Types.size.medium} copyText={warningMessage} />
                  </div>
                  <div className="ml-3 ml-md-0 mr-sm-5 mr-md-5 ml-md-3 mt-3 mt-lg-0 mb-3 mb-md-0 text-nowrap">
                    <Link
                      linkClassName={clsx(
                        isExternalLink ? 'm-link-tertiary-button-external' : 'm-link-tertiary-button'
                      )}
                      target={isExternalLink ? '_blank' : '_self'}
                      text={ctaLabel}
                      linkHref={ctaPath}
                      callback={(event: SyntheticEvent): void => {
                        if (key === NOTIFICATION_BANNER_KEYS.MISSING_MOBILE_NUMBER) {
                          event.preventDefault();
                          setProfileModalId(EDIT_PERSONAL_INFO_ID);
                        }
                      }}
                      data-testid={`${key}Cta`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </StyledAccountOverviewNotification>
        ) : (
          ''
        );
      })}
    </div>
  ) : isAboutToExpire && model?.bookYourNextStayLabel ? (
    <StyledAccountOverviewNotification
      className="container"
      data-component-name="o-account-accountoverviewnotification"
      data-testid="expireNotification"
    >
      <div className="col-sm-12 p-sm-0">
        <div className="card d-flex align-items-start align-items-lg-center pt-sm-0 pt-md-3 pt-lg-0 flex-sm-row flex-lg-row mb-4 mb-md-0">
          <div className="d-flex outer ml-3 ml-md-4 mt-3 mt-md-0">
            <div className="icon d-flex">
              <span className="icon-alert icon-inverse mx-auto my-auto"></span>
            </div>
          </div>
          <div className="d-flex navheader">
            <div className="ml-3 ml-lg-4 mt-3 mt-md-0 mr-3 mr-md-4 mr-lg-0">
              <Text
                element={Types.tags.span}
                fontSize={Types.size.medium}
                copyText={model?.pointsExpireWarningMessage}
                customClass=""
              />
            </div>
            <div className="ml-3 ml-md-0 mr-sm-5 mr-md-5 ml-md-3 mt-3 mt-lg-0 mb-3 mb-md-0">
              <Button className="m-link-tertiary-button" isLink href={model?.bookYourNextStayPath}>
                {model?.bookYourNextStayLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StyledAccountOverviewNotification>
  ) : null;
};

export const AccountOverviewNotificationConfig = {
  emptyLabel: 'accountoverviewnotification',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/accountoverviewnotification`,
};
export const AccountOverviewNotificationEditable = (props: any) => {
  return (
    <EditableComponent config={AccountOverviewNotificationConfig} {...props}>
      <AccountOverviewNotification {...props} />
    </EditableComponent>
  );
};
