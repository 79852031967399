import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledEarningAndRewards = styled.div`
  ${rtl`
  .modal__container {
    &__body {
      :is(${constants.RTL_LANG_LIST}) {
        text-align: right;
        .m-input-field {
          label {
            left: unset;
            right: ${toRem(16)};
          }
        }
      }
    }
  }
  .earning-rewards {
    &--dropdown {
      &__label {
        z-index: 1;
      }
      &__dropdownlist {
        border-radius: ${toRem(4)} !important;
        display: flex;
        align-items: center;
        width: 100%;
        height: ${toRem(48)};
        @media ${baseVariables.mediaQuery.md} {
          max-width: ${toRem(428)};
        }
        .dropdown-value {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    &--account {
      width: 100%;
      height: ${toRem(48)};
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(428)};
      }
    }
    &__disabled {
      color: ${baseVariables.color.alert20};
    }
    &--radiobtn:is(${constants.RTL_LANG_LIST}) {
      label {
        ::before {
          left: unset;
        }
        ::after {
          left: ${toRem(45)};
          right: ${toRem(6)};
        }
        span {
          margin-right: ${toRem(28)};
        }
      }
    }
  }
  .clear {
    :focus {
      border: ${toRem(2)} solid ${baseVariables.color.base10} !important;
    }
    @media ${baseVariables.mediaQuery.md} {
      position: unset;
    }
  }

  .apply-button {
    width: auto;
  }
  .modal-main-container {
    &__content__modalFooter {
      background-color: ${baseVariables.color.base20};
      height: ${toRem(105)} !important;
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      &__clear-btn {
        margin-left: ${toRem(28)} !important;
        @media ${baseVariables.mediaQuery.md} {
          margin-left: ${toRem(0)};
        }
      }
    }
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: ${toRem(160)};
    }
  }
  .modal_loader {
    &__heading {
      width: 40%;
      height: ${toRem(24)};
    }
    &__body {
      width: 80%;
      height: ${toRem(24)};
    }
  }
  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      &::-ms-reveal {
        display: none;
      }
    }
  }
  .m-message-content{
    p {
       margin-bottom: 0;
    }
  }
`}
`;
