import React, { useState, useContext, useRef, useEffect } from 'react';
import { inspect } from 'util';
import { useLazyQuery, useMutation } from '@apollo/client';
import { apiLogger, generateApolloClientHeaders } from '../../modules/utils';
import {
  phoenixAccountAddCustomerPreferences,
  phoenixAccountGetCustomerPreferenceFeatures,
} from '../../modules/graph/index';
import { useProfileStore } from '../../modules/store/profileStore';
import { PageContext } from '../../modules/context/PageContext';
import { CustomerFeatureResponse, WithDataComponentResponse } from './CustomerPreferenceHOC.types';

export const CustomerPreferenceHOC = (
  WrappedComponent: React.ComponentType<{
    preferenceData: CustomerFeatureResponse | null;
    hasError: boolean;
    profileId: string;
    model: {
      type: string;
      cancelButton: string;
      modelId: string;
      header: string;
      description: string;
      saveButton: string;
      items: {
        id?: string;
        value?: string;
      }[];
    };
    setProfileId: (data: string) => void;
    updateCustomerPreferences: () => void;
    resetPreferenceData: () => void;
    setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  }>,
  sourceProfileID: string,
  isAuthorMode: boolean
) => {
  const pageContext = useContext(PageContext);
  const dataLoaded = useRef<boolean>(false);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [updateCustomerPreferences] = useMutation(phoenixAccountAddCustomerPreferences);
  const [getCustomerPreferences] = useLazyQuery(phoenixAccountGetCustomerPreferenceFeatures);
  const { IS_LOCAL_DEV } = process.env;
  const [preferenceData, setPreferenceData] = useState<CustomerFeatureResponse | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const { profileModalId, setProfileModalId } = useProfileStore(state => state);

  const fetchCustomerPreferences = () => {
    getCustomerPreferences({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        dataLoaded.current = true;
        setPreferenceData(data?.customer?.featuresByGroup?.preferencesFeatures);
        setHasError(false);
        apiLogger(
          `[CustomerBevPreferences] getCustomerBevPreferencesData - sessionId: ${sessionData?.sessionToken}: ${inspect(
            data
          )}`
        );
      },
      onError: () => {
        setHasError(true);
      },
    });
  };

  useEffect(() => {
    !isAuthorMode && sourceProfileID === profileModalId && !preferenceData && fetchCustomerPreferences();
  }, [preferenceData, profileModalId]);

  const resetPreferenceData = () => {
    setPreferenceData(null);
  };

  return function WithDataComponent(props: WithDataComponentResponse) {
    return (
      <div data-component-name="m-account-CustomerPreferenceHOC" data-testid="account-CustomerPreferenceHOC">
        <WrappedComponent
          preferenceData={preferenceData}
          hasError={hasError}
          updateCustomerPreferences={updateCustomerPreferences}
          resetPreferenceData={resetPreferenceData}
          profileId={profileModalId}
          setProfileId={setProfileModalId}
          setHasError={setHasError}
          {...props}
        />
      </div>
    );
  };
};
