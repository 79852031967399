// Styles for ChinaConsentForm go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledDefaultConsentForm = styled.div<{ isOcj?: boolean }>`
  .privacy-consent {
    background-color: ${baseVariables.color.neutral20};
  }

  .kr-border-bottom {
    border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }
  .cn-border {
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }
  .paragraph-margin {
    p {
      margin: 0;
    }
  }
  .show-link {
    cursor: pointer;
  }
  label {
    margin: 0;
  }
  ${props =>
    props?.isOcj
      ? ``
      : `.consent-checkbox:is(${constants.RTL_LANG_LIST}) {
          label {
            :before {
              display: flex;
              left: unset !important;
            }
            :after {
              left: unset !important;
              right: ${toRem(6)};
              transform: rotateZ(45deg) !important;
            }
            p {
              text-align: right;
              padding-right: ${toRem(24)};
            }
          }
        }`};
  .create-account-consent {
    a {
      @media ${baseVariables.mediaQuery.md} {
        white-space: nowrap !important;
      }
    }
  }
`;
