// Imports for external libraries go here.
import { FC } from 'react';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import { COMPLETE_PROFILE_URL } from '../../modules/utils';
import { OnboardingComponentProps } from './OnboardingComponent.types';
import { StyledOnboardingComponent } from './OnboardingComponent.styles';
import { useProfileStore } from '../../modules/store/profileStore';

export const OnboardingComponent: FC<OnboardingComponentProps> = pageProps => {
  const { setProfileModalId } = useProfileStore(state => state);
  return (
    <StyledOnboardingComponent
      data-component-name="m-account-OnboardingComponent"
      data-testid="account-OnboardingComponent"
      className={clsx(pageProps.isHorizontal && 'col-lg-4 col-md-6 pl-0')}
    >
      <div
        className={clsx(
          'd-flex justify-content-center new-member-inner-container flex-column',
          pageProps.textClass,
          pageProps.isHorizontal ? 'horizontal-card' : ''
        )}
      >
        <div className={clsx(pageProps.isHorizontal ? 'd-flex text-left' : 'text-center')}>
          <div className={clsx('new-member-icon icon-decorative mx-auto', pageProps.iconClass)}></div>
          <div className={clsx('t-font-m', pageProps.isHorizontal ? 'pl-3 pb-3' : 'my-3')}>{pageProps.text}</div>
        </div>
        <div className={clsx('d-flex', pageProps.isHorizontal ? 'align-items-start pl-4 ml-4' : ' ')}>
          {pageProps.isProfilePage && pageProps.ctaPath?.includes(COMPLETE_PROFILE_URL) ? (
            <Button
              className={clsx(
                'm-button-secondary m-button-s py-2',
                pageProps.buttonClass,
                pageProps.isHorizontal ? '' : 'mx-auto'
              )}
              testId={pageProps.ctaLabel}
              buttonCopy={pageProps.ctaLabel}
              custom_click_track_value={pageProps.clickTrack}
              callback={() => setProfileModalId('personal-info')}
            ></Button>
          ) : (
            <Button
              target={pageProps.target ? '_blank' : '_self'}
              isLink={true}
              className={clsx(
                'm-button-secondary m-button-s py-2',
                pageProps.buttonClass,
                pageProps.isHorizontal ? '' : 'mx-auto'
              )}
              href={pageProps.ctaPath}
              testId={pageProps.ctaLabel}
              buttonCopy={pageProps.ctaLabel}
              custom_click_track_value={pageProps.clickTrack}
            ></Button>
          )}
        </div>
      </div>
    </StyledOnboardingComponent>
  );
};
