/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const ModifyUpgradeReqHeader = dynamic(() =>
  import('./ModifyUpgradeReqHeader').then(mod => mod.ModifyUpgradeReqHeader)
);
const ModifyUpgradeReqHeaderV2 = dynamic(() =>
  import('./ModifyUpgradeReqHeaderV2').then(mod => mod.ModifyUpgradeReqHeaderV2)
);

export const ModifyUpgradeReqHeaderConfig = {
  emptyLabel: 'modifyupgradereqheader',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/modifyupgradereqheader`,
};

export const ModifyUpgradeReqHeaderEditable = (props: any) => (
  <EditableComponent config={ModifyUpgradeReqHeaderConfig} {...props}>
    {props.isDTT ? <ModifyUpgradeReqHeaderV2 {...props} /> : <ModifyUpgradeReqHeader {...props} />}
  </EditableComponent>
);
