/* eslint-disable @typescript-eslint/no-explicit-any */
// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const UpcomingTrips = dynamic(() => import('./UpcomingTrips').then(mod => mod.UpcomingTrips));
const UpcomingTripsV2 = dynamic(() => import('./UpcomingTripsV2').then(mod => mod.UpcomingTripsV2));

export const UpcomingTripsConfig = {
  emptyLabel: 'upcomingtrips',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/upcomingtrips`,
};

export const UpcomingTripsEditable = (props: any) => (
  <EditableComponent config={UpcomingTripsConfig} {...props}>
    {props.isDTT ? <UpcomingTripsV2 {...props} /> : <UpcomingTrips {...props} />}
  </EditableComponent>
);
