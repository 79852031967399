// Styles for CreateAccountDescription go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const StyledCreateAccountDescription = styled.div`
  .create-account-description {
    &-container {
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};

      &__icon {
        font-size: ${toRem(36)};
        color: ${baseVariables.color.merch10};

        @media ${baseVariables.mediaQuery.lg} {
          padding-right: ${toRem(16)};
          :is(${constants.RTL_LANG_LIST}) {
            padding-left: ${toRem(16)};
            padding-right: 0;
          }
        }
      }
    }
  }
`;
