/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { arc } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import clsx from 'clsx';

import { Button, Text, Types, Image } from '@marriott/mi-ui-library';

import { OVERVIEW_CONSTANT, addParamToRenditions } from '../../modules';
import { getCoordsOnArc, getSpeedoMeterConstants } from '../../modules/utils/overviewHelper';
import { StyledNightsProgress } from './NightsProgress.styles';
import { NightsProgressProps } from './NightsProgress.types';
import { useStore } from '../../modules/store/memberLevelStore';

export const NightsProgress = (props: NightsProgressProps) => {
  const memberLevel = props.memberLevel;
  const value = props?.value ?? 0;
  const min = props?.min;
  const { maxValue, breakPoints } = getSpeedoMeterConstants(memberLevel);
  //initializing the min, max and value
  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);

  // Declaring Basic variables
  const backGroundArcs: any = [];
  const filledbackGroundArcs: any = []; //declaring breakpoints of max value
  let startAngle = 0;
  const percentScale = scaleLinear().domain([min, maxValue]).range([0, 1]);
  const percent = percentScale(value);
  const angle = angleScale(percent);
  const { isNightsModelOpen } = useStore(store => store);

  breakPoints.forEach((point: number) => {
    // starting and ending angle of each portion
    const endAngle = angleScale(point);
    const sAngle = angleScale(startAngle);
    const innerRad = OVERVIEW_CONSTANT?.NIGHT_PROGRESS_DATA?.innerRadius;
    const outerRad = OVERVIEW_CONSTANT?.NIGHT_PROGRESS_DATA?.outerRadius;
    const padAng = OVERVIEW_CONSTANT?.NIGHT_PROGRESS_DATA?.padAngle;
    const cornerRad = OVERVIEW_CONSTANT?.NIGHT_PROGRESS_DATA?.cornerRadius;
    // Creating a arc for empty portion
    const tempArc = arc().cornerRadius(cornerRad);
    backGroundArcs.push(
      tempArc({
        startAngle: sAngle,
        endAngle: endAngle,
        innerRadius: innerRad,
        outerRadius: outerRad,
        padAngle: padAng,
      })
    );

    // Creating a arc for the half filled portion
    if (sAngle <= angle && endAngle >= angle) {
      const filledArc = arc().cornerRadius(cornerRad);
      filledbackGroundArcs.push(
        filledArc({
          startAngle: sAngle,
          endAngle: angle,
          innerRadius: innerRad + (!props?.isNonMember ? 0.016 : 0),
          outerRadius: outerRad - (props?.isNonMember ? 0.005 : 0.01),
          padAngle: padAng,
        })
      );
    } else if (angle <= sAngle && angle <= endAngle) {
      // leaving this space as unfilled color
    } else {
      // Creating an arc for the filled portion
      filledbackGroundArcs.push(
        tempArc({
          startAngle: sAngle,
          endAngle: endAngle,
          innerRadius: innerRad,
          outerRadius: outerRad - 0.01,
          padAngle: padAng,
        })
      );
    }

    startAngle = point;
  });

  const markerLocation = getCoordsOnArc(angle, 1 - (1 - 0.58) / 2);
  const unfilledColor =
    isNightsModelOpen && props.fromNightModal
      ? OVERVIEW_CONSTANT.UNFILLED_COLOR_MEMBER_NO_NIGHT
      : !props?.isNonMember && value === 0
      ? OVERVIEW_CONSTANT.UNFILLED_COLOR_MEMBER_NO_NIGHT
      : OVERVIEW_CONSTANT.TEXT_COLOR_MEMBER;
  const filledColor =
    isNightsModelOpen && props.fromNightModal
      ? OVERVIEW_CONSTANT.TEXT_COLOR_MEMBER
      : props.isNonMember
      ? OVERVIEW_CONSTANT.FILLED_COLOR_NON_MEMBER
      : OVERVIEW_CONSTANT.FILLED_COLOR_MEMBER;
  const circleRad = OVERVIEW_CONSTANT?.NIGHT_PROGRESS_DATA?.circle?.radius;
  const circleStroke = OVERVIEW_CONSTANT?.NIGHT_PROGRESS_DATA?.circle?.stroke;

  const textBreakPoints = breakPoints.map((point, index) => {
    return (
      index !== breakPoints.length && (
        <text
          key={index}
          x={
            index === breakPoints.length - 1
              ? getCoordsOnArc(angleScale(point), 0.93)[0]
              : index === 0
              ? getCoordsOnArc(angleScale(point), 0.88)[0]
              : getCoordsOnArc(angleScale(point), 0.87)[0]
          }
          y={getCoordsOnArc(angleScale(point), 0.87)[1]}
          textAnchor="middle"
          alignmentBaseline="middle"
          fill={props.isNonMember ? OVERVIEW_CONSTANT.FILLED_COLOR_NON_MEMBER : OVERVIEW_CONSTANT.TEXT_COLOR_MEMBER}
          fontSize="0.4%"
        >
          {memberLevel && index === breakPoints.length - 1 && OVERVIEW_CONSTANT.AMBASSADOR_CODE.includes(memberLevel)
            ? Math.ceil(point * maxValue) + '+'
            : Math.ceil(point * maxValue)}
        </text>
      )
    );
  });

  return (
    <StyledNightsProgress
      data-component-name="m-account-NightsProgress"
      data-testid="account-NightsProgress"
      awardvalue={value === 0}
      isNightsModelOpen={isNightsModelOpen}
      isNonMember={props?.isNonMember}
    >
      <div className="svg position-relative night-progress-height">
        <svg role="img" aria-label="NightsProgress" viewBox={[-1, -1, 2, 1.18].join(' ')}>
          {backGroundArcs.map((arc: string | undefined, index: number) => (
            <path d={arc} fill={unfilledColor} key={index} />
          ))}
          {filledbackGroundArcs.map((arc: string | undefined, index: number) => (
            <path d={arc} fill={filledColor} key={index} />
          ))}
          {props?.isNonMember ? (
            <circle
              cx={markerLocation[0]}
              cy={markerLocation[1]}
              r={circleRad}
              strokeWidth={circleStroke}
              fill={filledColor}
              stroke={filledColor}
            />
          ) : (
            value !== 0 && (
              <circle
                cx={markerLocation[0]}
                cy={markerLocation[1]}
                r={circleRad}
                strokeWidth={circleStroke}
                fill={filledColor}
                stroke={filledColor}
              />
            )
          )}
          {(props?.fromNightModal || props?.isNonMember || (!props?.isNonMember && value !== 0)) && textBreakPoints}
        </svg>
        <div className="meterText" data-testid="meter-text">
          {value !== 0 || isNightsModelOpen ? (
            // If isNightsModelOpen is true or value is not 0
            <div
              className={clsx(
                't-numbers-l pt-5 pt-md-0 mx-auto number-height',
                props?.isNonMember && 'meterText__inverse'
              )}
            >
              {value}
            </div>
          ) : (
            // If isNightsModelOpen is false and value is 0
            <Image
              customClass={clsx(
                'night-cloud ',
                props.isNonMember ? 'meterText__icon other-member-level' : 'meterText__icon-member member-level'
              )}
              altText={clsx(
                props?.isNonMember
                  ? props?.model?.featuredDynamicImageOtherML?.altText || props?.model?.imageOtherMLAltText
                  : props?.model?.featuredDynamicImage?.altText || props?.model?.imageAltText
              )}
              defaultImageURL={clsx(
                props?.isNonMember
                  ? props?.model?.featuredDynamicImageOtherML?.assetPath || props?.model?.imageOtherML
                  : props?.model?.featuredDynamicImage?.assetPath || props?.model?.image
              )}
              renditions={addParamToRenditions(
                props?.isNonMember
                  ? props?.model?.featuredDynamicImageOtherML?.renditions
                  : props?.model?.featuredDynamicImage?.renditions
              )}
            />
          )}

          <div className={clsx('btn d-flex justify-content-center', value === 0 && 'mt-4')} tabIndex={0}>
            {isNightsModelOpen ? (
              <Text
                customClass={'t-label-inverse-alt-xs'}
                copyText={props?.model?.nightsThisYearCtaLabel}
                element={Types.tags.div}
                fontSize={Types.size.medium}
              />
            ) : value !== 0 || props?.isNonMember ? (
              <Button
                isLink={true}
                className={clsx(
                  'p-0 my-0 mr-2',
                  props?.isNonMember
                    ? 'm-link-tertiary-button-inverse transparent-button'
                    : 'm-link-tertiary-button transparent-button'
                )}
                buttonCopy={props?.model?.nightsThisYearCtaLabel}
                callback={() => props.setNightModelOpen(true)}
              />
            ) : (
              <Button
                isLink={true}
                className="m-button-s m-button-secondary pt-0 d-flex align-items-center"
                buttonCopy={props?.model?.exploreHotelsCtaLabel}
                href={props?.model?.exploreHotelsCtaPath}
              />
            )}
          </div>
        </div>
      </div>
    </StyledNightsProgress>
  );
};
