// Styles for AccountContainer go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledAccountContainer = styled.div<{ isAccountModal?: boolean }>`
  ${rtl`
    //TODO: Below css added with prop should be removed once RTL implementation is done for Account pages
    ${(props: { isAccountModal: boolean }) =>
      props?.isAccountModal
        ? ``
        : `
    &:is(${constants.RTL_LANG_LIST}){
      text-align: justify;
      input[type='checkbox']:checked + label:before, input[type='checkbox'] + label:before {
        right: 0 !important;
        left: unset;
      }
      input[type='checkbox']:checked + label:after, input[type='checkbox'] + label:after {
        right: ${toRem(6)} !important;
        left: unset;
        transform: rotate(45deg);
      }
      input[type='checkbox'] + label {
        padding-right: ${toRem(30)} !important;
      }
    }`}
  .m-input-field input:is(${constants.RTL_LANG_LIST}){
    text-indent: ${toRem(16)} !important;
    height: ${toRem(45)} !important;
  }
  .m-input-field label:is(${constants.RTL_LANG_LIST}){
    right: ${toRem(11)} !important;
    width: max-content;
  }
  .form-field-contaioner .trailing-element span:is(${constants.RTL_LANG_LIST}){
    left: ${toRem(13)} !important;
    right: unset;
  }
  .m-accordion-container .m-accordion-title .accordion-arrow-icon:is(${constants.RTL_LANG_LIST}){
    padding-right: ${toRem(8)} !important;
  }
  .overlay-msg-content {
    max-width: max-content !important;
  }
  .reslookup-theme {
    background-color: ${baseVariables.color.neutral20};
  }
  .account-page-error-msg {
    &:is(${constants.RTL_LANG_LIST}){
      .m-message-content-wrap:before{
        margin-left: ${toRem(8)} !important;
        margin-right: unset !important;
      }
    }
    .m-message-content {
      p {
        margin-bottom: 0;
      }
    }
  }
  .custom-padding {
    &:nth-child(1) {
      padding-right: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.lg} {
        padding-right: ${toRem(64)} !important;
      }
    }
    &:nth-child(1):is(${constants.RTL_LANG_LIST}) {
      padding-left: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.lg} {
        padding-left: ${toRem(64)} !important;
        padding-right: ${toRem(8)} !important;
      }
    } 
  
    &:nth-child(2) {
      padding-left: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.lg} {
        padding-left: ${toRem(64)} !important;
      }
    }
    &:nth-child(2):is(${constants.RTL_LANG_LIST}) {
      padding-right: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.lg} {
        padding-right: ${toRem(64)} !important;
        padding-left: ${toRem(8)} !important;
      }
    } 
  }
  .custom-padding-overlay {
    &:nth-child(1) {
      padding-right: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.md} {
        padding-right: ${toRem(32)} !important;
      }
      @media ${baseVariables.mediaQuery.lg} {
        padding-right: ${toRem(64)} !important;
      }
    }
    &:nth-child(1):is(${constants.RTL_LANG_LIST}) {
      padding-left: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.md} {
        padding-left: ${toRem(32)} !important;
        padding-right: ${toRem(8)} !important;
      }
      @media ${baseVariables.mediaQuery.lg} {
        padding-left: ${toRem(64)} !important;
        padding-right: ${toRem(8)} !important;
      }
    } 
    &:nth-child(2) {
      padding-left: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.md} {
        padding-left: ${toRem(40)} !important;
      }
      @media ${baseVariables.mediaQuery.lg} {
        padding-left: ${toRem(64)} !important;
      }
    }
    &:nth-child(2):is(${constants.RTL_LANG_LIST}) {
      padding-right: ${toRem(8)} !important;
      @media ${baseVariables.mediaQuery.md} {
        padding-right: ${toRem(40)} !important;
        padding-left: ${toRem(8)} !important;
      }
      @media ${baseVariables.mediaQuery.lg} {
        padding-right: ${toRem(64)} !important;
        padding-left: ${toRem(8)} !important;
      }
    } 
  }
  .border-left {
    &:nth-child(2) {
      border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
      @media ${baseVariables.mediaQuery.lg} {
        border-left: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        border-top: none;
      }
      &:nth-child(2):is(${constants.RTL_LANG_LIST}) {
        border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        @media ${baseVariables.mediaQuery.lg} {
          border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
          border-left: none;
          border-top: none;
        }
      } 
    }
  }
  .border-left-overlay {
    &:nth-child(2) {
      border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
      @media ${baseVariables.mediaQuery.md} {
        border-left: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        border-top: none !important;
      }
      &:nth-child(2):is(${constants.RTL_LANG_LIST}) {
        border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        @media ${baseVariables.mediaQuery.md} {
          border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
          border-left: none !important;
          border-top: none !important;
        }
      }
    }
  }
    .custom-padding-overlay {
      &:nth-child(1) {
        padding-right: ${toRem(8)} !important;
        @media ${baseVariables.mediaQuery.md} {
          padding-right: ${toRem(32)} !important;
        }
        @media ${baseVariables.mediaQuery.lg} {
          padding-right: ${toRem(64)} !important;
        }
      }
      &:nth-child(2) {
        padding-left: ${toRem(8)} !important;
        @media ${baseVariables.mediaQuery.md} {
          padding-left: ${toRem(32)} !important;
        }
        @media ${baseVariables.mediaQuery.lg} {
          padding-left: ${toRem(64)} !important;
        }
      }
    }
    .border-left {
      &:nth-child(2) {
        border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        @media ${baseVariables.mediaQuery.lg} {
          border-left: ${toRem(1)} solid ${baseVariables.color['neutral30']};
          border-top: none;
        }
      }
      &:nth-child(2):is(${constants.RTL_LANG_LIST}) {
          border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
          @media ${baseVariables.mediaQuery.lg} {
            border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
            border-left: none;
            border-top: none;
          }
        } 
    }
    .border-left-overlay {
      &:nth-child(2) {
        border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        @media ${baseVariables.mediaQuery.md} {
          border-left: ${toRem(1)} solid ${baseVariables.color['neutral30']};
          border-top: none;
        }
      }
    }
  `}
`;

export const StyledModalAccountContainerDiv = styled.div`
  > div[class*='Modal'] {
    overflow-y: scroll;
    &:before {
      position: fixed;
      @media ${baseVariables.mediaQuery.md} {
        right: ${toRem(4)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(16)};
      }
    }
  }
  .account-container-modal {
    &.show {
      @media ${baseVariables.mediaQuery.lg} {
        display: flex;
      }
    }
    .account-container-modal-secondary {
      max-width: 100%;
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(584)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(1124)};
      }
      width: 100%;
      .modal-header {
        height: auto;
        border-bottom: 0;
        position: relative;
        .popup-close {
          cursor: pointer;
          right: ${toRem(32)};
          top: ${toRem(35)};
          @media ${baseVariables.mediaQuery.lg} {
            position: absolute;
            z-index: 2;
          }
        }
      }
      .modal-body {
        max-height: 80vh;
        @media ${baseVariables.mediaQuery.md} {
          max-height: 90vh;
        }
        @media ${baseVariables.mediaQuery.lg} {
          padding: ${toRem(64)} !important;
          max-height: auto;
        }
      }
    }
  }
`;
