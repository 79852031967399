import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../../../modules';

export const StyledMemberBenefits = styled.div<{
  textColor: string | null | undefined;
  isNotBasicMember: boolean;
}>`
  .glide {
    &__track {
      width: 100%;
      height: 100%;
      overflow: visible;
      margin-top: ${toRem(11)} !important; //margin overlapping so added odd digit
    }

    &__slide {
      border: ${toRem(1)} solid white;
      border-radius: ${toRem(20)};
      height: auto;
    }

    &__arrow {
      position: inherit;
      transform: none;
      box-shadow: none;
      &--disabled {
        color: ${baseVariables.color.neutral30} !important;
        opacity: 1 !important;
        pointer-events: none;
      }
    }

    &__bullets {
      display: block;
      width: 100%;
      bottom: ${toRem(0)};
    }

    .glide__slide {
      border-color: ${({ isNotBasicMember, textColor }) =>
        isNotBasicMember ? textColor : baseVariables.color.neutral30};
      color: ${({ isNotBasicMember, textColor }) => (isNotBasicMember ? textColor : baseVariables.color.base10)};

      &-msg {
        color: ${({ isNotBasicMember, textColor }) => (isNotBasicMember ? textColor : baseVariables.color.base10)};
      }
    }

    .carouselControlType3b .right-arrow:hover {
      background: none;
    }
    .carouselControlType3b .left-arrow:hover {
      background: none;
    }

    &--swipeable {
      @media ${baseVariables.mediaQuery.sm} {
        cursor: initial;
      }
    }
  }

  .card {
    &__wrapper {
      &-message {
        color: ${({ isNotBasicMember, textColor }) => (isNotBasicMember ? textColor : baseVariables.color.base10)};
        a {
          color: inherit;
        }
      }
    }
  }
  ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        .icon-arrow-left:before,
        .icon-arrow-right:before {
          transform: rotate(-180deg) !important;
        }
      }
  `}
`;
