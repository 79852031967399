// Imports for external libraries go here.
import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import Glide from '@glidejs/glide';
import { Link, CardLayered, CardLayeredVariations, useCheckBreakpoint, Types } from '@marriott/mi-ui-library';
import { CarouselControls } from '../../molecules/CarouselControls';
import data from './__mock__/OverviewOffers.model.json';
import { getImageRenditionsWithCropQueryString } from '../../modules/utils/imageUtility';
import { getDirection } from '../../modules/utils/overviewHelper';
import { fallbackImages } from '../../modules';
import { OverviewOffersStyles } from './OverviewOffers.styles';
import { OverviewOffersPropsTypes, OverviewModal, DynamicMedia } from './OverviewOffers.types';

// Use named rather than default exports.
export const OverviewOffers: FC<OverviewOffersPropsTypes> = pageProps => {
  const model = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const [offersData, setOffersData] = useState<OverviewModal>();
  const isDesktop = useCheckBreakpoint('viewportL');
  const isTablet = useCheckBreakpoint('viewportM');
  const { sectionTitle, offersCTAButtonLabel, specialOffersLink } = offersData || {};
  const glideWrapper = useRef(null);
  const badgeText = '';
  const imageCroppingRatio = 12;
  const imageOutputQuality = 90;
  const imageOutputInterpolation = 'progressive-bilinear';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cardsToDisplay, noOfDots } = useMemo(() => {
    const cardsLength = offersData?.cards?.length || 0;
    let cardsToDisplay;
    if (isDesktop) {
      cardsToDisplay = 4;
    } else if (isTablet) {
      cardsToDisplay = 2;
    } else {
      cardsToDisplay = 1;
    }
    const dots = cardsLength > cardsToDisplay ? cardsLength - cardsToDisplay + 1 : 0;
    return { cardsToDisplay, noOfDots: dots };
  }, [isDesktop, isTablet, offersData?.cards]);

  useEffect(() => {
    if (isAuthorMode) {
      setOffersData(data);
    } else {
      setOffersData(model);
    }
    if (offersData?.cards?.length) {
      const direction = getDirection();
      if (glideWrapper.current) {
        const glide = new Glide(glideWrapper?.current, {
          type: 'slider',
          autoplay: false,
          startAt: 0,
          perView: 4,
          gap: 16,
          bound: true,
          direction: direction,
          peek: { before: 0, after: 0 },
          breakpoints: {
            992: {
              perView: 2,
              peek: {
                before: 0,
                after: 0,
              },
            },
            767: {
              perView: 1,
              peek: {
                before: 0,
                after: 0,
              },
            },
          },
        });
        glide?.mount();
      }
    }
  }, [offersData]);

  const getFeaturedDynamicImage = (dyamicMedia: DynamicMedia) => {
    const imageRenditionMap = [
      { mediaValue: 992, height: 269, width: 268, mediaQuery: 'min-width' },
      { mediaValue: 577, height: 344, width: 344, mediaQuery: 'min-width' },
      { mediaValue: 576, height: 332, width: 332, mediaQuery: 'max-width' },
    ];
    // updating rendition only for fallback image for now until we have confirmation on image urls
    const updatedDynamicMedia = { ...dyamicMedia };
    if (!dyamicMedia?.assetPath) {
      const renditions = imageRenditionMap.map(key => {
        return {
          renditionPath: `${fallbackImages.Classic}${getImageRenditionsWithCropQueryString(
            imageCroppingRatio,
            imageOutputQuality,
            imageOutputInterpolation,
            { width: key.width, height: key.height }
          )}`,
          mediaValue: `${key.mediaValue}px`,
          width: 0,
          dynamic: false,
          damPath: dyamicMedia?.assetPath,
          mediaQuery: key.mediaQuery,
          height: 0,
        };
      });
      updatedDynamicMedia['renditions'] = renditions;
    }
    return updatedDynamicMedia;
  };

  return offersData?.cards && offersData.cards.length > 0 ? (
    <OverviewOffersStyles data-testid="overviewoffers" data-component-name="o-account-overviewoffers">
      <div className="offers-card-container container pt-4 pt-md-5 mt-md-2 mb-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="offers-card-heading t-overline-normal" data-testid="offers-title">
            {sectionTitle}
          </div>
          {offersCTAButtonLabel && specialOffersLink && (
            <Link
              linkHref={specialOffersLink || ''}
              text={offersCTAButtonLabel}
              linkClassName="m-link-tertiary-button"
              target="_self"
            ></Link>
          )}
        </div>
        <div className="glide" ref={glideWrapper}>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides d-flex list-unstyled p-0 mb-1">
              {offersData?.cards?.map((ele, index) => (
                <li className="glide__slide" key={index}>
                  <CardLayered
                    cardLayeredVariations={CardLayeredVariations.Square}
                    {...ele}
                    eyebrow={ele?.offersEyebrow}
                    header={ele?.offersHeader}
                    dynamicMedia={getFeaturedDynamicImage(ele?.featuredDynamicImage)}
                    ctaLink={ele?.ctaLink || '#'}
                    badgeText={badgeText}
                    openInNewTab={ele?.isExternalLink || false}
                    fontSize={Types.size.large}
                    styleclass={Types.Themes.Inverse}
                    headerTag={Types.tags.h4}
                    additionalWrapperClass={`merch-card-${index + 1}`}
                  />
                </li>
              ))}
            </ul>
          </div>
          <CarouselControls customClasses="d-lg-none" dots={noOfDots} />
        </div>
      </div>
    </OverviewOffersStyles>
  ) : null;
};

export const OverviewOffersConfig = {
  emptyLabel: 'OverviewOffers',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/overviewoffers`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OverviewOffersEditable = (props: any) => {
  return (
    <EditableComponent config={OverviewOffersConfig} {...props}>
      <OverviewOffers {...props} />
    </EditableComponent>
  );
};
