/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiLogger = (message: string) => {
  const loggerApiURL = '/mi/account/api/logger';

  Axios.post(loggerApiURL, {
    message: message,
  });
};

/* eslint-disable @typescript-eslint/no-empty-function */
export const logger = function (_ids: object) {
  return function (_loggerName: string) {
    return {
      log: {
        log: function (..._args: any[]) {},
        warn: function (..._args: any[]) {},
        debug: function (..._args: any[]) {},
        error: function (..._args: any[]) {},
      },
      pLog: typeof Function,
    };
  };
};
