// Styles for MissingStayRequestForm go here.
import styled, { createGlobalStyle } from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';
import rtl from 'styled-components-rtl';

export const ReportMissingStayStepTwoStyle = styled.div`
  ${rtl`
    .step-two {
      &__findbill {
        &--accordian {
          .findbill-accordian {
            font-weight: ${constants.BOLD_FONT_WEIGHT};
          }
        }
      }
    }
    /* Style for the label of radio buttons that are not selected */
    .m-radio-button-sm input[type='radio']:not(:checked) + label {
      color: ${baseVariables.color['neutral40']};
    }
    .show-disabled {
      opacity: 0.4; /* or any other visual cue to indicate disabled state */
      pointer-events: none;
    }
    .DayPicker-wrapper {
      padding: ${toRem(16)};
    }
    .MuiInputBase-input {
      height: ${toRem(44)};
      border-bottom: none;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .PrivateNotchedOutline-root-1 {
      border: none;
    }
    .MuiOutlinedInput-root.Mui-focused {
      border-color: ${baseVariables.color['neutral40']};
    }
    .MuiFormLabel-root.Mui-focused {
      color: ${baseVariables.color['neutral00']};
    }
    .MuiOutlinedInput-input {
      padding: ${toRem(18)} ${toRem(14)} !important;
    }

    .search-input-field {
      caret-color: unset;
    }

    .MuiIconButton-edgeEnd {
      position: absolute;
      right: ${toRem(12)};
    }
    .MuiIconButton-edgeEnd:is(${constants.RTL_LANG_LIST}) {
      position: absolute;
      left: 0;
      right : auto;
    }
    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }

    .input-label {
      z-index: 1;
    }

    input {
      padding-right: ${toRem(30)}; /* Space for the calendar icon */
    }

    .icon-calendar {
      position: absolute;
      top: 50%;
      font-size: ${toRem(32)};
      right: ${toRem(10)}; /* Adjust the value to position the icon as per your preference */
      transform: translateY(-50%);
      cursor: pointer;
    }
    .input-box {
      border: 1px solid ${baseVariables.color['neutral40']};
    }

    .input-box.error {
      border: ${toRem(1)} solid ${baseVariables.color['alert50']};
    }

    .defaultField {
      border-color: ${baseVariables.color['neutral40']};
    }

    .errorField {
      border: ${toRem(1)} solid ${baseVariables.color['alert50']} !important;
    }
    .calendar-wrapper {
      border-radius: ${toRem(12)};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      background-color: ${baseVariables.color['merch50']};
    }
    .DayPicker-Weekday {
      color: ${baseVariables.color['neutral00']} !important;
    }

    .DayPicker {
      &-Day {
        &:hover,
        &:focus {
          background-color: ${
            baseVariables.color['neutral10']
          } !important; //important is neccesaary since we are overriding library class
          border-radius: 50% !important;
        }
      }
    }

    .DayPicker-Weekdays {
      border-bottom: none !important; //important is neccesaary since we are overriding library class
    }
    .MuiOutlinedInput-input {
      padding-right: ${toRem(36)} !important; //important is neccesaary since we are overriding library class
    }
    .MuiIconButton-edgeEnd {
      padding: 0px ${toRem(8)};
    }
    .clear-action-button {
      margin-right: ${toRem(12)};
      background: none;
      &:hover,
      &:focus {
        font-weight: ${constants.BOLD_FONT_WEIGHT} !important;
      }
    }
    .cursor-pointer {
      cursor: pointer;
    }
    .step-two__findbill {
      .icon-arrow-down:is(${constants.RTL_LANG_LIST}),
      .icon-arrow-up:is(${constants.RTL_LANG_LIST}) {
        padding-right: ${toRem(8)} !important;
      }
    }
    .m-input-field input:is(${constants.RTL_LANG_LIST}) {
      padding-right: ${toRem(16)} !important;
      height: ${toRem(44)} !important;
      &#hotelname{
        padding-right: ${toRem(30)} !important;
      }
      &#hotelname:is(${constants.RTL_LANG_LIST}){
        padding-right: ${toRem(16)} !important;
        padding-left: ${toRem(30)} !important; 
      }
    }

    .m-input-field:is(${constants.RTL_LANG_LIST}) {
      .icon-arrow-down:is(${constants.RTL_LANG_LIST}),
      .icon-arrow-up:is(${constants.RTL_LANG_LIST}) {
        right: auto !important;
        left: ${toRem(12)} !important;
        top: ${toRem(12)} !important;
      }
    }

    .step-two__findbill--accordian:is(${constants.RTL_LANG_LIST}) {
      display: flex !important;
    }

    .steptwo__question--radiobutton:is(${constants.RTL_LANG_LIST}) {
      .m-radio-button-sm:is(${constants.RTL_LANG_LIST}) {
        margin-right: 0 !important;
        margin-left: ${toRem(24)} !important;

        // update the css for radio rtl styles
        & input[type=radio] {
          &+label {
            padding-left: 0;
            padding-right: ${toRem(25)};

            &:before {
              right: 0;
            }

            &:after {
              right: ${toRem(5)};
            }
          }
        }
      }
    }

      .m-input-field label:is(${constants.RTL_LANG_LIST}) {
        left: auto !important;
        right: ${toRem(11)} !important;
      }
  `}
`;

export const GlobalStyles = createGlobalStyle`
.MuiAutocomplete-paper{
  padding-right:${toRem(4)};
.MuiAutocomplete-listbox {
  &::-webkit-scrollbar {
   margin-right:${toRem(8)};
  }

  &::-webkit-scrollbar {
    width: ${toRem(8)};
    height: ${toRem(8)};
    background-color: transparent; /* or add it to the track */
  }

  &::-webkit-scrollbar-thumb {
    border-radius:${toRem(4)};
    background: ${baseVariables.color.neutral20};
  }

}}`;
