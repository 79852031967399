/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Image } from '@marriott/mi-ui-library';
import { useRouter } from 'next/router';

import { useCreateAccountStore } from '../../modules/store/createAccountStore';
import { CountryList } from './CreateAccountForm/CreateAccountForm.types';
import { CreateAccountProps, SweepStakesEnrollmentListType, partnerPortalListType } from './CreateAccount.types';
import { StyledCreateAccount } from './CreateAccount.styles';
import { CreateAccountDescription } from './CreateAccountDescription';
import { CreateAccountForm } from './CreateAccountForm';
import { fallbackImages } from '../../modules/utils/constants';
import { PageContext, generateAEMImageRenditions, getCountryAndLangCode } from '../../modules';

export const CreateAccount: FC<CreateAccountProps> = (pageProps: any) => {
  const { setIsValidPartnerPortal, setvalidSweepEnrollObject, setGoBackLabel, setIsEnrollEAA } =
    useCreateAccountStore();
  const pageContext = useContext(PageContext);
  const { currentLocale } = pageContext;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const router = useRouter();
  const clientIdParam = router.query['clientId'] ?? '';
  const sourceSystemParam = router.query['sourceSystem'] ?? '';
  const clientId =
    typeof clientIdParam === 'string' ? clientIdParam : Array.isArray(clientIdParam) ? clientIdParam[0] || '' : '';
  // valid sweepstake object
  const sweepStakeObject =
    pageProps?.sweepStakesEnrollmentList &&
    pageProps?.sweepStakesEnrollmentList?.filter((item: SweepStakesEnrollmentListType) => item?.sweepsId === clientId);
  const partnerPortalObject = pageProps?.partnerPortalList?.find(
    (item: partnerPortalListType) => item?.partnerPortalId === sourceSystemParam
  );

  const isValidClientId = sweepStakeObject && sweepStakeObject?.length > 0;

  const sweepStakeEnrollmentCode = pageProps?.sweepStakesSourceCodes?.filter(
    (enrollmentcode: CountryList) => enrollmentcode?.value === clientId + '_' + countryCode
  )?.[0]?.id;

  useEffect(() => {
    if (partnerPortalObject) {
      setIsValidPartnerPortal(true);
      setGoBackLabel(partnerPortalObject?.goBackLabel);
    }

    !!clientId &&
      pageProps?.sweepStakesEnrollmentList &&
      setvalidSweepEnrollObject({
        sweepEnrollmentSourceCode: isValidClientId ? sweepStakeEnrollmentCode : '',
        sweepRedirectUrl: sweepStakeObject?.[0]?.sweepsRedirectUrl ?? '',
      });

    pageProps?.eaa && setIsEnrollEAA(pageProps?.eaa);
  }, []);

  return (
    <StyledCreateAccount data-component-name="o-account-createaccount">
      <div className="create-account">
        <div className={pageProps?.eaa ? 'create-account-image-container-eaa' : 'create-account-image-container'}>
          {!pageProps?.eaa && (
            <Image
              customClass="create-account__image"
              altText={
                isValidClientId
                  ? sweepStakeObject?.[0]?.imageAltText ?? sweepStakeObject?.[0].featuredDynamicImage?.altText
                  : pageProps?.imageAltText ?? pageProps?.featuredDynamicImage?.altText
              }
              defaultImageURL={
                isValidClientId
                  ? sweepStakeObject?.[0].image ?? sweepStakeObject?.[0].featuredDynamicImage?.assetPath
                  : pageProps?.image ?? pageProps?.featuredDynamicImage?.assetPath ?? fallbackImages.Classic
              }
              renditions={generateAEMImageRenditions(
                isValidClientId
                  ? sweepStakeObject?.[0].featuredDynamicImage?.assetPath
                  : pageProps?.featuredDynamicImage?.assetPath
              )}
              loading="eager"
            />
          )}
        </div>

        <div
          className={clsx('create-account__wrapper container', !pageProps?.eaa && 'create-account__wrapper__is-border')}
        >
          <CreateAccountDescription
            isValidClientId={isValidClientId}
            sweepStakeObject={sweepStakeObject?.[0]}
            partnerPortalObject={partnerPortalObject}
            {...pageProps}
          />
          <CreateAccountForm
            partnerPortalObject={partnerPortalObject}
            isValidClientId={isValidClientId}
            {...pageProps}
          />
        </div>
      </div>
    </StyledCreateAccount>
  );
};

export const CreateAccountConfig = {
  emptyLabel: 'createaccount',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/createaccount`,
};

export const CreateAccountEditable = (props: any) => {
  return (
    <EditableComponent config={CreateAccountConfig} {...props}>
      <CreateAccount {...props} />
    </EditableComponent>
  );
};
