// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const UpdateRoomSelection = dynamic(() => import('./UpdateRoomSelection').then(mod => mod.UpdateRoomSelection));
const UpdateRoomSelectionV2 = dynamic(() => import('./UpdateRoomSelectionV2').then(mod => mod.UpdateRoomSelectionV2));

export const UpdateRoomSelectionConfig = {
  emptyLabel: 'UpdateRoomSelection',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/updateroomselection`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UpdateRoomSelectionEditable = (props: any) => {
  return (
    <EditableComponent config={UpdateRoomSelectionConfig} {...props}>
      {props?.isDTT ? <UpdateRoomSelectionV2 {...props} /> : <UpdateRoomSelection {...props} />}
    </EditableComponent>
  );
};
