// Imports for external libraries go here.
import React, { FC, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { PageContext } from '../../../modules/context/PageContext';
import { Button, CheckBox, Heading, InputTextField, Messages, RichText, Types } from '@marriott/mi-ui-library';
import { phoenixAccountUpdateEmailSubscription } from '../../../modules/graph/index';
import { SUCCESS_CODE, UNSUBSCRIBE_ACTION } from '../../../modules/utils/constants/constants';
import { generateApolloClientHeaders, isValidEmaiFormat, scrollToClass } from '../../../modules/utils/helper';
import { ConsentOption } from '../EmailUnsubscribe.types';
import { UnsubscribeProps } from './Unsubscribe.types';
import { StyledUnsubscribe } from './Unsubscribe.styles';

// Use named rather than default exports.
export const Unsubscribe: FC<UnsubscribeProps> = props => {
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);

  const [updateEmailSubscribtion] = useMutation(phoenixAccountUpdateEmailSubscription);
  const [communicationConsents, setCommunicationConsents] = useState<Array<ConsentOption>>([]);
  const [selectAll, setSelectAll] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasEmail, setHasEmail] = useState('');
  const [hasMessage, setHasMessage] = useState({
    emailEmptyError: false,
    emailUpdatedSuccess: false,
    emailInvalidError: false,
  });

  useEffect(() => {
    //store value  for paylaod checkbox
    const initializedCheckConsents: Array<ConsentOption> = props?.pageProps?.consentDetails
      ?.filter(option => option.type !== '' && option.method !== '')
      ?.map(option => ({
        method: option.method,
        type: option.type,
        subscribed: true,
      }));
    setCommunicationConsents(initializedCheckConsents);
  }, []);

  useEffect(() => {
    if (hasError || hasMessage.emailUpdatedSuccess || hasMessage.emailEmptyError || hasMessage.emailInvalidError) {
      scrollToClass();
    }
  }, [hasError, hasMessage]);

  //check if any of the checkbox is subscribed
  const hasAnyCheckboxChecked = communicationConsents?.some(consent => consent.subscribed);
  const handleConsentChange = (method: string, type: string, subscribeAll: boolean) => {
    const updatedConsents = [...communicationConsents];
    if (subscribeAll) {
      updatedConsents.forEach(consent => {
        consent.subscribed = !selectAll;
      });
      setSelectAll(prev => !prev);
    } else {
      updatedConsents.forEach(consent => {
        if (consent.method === method && consent.type === type) {
          consent.subscribed = !consent.subscribed;
        }
      });
      setSelectAll(updatedConsents.every(consent => consent.subscribed));
    }
    setCommunicationConsents(updatedConsents);
  };

  // toggle the value of subscribed and append the consents
  const toggleAndAppendConsents = (consents: { subscribed: boolean }[]) => {
    const toggledConsents = consents?.map((consent: { subscribed: boolean }) => ({
      ...consent,
      subscribed: !consent.subscribed,
    }));

    const newConsent = {
      method: 'email',
      type: 'PRTNR',
      subscribed: false,
    };
    const payloadConsnet = [...toggledConsents, newConsent];
    return payloadConsnet;
  };

  //mutation
  const updateSubscribeload = () => {
    const payloadConsent = toggleAndAppendConsents(communicationConsents);

    setHasError(false);
    setHasMessage({
      emailEmptyError: false,
      emailUpdatedSuccess: false,
      emailInvalidError: false,
    });

    const checkEmailFormat = isValidEmaiFormat(hasEmail);
    if (!hasEmail) {
      setHasMessage(prevState => ({ ...prevState, emailEmptyError: true }));
    } else if (!checkEmailFormat) {
      setHasMessage(prevState => ({ ...prevState, emailInvalidError: true }));
    } else {
      // Check if there are any changes in consents
      setIsLoading(true);
      updateEmailSubscribtion({
        variables: {
          input: {
            action: UNSUBSCRIBE_ACTION,
            email: hasEmail,
            options: payloadConsent,
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        onCompleted: data => {
          setIsLoading(false);
          const responseCode = data?.updateAccountCommunicationOptions?.code;
          if (responseCode === SUCCESS_CODE) {
            setHasMessage(prevState => ({ ...prevState, emailUpdatedSuccess: true }));
            setHasError(false);
          } else {
            setHasError(true);
          }
        },
        onError: () => {
          setIsLoading(false);
          setHasError(true);
        },
      });
    }
  };
  return (
    <StyledUnsubscribe>
      <div className="container">
        <Heading
          variation={Types.headingType.title}
          fontSize={Types.size.small}
          titleText={props?.pageProps?.header}
          customClass="after-submitting--header "
        />
        <RichText text={props?.pageProps?.title} componentId="titleDescription" customClass="t-body-m" />
        {(hasError || hasMessage.emailUpdatedSuccess || hasMessage.emailEmptyError || hasMessage.emailInvalidError) && (
          <div id="banner-msg" data-testid="banner-msg" className="banner-msg pt-4 pb-2">
            <Messages
              messageType={clsx(hasMessage.emailUpdatedSuccess ? 'success' : 'error-sev1')}
              className="unsubscribe__errormsg px-2"
            >
              {hasMessage.emailUpdatedSuccess && (
                <RichText text={props?.pageProps?.emailUpdatedMsg} componentId="success-msg" />
              )}
              {hasMessage.emailEmptyError && <RichText text={props?.pageProps?.emailError} componentId="error-msg" />}
              {hasMessage.emailInvalidError && (
                <RichText text={props?.pageProps?.emailErrorMessage} componentId="error-msg" />
              )}
              {hasError && <RichText text={props?.pageProps?.apiFailureErrMsg} componentId="error-msg" />}
            </Messages>
          </div>
        )}
        <InputTextField
          label={props?.pageProps?.emailLabel}
          infoLabel={`${props?.pageProps?.emailLabel}-label`}
          testId="email-input"
          showErrorMessage={!!(props?.pageProps?.inlineEmailInvalidMsg && hasMessage?.emailInvalidError)}
          messageToShow={props?.pageProps?.inlineEmailInvalidMsg}
          messageClass="error-label t-font-xs m-0"
          setErrorHtml={true}
          inputMaxLength={80}
          className={clsx(
            'unsubscribe__email my-5 m-input-text-field',
            (hasMessage.emailEmptyError || hasMessage.emailInvalidError) && ' is-error'
          )}
          getInputValue={val => setHasEmail(val)}
          getInputProps={() => ({
            autoComplete: 'off',
            id: props?.pageProps?.emailLabel,
          })}
        />
      </div>

      <div className="unsubscribe__consents">
        <div className="pb-5">
          {props?.pageProps?.consentDetails?.map((options, index) => (
            <div className={`${options.type}-class`} key={index}>
              <div
                className={clsx(
                  'unsubscribe__consents__container',
                  options?.type === '' ? 'consent-bg px-3 ' : 'container'
                )}
              >
                <div
                  className={clsx(
                    'unsubscribe__consents__container__hrLine container px-0  py-4',
                    options?.type === '' && 'px-md-2'
                  )}
                >
                  <CheckBox
                    key={index}
                    checked={
                      communicationConsents?.find(
                        consent => consent.type === options.type && consent.method === options.method
                      )?.subscribed || selectAll
                    }
                    checkboxId={`checkbox-${index}`}
                    checkboxName={options.type}
                    className="unsubscribe__consents__container__checkbox"
                    onKeyDown={event =>
                      event.key === 'Enter' &&
                      handleConsentChange(
                        options?.method,
                        options?.type,
                        options?.type === '' && options?.method === ''
                      )
                    }
                    onChange={() =>
                      handleConsentChange(
                        options?.method,
                        options?.type,
                        options?.type === '' && options?.method === ''
                      )
                    }
                    children={
                      <RichText
                        customClass="unsubscribe__consents__container__checkbox__labelText m-0 p-0 t-subtitle-m"
                        text={options?.consentTitle}
                        componentId={`checkboxDescription-${index}`}
                      />
                    }
                  />
                  <div className="px-4">
                    <RichText
                      customClass="t-font-s"
                      text={options?.consentDescription}
                      componentId={`${options?.type}-id`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="container unsubscribe__footer d-flex flex-md-row flex-column align-items-center">
          <Button
            className={clsx(
              'm-button-m px-3 m-button-primary modal-footer__unsubscribe ml-md-0',
              !hasAnyCheckboxChecked && 'disabled'
            )}
            callback={() => updateSubscribeload()}
            isDisabled={!hasAnyCheckboxChecked} // Disable the button if no subscribed consent
            testId="unsubscribeBtn"
            ariaLabel={props?.pageProps?.unsubscribeLabel}
          >
            {isLoading ? (
              <div className="m-spinner-s" data-testid="loading-spinner"></div>
            ) : (
              props?.pageProps?.unsubscribeLabel
            )}
          </Button>
          <Button
            className={'modal-footer__cancel  m-link-action mx-md-5  mx-3 mt-5 mt-md-0'}
            buttonCopy={props?.pageProps?.cancelButton}
            isLink={true}
            href={props?.pageProps?.cancelCtaPath}
          />
        </div>
      </div>
    </StyledUnsubscribe>
  );
};
