// Styles for AccountStaticWrapper go here.
import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledAccountStaticWrapper = styled.div``;
export const StyledAccountWrapperParentContainer = styled.div`
  &.m-account-card {
    box-shadow: rgba(0, 0, 0, 0.07) ${toRem(0)} ${toRem(4)} ${toRem(20)} ${toRem(0)};
    border-radius: ${toRem(10)};
    box-sizing: border-box;
  }
`;
export const StyledAccountWrapperChildContainer = styled.div``;
