import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';
import rtl from 'styled-components-rtl';

export const StepThreeContainer = styled.div`
  ${rtl`
      .dropdown {
        width: 100%;
        padding: ${toRem(12)} ${toRem(
    16
  )} !important; //important is given to override the dropdown css property to make it aligned with text box
        border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
        border-color: ${baseVariables.color['neutral40']} !important;

        height: ${toRem(44)};

        .icon-dropdown-down,
        .icon-dropdown-up {
          position: absolute;
          right: ${toRem(8)};
        }
      }
      .show-disabled {
        opacity: 0.4; /* or any other visual cue to indicate disabled state */
        pointer-events: none;
      }
      .step-three {
        &__faxRequest {
          &--firstrow {
            .hotelCountry-label {
              z-index: 1;
            }
            .m-dropdown-container {
              .m-dropdown-children {
                height: ${toRem(224)};
              }
            }
            .m-input-field {
              z-index: 3;
            }
          }
          &--secondrow {
            .hotelState-label {
              z-index: 1;
            }
            .m-dropdown-container {
              .m-dropdown-children {
                height: ${toRem(224)};
              }
            }
          }
        }

        &__fields {
          &--error-state {
            color: ${baseVariables.color['alert50']};
          }
          &--uploadformat {
            color: ${baseVariables.color['base10']};
          }
        }
        &__attachbill {
          &--accordian {
            .attachbill-accordian {
              font-weight: ${constants.BOLD_FONT_WEIGHT};
            }
          }
        }
        &__comment {
          position: relative;
          &--label {
            position: absolute;
            top: ${toRem(-8)};
            background: ${baseVariables.color['base20']};
            left: ${toRem(16)};
          }
          &--commentsbox {
            caret-color: ${baseVariables.color['base10']};
            box-sizing: border-box;
            height: ${toRem(176)};
            width: 100%;
            border-radius: ${toRem(8)};
            &--error{
              border: ${toRem(1)} solid ${baseVariables.color['alert50']};
            }
            &--optional{
              border: ${toRem(1)} solid ${baseVariables.color['neutral40']};
            }
            @media ${baseVariables.mediaQuery.md} {
              height: ${toRem(184)};
            }
          }
        }
      }

      .fileupload__browse:is(${constants.RTL_LANG_LIST}) {
        margin-right: ${toRem(16)} !important;
      }

      .icon-arrow-down:is(${constants.RTL_LANG_LIST}) {
        padding-right: ${toRem(8)} !important;
      }

      .m-input-field label:is(${constants.RTL_LANG_LIST}),
      .step-three__comment label:is(${constants.RTL_LANG_LIST}) {
        left: auto !important;
        right: ${toRem(11)} !important;
      }

      .m-input-field input:is(${constants.RTL_LANG_LIST}) {
        padding-right: ${toRem(16)} !important;
        height: ${toRem(44)} !important;
      }

      .dropdown:is(${constants.RTL_LANG_LIST}) {
        text-align: right !important;

        .icon-dropdown-down:is(${constants.RTL_LANG_LIST}),
        .icon-dropdown-up:is(${constants.RTL_LANG_LIST}) {
          right: auto !important;
          left: ${toRem(8)} !important;
          padding: 0 !important;
        }
      }

      .dropdown__container:is(${constants.RTL_LANG_LIST}) li {
        text-align: right !important;
      }

      .step-three__question--radiobutton:is(${constants.RTL_LANG_LIST}) {
        .m-radio-button-sm:is(${constants.RTL_LANG_LIST}) {
          // update the css for radio rtl styles
          & input[type=radio] {
            &+label {
              padding-left: 0;
              padding-right: ${toRem(25)};

              &:before {
                right: 0;
              }

              &:after {
                right: ${toRem(5)};
              }
            }
          }
        }
      }
  `}
`;
