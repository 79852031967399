import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledForceChangePassword = styled.div`
  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
        text-align: justify;
        input[type='checkbox']:checked + label:before, input[type='checkbox'] + label:before {
          right: 0 !important;
          left: unset;
        }
        input[type='checkbox']:checked + label:after, input[type='checkbox'] + label:after {
          right: ${toRem(6)} !important;
          transform: rotate(45deg);
          left: unset;
        }
        input[type='checkbox'] + label {
          padding-right: ${toRem(30)} !important;
        }
      }
    }
    .m-input-field input:is(${constants.RTL_LANG_LIST}){
      text-indent: ${toRem(16)} !important;
      height: ${toRem(45)} !important;
    }
    .m-input-field label:is(${constants.RTL_LANG_LIST}){
      right: ${toRem(11)} !important;
      width: max-content;
    }
    .m-input-field .icon-visibility, .m-input-field .icon-visibility-off{
      &:is(${constants.RTL_LANG_LIST}){
        left: ${toRem(13)} !important;
        right: unset;
      }
    }
    .m-input-field {
      input {
        caret-color: ${baseVariables.color['neutral40']};
        &::-ms-reveal {
          display: none;
        }
      }
    }
    .activate_account--countrydropdown {
      width: 100%;
      height: ${toRem(44.67)};
      border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
      border-color: ${baseVariables.color['neutral40']} !important;
      padding-top: ${toRem(12)} !important;
      padding-bottom: ${toRem(12)} !important;
      .icon-dropdown-down,
      .icon-dropdown-up {
        position: absolute;
        right: ${toRem(8)};
      }
      .dropdown-value {
        color: ${baseVariables.color['neutral40']} !important;
        font-size: ${toRem(16)};
      }
    }
    .m-dropdown-container {
      .m-dropdown-children {
        height: ${toRem(224)};
      }
    }
    .country-label {
      z-index: 1;
    }
    .space-bottom {
      margin-bottom: ${toRem(88)};
    }
    .custom-btn {
      width: 100%;
      @media ${baseVariables.mediaQuery['sm']} {
        width: auto;
        min-width: ${toRem(128)}; // UX team has asked to set the width of button as per VD
      }
    }
    .submit_btn {
      width: 100%;
      @media ${baseVariables.mediaQuery.md} {
        width: auto;
      }
      &:is(${constants.RTL_LANG_LIST}){
        margin-left: unset !important;
        margin-right: unset !important;
        @media ${baseVariables.mediaQuery.md} {
          margin-left: ${toRem(32)} !important;
          margin-right: 0 !important;
        }
      }
    }
    .error-label,
    .error-label * {
      font-size: ${baseVariables.font.fontXs};
      color: ${baseVariables.color.alert50} !important;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .uxl-error-msg {
      p {
        margin-bottom: 0;
      }
    }
  `}
`;
