import { StateCreator } from 'zustand';

import { createAppStore } from '../zustand';
import { UserDetailsGlobalStoreType, UserDetailsGlobalStoreDataType } from './userDetailsGlobalStore.types';

export const userDetailsGlobalStoreIntitalState: UserDetailsGlobalStoreDataType = {
  rememberedUser: false,
  isUserDetailsSaved: false,
};

const userDetailsGlobalStore: StateCreator<UserDetailsGlobalStoreType> = set => {
  return {
    ...userDetailsGlobalStoreIntitalState,
    setUserInfo: (value?: Partial<UserDetailsGlobalStoreDataType>) => {
      set({ ...value });
    },
    setPartialUserInfo: (value?: Partial<UserDetailsGlobalStoreDataType>) => {
      /**
       * in any case we need to update single object
       */
      set(prevState => ({ ...prevState, ...value }));
    },
    resetUserInfo: () => {
      set({ ...userDetailsGlobalStoreIntitalState });
    },
  };
};

export const useUserDetailsGlobalStore = createAppStore(userDetailsGlobalStore, {
  usePersistentStore: true,
  persistentStoreName: 'UserDetailsGlobalStore',
});
