import React from 'react';
import { Link } from '@marriott/mi-ui-library';
import { ReportMissingStayLinkProps } from './ReportMissingStayLink.types';

export const ReportMissingStayLink: React.FC<ReportMissingStayLinkProps> = ({
  ctaLabel,
  ctaLink,
}: ReportMissingStayLinkProps) => {
  return <Link linkHref={ctaLink} linkClassName="m-link-tertiary-button t-font-s" text={ctaLabel} target="_self" />;
};
