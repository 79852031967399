/* eslint-disable @typescript-eslint/no-explicit-any */
// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { CancelReservation } from './CancelReservation';
import { CancelReservationV2 } from './CancelReservationV2';

export const CancelReservationConfig = {
  emptyLabel: 'cancelreservation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/cancelreservation`,
};

export const CancelReservationEditable = (props: any) => {
  return (
    <EditableComponent config={CancelReservationConfig} {...props}>
      {props?.isDTT ? <CancelReservationV2 {...props} /> : <CancelReservation {...props} />}
    </EditableComponent>
  );
};
