// Imports for external libraries go here.
import React, { FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CustomerPreferenceHOC } from '../../molecules/CustomerPreferenceHOC';
import { FolioOptionsChild } from './FolioOptionsChild';
import { FolioOptionsProps } from './FolioOptions.types';
import { StyledFolioOptions } from './FolioOptions.styles';

// Use named rather than default exports.
export const FolioOptions: FC<FolioOptionsProps> = props => {
  const RenderComponent = CustomerPreferenceHOC(FolioOptionsChild, 'folio-delivery', props?.isAuthorMode);
  return (
    <StyledFolioOptions data-testid="foliooptions" data-component-name="o-account-foliooptions">
      <RenderComponent model={props?.model} />
    </StyledFolioOptions>
  );
};

export const FolioOptionsConfig = {
  emptyLabel: 'foliooptions',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/foliooptions`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FolioOptionsEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <FolioOptions {...props} />
  ) : (
    <EditableComponent config={FolioOptionsConfig} {...props}>
      <FolioOptions {...props} />
    </EditableComponent>
  );
};
