/**
 * check user details saved or not?
 *
 */

import axios, { AxiosRequestHeaders } from 'axios';
import { useUserDetailsGlobalStore } from './userDetailsGlobalStore';

let isApiCallTriggred = false;

const capatilizeFirstLetter = (str: string) => {
  if (str) {
    const arr = str.split('');
    arr.shift();
    return `${str?.charAt(0).toUpperCase()}${arr.join('')}`;
  }
  return str;
};

export enum UserDetailsURL {
  /**
   * create enum for userDetails URL
   * other app also can use if required
   */
  urlPrefix = '/mi',
  v1 = '/v1',
  v2 = '/v2',
  appName = '/phoenix-account-auth',
  endPoint = '/userDetails',
}
const fetchUserDetails = async (customHeaders?: AxiosRequestHeaders, isDtt: boolean = false) => {
  /**
   * expose this function to use by all apps
   * if required we will pass the url in this function
   */
  const { setUserInfo } = useUserDetailsGlobalStore.getState();
  if (!isApiCallTriggred) {
    isApiCallTriggred = true;
    try {
      const { data } = await axios.get(
        `${UserDetailsURL.urlPrefix}${UserDetailsURL.appName}${isDtt ? UserDetailsURL.v2 : UserDetailsURL.v1}${
          UserDetailsURL.endPoint
        }`, //create URL for dtt and other apps
        {
          headers: {
            /**
             * pass custommize headers if required
             */
            'Content-Type': 'application/json',
            ...customHeaders,
          },
        }
      );

      setUserInfo({
        ...data,
        name: capatilizeFirstLetter(data?.headerSubtext?.consumerName?.toLowerCase() || ''),
        isUserDetailsSaved: true,
      });
      isApiCallTriggred = false;
    } catch (e) {
      /**
       * handle error at this place ,we have to use the logger
       */
      isApiCallTriggred = false;
      //TODO: need to add a logger at this place
      //throw new Error(e);
    }
  }
  /**
   * logs on else block
   */
};

/***
 * array of arguments
 * some are defined E.G
 */
export const getUserDetails = (
  forceFetchUserDetails: boolean = false,
  isDtt: boolean = false,
  customHeaders?: AxiosRequestHeaders
) => {
  /**
   * expose this function to use by all apps
   * if required we will pass the url in this function
   */
  const data = useUserDetailsGlobalStore.getState(); // fetch datra from userstore
  const { isUserDetailsSaved } = data;
  if (forceFetchUserDetails || !isUserDetailsSaved) {
    /**
     * fetch user details if data is not available in zustand
     */

    // resetUserDetails(); //TODO: if needed in future
    fetchUserDetails(customHeaders, isDtt);
  }
};

/**
 *
 */

export const resetUserDetails = (immediateUpdate: boolean = false) => {
  /**
   * reset user details
   * if@ page is redirected on user action
   * need to reset the store so that we can fetch the user details and save new data
   */
  if (!immediateUpdate) {
    /**
     * clear session storage so
     * store will clear on page refresh
     */
    sessionStorage.removeItem('UserDetailsGlobalStore');
  } else {
    /**
     * store will be clear when we call this function
     */
    const { resetUserInfo } = useUserDetailsGlobalStore.getState();
    resetUserInfo();
  }
};
