// Styles for EditFolioOptionsChild go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const StyledFolioOptionsChild = styled.div`
  ${rtl`
  .modal {
    &__container {
      max-width: 100%;
      margin: ${toRem(16)} auto;
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(598)} !important;
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(754)} !important;
      }

      &-content {
        padding: ${toRem(24)} ${toRem(24)};
      }
      &-footer {
        background-color: ${baseVariables.color['merch50']};
        border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      }
    }
  }
  .submit-btn-footer {
    text-decoration: underline;
    margin-left: ${toRem(28)};
    @media ${baseVariables.mediaQuery.md} {
      margin-left: ${toRem(0)};
    }
    :is(${constants.RTL_LANG_LIST}) {
      margin-right: ${toRem(28)};
      @media ${baseVariables.mediaQuery.md} {
        margin-right: ${toRem(0)};
      }
    }
  }

  .folio-option-loader {
    .skeleton-loader {
      width: 80%;
      height: ${toRem(30)};

      @media ${baseVariables.mediaQuery.md} {
        width: 50%;
      }
    }
  }

  .modal-footer {
    background-color: ${baseVariables.color['merch50']};
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }

  .apply-button {
    width:auto;
  }
`}
`;
