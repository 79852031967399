import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { CarousalArrowButtonColor, constants } from '../../modules';

export const StyledSavedHotel = styled.div`
  .glide--swipeable {
    @media ${baseVariables.mediaQuery.lg} {
      cursor: initial;
    }
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      .m-link-tertiary-button-external{
        &:after {
          transform: rotate(-180deg) translate(${toRem(2)}, ${toRem(0)});
        }
        &:hover{
          &:after {
          transform: rotate(-135deg) translate(${toRem(2)}, ${toRem(0)});
          }
        }
      }
      .m-link-tertiary-button{
        margin-left: ${toRem(27)} !important;
      }
    }
    .container {
      &.property-card {
        flex-direction: column;
        width: auto;
        max-width: ${toRem(343)};
        height: ${toRem(420)};
        @media ${baseVariables.mediaQuery.md} {
          width: ${toRem(352)};
          max-width: ${toRem(352)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(364)};
          max-width: ${toRem(364)};
        }

        .property-card {
          &__img-container,
          &__img-container--img,
          &__details-container,
          &__details-container_bottom,
          &__details-container_top {
            width: 1005;
            max-width: ${toRem(343)};
            @media ${baseVariables.mediaQuery.md} {
              width: ${toRem(352)};
              max-width: ${toRem(352)};
            }
            @media ${baseVariables.mediaQuery.lg} {
              width: ${toRem(364)};
              max-width: ${toRem(364)};
            }
          }
          &__img-container,
          &__img-container--img {
            height: ${toRem(216)};
          }
          &__details-container{
            max-height: ${toRem(204)} !important; 
          }
          &__tertiary-link {
            padding-right: 0 !important;
          }
          &__img-container {
            .property-card__img-container--logo {
              .brand-logo-WH::before {
                font-size: ${toRem(14)};
              }
              }
            .property-card__skeleton {
              margin-left: 0;
            }
          }
        }
      }
      .img-carousel__container {
        .glide__arrow {
          padding: 0px;
          transform: none;
          opacity: 1;
          background-color: ${CarousalArrowButtonColor};
          > .icon-arrow-left,
          > .icon-arrow-right {
            &:before {
              position: initial;
            }
          }

          &:hover {
            background-color: ${baseVariables.color.merch50};
            color: ${baseVariables.color.merch30};
          }
        }
      }
    }

    .img-carousel__container,
    .glide {
      width: auto;
      max-width: ${toRem(343)};

      @media ${baseVariables.mediaQuery.md} {
        width: ${toRem(352)};
        max-width: ${toRem(352)};
      }

      @media ${baseVariables.mediaQuery.lg} {
        width: ${toRem(364)};
        max-width: ${toRem(364)};
      }
      &__slide {
        width: auto;
        flex-shrink: initial;
        .card {
          max-width: ${toRem(310)};
          width: auto;
          @media ${baseVariables.mediaQuery.md} {
            width: ${toRem(332)};
          }
          @media ${baseVariables.mediaQuery.lg} {
            width: ${toRem(352)};
          }
        }
      }
    }

    @media ${baseVariables.mediaQuery.lg} {
      flex-wrap: wrap;
    }
    .glide__track {
      overflow: hidden;
    }
    .list-unstyled {
      list-style-type: none;
    }
    .glide-find-favorites {
      max-width: 100%;
      padding: 0;
      .card {
        max-width: 100%;
        width: 100%;

        @media ${baseVariables.mediaQuery.md} {
          max-width: ${toRem(352)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          max-width: ${toRem(364)};
        }
      }
    }
    
  .property-card-rtl:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;

      .glide__arrow span:before {
        right: 22% !important;
      }

      .glide-bullet-container:is(${constants.RTL_LANG_LIST}) {
        right: auto !important;
        left: ${toRem(16)} !important;
      }

      .property-card {
        &__title button:is(${constants.RTL_LANG_LIST}) {
          text-align: right !important;
        }

        &__details-container_footer_link:is(${constants.RTL_LANG_LIST}) {
          margin: 0 ${toRem(512)} 0 0 !important;
        }
      }

      .property-card__img-container--favorite:is(${constants.RTL_LANG_LIST}) {
            right: auto !important;
            left: ${toRem(15)} !important;
        }

      .property-card__img-container--logo:is(${constants.RTL_LANG_LIST}) {
          left: auto !important;
          right: ${toRem(16)} !important;
      }
    }
    .saved-container{
      &__row{
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(1164)};
        }
      }
    }
    .property-card {

      &__tertiary-link{
        margin-left: ${toRem(24)} !important;

        &:is(${constants.RTL_LANG_LIST}) { 
          margin-right: ${toRem(24)} !important;
        }
      }

      &__details-container{
        max-height: ${toRem(204)} !important;  
        padding-bottom: ${toRem(24)} !important;  
        
        &_footer{
          border-top:  ${toRem(1)} solid ${baseVariables.color.neutral20};
          padding-top: ${toRem(10)};
        }
      &_footer_link {
        min-width: ${toRem(82)};
      }

      .footer-container{
        position: relative;
        top: ${toRem(6)} !important;
       
        @media ${baseVariables.mediaQuery.md} {
          top: ${toRem(4)} !important;
        }
      }
      .separator {
       display:none;
      }

  `}
`;
