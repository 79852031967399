import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { SkeletonLoaderDuration } from '../../modules/utils/constants';

export const OverviewMainContainer = styled.div`
  background-color: ${baseVariables.color['base20']};

  & .member-status {
    &__skeleton {
      &__level {
        width: ${toRem(280)};
        height: ${toRem(24)};
        &:after {
          animation-duration: ${SkeletonLoaderDuration};
        }
      }

      &__name {
        width: ${toRem(344)};
        height: ${toRem(64)};
        &:after {
          animation-duration: ${SkeletonLoaderDuration};
        }
      }
    }
  }
`;
