/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { Heading, Types } from '@marriott/mi-ui-library';

import { PROMOTIONS_CONSTANT } from '../../modules';
import CustomTitleMock from './__mock__/CustomTitleText.model.json';
import { CustomTitleTextProps } from './CustomTitleText.types';
import { StyledCustomTitleText } from './CustomTitleText.styles';

// Use named rather than default exports.
export const CustomTitleText: FC<CustomTitleTextProps> = (pageProps: CustomTitleTextProps) => {
  const model = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const pathParam = useSearchParams();
  const promotion = isAuthorMode ? CustomTitleMock.promotionId : pathParam && pathParam.get('promotion')?.toUpperCase();

  const customTitle = promotion
    ? model?.customTitleTexts?.[promotion]
      ? model?.customTitleTexts?.[promotion]?.replace(PROMOTIONS_CONSTANT.HTML_TAG_REGEX, '')
      : model?.defaultTitle
    : '';

  const getBackGroundTheme = (color: string) => {
    switch (color.toLowerCase()) {
      case 'black':
        return 'color-scheme2';
      case 'white':
        return 'color-scheme1';
      case 'grey':
        return 'color-scheme3';

      default:
        return '';
    }
  };
  return promotion ? (
    <StyledCustomTitleText
      className={clsx(
        !model?.isFullWidth ? 'container' : 'px-4',
        getBackGroundTheme(model?.backgroundColor),
        'pt-4 pt-md-5',
        'text-center'
      )}
      data-component-name="o-account-customtitletext"
      data-testid="customtitletext"
    >
      <Heading
        fontSize={Types.size.large}
        variation={Types.headingType.subtitle}
        element={Types.tags.div}
        dangerouslySet={customTitle}
      />
    </StyledCustomTitleText>
  ) : (
    <></>
  );
};
export const CustomTitleTextConfig = {
  emptyLabel: 'CustomTitleText',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/customtitletext`,
};
export const CustomTitleTextEditable = (props: any) => {
  return (
    <EditableComponent config={CustomTitleTextConfig} {...props}>
      <CustomTitleText {...props} />
    </EditableComponent>
  );
};
