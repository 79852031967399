/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import { inspect } from 'util';
import moment from 'moment';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Eyebrow, Messages, RichText } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import {
  OverviewMovableComponents,
  PROMOTIONS_CONSTANT,
  PageContext,
  REGISTERED_PROMOTIONS_ID,
  apiLogger,
  generateApolloClientHeaders,
  constants,
  getCalednarLocaleKeys,
} from '../../modules';
import { phoenixAccountGetAllPromotionsByCustomerId } from '../../modules/graph';
import { useStore } from '../../modules/store/memberLevelStore';
import { useOverviewStore } from '../../modules/store/overviewStore';
import { getNewPromotions, isPresentPromotion, sortOverviewPromotions } from '../../modules/utils/promotionHelper';
import promotionsMock from './__mock__/RegisteredPromotions.model.json';
import { RegisteredPromotionsProps } from './RegisteredPromotions.types';
import { StyledRegisteredPromotions } from './RegisteredPromotions.styles';
import { LoyaltyPromotionEdge } from './RegisteredPromotions.types';

const PromotionEmptyStatus = dynamic(() =>
  import('../../molecules/PromotionEmptyStatus').then(mod => mod.PromotionEmptyStatus)
);

const RegisteredPromotionCard = dynamic(() =>
  import('../../molecules/RegisteredPromotionCard').then(mod => mod.RegisteredPromotionCard)
);

export const RegisteredSkeleton = () => (
  <>
    {' '}
    <div className="promotion__skeleton p-3 mt-3 d-md-flex d-none">
      <div className="skeleton-loader mx-sm-auto mx-md-0 promotion__skeleton__img p-3 "></div>
      <div className="promotion__skeleton__section d-flex flex-column promotion justify-content-between ml-3">
        <div className="skeleton-loader promotion__skeleton__section__label"></div>
        <div className="skeleton-loader promotion__skeleton__section__desc"></div>
        <div className="skeleton-loader promotion__skeleton__section__progress"></div>
        <div className="skeleton-loader promotion__skeleton__section__btn"></div>
      </div>
    </div>
    <div className="promotion__skeleton p-3 mt-3 d-md-none">
      <div className="d-flex mb-3">
        <div className="skeleton-loader mx-sm-auto mx-md-0 promotion__skeleton__img"></div>
        <div className="skeleton-loader promotion__skeleton__section__label ml-3"></div>
      </div>
      <div className="promotion__skeleton__section d-flex flex-column promotion justify-content-between">
        <div className="skeleton-loader promotion__skeleton__section__desc"></div>
        <div className="skeleton-loader promotion__skeleton__section__progress mt-2"></div>
        <div className="skeleton-loader promotion__skeleton__section__btn mt-2"></div>
        <div className="skeleton-loader promotion__skeleton__section__line mt-3"></div>
        <div className="skeleton-loader promotion__skeleton__section__desc my-3"></div>
      </div>
    </div>
  </>
);

export const sortPromotions = (promotionsList: LoyaltyPromotionEdge[], isAscending: boolean) => {
  const sortMultiplier = isAscending ? 1 : -1;
  return [...promotionsList].sort((a, b) => {
    const endDateA = a.node.offers?.[0]?.hurdle?.endDate;
    const endDateB = b.node.offers?.[0]?.hurdle?.endDate;
    if (endDateA && endDateB) {
      return sortMultiplier * (new Date(endDateA).getTime() - new Date(endDateB).getTime());
    }
    return 0;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RegisteredPromotions: FC<RegisteredPromotionsProps> = (pageProps: any) => {
  const {
    promotions,
    isPromotionLoading,
    promotionDetails,
    isPromotionDetailsLoading,
    setPromotions,
    setIsPromotionLoading,
  } = useStore(state => state);
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { PROMOS_WITHOUT_DEFAULT_EARNED_TEXT: promosWithoutDefaultEarnedText } = clientEnvVars;
  const { IS_LOCAL_DEV } = process.env;
  const model = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const pageContext = useContext(PageContext);
  const isOverViewPage = pageContext?.isOverViewPage;
  const dataLoaded = useRef<boolean>(false);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { componentMoved } = useOverviewStore(state => state);
  const isPromotionsMoved = componentMoved === OverviewMovableComponents.PROMOTIONS;
  getCalednarLocaleKeys(pageProps?.model); // to initialize moment with the locale date object

  useEffect(() => {
    if (isAuthorMode) {
      setPromotions(promotionsMock.data);
    }
    return () => {
      sessionStorage?.removeItem(constants.MR_ENROLLED);
    };
  }, []);

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode ||
    promotions ||
    isPromotionLoading ||
    pageProps?.isPromotionDetails;

  const { loading, error } = useQuery(phoenixAccountGetAllPromotionsByCustomerId, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    skip: skipQuery,
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onCompleted: (data: any) => {
      setPromotions(data);
      setIsPromotionLoading(false);
      apiLogger(
        `[RegisteredPromotions] getAllPromotionsData - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
      );
    },
    onError: error => {
      apiLogger(
        `[RegisteredPromotions] getAllPromotionsData - sessionId value:: ${
          sessionData?.sessionToken
        } - error: ${inspect(error)}`
      );
    },
  });

  useEffect(() => {
    if (loading) {
      setIsPromotionLoading(true);
    }
  }, [loading]);

  const { presentPromotions, pastPromotions, hasNewPromotions } = useMemo(() => {
    const today = moment();
    const presentPromoList: LoyaltyPromotionEdge[] = [];
    const pastPromoList: LoyaltyPromotionEdge[] = [];
    if (!pageProps?.isPromotionDetails) {
      promotions?.loyaltyPromotions?.edges.forEach(promo => {
        if (isPresentPromotion(promo, today)) {
          presentPromoList.push(promo);
        } else {
          const pastPromoLimitDate = moment(today).subtract(model?.pastPromoMonthLimit || 12, 'months');
          if (
            moment(promo.node.offers?.[0].hurdle?.endDate).isBefore(today, 'day') &&
            moment(promo.node.offers?.[0].hurdle?.endDate).isSameOrAfter(pastPromoLimitDate, 'day')
          ) {
            pastPromoList.push(promo);
          }
        }
      });
    }
    let sortedPresentPromotionsData;
    if (isOverViewPage) {
      const nonRMAPromoList = presentPromoList?.filter(promo => promo.node.state.code !== 'RMA');
      if (nonRMAPromoList.length > 0) {
        const overviewPromos = sortOverviewPromotions(nonRMAPromoList);
        if (overviewPromos && overviewPromos.length > 0) {
          sortedPresentPromotionsData = overviewPromos.slice(0, 1);
        }
      } else {
        sortedPresentPromotionsData = sortPromotions(presentPromoList, true)?.slice(0, 1) || [];
      }
    } else {
      sortedPresentPromotionsData = sortPromotions(presentPromoList, true);
    }
    const sortedPastPromotionsData = sortPromotions(pastPromoList, false);
    return {
      presentPromotions: sortedPresentPromotionsData || [],
      pastPromotions: sortedPastPromotionsData,
      hasNewPromotions: isOverViewPage && getNewPromotions(promotions).length > 0,
    };
  }, [promotions, model?.pastPromoMonthLimit, isPromotionsMoved]);

  if (error) {
    return (
      <Messages messageType="warning" className="my-4">
        <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
      </Messages>
    );
  }

  return (
    <StyledRegisteredPromotions
      id={REGISTERED_PROMOTIONS_ID}
      className={clsx(pageProps?.isPromotionDetails && 'color-scheme3')}
      data-component-name="o-account-registeredpromotions"
      data-testid="registeredpromotions"
    >
      {!isOverViewPage || (isOverViewPage && componentMoved && (hasNewPromotions || presentPromotions?.length)) ? (
        <div className="container">
          {pageProps?.isPromotionDetails ? (
            sessionData?.consumerID &&
            (isPromotionDetailsLoading ? (
              <RegisteredSkeleton />
            ) : (
              promotionDetails?.loyaltyPromotion &&
              !PROMOTIONS_CONSTANT.NEW_PROMOTIONS_STATE_CODES.includes(
                promotionDetails?.loyaltyPromotion.state.code
              ) && (
                <div className="pt-3">
                  <RegisteredPromotionCard
                    data={{ node: promotionDetails?.loyaltyPromotion }}
                    model={model}
                    promotions="present"
                    isDetailPage={true}
                    isLast={true}
                    promosWithoutDefaultEarnedText={promosWithoutDefaultEarnedText ?? ''}
                  />
                </div>
              )
            ))
          ) : (
            <div
              className={clsx(
                !isOverViewPage ? 'mt-4 mt-lg-5' : isPromotionsMoved ? hasNewPromotions && 'pt-3' : 'pb-3'
              )}
            >
              {!isOverViewPage && (
                <span className="color-scheme1">
                  <Eyebrow text={model?.registeredPromotionsSectionTitle} />
                </span>
              )}
              {isPromotionLoading ? (
                <RegisteredSkeleton />
              ) : presentPromotions.length > 0 ? (
                presentPromotions.map((card, index) => (
                  <RegisteredPromotionCard
                    data={card}
                    model={model}
                    promotions="present"
                    key={index}
                    isLast={index === presentPromotions.length - 1}
                    isPromotionsMoved={isPromotionsMoved}
                    hasNewPromotions={hasNewPromotions}
                    promosWithoutDefaultEarnedText={promosWithoutDefaultEarnedText ?? ''}
                  />
                ))
              ) : (
                !isOverViewPage && (
                  <div className="mt-3 mb-3">
                    <PromotionEmptyStatus message={model?.registeredPromotionsDescription} />
                  </div>
                )
              )}
              {!isOverViewPage && (
                <div className="mt-registered-promo">
                  <span className="color-scheme1">
                    <Eyebrow text={model?.pastPromotionsSectionTitle} />
                  </span>
                  {isPromotionLoading ? (
                    <RegisteredSkeleton />
                  ) : pastPromotions.length > 0 ? (
                    pastPromotions.map((card, index) => (
                      <RegisteredPromotionCard
                        data={card}
                        model={model}
                        promotions="past"
                        key={index}
                        isLast={index === pastPromotions.length - 1}
                        promosWithoutDefaultEarnedText={promosWithoutDefaultEarnedText ?? ''}
                      />
                    ))
                  ) : (
                    <div className="mt-3 mb-5">
                      <PromotionEmptyStatus message={model?.pastPromotionsEmptyDescription} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </StyledRegisteredPromotions>
  );
};

export const RegisteredPromotionsConfig = {
  emptyLabel: 'RegisteredPromotions',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/registeredpromotions`,
};

export const RegisteredPromotionsEditable = (props: any) => {
  return (
    <EditableComponent config={RegisteredPromotionsConfig} {...props}>
      <RegisteredPromotions {...props} />
    </EditableComponent>
  );
};
