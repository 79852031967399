import { canUseDOM } from './helper';
import { constants } from './constants/constants';
import { logger } from '../utils';

const { DEFAULT_LANG, KOREAN_CURRENCY, NO_DECIMAL_LOCALE } = constants;

/**
 * returns the decimal count of the value
 * @param value
 * @returns
 */
export const getDecimalCount = (value: number | string) => {
  const match = value?.toString().match(/(?:\.(\d+))?$/);
  if (!match || !match[1]) {
    return 0;
  }
  return match[1].length;
};

/**
 * return the localized delimiter value for Number and Amount values
 * @param value
 * @returns
 */

const removeHtmlTagSpaceTagFromString = (value: string) => {
  /* we are getting "&nbsp;" in locale string for number formatter
  // this "&nbsp;" is causing double space in view due to font family
  // we have to remove this while page is rendering to avoid flickering
  */
  const domEl = new DOMParser().parseFromString(value, 'text/html');
  // parse the string in domparser so we can get the "&nbsp;" in string
  // then replace the &nbsp; with single space;
  if (domEl.body.innerHTML.indexOf('&nbsp;') !== -1) {
    // remove npsp; if we get in the string
    return domEl.body.innerHTML.replaceAll('&nbsp;', ' ');
  }
  return value;
};

export const numberWithDelimiter = (value: number | string) => {
  const docLang = document?.documentElement?.lang.split(/_|-/)[0].toLowerCase();
  if (typeof value === 'string') {
    return removeHtmlTagSpaceTagFromString(
      Number(value)?.toLocaleString(docLang === '' ? DEFAULT_LANG : docLang, {
        minimumFractionDigits: getDecimalCount(value),
        useGrouping: true,
      })
    );
  }
  return removeHtmlTagSpaceTagFromString(value?.toLocaleString(docLang === '' ? DEFAULT_LANG : docLang));
};

export const numberWithCommas = (val: number | string) => {
  return canUseDOM ? numberWithDelimiter(val) : String(val);
};

/**
 * returns the decimalValue as per the locale
 * @param value
 * @returns
 */
export const handleDecimalValue = (value: number, locale: string | undefined, currency: string): string => {
  const isKRWCurency = currency === KOREAN_CURRENCY;
  const isNoDecimal = locale && NO_DECIMAL_LOCALE.includes(locale);
  return isKRWCurency || isNoDecimal ? numberWithCommas(value?.toFixed(0)) : numberWithCommas(value?.toFixed(2));
};

/*
To convert phone number format for DE domain
*/
const convertPhoneToDE = (phoneNumber: string): string => {
  const index = phoneNumber?.indexOf(' ');
  const parts = [phoneNumber?.slice(0, index), phoneNumber?.slice(index + 1)];
  const cleaned = parts[1].replace(/\D/g, '');
  let match;
  if (cleaned.length === 7) {
    match = cleaned.match(/^(1|)?(\d{3})(\d{4})$/);
  } else if (cleaned.length === 8) {
    match = cleaned.match(/^(1|)?(\d{3})(\d{5})$/);
  } else if (cleaned.length === 9) {
    match = cleaned.match(/^(1|)?(\d{3})(\d{6})$/);
  } else {
    match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  }
  if (match) {
    return cleaned.length < 10
      ? [parts[0], ' ', match[2], '-', match[3]].join('')
      : [parts[0], ' ', match[2], '-', match[3], '-', match[4]].join('');
  }

  return phoneNumber;
};

/*
To format phone number format for DE domain and US domain
*/
export const formatPhoneNumber = (phoneNumberString: string, isbracketflag?: boolean): string => {
  const lang = canUseDOM ? document?.documentElement?.lang.split(/_|-/)[0].toLowerCase() : 'en-US';
  const { log } = logger({})('formatPhoneNumber');
  log.debug(
    `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Entering the 
    formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
  );
  let returnedNumber = '';
  switch (lang) {
    case 'de':
      log.debug(
        `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Exiting the case function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
      );
      returnedNumber = convertPhoneToDE(phoneNumberString);
      return returnedNumber;
    default:
      if (phoneNumberString?.includes('+')) {
        if (phoneNumberString.replaceAll(' ', '').replaceAll('-', '')?.split('')?.length > 12) {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(
              isbracketflag ? /(\d{2})(\d{3})(\d{3})(\d{4})/ : /(\d{2})(\d{3})(\d{4})/,
              isbracketflag ? '$1 ($2) $3 - $4' : '$1 $2 $3'
            );
        } else {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, isbracketflag ? '$1 ($2) $3 - $4' : '$1 $2-$3-$4');
        }
      }

      log.debug(
        `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Exiting the formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
      );
      return returnedNumber;
  }
};
