// Styles for FoodBevPreferences go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledFoodBevPreferences = styled.div`
  ${rtl`
  > div[class*='Modal'] {
    &:before {
      position: fixed;
      @media ${baseVariables.mediaQuery.md} {
        right: ${toRem(4)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(16)};
      }
    }
  }
  .uxl-error-msg{
    width:100%;
  }
  .modal__container {
    max-width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(584)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(902)} !important;
    }
    &-content {
      :is(${constants.RTL_LANG_LIST}){
        text-align: right;
      }
      .checkbox {
        padding-left: 0;

        :is(${constants.RTL_LANG_LIST}){
          label {
            padding-right:${toRem(24)};

            ::before {
              left: unset;
              right: 0;
            }
            
            ::after {
              left: unset;
              right:${toRem(6)};
            }
          }
        }

        label {
          font-size: ${toRem(16)};
          font-weight: ${baseVariables.font.fontWeightMedium};
        }
        &-consent {
          label {
            font-weight: ${constants.BOLD_FONT_WEIGHT};
          }
        }
      }
    }
    .clear-btn {
      margin-left: ${toRem(28)} !important;
      @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(24)} !important;
      }
    }

    &-footer {
      background-color: ${baseVariables.color['merch50']};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30} !important;
      .apply-button {
        width:auto;
      }
    }
  }
  .accordion-checkbox:is(${constants.RTL_LANG_LIST}){
    label {
      padding-right: ${toRem(24)};

      ::before {
        left: unset;
        right: 0;
        display: flex;
      }

      ::after {
        left: unset;
        right: ${toRem(6)};
        transform: rotateZ(45deg) !important;
      }
      h6 {
        padding-right: ${toRem(32)};
      }
    }
  }
  .accordion__container:is(${constants.RTL_LANG_LIST}){
    .accordion__icon{
      margin-left: unset !important;
    }
    .accordion__title{
      .icon-decorative {
        margin-left:${toRem(16)} ;
        margin-right: 0 !important
      }
    }
  }
`}
`;
