// Styles for MissingStayRequestForm go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledMissingStayRequestForm = styled.div`
  ${rtl`
    .blur {
      opacity: 0.5;
    }

    .form:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }

    .form {
      border-radius: ${toRem(14)};

      .form__line {
        border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral20']};
        padding: ${toRem(12)} 0;
        &--first,
        &--last {
          border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        }
        &.blur {
          opacity: 1;
        }
      }

      &__footer {
        &--submit {
          width: 100%;
          @media ${baseVariables.mediaQuery.md} {
            width: auto;
          }
        }
      }
    }
    .validation-msg {
      color: ${baseVariables.color['alert50']};
    }
  `}
`;
