// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const TripsNavigation = dynamic(() => import('./TripsNavigation').then(mod => mod.TripsNavigation));
const TripsNavigationV2 = dynamic(() => import('./TripsNavigationV2').then(mod => mod.TripsNavigationV2));

export const TripsNavigationConfig = {
  emptyLabel: 'tripsNavigation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/tripsnavigation`,
};

export const TripsNavigationEditable = (props: any) => {
  return (
    <EditableComponent config={TripsNavigationConfig} {...props}>
      {props?.isDTT ? <TripsNavigationV2 {...props} /> : <TripsNavigation {...props} />}
    </EditableComponent>
  );
};
