// Imports for external libraries go here.
import React, { FC } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
import { Icon, Label, Types, Text } from '@marriott/mi-ui-library';
// The closer the import is to the file the lower it should be in this list.
import { PasswordInputProps } from './PasswordInput.types';
import { StyledPasswordInput } from './PasswordInput.styles';

// Use named rather than default exports.
export const PasswordInput: FC<PasswordInputProps> = ({
  hasError,
  value,
  disable,
  label,
  name,
  id,
  passwordType,
  error,
  isValid,
  iconClass,
  customClass,
  labelClass,
  showValidations,
  passwordConditions,
  inputCustomClass,
  showCheckIcon,
  onIconClick,
  handleChange,
  ariaLabel,
}) => {
  return (
    <StyledPasswordInput data-component-name="m-account-PasswordInput" data-testid="account-PasswordInput">
      <div
        className={clsx(
          'm-input-field',
          disable ? 'is-disabled' : hasError ? 'is-error' : value ? 'is-active' : '',
          customClass
        )}
      >
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
        <input
          type={passwordType}
          id={id}
          name={name}
          value={value}
          maxLength={20}
          className={inputCustomClass ?? ''}
          onChange={handleChange}
          disabled={disable}
          autoComplete="off"
          data-testId={id}
          aria-label={ariaLabel}
          role="textbox"
        />

        {hasError && <Label labelText={error ?? ''} element={Types.tags.span} size={Types.size.small} />}
        <span
          className={clsx(`icon ${disable && 'fade-icon'}`, iconClass)}
          onClick={!disable ? onIconClick : undefined}
        ></span>
        {showCheckIcon && (
          <Icon
            iconClass={clsx(
              'icon password-valid-check',
              isValid ? 'icon-check green-check' : value?.length ? 'icon-clear red-clear' : ''
            )}
          />
        )}
      </div>
      {showValidations && (
        <div className={clsx('password-checks mb-5', disable && 'is-disabled')}>
          <div className="list-items t-body-xs">
            {passwordConditions?.map(password => {
              return (
                <div className="d-flex align-items-baseline" key={password.condition}>
                  <Icon
                    iconClass={clsx(
                      value?.length
                        ? {
                            'icon-check green-check': password.isValid,
                            'icon-clear red-clear': !password.isValid,
                          }
                        : 'icon mr-1 icon-dot'
                    )}
                  />
                  <Text copyText={password.condition} fontSize={Types.size.extraSmall} element={Types.tags.span} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </StyledPasswordInput>
  );
};
