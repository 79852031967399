import { useState, useEffect, useContext } from 'react';
import { inspect } from 'util';
import { useLazyQuery } from '@apollo/client';
import { Modal } from '@marriott/mi-ui-library';
import { phoenixAccountDttGetCancelReservationDetails } from '../../modules/graphV2';
import { useStore } from '../../modules/store/memberLevelStore';
import { PageContext } from '../../modules/context/PageContext';
import { apiLogger } from '../../modules/utils';
import { generateApolloClientHeaders } from '../../modules/utils/helper';
import { CancelReservationDetailsV2 } from '../../molecules/CancelReservationDetails/CancelReservationDetailsV2';
import { CancelReservationDetailsProps } from '../../molecules/CancelReservationDetails/CancelReservationDetails.types';
import { StyledCancelReservation } from './CancelReservation.styles';
import {
  CancelReservationProps,
  PointsDetailsStateDtt,
  pointsResposeData,
  basicInformation,
} from './CancelReservation.types';
import data from './__mock__/CancelReservationV2.json';
import { getOrderLineItemTransform } from './mapper';

// Use named rather than default exports.
export const CancelReservationV2: React.FC<CancelReservationProps> = props => {
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const {
    cancelModal: {
      canceledReservations,
      confirmationNumber,
      confirmationId,
      showCancelReservation,
      setShowCancelReservation,
    },
  } = useStore();
  const initialData = canceledReservations?.bookingData?.node?.items;
  const [bookingData, setBookingData] = useState(Array<string | null>);
  const [pointsDetails, setPointsDetails] = useState<PointsDetailsStateDtt | null>(null);
  const [pointsMock, setPointsMock] = useState<PointsDetailsStateDtt | null>(null);
  const isAuthorMode = props?.isAuthorMode;

  const cardData: CancelReservationDetailsProps = {
    freeNightAwardReturnDescription: props?.model?.freeNightAwardReturnDescription,
    pointsReturnDescription: props?.model?.pointsReturnDescription,
    nightlyUpgradeAwardReturnDescription: props?.model?.nightlyUpgradeAwardReturnDescription,
    bookingData: bookingData,
    cancelButtonOverlayLabel: props?.model?.cancelButtonOverlayLabel,
    keepRoomButtonLabel: props?.model?.keepRoomButtonLabel,
    pointsDetailsDtt: pointsDetails || pointsMock,
  };

  const dataAvailable =
    !!confirmationNumber && !!confirmationId && !!bookingData.length && (!!pointsDetails || !!pointsMock);

  const [generatePointsData] = useLazyQuery(phoenixAccountDttGetCancelReservationDetails, {
    variables: {
      orderByIdId: confirmationNumber,
      itemId: confirmationId,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    fetchPolicy: 'no-cache',
    onCompleted: (data: pointsResposeData) => {
      const points = getOrderLineItemTransform(data?.commerce?.order);
      setPointsDetails(points?.orderById);
      apiLogger(`[CancelReservation] AccountOrderLineItem - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`);
    },
    onError: error => {
      apiLogger(
        `[CancelReservation] AccountOrderLineItem - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    },
  });

  useEffect(() => {
    if (confirmationNumber) {
      generatePointsData();
    }
  }, [confirmationNumber]);

  useEffect(() => {
    isAuthorMode ? setPointsMock(data?.data?.commerce?.order?.orderById) : setPointsMock(null);
  }, [showCancelReservation, confirmationNumber, confirmationId]);

  useEffect(() => {
    if (initialData && confirmationNumber && showCancelReservation) {
      const filtered = initialData.filter(
        (data: basicInformation) => data?.basicInformation?.confirmationNumber === confirmationNumber
      );
      setBookingData(filtered);
    }
  }, [initialData, confirmationNumber, showCancelReservation]);

  useEffect(() => {
    if (showCancelReservation === false) {
      setBookingData([]);
    }
  }, [showCancelReservation]);

  return dataAvailable ? (
    <StyledCancelReservation data-testid="cancelreservation" data-component-name="o-account-cancelreservation">
      <Modal
        className="modal-main-container"
        show={showCancelReservation}
        popupOpenState={showCancelReservation}
        setPopupOpenState={(): void => {
          setShowCancelReservation(false);
        }}
        allFilterPopUp={true}
        secondaryClassName="modal-main-container__content"
        role="alertdialog"
        aria-modal="true"
        labelledBy="CancelReservation"
        modalId={'CancelReservation'}
      >
        <Modal.Header
          className="modal-header p-4 p-md-5 pb-md-4"
          labelText={props?.model?.cancelRoomTitle ?? ''}
          popupHeaderOnCLoseFunc={() => setShowCancelReservation(false)}
          customHeadingClass="t-subtitle-xl"
        />
        <Modal.Body className="p-0 modal-body">
          <CancelReservationDetailsV2 {...cardData} />
        </Modal.Body>
      </Modal>
    </StyledCancelReservation>
  ) : null;
};
