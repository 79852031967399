// Styles for AmbassadorElitePreferences go here.
import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledAmbassadorServiceSection = styled.div`
  .brand-filter {
    padding: 0px !important;
  }
  .brand-logo-container {
    width: ${toRem(145)} !important;
    min-width: ${toRem(145)} !important;
  }
`;
