import { FC, KeyboardEvent } from 'react';
import cssClass from 'classnames';

import Modal from '@material-ui/core/Modal';

import { IModal } from './Modal.types';
import { StyledContainer } from './Modal.styles';
import { canUseDOM, getClickTrackValue } from '../../utils';
import { constants, TRACKING_CONSTANT } from '../../constants';

const { INTERNAL_LINK } = TRACKING_CONSTANT;

export const ModalUI: FC<IModal> = ({
  open = false,
  keepMounted = false,
  onClose,
  ariaLabeledby,
  ariaDescribedby,
  children,
  variation,
  customCss,
  useAriaLive = true,
  onModalCloseCta,
  isDialog,
  isBrandCss = false,
}) => {
  const onKeyUpHandler = (e: KeyboardEvent<HTMLSpanElement>) => e.keyCode === constants.ENTER_KEY && onClose();
  const closeButtonTxt = canUseDOM ? window?.commonPageContent?.['closeButton'] : '';
  const containerClassName = cssClass(
    { 'modal-design': variation != null, paper: variation == null, standard: !isBrandCss },
    `content ${customCss}`
  );

  return (
    <div data-component-name="m-book-ModalUI">
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby={ariaLabeledby}
        aria-describedby={ariaDescribedby}
        keepMounted={keepMounted}
        style={{ overflowY: 'scroll' }}
      >
        <StyledContainer
          className={containerClassName}
          role={isDialog ? 'dialog' : ''}
          aria-atomic={!useAriaLive ? true : undefined}
          aria-label="dialogTitle"
          aria-describedby={ariaDescribedby ?? 'popUpContent'}
        >
          <span
            className="close-icon icon-clear custom_click_track"
            tabIndex={0}
            role="button"
            onClick={onClose}
            onKeyUp={e => onKeyUpHandler(e)}
            data-testid="close-button"
            data-custom_click_track_value={
              onModalCloseCta?.trackingDescription ? getClickTrackValue(INTERNAL_LINK, onModalCloseCta) : 'false'
            }
          >
            <span className="sr-only">{closeButtonTxt}</span>
          </span>
          <div id={ariaDescribedby ?? 'popUpContent'}>{children ?? 'Modal content goes here...'}</div>
        </StyledContainer>
      </Modal>
    </div>
  );
};
