import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledCustomTableCell = styled.td<{ backGroundColor?: string; isIcon?: boolean }>`
  ${({ backGroundColor, isIcon }) =>
    backGroundColor &&
    !isIcon &&
    `background: ${baseVariables.color[backGroundColor]};
    color: ${baseVariables.color[backGroundColor ? 'base20' : 'base10']}
  `}
  .icon {
    ${({ backGroundColor }) =>
      backGroundColor
        ? `background-color: ${baseVariables.color[backGroundColor]};`
        : `background-color: ${baseVariables.color['bvyBase30']};`}
    height: ${toRem(32)};
    width: ${toRem(32)};
    text-align: center;
    justify-content: center;
    border-radius: ${toRem(50)};
    border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
  }
`;
