// Imports for external libraries go here.
import React, { useState, useContext, useRef, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery } from '@apollo/client';
import { inspect } from 'util';
import axios from 'axios';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Modal, Heading, Types, Text, Messages, RichText } from '@marriott/mi-ui-library';
import { phoenixAccountGetMFAAuthentication } from '../../modules/graph';
import { useProfileStore } from '../../modules/store/profileStore';
import { apiLogger, generateApolloClientHeaders, getCurrentUrlParams } from '../../modules/utils';
import { PageContext } from '../../modules/context/PageContext';
import { handleRedirect, addSubDirectoryPrefix, getCountryAndLangCode } from '../../modules';
import {
  UPDATE_CUSTOMER_PROFILE,
  PROFILE_REDIRECTION_URL,
  ENCRYPTED_DATA,
  TWO_STEP_VERIFICATION_ID,
  CLICK_TRACK_TURN_ON,
  CLICK_TRACK_TURN_OFF,
  constants,
} from '../../modules/utils/constants/constants';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { BANNER_MSGS_KEYS } from '../../modules/utils/constants/constants';
import { StyledTwoStepVerification } from './TwoStepVerification.styles';
import mockData from './__mock__/TwoStepVerification.model.json';
import { TwoStepVerificationProps, mfaAuthData } from './TwoStepVerification.types';

//  Use named rather than default exports.
export const TwoStepVerification = (props: TwoStepVerificationProps) => {
  const isAuthorMode = props?.isAuthorMode;
  const { profileModalId, setProfileModalId } = useProfileStore(state => state);
  const [getCustomerMfaOpt] = useLazyQuery(phoenixAccountGetMFAAuthentication);
  const { IS_LOCAL_DEV } = process.env;
  const dataLoaded = useRef<boolean>(false);
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [mfaOpted, setMfaOpted] = useState<boolean | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const currentLocale = pageContext?.currentLocale;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode || constants.USA_COUNTRY_CODE;
  const { setBannerMsgs, setBannerId } = useBannerMessagesStore(state => state);

  useEffect(() => {
    if (isAuthorMode) {
      setMfaOpted(mockData?.customer?.mfaOptIn);
    }
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isEditTwoStepAuthModal = param.get('editSecurityPreferences');
    if (isEditTwoStepAuthModal === 'true') {
      setProfileModalId(TWO_STEP_VERIFICATION_ID);
    }
  }, []);

  useEffect(() => {
    if (!isAuthorMode && profileModalId === TWO_STEP_VERIFICATION_ID && !dataLoaded.current) {
      fetchMfaOpted();
    }
  }, [profileModalId]);

  const fetchMfaOpted = () => {
    getCustomerMfaOpt({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: (data: mfaAuthData) => {
        dataLoaded.current = true;
        setMfaOpted(data?.customer?.mfaOptIn);
        setHasError(false);
        apiLogger(
          `[TwoStepVerification] getTwoStepAuthData - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        dataLoaded.current = false;
        setHasError(true);
      },
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        addSubDirectoryPrefix(UPDATE_CUSTOMER_PROFILE),
        {
          mfaOptIn: JSON.stringify(!mfaOpted),
          updateMfaOptin: true,
          countryCode: localeCountryCode,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Cookie: 'sessionID=' + sessionData?.sessionToken,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.isRedirect) {
          setBannerId(TWO_STEP_VERIFICATION_ID);
          setHasError(false);
          sessionStorage.setItem(ENCRYPTED_DATA, response?.data?.encodePayload);
          const { nextStateURI } = response.data;
          handleRedirect(
            nextStateURI +
              `${nextStateURI?.includes('?') ? '&' : '?'}returnTo=${addSubDirectoryPrefix(PROFILE_REDIRECTION_URL)}`
          );
        } else {
          setBannerMsgs(BANNER_MSGS_KEYS.TWO_STEP_VERIFICATION_MSG);
          setMfaOpted(!mfaOpted);
          setProfileModalId('');
          setHasError(false);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setHasError(true);
    } finally {
      dataLoaded.current = false;
    }
  };

  const handleResetData = () => {
    setProfileModalId('');
    setHasError(false);
  };

  return (
    <StyledTwoStepVerification data-testid="twostepverification" data-component-name="o-account-twostepverification">
      <Modal
        show={profileModalId === TWO_STEP_VERIFICATION_ID}
        popupOpenState={profileModalId === TWO_STEP_VERIFICATION_ID}
        setPopupOpenState={(): void => {
          handleResetData();
        }}
        modalId={props?.model?.modalId + 'modal'}
        labelledBy={props?.model?.modalId}
        // secondaryClassName="modal__container"
        disableScrollOnBody={true}
        aria-modal="true"
        handleBlur={true}
        onClose={(): void => {
          setProfileModalId('');
        }}
        className="modal__container"
        role="dialog"
      >
        <Modal.Header
          className="food-bev-header t-subtitle-xl py-4 pl-4 pr-4 py-md-5 px-md-5 pt-md-5"
          customHeadingClass="t-subtitle-xl"
          labelText={props.model?.header}
          popupHeaderOnCLoseFunc={(): void => {
            handleResetData();
          }}
        />
        <Modal.Body className="modal__container-content px-4 px-md-5 py-4 mt-md-2 mb-4 mb-md-3">
          <div className="modal__container-content__section row mx-0">
            {hasError && (
              <div id={'two-step-verification'} data-testId="uxl-error-msg" className="uxl-error-msg">
                <Messages messageType="warning" className="mb-4">
                  <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                </Messages>
              </div>
            )}
            <Heading
              variation={Types.headingType.subtitle}
              fontSize={Types.size.large}
              titleText={mfaOpted ? props.model?.bodyTitleWhenEnabled : props.model?.bodyTitleWhenDisabled}
              disableCustomClickTrack={true}
              customClass={'mb-3'}
              data-testid="modal-heading"
            />
            {mfaOpted !== null ? (
              <Text
                element={Types.tags.paragraph}
                fontSize={Types.size.medium}
                copyText={mfaOpted ? props.model?.bodyDescriptionWhenEnabled : props.model?.bodyDescriptionWhenDisabled}
                customClass={''}
                data-testid="modal-text"
              />
            ) : (
              <div className="col-12 two-step-loader px-0">
                <div className="skeleton-loader" />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          className="modal__container-footer m-auto py-4 px-5 py-md-5 align-items-baseline justify-content-md-end"
          clearButtonLabel={props.model?.cancelButton}
          clearClickHandler={(): void => {
            handleResetData();
          }}
          applyLabel={mfaOpted ? props.model?.turnOffButton : props.model?.continueButton}
          data-testid="Submit"
          applyBtnClickHandler={() => {
            handleSubmit();
          }}
          applyBtnClickTrackValue={mfaOpted ? CLICK_TRACK_TURN_OFF : CLICK_TRACK_TURN_ON}
          clearBtnClickTrackValue={props.model?.cancelButton}
          clearBtnClassname="clear m-link-action mr-md-4 p-0 px-auto px-md-0 custom_click_track clear-btn"
          applyButtonDisabled={mfaOpted === null}
        ></Modal.Footer>
      </Modal>
    </StyledTwoStepVerification>
  );
};

export const TwoStepVerificationConfig = {
  emptyLabel: 'twostepverification',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/twostepverification`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TwoStepVerificationEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <TwoStepVerification {...props} />
  ) : (
    <EditableComponent config={TwoStepVerificationConfig} {...props}>
      <TwoStepVerification {...props} />
    </EditableComponent>
  );
};
