import { FC, useContext } from 'react';
import clsx from 'clsx';

import { Heading, Icon, Label, Text, Types } from '@marriott/mi-ui-library';

import { replaceText } from '../../../modules/utils/promotionHelper';
import {
  getGradientByCode,
  OVERVIEW_CONSTANT,
  getTextColorByCode,
  numberWithCommas,
  getMemberStatusDescription,
  PageContext,
  getMemberLevel,
} from '../../../modules';
import { NightsProgress } from '../../../molecules/NightsProgress';
import { MemberBenefits } from './MemberBenefits/MemberBenefits';
import { StyledMemberStatusCard } from './MemberStatusCard.styles';
import { MemberStatusCardProps } from './MemberStatusCard.types';

export const MemberStatusCardSkeleton = () => (
  <div className="member_status_card__skeleton px-0 my-sm-3 my-md-4">
    <div className="pt-4 px-4">
      <div className="d-flex member_status_card__skeleton__section top_section py-3">
        <div className="skeleton-loader member_status_card__skeleton__section__label1 mr-2"></div>
        <div className="skeleton-loader member_status_card__skeleton__section__label1 ml-2"></div>
      </div>
      <div className="skeleton-loader member_status_card__skeleton__section__label2 py-2"></div>
    </div>
    <div className="skeleton__container gaugemeter mx-auto member_status_card__skeleton__section my-5">
      <div className="skeleton-loader skeleton__container__curved-content "></div>
    </div>
    <div className="m-3"></div>
    <div className="skeleton-loader member_status_card__skeleton__section__label3 gaugemeter mx-auto "></div>
    <div className="skeleton__container py-2"></div>
  </div>
);
export const MemberStatusCard: FC<MemberStatusCardProps> = ({
  customerData,
  givenName,
  model,
  setIsNightsModelOpen,
  memberList,
}) => {
  const pageContext = useContext(PageContext);
  const memberStatusList = pageContext?.memberStatusList;
  const rewards = customerData?.loyaltyInformation?.rewards;
  const levelType = rewards?.levelType;
  const rewardsSummary = customerData?.loyaltyInformation?.rewardsSummary;
  const memberLevel = rewards?.level?.code?.toUpperCase() === 'P' ? levelType?.code : rewards?.level?.code;
  const isNotBasicMember = memberLevel !== 'R';
  const textColorClass = isNotBasicMember ? 'card__wrapper__inverse' : '';
  const revenue = rewardsSummary?.yearly?.[0]?.totalRevenue || 0;
  const mermberId = rewards?.number;

  return (
    <StyledMemberStatusCard
      bgColor={getGradientByCode(memberLevel, true)}
      textColor={getTextColorByCode(memberLevel)}
      footerColor={getGradientByCode(memberLevel)}
      // className={isMobileViewPort ? 'col-md-12 px-0' : 'col-md-4'}
    >
      {customerData ? (
        <div className="card card__wrapper px-0 my-sm-3 my-md-4 position-relative">
          <div className="pt-4 px-5 pb-1">
            <Label
              element={Types.tags.span}
              size={Types.size.extraSmall}
              labelText={givenName?.toLowerCase() || customerData?.profile?.name?.givenName?.toLowerCase()}
              customClass={`${textColorClass} t-label-alt-xs member-title`}
            />
            <Icon iconClass={clsx('icon-dot icon-l', isNotBasicMember ? 'icon-inverse' : '')} />
            <Label
              element={Types.tags.span}
              size={Types.size.extraSmall}
              labelText={mermberId?.toString() || ''}
              customClass={`${textColorClass} t-label-alt-xs`}
            />
            {levelType?.code && (
              <Heading
                fontSize={Types.size.extraLarge}
                variation={Types.headingType.subtitle}
                titleText={getMemberStatusDescription(
                  getMemberLevel(rewards?.level?.code, levelType?.code) ?? '',
                  memberStatusList
                )}
                customClass={textColorClass}
              />
            )}
          </div>
          <div
            className={`card-gaugemeter px-3 ${
              !rewardsSummary?.yearly?.[0]?.totalNights && isNotBasicMember ? 'pb-4' : 'pb-0'
            }`}
          >
            <NightsProgress
              value={rewardsSummary?.yearly?.[0]?.totalNights}
              min={0}
              isNonMember={isNotBasicMember}
              model={model}
              memberLevel={memberLevel}
              setNightModelOpen={setIsNightsModelOpen}
            />
          </div>
          {levelType?.code && OVERVIEW_CONSTANT.ANNUAL_CONSTANTS.includes(levelType?.code) && (
            <div className="px-5 mx-1 anual-container">
              <div className="d-flex flex-column text-center card__wrapper-annual py-3">
                <Text
                  element={Types.tags.span}
                  fontSize={Types.size.small}
                  copyText={model?.annualQualifyingLabel}
                  customClass="inverse t-overline-overlay"
                />
                <Text
                  element={Types.tags.span}
                  fontSize={Types.size.small}
                  copyText={
                    model?.annualQualifyingValueLabel && model?.achieveAmbassadorLimitAmount
                      ? replaceText(model?.annualQualifyingValueLabel, [
                          numberWithCommas(revenue),
                          numberWithCommas(model?.achieveAmbassadorLimitAmount),
                        ])
                      : ''
                  }
                  customClass="inverse"
                />
              </div>
            </div>
          )}
          {customerData && model && (
            <MemberBenefits model={model} customerData={customerData} isNotBasicMember={isNotBasicMember} />
          )}
          {rewards?.eliteLifetimeLevel?.code && (
            <div className="card__footer d-flex justify-content-center align-items-center text-center m-icon-text">
              <Icon iconClass="icon-lifetime icon-l mx-2 icon-inverse" />
              <Text
                element={Types.tags.span}
                fontSize={Types.size.medium}
                copyText={getMemberStatusDescription(rewards?.eliteLifetimeLevel?.code ?? '', memberList)}
                customClass="inverse"
              ></Text>
            </div>
          )}
        </div>
      ) : (
        <MemberStatusCardSkeleton />
      )}
    </StyledMemberStatusCard>
  );
};
