import { orderByFactorData } from './FindReservation.types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mapper = require('object-mapper');

const transformTemplate = {
  orderByFactor: {
    key: 'orderByFactor',
  },
};

export const getOrderDetailsByOrderIdTransform = (data: orderByFactorData): orderByFactorData => {
  return mapper(data, transformTemplate);
};
