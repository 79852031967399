/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';

import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Heading, Text, Types, Icon } from '@marriott/mi-ui-library';

import {
  BREAKPOINT_MOBILE_TEXT,
  OverviewMovableComponents,
  PageContext,
  REGISTERED_PROMOTIONS_ID,
  useGetBreakpoint,
} from '../../modules';
import { OverviewState, useOverviewStore } from '../../modules/store/overviewStore';
import { useStore } from '../../modules/store/memberLevelStore';
import { AccountPersistentState, useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { constants } from '../../modules';
import { getMovableComponentId } from '../../modules/utils/overviewHelper';
import { TotalPoints } from './TotalPoints';
import { OverviewSectionHeading } from '../../molecules/OverviewSectionHeading';
import memberMock from './__mock__/MemberSnapshotRibbon.model.json';
import { MemberSnapshotRibbonProps } from './MemberSnapshotRibbon.types';
import { StyledMemberSnapshotRibbon } from './MemberSnapshotRibbon.styles';
import { MemberStatusCard } from './MemberStatusCard';

const OnboardingSection = dynamic(() => import('../OnboardingSection').then(mod => mod.OnboardingSectionEditable));

// Use named rather than default exports.
export const MemberSnapshotRibbon: FC<MemberSnapshotRibbonProps> = (pageProps: any) => {
  const model = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const pageContext = useContext(PageContext);

  const currentLocale = pageContext?.currentLocale ?? 'en_US';
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { setIsNightsModelOpen } = useStore(store => store);
  const { memberData, setMemberDetails } = useAccountPersistentStore(store => store);
  const [memberDataState, setMemberDataState] = useState<AccountPersistentState['memberData']>();
  const overviewState = useOverviewStore((state: OverviewState) => state);
  const [hasPlaceHolderComponent, setHasPlaceHolderComponent] = useState(false);
  const [newUser, setNewUser] = useState(false);
  //object for newly enrolled member empty state

  const newMemberDataObject = [
    {
      iconClass: 'icon-book-direct-shield',
      text: model?.ratesStatement,
      ctaLabel: model?.bookHotelCTALabel,
      ctaPath: model?.bookHotelCTAPath,
      target: model?.bookHotelOpenInANewTab,
      clickTrack:
        '3 Pack Welcome Tile|Book A Hotel|internal|event167,event168, evar48=MER-web-enrollWelcomeTile-BookHotel',
    },
    {
      iconClass: 'icon-internet',
      text: model?.benefitsStatement,
      ctaLabel: model?.benefitsCTALabel,
      ctaPath: model?.benefitsCTAPath,
      target: model?.benefitsOpenInANewTab,
      clickTrack:
        '3 Pack Welcome Tile|View Benefits|internal|event167,event168, evar48=MER-web-enrollWelcomeTile-ViewBenefits',
    },
    {
      iconClass: 'icon-avatar',
      text: model?.completeProfile,
      ctaLabel: model?.completeProfileCTALabel,
      ctaPath: model?.completeProfileCTAPath,
      target: model?.completeProfileOpenInANewTab,
      clickTrack:
        '3 Pack Welcome Tile|Complete Profile|internal|event167, event168, evar48=MER-web-enrollWelcomeTile-CompleteProfile',
    },
  ];

  useEffect(() => {
    if (isAuthorMode) {
      setMemberDetails?.(memberMock?.data);
    }
    // using handleResize to avoid flickering of container class happening on the initial load
    const handleResize = () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobile(useGetBreakpoint() === BREAKPOINT_MOBILE_TEXT);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      sessionStorage?.removeItem(constants.MR_ENROLLED);
    };
  }, []);

  useEffect(() => {
    if (memberData) {
      setMemberDataState(memberData);
    }
  }, [memberData]);

  useEffect(() => {
    if (!overviewState.componentMoved) {
      const isNewUser = sessionStorage?.getItem(constants.MR_ENROLLED) === 'true';
      setNewUser(isNewUser);
      if (isNewUser) {
        overviewState.setComponentMoved(OverviewMovableComponents.NONE);
        setHasPlaceHolderComponent(true);
      } else if (overviewState.uxlTriggered) {
        const movableCompId = getMovableComponentId(overviewState.components);
        if (movableCompId === OverviewMovableComponents.NONE) {
          overviewState.setComponentMoved(movableCompId);
          setHasPlaceHolderComponent(true);
        }
        if (movableCompId && movableCompId !== OverviewMovableComponents.NONE) {
          const isPromotions = movableCompId === OverviewMovableComponents.PROMOTIONS;
          const elementToMove = document?.getElementById(`${movableCompId}`)?.parentElement;
          const parentElement = document?.getElementById('member-status-placeholder');
          if (parentElement && elementToMove) {
            parentElement.appendChild(elementToMove);
            if (isPromotions) {
              const promotionElement = document?.getElementById(`${REGISTERED_PROMOTIONS_ID}`)?.parentElement;
              promotionElement && parentElement.appendChild(promotionElement);
            }
            overviewState.setComponentMoved(movableCompId);
          }
        }
      }
    }
  }, [overviewState.componentMoved, overviewState.components]);

  return (
    <StyledMemberSnapshotRibbon
      className={clsx(!isMobile && 'container')}
      data-testid="membersnapshotribbon"
      data-component-name="o-account-membersnapshotribbon"
    >
      <div className={clsx('row', isMobile ? 'no-gutters' : '')}>
        <div className="col-sm-6 col-lg-4 d-flex flex-column mb-4 mb-md-5">
          <MemberStatusCard
            customerData={memberDataState?.customer}
            {...{ setIsNightsModelOpen, model }}
            memberList={pageContext?.memberStatusList}
          />
          <TotalPoints
            model={model}
            points={memberDataState?.customer?.loyaltyInformation?.rewards?.currentPointBalance}
            locale={currentLocale}
            customClass={hasPlaceHolderComponent ? '' : 'card-skeleton'}
          />
        </div>
        <div
          id="member-status-placeholder"
          className={clsx(
            'col-sm-6 col-lg-8 d-flex flex-column',
            hasPlaceHolderComponent && !newUser ? 'px-md-2' : 'px-md-0'
          )}
        >
          {(!overviewState.componentMoved || hasPlaceHolderComponent) && (
            <div
              className={clsx(
                'card card__body mt-3 mt-md-4',
                hasPlaceHolderComponent ? 'd-flex flex-column' : 'ml-2',
                isMobile && 'mx-3 ',
                newUser ? 'new-member-card ml-2' : 'recent-activity-card mb-5'
              )}
            >
              {hasPlaceHolderComponent ? (
                newUser ? (
                  <OnboardingSection
                    onboardingDataObject={newMemberDataObject}
                    header={model?.header}
                    isAuthorMode={isAuthorMode}
                    isHorizontal={false}
                  />
                ) : (
                  <div className="recent-activity__heading mb-4 mb-md-5" data-testid="recent-activity">
                    <OverviewSectionHeading
                      title={pageProps.sectionHeader}
                      sectionHeadingClass={
                        'recent-activity align-items-center px-0 pb-3 pt-0 py-md-3 px-md-4 px-lg-5 container'
                      }
                      isComponentMoved={true}
                      ctaLabel={pageProps?.viewActivityCTABtnLabel}
                      ctaPath={pageProps?.viewActivityPath}
                      isLinkOpenNewTab={pageProps?.openInANewTab}
                    />
                    <div className="border-line" />
                    <div className="recent-activity__textcardbody text-center px-md-4">
                      <Icon
                        iconClass={'recent-activity__icon icon-status-cobalt icon-decorative position-relative'}
                      ></Icon>
                      <div className="recent-activity__text text-center py-2">
                        <Heading
                          variation={Types.headingType.subtitle}
                          fontSize={Types.size.large}
                          titleText={pageProps.recentActivityTitle}
                          element={Types.tags.div}
                          customClass="recent-activity__text__title py-2 "
                        />
                        <Text
                          element={Types.tags.div}
                          fontSize={Types.size.medium}
                          copyText={pageProps.recentActivitySubTitle}
                          customClass={'recent-activity__text__subtitle'}
                        />
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="skeleton__body py-3">
                  <div className="d-flex justify-content-between px-4">
                    <div className="skeleton-loader card__placeholder__skeleton__label"></div>
                    <div className="skeleton-loader card__placeholder__skeleton__cta"></div>
                  </div>
                  <div className="skeleton-loader card__placeholder__skeleton__img my-3"></div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </StyledMemberSnapshotRibbon>
  );
};
export const MemberSnapshotRibbonConfig = {
  emptyLabel: 'MemberSnapshotRibbon',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/membersnapshotribbon`,
};
export const MemberSnapshotRibbonEditable = (props: any) => {
  return (
    <EditableComponent config={MemberSnapshotRibbon} {...props}>
      <MemberSnapshotRibbon {...props} />
    </EditableComponent>
  );
};
