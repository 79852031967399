import styled from 'styled-components';
import { baseVariables as theme, toRem } from '@marriott/mi-ui-library';
import { borderColor } from '../../../modules/utils/constants/constants';
export const FavouritesStyle = styled.div`
  .card {
    height: ${toRem(420)};
    width: ${toRem(343)};
    border: ${toRem(2)} dashed ${borderColor};
    border-radius: ${toRem(14)};
    @media ${theme.mediaQuery.md} {
      width: ${toRem(352)};
    }
    @media ${theme.mediaQuery.lg} {
      width: ${toRem(364)};
    }
  }
  .m-button-secondary {
    max-height: ${toRem(60)};
    height: auto;
    border-radius: ${toRem(26)};
    border: ${toRem(1)} solid ${theme.color.merch - 30};
    width: ${toRem(228)};
    @media ${theme.mediaQuery.md} {
      width: ${toRem(228)};
    }
    @media ${theme.mediaQuery.lg} {
      width: ${toRem(180)};
    }
  }
`;
