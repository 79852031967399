// Imports for external libraries go here.
import React, { useContext, useEffect, useRef, useState } from 'react';
import { inspect } from 'util';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Accordion, Heading, Modal, Types, Text, Icon, CheckBox, RichText } from '@marriott/mi-ui-library';
import { PageContext } from '../../modules/context/PageContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useProfileStore } from '../../modules/store/profileStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import {
  AMBASSADOR_ELITE_PREFERENCE_ID,
  BANNER_MSGS_KEYS,
  OPTED_OUT_PERSONALIZATION_KEY,
  AMBASSADOR_ELITE_CLICK_TRACK,
  apiLogger,
  checkAndSetConsentChecked,
  constants,
  generateApolloClientHeaders,
  getCurrentUrlParams,
  updateConsentFeatureOptOut,
} from '../../modules';
import {
  phoenixAccountAddAmbassadorElitePreferences,
  phoenixAccountAddCustomerPreferences,
  phoenixAccountGetCustomerAmbassadorElitePreferences,
} from '../../modules/graph';
import {
  AmbassadorElitePayload,
  AmbassadorElitePreferencesProps,
  CustomerFeatures,
  PersonalInfoPayload,
} from './AmbassadorElitePreferences.types';
import { ExperiencesSection } from './ExperiencesSection/ExperiencesSection';
import { PersonalInfoSection } from './PersonalInfoSection/PersonalInfoSection';
import { ExperienceType } from './ExperiencesSection/ExperiencesSection.types';
import dataProps from './__mock__/AmbassadorElitePreferences.model.json';
import { AmbassadorServiceSection } from './AmbassadorServiceSection/AmbassadorServiceSection';
import { StyledAmbassadorElitePreferences } from './AmbassadorElitePreferences.styles';
import { AmbassadorServicePayload } from './AmbassadorServiceSection/AmbassadorServiceSection.types';

export const AmbassadorElitePreferences = (props: AmbassadorElitePreferencesProps) => {
  const isAuthorMode = props?.isAuthorMode;
  const modelprops = props?.model;
  const modalBodyClass = 'ambassdor-elite-modal-body';
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const personalProps = dataProps?.data?.customer?.featuresByGroup;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [consentChecked, setConsentChecked] = useState<boolean>();
  const [isPersonalError, setIsPersonalError] = useState<boolean>(false);
  const { setProfileDetails, profileDetails, profileModalId, setProfileModalId } = useProfileStore(state => state);
  const [customerData, setCustomerData] = useState<CustomerFeatures | undefined>(undefined);
  const [getAmbassadorElitePreferences] = useLazyQuery(phoenixAccountGetCustomerAmbassadorElitePreferences);
  const [updateCustomerInterestsMutation] = useMutation(phoenixAccountAddCustomerPreferences);
  const [updateAmbassadorElitePreferencesMutation] = useMutation(phoenixAccountAddAmbassadorElitePreferences);
  const getPayload = useRef<AmbassadorElitePayload>({});
  const [onSubmitFlag, setOnSubmitFlag] = useState(false);
  const [accordionClicked, setAccordianClicked] = useState<string | null>(null);
  const userProfileCountryCode = profileDetails?.profileAddress?.filter(address => address?.primary === true)?.[0];
  const userCountryCode = userProfileCountryCode?.address?.country?.code;
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const isDataChanged = useRef({
    personalInfo: false,
    experiences: false,
    ambassadorService: false,
  });
  useEffect(() => {
    if (isAuthorMode) {
      setCustomerData(personalProps as CustomerFeatures);
    } else {
      const urlParam = getCurrentUrlParams();
      const param = new URLSearchParams(urlParam);
      const isAmbassadorPreferencesModal = param.get('editAmbassadorPreferences');
      if (isAmbassadorPreferencesModal === 'true') {
        setProfileModalId(AMBASSADOR_ELITE_PREFERENCE_ID);
      }
    }
  }, []);

  const handleSaveBtnFlag = (value: boolean) => {
    setIsSaveButtonEnabled(value);
  };
  useEffect(() => {
    if (!isAuthorMode && profileModalId === AMBASSADOR_ELITE_PREFERENCE_ID && !customerData) {
      fetchAmbassadorElite();
    }
  }, [profileModalId]);

  useEffect(() => {
    if (onSubmitFlag) {
      if (
        getPayload?.current.ambassadorService &&
        getPayload?.current.personalInfo &&
        getPayload?.current.experiences
      ) {
        handleSubmit();
      }
    }
  }, [onSubmitFlag, getPayload.current]);

  const handleCheckboxChange = () => {
    setConsentChecked(prevIsDefaultCard => !prevIsDefaultCard);
    setIsSaveButtonEnabled(true);
  };
  const handleButtonClick = async () => {
    setOnSubmitFlag(true);
  };
  const handlePersonalInfoSubmit = (data: PersonalInfoPayload, dataChanged: boolean) => {
    isDataChanged.current = { ...isDataChanged.current, personalInfo: dataChanged };

    getPayload.current = {
      ...getPayload.current,
      personalInfo: data,
    };
  };
  const handleExperienceSubmit = (data: ExperienceType, dataChanged: boolean) => {
    isDataChanged.current = { ...isDataChanged.current, experiences: dataChanged };

    getPayload.current = {
      ...getPayload.current,
      experiences: data,
    };
  };

  const handleAmbassadorServiceSubmit = (data: AmbassadorServicePayload, dataChanged: boolean) => {
    isDataChanged.current = { ...isDataChanged.current, ambassadorService: dataChanged };

    getPayload.current = {
      ...getPayload.current,
      ambassadorService: data,
    };
  };
  const fetchAmbassadorElite = () => {
    getAmbassadorElitePreferences({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setCustomerData(data?.customer?.featuresByGroup);
        setProfileDetails({
          ...profileDetails,
          consentFeatures: data?.customer?.featuresByGroup?.preferencesFeatures,
        });
        const optInConsent = checkAndSetConsentChecked(
          data?.customer?.featuresByGroup?.preferencesFeatures,
          OPTED_OUT_PERSONALIZATION_KEY,
          'N'
        );
        setConsentChecked(optInConsent);
        apiLogger(
          `[AmbassadorElitePreferences] getAmbassadorElitePreferencesData - sessionId: ${
            sessionData?.sessionToken
          }: ${inspect(data)}`
        );
      },
      onError: () => {
        setBannerMsgs(BANNER_MSGS_KEYS.UXL_ERROR_MSG);
        setProfileModalId('');
      },
    });
  };
  const updateCustomerConsentPreferrence = async () => {
    try {
      const { data } = await updateCustomerInterestsMutation({
        variables: {
          input: {
            customerId: sessionData?.consumerID,
            customerFeatures:
              consentChecked === true
                ? { code: OPTED_OUT_PERSONALIZATION_KEY.replaceAll('-', '_').toUpperCase(), value: 'N' }
                : { code: OPTED_OUT_PERSONALIZATION_KEY.replaceAll('-', '_').toUpperCase(), value: 'Y' },
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        fetchPolicy: 'no-cache',
      });
      setProfileDetails({
        ...profileDetails,
        consentFeatures: updateConsentFeatureOptOut(profileDetails?.consentFeatures, consentChecked),
      });
      resetData();
      setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
      apiLogger(
        `[CustomerConsentPreferences] updateCustomerConsentPreferences - sessionId value: ${
          sessionData?.sessionToken
        }: ${inspect(data)}`
      );
    } catch (error) {
      setBannerMsgs(BANNER_MSGS_KEYS.UXL_ERROR_MSG);
      setProfileModalId('');
      apiLogger(
        `[CustomerConsentPreferences] addCustomerConsentPreferences - sessionId value: ${
          sessionData?.sessionToken
        } - error: ${inspect(error)}`
      );
    }
  };

  const updateAmbassadorElitePreferences = async () => {
    try {
      const { data } = await updateAmbassadorElitePreferencesMutation({
        variables: {
          input: {
            customerId: sessionData?.consumerID,
            ...getPayload?.current.ambassadorService,
          },
          saveCustomerExperiencePreferencesInput2: {
            customerId: sessionData?.consumerID,
            liveEvents: getPayload.current.experiences,
          },
          saveCustomerPersonalInfoPreferencesInput2: {
            customerId: sessionData?.consumerID,
            ...getPayload.current.personalInfo,
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      });

      resetData();
      setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
      apiLogger(
        `[AmbassadorElitePreferences ] updateAmbassadorElitePreferences - sessionId value: ${
          sessionData?.sessionToken
        }: ${inspect(data)}`
      );
    } catch (error) {
      setBannerMsgs(BANNER_MSGS_KEYS.UXL_ERROR_MSG);
      setProfileModalId('');
      apiLogger(
        `[AmbassadorElitePreferences ] updateAmbassadorElitePreferences  - sessionId value: ${
          sessionData?.sessionToken
        } - error: ${inspect(error)}`
      );
    }
  };

  const handleSubmit = async () => {
    setOnSubmitFlag(false);
    const hasConsentChange =
      consentChecked !== checkAndSetConsentChecked(profileDetails?.consentFeatures, OPTED_OUT_PERSONALIZATION_KEY, 'N');
    const hasChangedData =
      isDataChanged.current.personalInfo ||
      isDataChanged.current.experiences ||
      isDataChanged.current.ambassadorService;

    if (!hasConsentChange && !hasChangedData) {
      setProfileModalId('');
    } else {
      if (hasChangedData) {
        updateAmbassadorElitePreferences();
      }
      if (hasConsentChange) {
        updateCustomerConsentPreferrence();
      }
    }
  };
  const resetData = () => {
    setProfileModalId('');
    setCustomerData(undefined);
    setAccordianClicked(null);
    getPayload.current = {};
    isDataChanged.current = {
      personalInfo: false,
      experiences: false,
      ambassadorService: false,
    };
    setIsSaveButtonEnabled(false);
  };

  const handleHeaderClick = (id: string) => {
    setAccordianClicked(accordionClicked === id ? null : id);
  };

  return (
    <StyledAmbassadorElitePreferences
      data-testid="ambassadorelitepreferences"
      data-component-name="o-account-ambassadorelitepreferences"
    >
      <Modal
        show={profileModalId === AMBASSADOR_ELITE_PREFERENCE_ID}
        popupOpenState={profileModalId === AMBASSADOR_ELITE_PREFERENCE_ID}
        setPopupOpenState={(): void => {
          resetData();
        }}
        modalId={props?.model?.modelId + 'modal'}
        labelledBy={props?.model?.modelId}
        onClose={(): void => {
          resetData();
        }}
        secondaryClassName="modal__container  communication-preference"
        disableScrollOnBody={true}
        aria-modal="true"
        data-testid="communication"
        role="dialog"
        handleBlur={true}
      >
        <Modal.Header
          className="ambassador-service-header t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
          labelText={modelprops?.header}
          popupHeaderOnCLoseFunc={(): void => {
            resetData();
          }}
          data-testid="ambassador-service-header"
        />
        <Modal.Body
          className={`modal__container-content custom-scrollbar px-4 px-md-5 py-4 mt-md-2 mb-3 mx-2 ${modalBodyClass}`}
        >
          {profileModalId === AMBASSADOR_ELITE_PREFERENCE_ID ? (
            <div className="">
              <div className={clsx('t-font-m col-12 m-modal-content mt-2 mt-md-0 mb-0 px-0 text_modal-align')}>
                <Text fontSize={Types.size.medium} element={Types.tags.paragraph} copyText={modelprops?.description} />
              </div>
              <div className="accordion-personal-info pb-2">
                <Accordion
                  id={'personal-info'}
                  enableAccordionSeparator={true}
                  key={'personal-info'}
                  headerChildren={
                    <div>
                      <Icon iconClass={clsx('icon-nav---account icon-decorative mr-3')}></Icon>
                      <Heading
                        variation={Types.headingType.subtitle}
                        fontSize={Types.size.large}
                        titleText={modelprops?.personalInfoTitle}
                        customClass={clsx('d-inline')}
                      />
                    </div>
                  }
                  isOpen={accordionClicked === 'personal-info'}
                  handleHeaderClick={handleHeaderClick}
                >
                  <PersonalInfoSection
                    personalInfo={customerData?.personalInfo}
                    modalProps={modelprops}
                    onSubmitFlag={onSubmitFlag} // Pass current flag state
                    onHandleFlag={handlePersonalInfoSubmit}
                    setIsPersonalError={setIsPersonalError}
                    setFlagforSaveBtn={handleSaveBtnFlag}
                  />
                </Accordion>
                <Accordion
                  id={'ambassador-service'}
                  enableAccordionSeparator={true}
                  key={'ambassador-service'}
                  headerChildren={
                    <div>
                      <Icon iconClass={clsx('icon-ambassador icon-decorative mr-3')}></Icon>
                      <Heading
                        variation={Types.headingType.subtitle}
                        fontSize={Types.size.large}
                        titleText={modelprops?.ambServiceTitle}
                        customClass={clsx('d-inline f')}
                      />
                    </div>
                  }
                  isOpen={accordionClicked === 'ambassador-service'}
                  handleHeaderClick={handleHeaderClick}
                >
                  <div className="mx-md-5 text_modal-align">
                    <AmbassadorServiceSection
                      model={modelprops}
                      customerAmbassadorData={customerData?.ambassadorPreferences}
                      isAuthorMode={isAuthorMode}
                      onSubmitFlag={onSubmitFlag}
                      onHandleFlag={handleAmbassadorServiceSubmit}
                      profileModalId={profileModalId}
                      setFlagforSaveBtn={handleSaveBtnFlag}
                    />
                  </div>
                </Accordion>
                <Accordion
                  id={'experienceTitle'}
                  enableAccordionSeparator={false}
                  key={'experienceTitle'}
                  headerChildren={
                    <div>
                      <Icon iconClass={clsx('icon-events icon-decorative mr-3')}></Icon>
                      <Heading
                        variation={Types.headingType.subtitle}
                        fontSize={Types.size.large}
                        titleText={modelprops?.experienceTitle}
                        customClass={clsx('d-inline ')}
                      />
                    </div>
                  }
                  isOpen={accordionClicked === 'experienceTitle'}
                  handleHeaderClick={handleHeaderClick}
                >
                  <ExperiencesSection
                    model={modelprops}
                    customerExperienceData={customerData?.experience}
                    onSubmitFlag={onSubmitFlag} // Pass current flag state
                    onHandleFlag={handleExperienceSubmit}
                    setFlagforSaveBtn={handleSaveBtnFlag}
                  />
                </Accordion>
              </div>
              {userCountryCode && userCountryCode !== constants.KOREA_COUNTRY_CODE && (
                <div>
                  {modelprops?.consentDetails?.map(consentDetail => (
                    <div
                      key={consentDetail?.consentKey}
                      className="consent-model_checkbox mt-5 pt-2 text_modal-align"
                      role="group"
                      aria-labelledby="checkbox-consent"
                    >
                      <CheckBox
                        checked={consentChecked}
                        onChange={handleCheckboxChange}
                        data-testId="checkbox_ambassador"
                        checkboxName="checkbox_checkbox_ambassador"
                        checkboxId={'checkbox_ambassador'}
                        className="checkbox_ambassador t-subtitle-m "
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setConsentChecked(!consentChecked);
                            setIsSaveButtonEnabled(true);
                          }
                        }}
                        children={
                          <Heading
                            variation={Types.headingType.subtitle}
                            fontSize={Types.size.medium}
                            titleText={consentDetail?.consentTitle}
                            customClass={clsx('')}
                          />
                        }
                      />
                      <Text
                        fontSize={Types.size.medium}
                        element={Types.tags.paragraph}
                        copyText={consentDetail?.consentDescription}
                        customClass={'ml-5'}
                        {...{ id: 'checkbox-consent' }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer
          className="modal-main-container__content__modalFooter m-auto py-4 px-5 py-md-5 d-flex justify-content-md-end"
          clearButtonLabel={modelprops?.cancelButton}
          clearBtnClassname="modal-main-container__content__modalFooter__clear-btn m-link-action p-0"
          clearClickHandler={(): void => {
            resetData();
          }}
          applyLabel={modelprops?.saveButton}
          data-testid="Save"
          applyBtnClickHandler={() => {
            handleButtonClick();
          }}
          applyButtonDisabled={isPersonalError || !isSaveButtonEnabled}
          applyBtnClickTrackValue={AMBASSADOR_ELITE_CLICK_TRACK}
        ></Modal.Footer>
      </Modal>
    </StyledAmbassadorElitePreferences>
  );
};

export const AmbassadorElitePreferencesConfig = {
  emptyLabel: 'ambassadorelitepreferences',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/ambassadorelitepreferences`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AmbassadorElitePreferencesEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <AmbassadorElitePreferences {...props} />
  ) : (
    <EditableComponent config={AmbassadorElitePreferencesConfig} {...props}>
      <AmbassadorElitePreferences {...props} />
    </EditableComponent>
  );
};
