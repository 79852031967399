import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants, memberStatusCardBorder } from '../../../modules';

export const StyledMemberStatusCard = styled.div<{
  bgColor: string | null | undefined;
  footerColor: string | null | undefined;
  textColor: string | null | undefined;
}>`
  .card {
    &__wrapper {
      background: ${({ bgColor }) => bgColor};
      overflow: hidden;
      @media ${baseVariables.mediaQuery.sm} {
        box-sizing: border-box;
        box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
        border-radius: ${toRem(14)};
      }
      &-annual {
        border-top: ${toRem(1)} solid ${memberStatusCardBorder};
        width: 100%;
      }
      &__inverse {
        color: ${baseVariables.color.base20};
      }
      &__gaugemeter {
        min-height: ${toRem(176)};
      }
    }

    &__footer {
      height: ${toRem(64)};
      background: ${({ footerColor }) => footerColor};
      @media ${baseVariables.mediaQuery.sm} {
        border-radius: ${toRem(0)} ${toRem(0)} ${toRem(14)} ${toRem(14)};
      }
    }
  }
  .controls_wrapper {
    .carouselControl {
      height: ${toRem(12)};
      margin: ${toRem(16)} 0 ${toRem(40)} 0;
    }
  }

  .carouselControlType3b .right-arrow {
    color: ${({ textColor }) => textColor};
  }
  .carouselControlType3b .left-arrow {
    color: ${({ textColor }) => textColor};
  }
  .carouselControlType3b .bullets {
    background-color: ${({ textColor }) => textColor};
  }

  .carouselControlType3b .bullets[class*='-active']::after {
    background-color: ${({ textColor }) => textColor};
    opacity: 1;
  }

  .icon-lifetime {
    padding: 0px;
    border: none;
  }
  .member_status_card {
    &__skeleton {
      background-color: ${baseVariables.color['alert10']} !important;
      border-radius: ${toRem(14)};
      box-sizing: border-box;
      box-shadow: none;
      @media ${baseVariables.mediaQuery.md} {
        box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
      }
      &__section {
        width: 85%;
        &__label1 {
          width: 35%;
          height: ${toRem(24)};
        }
        &__label2 {
          width: 65%;
          height: ${toRem(24)};
        }
        &__label3 {
          width: 70%;
          height: ${toRem(52)};
        }
        &__progress {
          width: 100%;
          height: ${toRem(10)};
          border-radius: ${toRem(32)};
        }
        &__btn {
          width: ${toRem(144)};
          height: ${toRem(34)};
        }
      }
      &__img {
        width: ${toRem(200)};
        height: ${toRem(160)};
        border-radius: ${toRem(14)};
      }
    }
    .anual-container {
      margin-top: -4%;
    }
  }
  .member-title {
    text-transform: capitalize !important;
  }
  ${rtl`
    .skeleton__container {
            width: ${toRem(200)};
            height:  ${toRem(100)};
            position: relative;
            overflow: hidden;
        
        &__curved-content {
            width: 100%;
            height: 100%;
            background: ${baseVariables.color.neutral20};
            border-radius: ${toRem(100)} ${toRem(100)} 0 0;
            position: absolute;
            top: 0;
            left: 0;
        }
  }
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: justify;
      .m-link-tertiary-button-inverse{
        margin-left: ${toRem(27)} !important;
      }
      .m-link-tertiary-button-inverse:after {
        transform: rotate(-180deg) translate(${toRem(2)}, ${toRem(0)}) !important;
      }
      .icon-lifetime {
        margin-lrft: ${toRem(8)} !important;
      }
    }
  `}
`;
