import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledContainer = styled.div`
  #popUpContent {
    #privacy-statement-modal {
      .m-modal-header,
      .m-modal-body {
        padding-top: 0;
      }
      ul {
        padding-left: ${toRem(16)};
        li {
          margin-bottom: ${toRem(16)};
        }
      }
    }
  }
  &.paper {
    position: relative;
    margin: auto;
    width: 66%;
    min-height: 20%;
    padding: ${toRem(32)} ${toRem(40)};
    margin-bottom: ${toRem(40)};
    outline: 0;
    background-color: ${theme.colors.white};
    top: ${toRem(60)};
    border-radius: ${toRem(4)};
    & .close-icon {
      position: absolute;
      top: ${toRem(2)};
      right: ${toRem(2)};
      float: right;
      font-size: ${toRem(24)};
      padding: ${toRem(2)} ${toRem(6)};
      font-weight: bold;
      border-radius: 50%;
      color: ${theme.colors.black};
      &:focus,
      &:hover {
        opacity: 0.8;
        text-decoration: none;
        cursor: pointer;
      }
    }
    & input[type='checkbox']:checked + label:after {
      display: none;
    }
    & input[type='password'],
    & input[type='text'] {
      padding: 0.9rem 3rem 0.9rem 1.25rem;
    }
    & .message-title,
    & .message-items.t-message {
      color: ${theme.colors.monza};
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 ${toRem(6)} rgba(0, 0, 0, 0.3);
      border-radius: ${toRem(6)};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: ${toRem(6)};
      -webkit-box-shadow: inset 0 0 ${toRem(6)} rgba(0, 0, 0, 0.3);
    }
    & select {
      padding: ${toRem(4)};
      &:focus {
        border: ${toRem(1)} solid;
      }
    }
  }

  &.modal-design {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: ${toRem(28)};
    width: ${toRem(313)};
    border-radius: ${toRem(14)};
    background-color: ${theme.colors.background};
    & .close-icon {
      position: absolute;
      top: ${toRem(20)};
      right: ${toRem(12)};
      float: right;
      font-size: ${toRem(17)};
      font-weight: bold;
      padding: ${toRem(1)} ${toRem(4)};
      border-radius: ${toRem(4)};
      color: ${theme.colors['base-10']};
      background-color: '#eeeeee';
      &:focus,
      &:hover {
        color: ${theme.colors.black};
        opacity: 0.8;
        text-decoration: none;
        cursor: pointer;
      }
    }
    & ::-webkit-scrollbar-track {
      border-radius: ${toRem(6)};
    }
    & ::-webkit-scrollbar {
      width: ${toRem(8)};
    }
    & ::-webkit-scrollbar-thumb {
      border-radius: ${toRem(6)};
      box-shadow: inset 0 0 ${toRem(6)} rgba(0, 0, 0, 0.3);
    }
  }

  &.custom {
    padding: 0;
    max-height: 60%;
    height: fit-content;
    width: fit-content;
    min-width: 43%;
    max-width: 70%;
    &:focus-visible {
      border: none;
      outline: none;
    }

    &.custom-popup-soc {
      top: 20%;
      bottom: unset;
      max-height: initial;
      background-color: ${theme.colors.white};
      header {
        padding: ${toRem(32)};
        .heading {
          padding-left: 0;
        }
      }
      .close-icon {
        top: ${toRem(32)};
      }
    }

    .close-icon {
      top: ${toRem(42)};
      right: ${toRem(42)};
      height: ${toRem(32)};
      width: ${toRem(32)};
      border-radius: ${toRem(4)};
      background-color: ${theme.colors.gallery};
      padding: ${toRem(4)} ${toRem(7)} 0;
    }

    .icon-clear::before {
      height: ${toRem(24)};
      width: ${toRem(16)};
    }

    &.filters-custom-popup {
      background-color: ${theme.colors.white};
      max-height: 90%;
      overflow-y: scroll;
      .rrd-header .heading {
        font-size: ${toRem(22)};
        line-height: ${toRem(26)};
        padding-left: ${toRem(16)};
      }
    }
    &.rs-brand-modal {
      background-color: ${theme.colors.white};
    }
  }

  &.special-popup {
    .close-icon {
      top: ${toRem(32)};
      right: ${toRem(32)};
    }
  }

  &.custom-popup {
    top: 20%;
    bottom: unset;
    max-height: initial;
  }

  &.share-email-header {
    .close-icon {
      top: ${toRem(32)};
      right: ${toRem(33)};
    }
  }

  &.stay-details-modal {
    top: 12%;
    width: 52%;
    margin: 0 auto;
    bottom: unset;
    max-height: initial;
    height: fit-content;
    padding: ${toRem(64)} 0 ${toRem(40)};
    border-radius: ${toRem(14)};
    .close-icon {
      top: ${toRem(35)};
      right: ${toRem(34)};
    }
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.gallery} ${theme.colors.white};
    & ::-webkit-scrollbar {
      width: ${toRem(12)};
    }
    & ::-webkit-scrollbar-track {
      background: ${theme.colors.white};
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.gallery};
      border-radius: ${toRem(20)};
      border: ${toRem(3)} solid ${theme.colors.white};
    }
  }

  &.room-details-modal {
    height: 100%;
    overflow: scroll;
    .loading {
      display: flex;
      justify-content: center;
    }
  }

  &.legalConsent {
    padding: ${toRem(32)} ${toRem(32)};
    border-radius: ${toRem(14)};
    a#agree-button {
      text-decoration: none;
    }

    li:has(#agree-button) {
      text-align: right;
    }

    .m-modal-header {
      padding: ${toRem(0)} ${toRem(48)} ${toRem(32)} ${toRem(0)};
    }
    a {
      text-decoration: underline;
    }

    .m-modal-body {
      margin: 0;
      padding: ${toRem(14)} ${toRem(32)} ${toRem(32)} ${toRem(0)};
      font-size: ${toRem(14)};
    }
    .close-icon {
      top: ${toRem(32)};
      right: ${toRem(31)};
      border: ${toRem(1)};
      background: ${theme.colors['light-gray-3']};
      border-radius: ${toRem(4)};
    }
  }

  &.ocj-modal {
    top: 12%;
    max-height: 85%;
    margin: 0 auto;
    height: fit-content;
    padding: ${toRem(35)};
    border-radius: ${toRem(14)};
    overflow: auto;
    .tile-country-consent {
      .gray-background-content {
        background-color: ${theme.colors.white};
      }
    }
    .close-icon {
      top: ${toRem(16)};
      right: ${toRem(34)};
    }
    .loading {
      margin: 0;
      padding-top: 0.75rem;
      color: rgb(112 112 112);
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  &.room-modal {
    padding: ${toRem(16)} 0;
    border-radius: ${toRem(14)};
    top: 0;
    margin: ${toRem(60)} auto;
    .close-icon {
      top: ${toRem(32)};
      right: ${toRem(32)};
      border-radius: 10%;
      background-color: ${theme.colors.gallery};
    }
  }
  &.OTP-validation-modal {
    width: ${toRem(744)};
    padding: ${toRem(32)} 0;
    top: 6%;
    margin-bottom: 0;
    .close-icon {
      top: ${toRem(32)};
      right: ${toRem(32)};
      border-radius: 10%;
      background-color: ${theme.colors.gallery};
    }
  }
  &.OTP-sub-validation-modal {
    width: ${toRem(744)};
    padding: ${toRem(32)} 0;
    top: 50%;
    transform: translateY(-50%);
    .close-icon {
      top: ${toRem(32)};
      right: ${toRem(32)};
      border-radius: 10%;
      background-color: ${theme.colors.gallery};
    }
  }
  &.rate-modal {
    padding: ${toRem(16)} 0;
    top: 0;
    margin: ${toRem(60)} auto;
    border-radius: ${toRem(14)};
    width: 52%;
    .close-icon {
      top: ${toRem(32)};
      right: ${toRem(32)};
      border-radius: 10%;
      background-color: ${theme.colors['light-gray-9']};
      opacity: 0.4;
    }
  }

  .close-button {
    position: absolute;
    color: transparent;
    font-size: ${toRem(12)};
    right: 0;
    height: ${toRem(32)};
    width: ${toRem(32)};
  }

  &.sign-in-modal {
    .loading {
      text-align: center;
      padding-top: ${toRem(14)};
    }
    .t-extend-link,
    .t-sign-in-container a {
      color: ${theme.colors['fiery-Orange']};
    }
    .t-sign-in-container .l-form-chunk {
      text-transform: none;
      color: initial;
    }
    .l-joinnow-button {
      height: auto;
      min-height: ${toRem(46)};
    }
  }

  &.hqv-modal {
    padding: 1rem 0px;
    padding-right: 0.25rem;
    border-radius: 0.875rem;
    top: 0px;
    margin: 3.75rem auto;
    width: 46.5rem;
    .close-icon {
      border-radius: 10%;
      background-color: rgb(238, 238, 238);
      top: ${toRem(16)};
      right: ${toRem(20)};
      font-size: ${toRem(16)};
    }
    .close-button {
      position: absolute;
      color: transparent;
      font-size: 0.3125rem;
      right: 0px;
      height: 2rem;
      width: 2rem;
    }
    .hqv-modal-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vh;
      @media ${theme.mediaQuery.desktop} {
        height: ${toRem(250)};
        min-height: ${toRem(250)};
        max-height: ${toRem(250)};
      }
      @media ${theme.mediaQuery.allTablets} {
        height: ${toRem(200)};
        min-height: ${toRem(200)};
        max-height: ${toRem(200)};
      }
      .m-spinner-m {
      }
    }
  }

  @media ${theme.mediaQuery.tablet} {
    &.custom {
      &.confirm-changes-fix-width {
        width: ${toRem(584)};
        .close-icon {
          top: ${toRem(32)};
          right: ${toRem(32)};
        }
      }
    }
  }

  @media ${theme.mediaQuery.desktop} {
    &.modal-design {
      width: ${toRem(744)};

      & .close-icon {
        padding: ${toRem(3)} ${toRem(6)};
        top: ${toRem(29)};
        right: ${toRem(31)};
        font-size: ${toRem(20)};
      }
    }

    &.custom {
      width: fit-content;
      min-width: 43%;
      max-width: 70%;
      .close-icon {
        top: ${toRem(42)};
        right: ${toRem(32)};
      }
      &.filters-custom-popup {
        overflow-y: initial;
      }
      &.confirm-changes-fix-width {
        width: ${toRem(934)};
        .close-icon {
          top: ${toRem(32)};
          right: ${toRem(32)};
        }
      }
      &.rs-brand-modal {
        width: ${toRem(752)};
        .close-icon {
          top: ${toRem(28)};
        }
      }
    }

    &.special-popup {
      .close-icon {
        top: ${toRem(32)};
        right: ${toRem(32)};
      }
    }

    &.custom-popup {
      top: 20%;
      bottom: unset;
      max-height: initial;
    }

    &.share-email-header {
      .close-icon {
        top: ${toRem(32)};
        right: ${toRem(33)};
      }
    }

    &.stay-details-modal {
      width: ${toRem(745)};
    }
  }

  @media ${theme.mediaQuery.allMobiles} {
    &.paper {
      top: 0px;
      width: 100%;
    }
    &.sign-in-modal {
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
      width: 100%;
      max-height: fit-content;
      overflow-y: scroll;
    }
    &.custom {
      bottom: 0;
      width: 100%;
      max-width: 100%;
      max-height: 85vh;
      margin: auto auto 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &.custom-popup-soc {
        bottom: auto;
        top: ${toRem(54)};
        max-height: none;
        height: calc(100% - ${toRem(54)});
        background-color: ${theme.colors.white};
        overflow-y: scroll;
        header {
          padding: ${toRem(30)} ${toRem(24)} ${toRem(28)};
          .heading {
            line-height: ${toRem(22)};
            padding-left: 0;
          }
        }
        .close-icon {
          top: ${toRem(28)};
        }
      }
      .close-icon {
        top: ${toRem(24)};
        right: ${toRem(29)};
      }
      &.filters-custom-popup {
        .rrd-header .heading {
          font-size: ${toRem(18)};
          line-height: ${toRem(20)};
          padding-left: ${toRem(8)};
        }
      }
    }
    &.special-popup {
      .close-icon {
        top: ${toRem(32)};
        right: ${toRem(32)};
      }
    }
    &.share-email-header {
      .close-icon {
        top: ${toRem(24)};
        right: ${toRem(29)};
      }
    }
    &.stay-details-modal {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      margin: auto auto 0;
      border-radius: ${toRem(14)} ${toRem(14)} 0 0;
      .close-icon {
        top: ${toRem(21)};
        right: ${toRem(21)};
      }
    }
    &.ocj-modal {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      margin: auto auto 0;
      border-radius: ${toRem(14)} ${toRem(14)} 0 0;
      .close-icon {
        top: ${toRem(21)};
        right: ${toRem(21)};
      }
    }
    &.hqv-modal {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: 100%;
      height: 80%;
      max-height: 80%;
      margin: auto auto 0;
      border-radius: ${toRem(14)} ${toRem(14)} 0 0;
      .close-icon {
        top: ${toRem(21)};
        right: ${toRem(21)};
      }
    }
    &.hide-close {
      .close-icon {
        display: none;
      }
    }
    &.OTP-validation-modal,
    &.OTP-sub-validation-modal {
      top: 100%;
      transform: translateY(-100%);
    }
    &.room-modal {
      margin: ${toRem(60)} auto 0;
    }
    &.rs-brand-modal {
      .close-icon {
        top: ${toRem(28)};
        right: ${toRem(24)};
      }
    }
  }

  @media ${theme.mediaQuery.allTablets} {
    &.modal-design {
      width: ${toRem(501)};

      & .close-icon {
        top: ${toRem(22)};
        right: ${toRem(20)};
      }
    }
    &.custom {
      width: 82%;
      max-width: 82%;
      max-height: 67%;

      .close-icon {
        top: ${toRem(42)};
        right: ${toRem(32)};
      }
    }

    &.rs-brand-modal {
      .close-icon {
        top: ${toRem(28)};
        right: ${toRem(32)};
      }
    }

    &.special-popup {
      .close-icon {
        top: ${toRem(32)};
        right: ${toRem(32)};
      }
    }

    &.custom-popup {
      top: 20%;
      bottom: unset;
      max-height: initial;
    }

    &.share-email-header {
      .close-icon {
        top: ${toRem(32)};
        right: ${toRem(33)};
      }
    }

    &.stay-details-modal {
      top: 6%;
      width: 86%;
      margin: 0 auto;
      bottom: unset;
      max-height: initial;
      height: fit-content;
      .close-icon {
        top: ${toRem(35)};
        right: ${toRem(25)};
      }
    }
    &.ocj-modal {
      top: 6%;
      width: 86%;
      margin: 0 auto;
      max-height: 88%;
      height: fit-content;
      .close-icon {
        top: ${toRem(35)};
        right: ${toRem(25)};
      }
    }
    &.room-modal,
    &.rate-modal {
      width: 80%;
      padding: ${toRem(16)} 0;
      top: 0;
      margin: ${toRem(60)} auto;
    }
    &.sign-in-modal {
      width: 92%;
    }
    &.OTP-validation-modal,
    &.OTP-sub-validation-modal {
      top: 50%;
      width: ${toRem(585)};
      transform: translateY(-50%);
    }
  }

  &.room-rate-detail-modal {
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    border-radius: 0;

    .close-icon {
      z-index: 99;
      padding: ${toRem(1)} ${toRem(4)};
      border-radius: ${toRem(4)};
      color: ${theme.colors['base-10']};
      background-color: ${theme.colors.gallery};
      top: ${toRem(24)};
      right: ${toRem(24)};
    }
  }
`;
