// Findfavorites component
import clsx from 'clsx';
import { Link } from '@marriott/mi-ui-library';
import { FavouritesStyle } from './FindFavorites.styles';
import { FindFavoritesProp } from './FindFavorites.types';
import { usePageContext, AllLocalesKeysListConstant } from '../../../modules';

export const FindFavorites: React.FC<FindFavoritesProp> = FindFavoritesProp => {
  const { currentLocale } = usePageContext();
  const isLocaleEN = currentLocale?.replace('_', '-') === AllLocalesKeysListConstant.en;

  return (
    <FavouritesStyle
      className={clsx(
        'col col-md-6 col-lg-5 col-xl-4 pb-4 pt-3  d-flex justify-content-center ',
        FindFavoritesProp?.customClass
      )}
      data-testid="find-favourite-card"
    >
      <div className="card container d-flex flex-column justify-content-center align-items-center text-center p-lg-5 p-md-4 p-3">
        <div className="t-subtitle-xl">{FindFavoritesProp?.model?.title}</div>
        <div className="t-font-m px-5 pt-lg-5 pt-3">{FindFavoritesProp?.model?.description}</div>
        <Link
          linkHref={(isLocaleEN ? FindFavoritesProp?.model?.ctaPath : FindFavoritesProp?.model?.ctaPathOthers) || ''}
          text={FindFavoritesProp?.model?.ctaLabel}
          linkClassName={'m-button-secondary t-font-m py-2 px-3 mt-4 mt-lg-5'}
          target="_self"
        ></Link>
      </div>
    </FavouritesStyle>
  );
};
