// Styles for TwoStepVerification go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledCommunicationPreference = styled.div`
  ${rtl`
  .modal__container-content {
    .korean-consents-checkbox{
      input[type='checkbox'] + label {
        font-size: ${toRem(16)}; 
      }
    }
    .modal-main-container {
      &__hrLine {
        box-sizing: border-box;
        height: ${toRem(1)};

        border: ${toRem(1)} solid ${baseVariables.color['neutral30']};
        margin: 0;
      }
    }
    .dropdown__languageLabel {
      z-index: 1;
    }
    .languageFilter {
      height: ${toRem(48)} !important;
     
      .dropdown-value {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-right: ${toRem(2)} !important;
      }
    }

    .m-dropdown-container {
      top: ${toRem(16)};
    }
    .m-button-filter {
      :focus {
        color: ${baseVariables.color.base10};
        border: ${toRem(2)} solid ${baseVariables.color.base10};
        border-radius: ${toRem(8)};
        background-color: ${baseVariables.color.base20};
      }

      :hover {
        border: ${toRem(1)} solid ${baseVariables.color.neutral30};
        border-radius: ${toRem(8)};
        background-color: ${baseVariables.color.base20};
      }
    }
    .m-link-action {
      :focus-visible {
        outline: ${toRem(1)} !important;
      }
    }
    :is(${constants.RTL_LANG_LIST}) {
      text-align: right;
    }
  }

  .modal-main-container {
    &__content__modalFooter {
      background-color: ${baseVariables.color.base20};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};

      .clear {
        :focus {
          border: ${toRem(2)} solid ${baseVariables.color.base10} !important;
        }
        @media ${baseVariables.mediaQuery.md} {
          position: unset;
        }
      }

      &__clear-btn {
        margin-left: ${toRem(28)};
        
      }
    }
    &__languageSection{
      width: 100%;
      :is(${constants.RTL_LANG_LIST}){
        label {
          left:unset;
          right: ${toRem(16)};
        }
      }

      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(344)};
      }
      :is(${constants.RTL_LANG_LIST}){
        display:inline-block;
      }
    }
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: ${toRem(160)};
    }
  }
  .modal_loader {
    &__heading {
      width: 40%;
      height: ${toRem(24)};
    }
    &__body {
      width: 80%;
      height: ${toRem(24)};
    }
  }

  .apply-button {
    width:auto;
  }
  .notification-option:is(${constants.RTL_LANG_LIST}) {
    label{
      padding-right:${toRem(24)};
      ::before {
        left: unset;
        right: 0px;
      }
    
      ::after {
        left: unset;
        right:${toRem(6)};
        transform: rotateZ(45deg) !important;
      }
    }
  }
  
`}
`;
