// Styles for ActivateOrForgotForm go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledActivateOrForgotForm = styled.div`
  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
      text-align: justify;
      .dropdown-value{
        text-align: justify;
      }
      .activate_account--countrydropdown .icon-dropdown-down,.activate_account--countrydropdown .icon-dropdown-up{
        left: ${toRem(8)} !important;
        right: unset !important;
      }
      .rtl-content{
        @media ${baseVariables.mediaQuery['lg']} {
          gap: ${toRem(32)} !important;
        }
      }
    }
    .m-input-field input:is(${constants.RTL_LANG_LIST}){
      text-indent: ${toRem(16)} !important;
      height: ${toRem(45)} !important;
    }
    .m-input-field label:is(${constants.RTL_LANG_LIST}){
      right: ${toRem(11)} !important;
      width: max-content;
    }
    .account-page-error-msg {
      &:is(${constants.RTL_LANG_LIST}){
        .m-message-content-wrap:before{
          margin-left: ${toRem(8)} !important;
          margin-right: unset !important;
        }
      }
      .m-message-content {
        p {
          margin-bottom: 0;
        }
      }
    }
    .activate_account--countrydropdown {
      width: 100%;
      height: ${toRem(44)};
      border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
      border-color: ${baseVariables.color['neutral40']};
      padding-top: ${toRem(12)} !important;
      padding-bottom: ${toRem(12)} !important;
      .icon-dropdown-down,
      .icon-dropdown-up {
        position: absolute;
        right: ${toRem(8)};
      }
      .dropdown-value {
        color: ${baseVariables.color['neutral40']};
        font-size: ${toRem(16)};
      }
    }
    .country-label {
      z-index: 1;
    }
    .submit_btn {
      width: 100%;
      @media ${baseVariables.mediaQuery.md} {
        width: auto;
      }
    }
    .divider {
      background-color: ${baseVariables.color['neutral30']};

      height: ${toRem(1)} !important;
      width: 100% !important;

      @media ${baseVariables.mediaQuery.lg} {
        position: absolute;
        height: 100% !important;
        width: ${toRem(1)} !important;
        right: 0;
      }
    }
    .activate-form-container {
      position: relative;
    }
    .m-dropdown-container {
      .m-dropdown-children {
        height: ${toRem(224)};
      }
    }
    .m-input-field {
      input {
        caret-color: ${baseVariables.color['neutral40']};
        &::-ms-reveal {
          display: none;
        }
      }
    }
    .m-input-field {
      label {
      z-index:1;
      }
      input {
        caret-color: ${baseVariables.color['neutral40']};
        &::-ms-reveal {
          display: none;
        }
      }
      &.is-error {
        a {
          color: ${baseVariables.color.alert50};
        }
        &:focus-within {
          label {
            color: ${baseVariables.color.alert50};
          }
        }
        input:focus {
          box-shadow: none;
          ouline: 0;
        }
      }
      &.disabled {
        label {
          color: ${baseVariables.color.alert20};
        }
        input {
          border-color: ${baseVariables.color.alert20};
          outline: 0;
          color: ${baseVariables.color.neutral40} !important;
          &:focus {
            outline: 0;
            border-color: ${baseVariables.color.alert20};
            box-shadow: none;
          }
        }
      }
    }
    .is-disabled {
      input {
      color: ${baseVariables.color.neutral40} !important;
      }
    }
    .accordion-container {
      .accordion-item {
        border-top: 0;
        .accordion-header {
          .accordion-button {
            border-top: 0;
            width: auto;
            .arrow-icon {
              top: 0;
              right: ${toRem(-20)} !important;
            }
            .accordian-title {
              color: ${baseVariables.color['base10']} !important;
            }
            .accordion-arrow-icon {
              line-height: 1;
            }
          }
        }
        .accordion-body {
          color: ${baseVariables.color['base10']} !important;
        }
      }
    }
    .help-text {
      color: ${baseVariables.color['base10']} !important;
    }
    .custom-btn {
      width: 100%;
      @media ${baseVariables.mediaQuery['sm']} {
        width: auto;
        min-width: ${toRem(128)}; // UX team has asked to set the width of button as per VD
      }
    }
    p,
    input + span:not(.trailing-element) * {
      margin-bottom: 0;
      a {
        font-weight: ${baseVariables.font.fontWeightBold};
      }
      u,
      a {
        padding: 0;
      }
    }
    .error-label,
    .error-label * {
      font-size: ${baseVariables.font.fontXs};
      color: ${baseVariables.color.alert50} !important;
    }
    .form-disabled * {
      color: ${baseVariables.color['neutral30']};
    }
  `}
`;
