// Imports for external libraries go here.
import React, { FC, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Messages, RichText } from '@marriott/mi-ui-library';
import {
  BANNER_MSGS_ERROR_TYPE,
  BANNER_MSGS_ERROR_VALUE,
  BANNER_MSGS_KEYS,
  JOIN_CONFIRMATION_PROMOTION_BASE_URL,
} from '../../modules/utils/constants/constants';
import { PageContext } from '../../modules';
import { useStore } from '../../modules/store/memberLevelStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { handleClickRte, scrollToClass } from '../../modules/utils/helper';
import { BannerMessagesProps } from './BannerMessages.types';
import { StyledBannerMessages } from './BannerMessages.styles';

interface BannerMessageObj {
  text: string;
  type: string;
}

export const BannerMessages: FC<BannerMessagesProps> = props => {
  const { bannerMsgs, setBannerMsgs } = useBannerMessagesStore(state => state);
  const [bannerMessage, setBannerMessage] = useState<BannerMessageObj[]>([]);
  const isAuthorMode = props?.isAuthorMode;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { setShowFindReservationModal } = useStore(state => state);
  const [isJoinConfirmPromotion, setIsJoinConfirmPromotion] = useState(false);

  useEffect(() => {
    if (isAuthorMode) {
      setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
    }
  }, []);

  useEffect(() => {
    const rteLinkClass = '.look-up-reservation';
    const handleLinkClick = (event: MouseEvent) => {
      event.preventDefault();
      setShowFindReservationModal(true);
    };
    handleClickRte(handleLinkClick, rteLinkClass);
  }, [bannerMessage]);

  useEffect(() => {
    if (bannerMsgs?.key) {
      if (typeof bannerMsgs?.key === 'string') {
        if (bannerMsgs?.key === BANNER_MSGS_KEYS.UXL_ERROR_MSG) {
          setBannerMessage([
            {
              text: pageContext?.uxlErrorMessage,
              type: 'warning', // display warning messages for uxl error on uxl
            },
          ]);
        } else {
          props?.bannerdata?.map(bannerObj => {
            if (bannerMsgs?.key === bannerObj?.bannerMsgKey) {
              let bannerMessage = bannerObj?.bannerMsgValue || (bannerObj?.bannerMsgRTE as string);
              if (
                bannerMsgs?.key === BANNER_MSGS_KEYS.NEW_MEMBER_NUMBER_MSG ||
                BANNER_MSGS_KEYS.RESERVATION_NOT_LINKED_ERROR_MSG
              ) {
                bannerMessage = bannerMessage?.replace('{memberNumber}', sessionData?.rewardsId);
              }

              setBannerMessage([
                {
                  text: bannerMessage,
                  type: bannerObj?.type === BANNER_MSGS_ERROR_TYPE ? BANNER_MSGS_ERROR_VALUE : bannerObj?.type,
                },
              ]);
            }
          });
        }
      } else if (Array.isArray(bannerMsgs?.key)) {
        if (bannerMsgs?.key?.includes(BANNER_MSGS_KEYS.UXL_ERROR_MSG)) {
          setBannerMessage([
            {
              text: pageContext?.uxlErrorMessage,
              type: 'warning', // display warning messages for uxl error on uxl
            },
          ]);
        } else {
          const bannerMsgArray = bannerMsgs?.key?.map(msg => {
            const msgObj = props?.bannerdata?.find(bannerObj => bannerObj?.bannerMsgKey === msg);
            const bannerMessage = msgObj?.bannerMsgValue || (msgObj?.bannerMsgRTE as string);
            return {
              text: bannerMessage,
              type: msgObj?.type === BANNER_MSGS_ERROR_TYPE ? BANNER_MSGS_ERROR_VALUE : msgObj?.type || '',
            };
          });

          setBannerMessage(bannerMsgArray);
        }
      }
      scrollToClass();
      setBannerMsgs('');
    }
  }, [bannerMsgs?.key]);

  useEffect(() => {
    setIsJoinConfirmPromotion(
      typeof window !== 'undefined' && window.location?.pathname
        ? window?.location?.pathname.includes(JOIN_CONFIRMATION_PROMOTION_BASE_URL)
        : false
    );
  }, []);

  return bannerMessage?.length ? (
    <StyledBannerMessages
      data-component-name="o-account-bannermessages"
      data-testid="bannermessages"
      className={clsx(isJoinConfirmPromotion ? 'color-scheme3 pt-5 pb-2' : 'pb-5')}
    >
      <Messages messageType={bannerMessage?.[0]?.type}>
        {bannerMessage?.map(message => (
          <RichText text={message?.text} componentId="banner-messages" />
        ))}
      </Messages>
    </StyledBannerMessages>
  ) : (
    <></>
  );
};

export const BannerMessagesConfig = {
  emptyLabel: 'BannerMessages',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/bannermessages`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BannerMessagesEditable = (props: any) => {
  return (
    <EditableComponent config={BannerMessagesConfig} {...props}>
      <BannerMessages {...props} />
    </EditableComponent>
  );
};
