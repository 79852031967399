/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useContext, useMemo, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import axios from 'axios';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import { inspect } from 'util';
import Cookies from 'js-cookie';

import { PageContext } from '../../modules/context/PageContext';
import {
  apiLogger,
  generateApolloClientHeaders,
  CONSENT_FORM_ADDRESS_TYPE,
  CONSENT_FORM_LOGIN_COUNTS,
  EMEA_CONSENT_FORM_COUNTRY_CODES,
  KOREA_COUNTRY_CODE,
  CONSENT_FORM_DECRYPT_ENDPOINT,
  CONSENT_FORM_AGE_DEFAULT,
  CONSENT_FORM_KOREA_COOKIE_NAME,
  CONSENT_FORM_EMEA_COOKIE_NAME,
  COMMUNICATION_PREFERENCE,
  FOOD_AND_BEVERAGE,
  INTERESTS_AND_PASSION,
  AMBASSADOR_ELITE_PREFERENCE_ID,
} from '../../modules/utils';
import { useProfileStore } from '../../modules/store/profileStore';
import { StyledConsentForm } from './ConsentForm.styles';

import { phoenixAccountGetAddressByCustomerId } from '../../modules/graph';

import mockData from './__mock__/ConsentForm.model.json';

const KoreanConsentForm = dynamic(() =>
  import('./KoreanConsentForm/KoreanConsentForm').then(mod => mod.KoreanConsentForm)
);
const EMEAConsentForm = dynamic(() => import('./EMEAConsentForm').then(mod => mod.EMEAConsentForm));

export const ConsentForm = (pageProps: any) => {
  const { IS_LOCAL_DEV } = process.env;
  const isAuthorMode = pageProps?.isAuthorMode;
  const [customerCountryCode, setCustomerCountryCode] = useState('');
  const dataLoaded = useRef<boolean>(false);
  const { openConsentFormProfileModalId, setKoreanUserConsents, setUserBaseCountryCode } = useProfileStore(
    state => state
  );
  /*
   * If Korea we need to check the "MarketingConsent" cookiee value
   * else we need to check "EmeaMarketingConsent" cookie value
   * for displaying the consent form
   */
  const cookieName =
    customerCountryCode === KOREA_COUNTRY_CODE
      ? CONSENT_FORM_KOREA_COOKIE_NAME
      : EMEA_CONSENT_FORM_COUNTRY_CODES.includes(customerCountryCode)
      ? CONSENT_FORM_EMEA_COOKIE_NAME
      : '';
  const consentCookieVal = Cookies?.get(cookieName);
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [userLogin, setUserLogin] = useState({
    login: CONSENT_FORM_LOGIN_COUNTS[0],
    age: CONSENT_FORM_AGE_DEFAULT,
    showConsentForm: false,
  });
  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    isAuthorMode ||
    dataLoaded.current;

  useQuery(phoenixAccountGetAddressByCustomerId, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    skip: skipQuery,
    onCompleted: (data: any) => {
      dataLoaded.current = true;
      const code = data?.customer?.contactInformation?.addresses?.filter(
        (address: any) => address?.type === CONSENT_FORM_ADDRESS_TYPE
      )?.[0]?.address?.country?.code;
      setUserBaseCountryCode(code);
      setCustomerCountryCode(code);

      apiLogger(
        `[EMEAConsentForm] getCustomerAddressData - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
      );
    },
    onError: error => {
      apiLogger(
        `[EMEAConsentForm] getCustomerAddressData - sessionId value: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    },
  });

  /*
   * This function fetches the user login count and checks whether to display the consent form or not
   */
  const marketingConsentDecryption = async () => {
    try {
      const response = await axios.post(CONSENT_FORM_DECRYPT_ENDPOINT, {
        data: [
          {
            key: 'MarketingConsentUserLoginCount',
            value: '',
            algorithmKey: 'aesCBC',
          },
        ],
        clientId: 'mcom_account',
      });
      if (response?.status === 200) {
        const decryptedVal = JSON.parse(response?.data?.data[0]?.value);
        setUserLogin({
          login: decryptedVal?.value,
          age: decryptedVal?.age,
          showConsentForm: CONSENT_FORM_LOGIN_COUNTS.includes(decryptedVal?.value),
        });
      }
    } catch (error: any) {
      apiLogger(
        `[EMEAConsentForm] decryptLoginCount - sessionId value: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    }
  };

  useEffect(() => {
    if (isAuthorMode) {
      const code = mockData?.data?.customer?.contactInformation?.addresses?.filter(
        (address: any) => address?.type === CONSENT_FORM_ADDRESS_TYPE
      )[0]?.address?.country?.code;
      setUserBaseCountryCode(code);
      setCustomerCountryCode(code);
      // Added hardcoded value for Author Mode only
      // ToDo: remove and push to model.json post testing
      const decryptedVal = JSON.parse('{"value":"1","age":"2025-10-04T14:52:45.655442Z"}');
      setUserLogin({
        login: decryptedVal?.value,
        age: decryptedVal?.age,
        showConsentForm: true,
      });
      setKoreanUserConsents(
        pageProps?.model?.checkboxDetails,
        pageProps?.model?.showMoreLabel,
        pageProps?.model?.showLessLabel
      );
    } else {
      setKoreanUserConsents(
        pageProps?.model?.checkboxDetails,
        pageProps?.model?.showMoreLabel,
        pageProps?.model?.showLessLabel
      );
      marketingConsentDecryption();
    }
  }, []);

  // We need this change to load the EmeaConsentform/KoreaConsentForm once for signin
  // If cookie is false we need to show the consent form
  const saveModalOpenStatusToBrowserCookie = () => {
    /** storing cookies info on load */
    if (
      !isAuthorMode &&
      customerCountryCode &&
      (customerCountryCode === KOREA_COUNTRY_CODE || EMEA_CONSENT_FORM_COUNTRY_CODES.includes(customerCountryCode))
    ) {
      if (userLogin?.showConsentForm) {
        Cookies.set(cookieName, 'true');
      } else {
        Cookies.set(cookieName, 'false');
      }
    }
  };
  //Cookie Logic end

  return (
    <StyledConsentForm data-testid="consentform" data-component-name="o-account-consentform">
      {userLogin?.showConsentForm &&
        consentCookieVal !== 'true' &&
        (customerCountryCode === KOREA_COUNTRY_CODE ? (
          <KoreanConsentForm saveCookieInfo={saveModalOpenStatusToBrowserCookie} pageProps={pageProps} />
        ) : EMEA_CONSENT_FORM_COUNTRY_CODES.includes(customerCountryCode) ? (
          <EMEAConsentForm saveCookieInfo={saveModalOpenStatusToBrowserCookie} pageProps={pageProps} />
        ) : (
          <></>
        ))}
      {(openConsentFormProfileModalId === COMMUNICATION_PREFERENCE ||
        openConsentFormProfileModalId === FOOD_AND_BEVERAGE ||
        openConsentFormProfileModalId === INTERESTS_AND_PASSION ||
        openConsentFormProfileModalId === AMBASSADOR_ELITE_PREFERENCE_ID) &&
      customerCountryCode === KOREA_COUNTRY_CODE ? (
        <KoreanConsentForm
          saveCookieInfo={saveModalOpenStatusToBrowserCookie}
          pageProps={pageProps}
          isProfileModal={true}
        />
      ) : (
        <></>
      )}
    </StyledConsentForm>
  );
};

export const ConsentFormConfig = {
  emptyLabel: 'consentform',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/consentform`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ConsentFormEditable = (props: any) => {
  return props.cqPath.includes('datapagecomponent') ? (
    <ConsentForm {...props} />
  ) : (
    <EditableComponent config={ConsentFormConfig} {...props}>
      <ConsentForm {...props} />
    </EditableComponent>
  );
};
