// Styles for AmbassadorServiceDetails go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { SkeletonLoaderDuration, constants } from '../../modules';

export const StyledAmbassadorService = styled.div`
  ${rtl`
  .personal-ambassador-container {
    border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};

    &__icon {
      color: ${baseVariables.color.merch10};
    }
  }

  .modal__container {
    max-width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(584)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(800)};
    }
    &-header {
      border-radius: ${toRem(16)} ${toRem(16)} 0 0;
      background-color: ${baseVariables.color.base20};
    }
    &-content {
      background-color: ${baseVariables.color.base20};
      border-radius: 0 0 ${toRem(16)} ${toRem(16)};
      @media ${baseVariables.mediaQuery.lg} {
        max-height: ${toRem(300)};
      }

      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border-radius: ${toRem(400)};
        background: ${baseVariables.color['neutral30']};
      }
      ::-webkit-scrollbar {
        width: ${toRem(10)};
        height: ${toRem(40)};
      }
      :is(${constants.RTL_LANG_LIST}) {
        text-align: right;
      }
    }
    &-footer {
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30} !important;
      background-color: ${baseVariables.color.base20};
      div {
        width: 100%;
        @media ${baseVariables.mediaQuery.md} {
          width: auto;
        }
      }
      .apply-button {
        width: 100%;
      }
    }
  }

  .skeleton-style {
    width: 100%;
    height: ${toRem(30)};
    &:after {
      animation-duration: ${SkeletonLoaderDuration};
    }
  }
  .personal-ambassador-container__icon:is(${constants.RTL_LANG_LIST}),
  .anytime-ambassador-container__icon:is(${constants.RTL_LANG_LIST}) {
    padding-left: ${toRem(8)} !important;
    padding-right: 0 !important;
  }
`}
`;
