import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const FooterStyle = styled.div`
  ${rtl`
  .footer-container{
    &__wrapper {
      @media ${baseVariables.mediaQuery.md} {
        gap: ${toRem(16)};
      }

      @media ${baseVariables.mediaQuery.lg} {
        gap:${toRem(72)};
      }
    }

    &__border {
      border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
    }

    &__border:is(${constants.RTL_LANG_LIST}) {
      border-right: none !important;
      border-left: ${toRem(1)} solid ${baseVariables.color['neutral30']} !important;
    }
  }

    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;

      .icon-arrow-right:before {
        content: '\\e921' !important;
      }
    }
  `}
`;

export const FooterMobileDropDownStyle = styled.div`
  ${rtl`
    .footermobile {
      border-color: ${baseVariables.color['neutral30']};
      border-width: 0 0 ${toRem(1)} 0;
      border-style: solid;

      &__data {
        width: 100%;
      }

      &__button {
        background-color: transparent;
      }
    }

    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }
  `}
`;

export const ItemGroupStyle = styled.div`
  ${rtl`
    .footer-box {
      @media ${baseVariables.mediaQuery.md}{
        height: auto;
      }

      &--header {
        @media ${baseVariables.mediaQuery.md}{
          width: 100%;
        }
      }
    }

    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }
  `}
`;
