import { DATALAYER_PAGE_EVENT, SUCCESS_CODE, FAILURE_CODE, updateDataLayerProperties } from '../../../modules';
import { allZeroDigitsRegex, nonDigitRegex } from '../../../modules/utils/regexUtils';
import { numberWithCommas } from '../../../modules/utils/numericalFormatsHelper';
import { replaceText } from '../../../modules/utils/promotionHelper';
import { PointsTransferErrors } from '../PointsTransfer.types';
import { PartnerDetails, FormData, FormDataErrors } from '../TransferPartnerPoints/TransferPartnerPoints.types';

export const isMultipleOf = (points: number, multiplier: string) => {
  return points % Number(multiplier) === 0;
};

export const handlePartnerPoints = (
  value: string,
  partnerDetails: PartnerDetails,
  formData: FormData,
  errorList: PointsTransferErrors,
  pointsBalance: number | undefined,
  defaultErrorsList: PointsTransferErrors
) => {
  const points = value?.replace(/^0+/, ''); //removing all leading zero's
  let inlinePointsError;
  let invalidPointsError;
  let isValid = true;
  let convertedPoints;
  const hasSpecialChar = nonDigitRegex.test(points);
  const totalPoints = Number(points);

  if (formData.partnerID) {
    if (points && hasSpecialChar) {
      invalidPointsError = errorList?.invalidPointsFieldErrorMessage
        ? errorList?.invalidPointsFieldErrorMessage
        : defaultErrorsList?.invalidPointsFieldErrorMessage;
      inlinePointsError = errorList?.invalidPointsFieldErrorMessage
        ? errorList?.invalidPointsFieldErrorMessage
        : defaultErrorsList?.invalidPointsFieldErrorMessage;
      isValid = false;
    } else if (value && allZeroDigitsRegex.test(value)) {
      inlinePointsError = errorList?.invalidPointsFieldErrorMessage
        ? errorList?.invalidPointsFieldErrorMessage
        : defaultErrorsList?.invalidPointsFieldErrorMessage;
      isValid = false;
    } else if (points && pointsBalance !== undefined && totalPoints > pointsBalance) {
      inlinePointsError = errorList?.notEnoughErrorMessage
        ? errorList?.notEnoughErrorMessage
        : defaultErrorsList?.notEnoughErrorMessage;
      isValid = false;
    } else if (points && partnerDetails?.maxGiveAmt && totalPoints > Number(partnerDetails?.maxGiveAmt)) {
      inlinePointsError = replaceText(errorList?.upperThreshPointErrorMessage || '', [
        numberWithCommas(partnerDetails?.maxGiveAmt),
      ]);
      isValid = false;
    } else if (points && partnerDetails?.maxYearLimit && totalPoints > Number(partnerDetails?.maxYearLimit)) {
      inlinePointsError = replaceText(errorList?.maxYearLimitErrorMessage || '', [
        numberWithCommas(partnerDetails?.maxYearLimit),
      ]);
      isValid = false;
    } else if (
      points &&
      partnerDetails?.minPointsToTransfer &&
      totalPoints < Number(partnerDetails?.minPointsToTransfer)
    ) {
      inlinePointsError = replaceText(errorList?.lowerThreshPointErrorMessage || '', [
        numberWithCommas(partnerDetails?.minPointsToTransfer),
      ]);
      isValid = false;
    } else if (points && partnerDetails && !hasSpecialChar) {
      const isVaildMultiple = isMultipleOf(totalPoints, partnerDetails?.pointsTransferMultiplier || '1');
      inlinePointsError = !isVaildMultiple
        ? replaceText(errorList?.multiplierErrorMessage || '', [
            numberWithCommas(partnerDetails?.pointsTransferMultiplier || '1'),
          ])
        : '';
      isValid = isVaildMultiple ? true : false;
    }
  }

  // converting points based on ratio's
  if (points && partnerDetails?.marriottWeight && isValid) {
    const value = Math.round(totalPoints / Number(partnerDetails?.marriottWeight));
    convertedPoints = (value * Number(partnerDetails?.partnerWeight)).toString();
  }

  return {
    inlinePointsError: inlinePointsError,
    convertedPoints: convertedPoints,
    invalidPointsError: invalidPointsError,
  };
};

export const errorKeyHandler = (
  errorKey: string,
  setFormErrors: React.Dispatch<React.SetStateAction<FormDataErrors>>,
  defaultErrorsList?: { [key: string]: string },
  partnerDetails?: PartnerDetails,
  fallBackMethod?: () => void
) => {
  const errors: { [key: string]: string } = {};
  if (defaultErrorsList && Object.keys(defaultErrorsList)?.includes(`${errorKey}ErrorMessage`)) {
    if (partnerDetails && Object.keys(partnerDetails)?.includes(errorKey)) {
      errors[`${errorKey}ErrorMessage`] = replaceText(defaultErrorsList?.[`${errorKey}ErrorMessage`] || '', [
        numberWithCommas((partnerDetails as { [key: string]: string })?.[errorKey]),
      ]);
    } else {
      errors[`${errorKey}ErrorMessage`] = defaultErrorsList?.[`${errorKey}ErrorMessage`];
    }
    setFormErrors(errors);
  } else {
    fallBackMethod && fallBackMethod();
  }
};

export const updatePointsTransferDataLayer = (partnerName: string, isSuccess: boolean = false) => {
  if (partnerName) {
    const dataLayerProps = {
      partner_point_transfer_status: isSuccess ? SUCCESS_CODE : FAILURE_CODE,
      partner_point_transfer: partnerName,
    };
    updateDataLayerProperties(
      dataLayerProps,
      isSuccess ? DATALAYER_PAGE_EVENT.CONVERT_POINTS_SUCCESS : DATALAYER_PAGE_EVENT.CONVERT_POINTS_FAILURE
    );
  }
};
