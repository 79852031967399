/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type awardDetailsType = {
  count: number;
  expirationDate: string;
};
export interface BannerMessagesState {
  randParameter: string;
  setRandParameter: (key: string) => void;
  isOCJFlow: boolean;
  setIsOCJFlow: (key: boolean) => void;
  bannerMsgs: {
    key: string | string[];
  };
  setBannerMsgs: (key: string | string[]) => void;
  awardsRequestCount: number | null;
  setAwardsRequestCount: (count: number) => void;
  interPageErrorMsg?: string;
  setInterPageErrorMsg: (key: string) => void;
  bannerId: string;
  setBannerId: (bannerId: string) => void;
}

const initialState: BannerMessagesState = {
  randParameter: '',
  setRandParameter: () => {},
  isOCJFlow: false,
  setIsOCJFlow: () => {},
  bannerMsgs: {
    key: '',
  },
  setBannerMsgs: () => {},
  awardsRequestCount: null,
  setAwardsRequestCount: () => {},
  interPageErrorMsg: '',
  setInterPageErrorMsg: () => {},
  bannerId: '',
  setBannerId: () => {},
};

const BannerMessagesStore: StateCreator<BannerMessagesState> = set => {
  return {
    ...initialState,
    setBannerMsgs: (key: string | string[]) => {
      set({ bannerMsgs: { key } });
    },
    setRandParameter: (key: string) => {
      set({ randParameter: key });
    },
    setIsOCJFlow: (key: boolean) => {
      set({ isOCJFlow: key });
    },
    setAwardsRequestCount: (count: number) => {
      set({ awardsRequestCount: count });
    },
    setInterPageErrorMsg: (key: string) => {
      set({ interPageErrorMsg: key });
    },
    setBannerId: (bnrId: string) => {
      set({ bannerId: bnrId });
    },
  };
};

export const useBannerMessagesStore = createAppStore(BannerMessagesStore, {
  usePersistentStore: true,
  persistentStoreName: 'bannerMessagesState',
});
