// Styles for MyTripsCancelRoomModal go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledCancelReservation = styled.div`
  > div[class*='Modal'] {
    overflow-y: scroll;
    &:before {
      position: fixed;
    }
  }
  .modal-main-container {
    &__content {
      height: calc(100% - ${toRem(64)});
      overflow-y: auto;
      @media ${baseVariables.mediaQuery.md} {
        height: fit-content;
      }
    }
  }
  .modal-header {
    min-height: ${toRem(80)};

    @media ${baseVariables.mediaQuery.md} {
      min-height: ${toRem(96)};
    }

    .popup-close {
      max-height: ${toRem(32)};
      max-width: ${toRem(32)};
    }
  }

  .modal-body {
    max-height: none;
  }
`;
