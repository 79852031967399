// Styles for OverviewSectionHeading go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledOverviewSectionHeading = styled.div<{ isComponentMoved?: boolean }>`
  ${({ isComponentMoved }) =>
    isComponentMoved &&
    `
        background-color: ${baseVariables.color.base20};
        border-radius: ${toRem(16)} ${toRem(16)} 0rem 0rem;
        position: relative;
        z-index: 10;
    `}
`;
