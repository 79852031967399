// Imports for external libraries go here.
import React, { FC } from 'react';
import clsx from 'clsx';

import { RichText } from '@marriott/mi-ui-library';
import { CustomTableCellProps } from './CustomTableCell.types';
import { StyledCustomTableCell } from './CustomTableCell.styles';

// Use named rather than default exports.
export const CustomTableCell: FC<CustomTableCellProps> = ({
  fontIconClass,
  classname,
  colIndex,
  rowIndex,
  backGroundColor,
  rowSpan = 1,
  text,
  isIcon,
  id,
  customTextClass,
  colSpan = 1,
  children,
}) => {
  return (
    <StyledCustomTableCell
      data-testid="account-CustomTableCell"
      data-component-name="o-account-CustomTableCell"
      className={classname}
      backGroundColor={backGroundColor}
      rowSpan={rowSpan}
      isIcon={!!isIcon}
      colSpan={colSpan}
    >
      {children || (
        <>
          {text && !isIcon && (
            <RichText
              text={text || ''}
              customClass={clsx(
                customTextClass ||
                  'member_benefit_header header-cell p-2 d-flex align-items-center justify-content-center text-center',
                fontIconClass
              )}
              componentId={id || `member-benefit-header-${colIndex}${rowIndex}`}
            />
          )}
          {fontIconClass && isIcon && (
            <div className="icon d-flex" id={id}>
              <span className={clsx(fontIconClass, 'icon-inverse mx-auto my-auto')}></span>
            </div>
          )}
        </>
      )}
    </StyledCustomTableCell>
  );
};
