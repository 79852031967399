/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { Text, Types, Link, Icon, useCheckBreakpoint, Button } from '@marriott/mi-ui-library';
import { constants, getFileNameFromContentDisposition } from '../../../modules/utils';
import {
  hotelReservationUrl,
  countryUSCode,
  ritzCarltonUrl,
  ritzCarltonBrandId,
  generateViewHotelUrl,
  generateRitzCarltonHotelUrl,
  ValidMarshCodes,
  ValidBrandIds,
  addSubDirectoryPrefix,
  numberWithCommas,
  convertDateToLocaleSpecific,
} from '../../../modules/utils';
import { getDirection } from '../../../modules/utils/overviewHelper';
import {
  CreateViewBillColumnProps,
  CreateDescriptionColumnProps,
  CreatePointsColumnProps,
  ActionsDataProps,
} from '../MyActivity.types';
import StyledMyActivity from '../MyActivity.styles';

export function calculateDays(startDateStr: Date | string, endDateStr: Date | string) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const timeDifference = endDate.getTime() - startDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
}

export function addSign(number: any, overviewComponentMoved?: boolean, pointLabel?: string) {
  if (number >= 0) {
    return (
      <StyledMyActivity>
        <Icon iconClass={clsx(`icon-plus ${overviewComponentMoved ? 'customized-icon' : 'icon-s'}`)}></Icon>
        {numberWithCommas(number)} {pointLabel}
      </StyledMyActivity>
    );
  } else {
    return (
      <>
        <Icon iconClass={`icon-minus`}></Icon>
        {numberWithCommas(String(number).replace('-', ''))} {pointLabel}
      </>
    );
  }
}
export const pageSizeOptions = [
  { id: '5', value: '5' },
  { id: '10', value: '10' },
  { id: 'All', value: 'All' },
];

export function getViewBillAndStayAgainCTAs(node: Record<string, any>, locale: string) {
  const countryCode = locale?.split('-')?.[1]?.toLowerCase();
  if (locale === 'en_US' || locale === 'en_GB') {
    locale = locale.replace('_', '-');
  } else {
    locale = locale?.split('-')?.[0];
  }
  const showCTAs = {
    showViewBillCTA: false,
    showStayAgainCTA: false,
    stayAgainTarget: '_self',
    stayAgainLink: '/',
  };
  const { endDate, properties, awardType } = node ?? {};
  if (endDate) {
    const daysFromLastActivity = calculateDays(endDate, new Date());
    const hasProperties = properties && properties.length > 0;
    const daysFolioAvailableOnline = hasProperties && properties[0].policies?.daysFolioAvailableOnline;
    const { bookable, brand } = (hasProperties && properties[0].basicInformation) ?? {};

    showCTAs.showViewBillCTA = countryCode === countryUSCode && daysFromLastActivity < daysFolioAvailableOnline;
    showCTAs.showStayAgainCTA =
      (bookable &&
        // this condition added so that the stay again link should not appear for suite-night awards row in activity table
        awardType?.code !== constants.SUITE_NIGHT) ||
      brand?.id?.toLowerCase() === ritzCarltonBrandId;
    /**
     * stay again should display if hotel is bookable or hotel is ritz carton as per aries
     * removed locale as check from this place, part of the defect WEB-68009
     */

    if (hasProperties) {
      showCTAs.stayAgainLink =
        brand?.id?.toLowerCase() === ritzCarltonBrandId
          ? `${ritzCarltonUrl.replace('{marshaCode}', properties[0]?.id).replace('{locale}', locale?.toLowerCase())}`
          : `${addSubDirectoryPrefix(hotelReservationUrl)}${properties[0]?.id}`;
    }
    showCTAs.stayAgainTarget = brand?.id?.toLowerCase() === ritzCarltonBrandId ? '_blank' : '_self';
  }
  return showCTAs;
}

const downloadPDF = async (request: any, url: string) => {
  let downloadRes;
  try {
    const response = await axios.post(url, request, { responseType: 'blob' });
    if (response) {
      downloadRes = new Blob([response.data], { type: 'application/pdf' });
      const fileName = getFileNameFromContentDisposition(response.headers?.['content-disposition'] as string);
      if (downloadRes) {
        const downloadableAnchorEle = document.createElement('a');
        downloadableAnchorEle.href = window.URL.createObjectURL(downloadRes);
        downloadableAnchorEle.download = fileName;
        document.body.appendChild(downloadableAnchorEle);
        downloadableAnchorEle.click();
        downloadableAnchorEle.remove();
      }
    } else {
      // TODO: need to handle error scenario
      downloadRes = {};
    }
  } catch (err) {
    // TODO: need to handle error scenario
    return downloadRes;
  }
  return downloadRes;
};

export function downloadViewBillPDF(nodeData: Record<string, any>, labels: any, rewardNumber?: string) {
  const { startDate, endDate, properties } = nodeData ?? {};
  const hasProperties = properties && properties.length > 0;
  const { brand } = (hasProperties && properties[0].basicInformation) ?? {};
  const {
    summaryChargesLabel,
    guestInformationLabel,
    stayDateLabel,
    roomNumberLabel,
    guestNumberLabel,
    rewardNumberLabel,
    groupNumberLabel,
    dateLabel,
    descriptionLabel,
    referencesLabel,
    chargesLabel,
    creditsLabel,
    totalBalanceLabel,
    importantInformationLabel,
    questionBillLabel,
    hotelContactLabel,
    privacyTitleLabel,
    billAuthenticityTitleLabel,
    billAuthenticityTextLabel,
    pointsCreditTitleLabel,
    pointsCreditTextLabel,
    imageAriaTextLabel,
    privacyTextActionLabel,
    privacyTextLabel,
  } = labels ?? {};
  const request = {
    startDate: startDate,
    endDate: endDate,
    marshaCode: hasProperties ? properties[0]?.id : '',
    rewardNumber: rewardNumber,
    brandCode: brand?.id,
    labels: {
      summaryChargesLabel: summaryChargesLabel,
      guestInformationLabel: guestInformationLabel,
      stayDateLabel: stayDateLabel,
      roomNumberLabel: roomNumberLabel,
      guestNumberLabel: guestNumberLabel,
      rewardNumberLabel: rewardNumberLabel,
      groupNumberLabel: groupNumberLabel,
      dateLabel: dateLabel,
      descriptionLabel: descriptionLabel,
      referencesLabel: referencesLabel,
      chargesLabel: chargesLabel,
      creditsLabel: creditsLabel,
      totalBalanceLabel: totalBalanceLabel,
      importantInformationLabel: importantInformationLabel,
      questionBillLabel: questionBillLabel,
      hotelContactLabel: hotelContactLabel,
      privacyTitleLabel: privacyTitleLabel,
      billAuthenticityTitleLabel: billAuthenticityTitleLabel,
      billAuthenticityTextLabel: billAuthenticityTextLabel,
      pointsCreditTitleLabel: pointsCreditTitleLabel,
      pointsCreditTextLabel: pointsCreditTextLabel,
      imageAriaTextLabel: imageAriaTextLabel,
      privacyTextActionLabel: privacyTextActionLabel,
      privacyTextLabel: privacyTextLabel,
    },
  };
  downloadPDF(request, '/mi/phoenix-account-navigation/v1/folioPdfDownload');
}

const CreateDescriptionColumn: React.FC<CreateDescriptionColumnProps> = ({ data, currentLocale, model }) => {
  const { showStayAgainCTA, stayAgainTarget, stayAgainLink } = getViewBillAndStayAgainCTAs(data?.node, currentLocale);

  const getdescription2ndRow = (data: any) => {
    if (data?.node?.type?.code === constants.RWD_EVENT) {
      if (data?.node?.partner?.account) {
        if (data?.node?.currency?.code === constants.MILES_CURRENCY_CODE) {
          return (
            <p className="m-0 fade-txt">
              {model?.milesLabel} {model?.submittedToLabel?.toLowerCase()} {data?.node?.partner?.type?.description}{' '}
              {data?.node?.partner?.account}
            </p>
          );
        } else {
          return (
            <p className="m-0 fade-txt">
              {model?.submittedToLabel} {data?.node?.partner?.type?.description} {data?.node?.partner?.account}
            </p>
          );
        }
      } else if (data?.node?.totalEarning !== null && data?.node?.awardType?.code !== constants.SUITE_NIGHT) {
        return data?.node?.actions?.map((actionsData: ActionsDataProps) => {
          // Even for 0 total earnings we need to use + sign with value on the description
          if (actionsData?.totalEarning !== undefined && actionsData?.totalEarning >= 0) {
            return (
              <p className="m-0 fade-txt">
                {convertDateToLocaleSpecific(actionsData?.actionDate, currentLocale)} {model?.refundedLabel} {'+'}
                {actionsData?.totalEarning} {data?.node?.type?.description}
              </p>
            );
          } else {
            return (
              <p className="m-0 fade-txt">
                {convertDateToLocaleSpecific(actionsData?.actionDate, currentLocale)} {model?.redeemedLabel}{' '}
                {actionsData?.totalEarning} {data?.node?.type?.description}
              </p>
            );
          }
        });
      }
    } else if (
      data?.node?.type?.code !== constants.BONUS &&
      data?.node?.type?.code !== constants.OTHER &&
      data?.node?.type?.code !== constants.SUITE_NIGHT
    ) {
      return (
        <p className="m-0 fade-txt text-nowrap">
          {convertDateToLocaleSpecific(data?.node?.startDate, currentLocale)} -{' '}
          {convertDateToLocaleSpecific(data?.node?.endDate, currentLocale)}
          <Icon iconClass={'icon-dot icon-l'}></Icon>
          <div className="d-inline-block">
            {calculateDays(data?.node?.startDate, data?.node?.endDate)}{' '}
            {Number(calculateDays(data?.node?.startDate, data?.node?.endDate) || 0) <= 1
              ? model?.nightLabel
              : model?.nightsLabel}
          </div>
        </p>
      );
    } else if (data?.node?.type?.code === constants.OTHER) {
      return <p className="m-0 fade-txt">{convertDateToLocaleSpecific(data?.node?.postDate, currentLocale)}</p>;
    }
    if (data?.node?.awardType?.code === constants.SUITE_NIGHT) {
      return (
        <span>
          <p className="m-0 fade-txt ">{data?.node?.properties?.[0]?.basicInformation?.name},</p>
          <p className="m-0 fade-txt ">
            {convertDateToLocaleSpecific(data?.node?.startDate, currentLocale)}
            {data?.node?.endDate ? ' - ' + convertDateToLocaleSpecific(data?.node?.endDate, currentLocale) : ''}
          </p>
        </span>
      );
    }
    return <></>;
  };

  return (
    <StyledMyActivity>
      <div className="">
        {(data?.node?.description || data?.node?.type?.code === constants.STAY) && (
          <div>
            {data?.node?.type?.code === constants.STAY ? ( // Link only for Stay hotel acivity
              <Link
                linkHref={
                  ValidBrandIds?.includes(data?.node?.properties[0]?.id)
                    ? generateRitzCarltonHotelUrl(
                        data?.node?.properties && data?.node?.properties?.length > 0
                          ? data?.node?.properties[0]?.id
                          : '',
                        data?.node?.properties?.[0]?.basicInformation?.nameInDefaultLanguage?.toLowerCase(),
                        currentLocale
                      )
                    : generateViewHotelUrl(
                        data?.node?.properties && data?.node?.properties?.length > 0
                          ? data?.node?.properties[0]?.id
                          : '',
                        data?.node?.properties?.[0]?.basicInformation?.nameInDefaultLanguage?.toLowerCase()
                      )
                }
                text={data?.node?.properties?.[0]?.basicInformation?.name}
                linkClassName="m-0 t-subtitle-m custom-hotel-name"
                target={
                  ValidMarshCodes.includes(
                    data?.node?.properties && data?.node?.properties?.length > 0 ? data?.node?.properties[0]?.id : ''
                  ) || ValidBrandIds?.includes(data?.node?.properties[0]?.basicInformation?.brand?.id)
                    ? '_blank'
                    : '_self'
                }
              ></Link>
            ) : (
              <p className="m-0 t-subtitle-m">{data?.node?.description}</p>
            )}
            {getdescription2ndRow(data)}
            {showStayAgainCTA && (
              <Link
                linkHref={stayAgainLink}
                text={model?.ctalabel}
                linkClassName="m-0 m-link-tertiary-button"
                target={stayAgainTarget}
              ></Link>
            )}
          </div>
        )}
      </div>
    </StyledMyActivity>
  );
};

const CreateViewBillColumn: React.FC<CreateViewBillColumnProps> = ({
  data,
  currentLocale,
  rewardNumber,
  viewBillCTALabel,
  dataLabel,
  overviewComponentMoved,
}) => {
  const { showViewBillCTA } = getViewBillAndStayAgainCTAs(data?.node, currentLocale);
  const isTabletAndAboveViewPort = useCheckBreakpoint('viewportL');
  return (
    <StyledMyActivity>
      {data?.node?.endDate && (
        <div
          className={clsx(
            overviewComponentMoved ? 'text-right' : isTabletAndAboveViewPort ? 'text-center' : 'text-right'
          )}
        >
          {showViewBillCTA && (
            <Button
              callback={(): void => downloadViewBillPDF(data?.node, dataLabel, rewardNumber)}
              className={'button-bg-color'}
            >
              <div className="d-flex justify-content-center align-items-center">
                <Icon iconClass={'icon-overview icon-s'}></Icon>
                <Text
                  copyText={viewBillCTALabel}
                  fontSize={Types.size.extraLarge}
                  customClass={'ml-2 t-subtitle-xs text-nowrap'}
                  element={Types.tags.span}
                />
              </div>
            </Button>
          )}
        </div>
      )}
    </StyledMyActivity>
  );
};

const CreatePointsColumn: React.FC<CreatePointsColumnProps> = ({ data, pointsLabel, overviewComponentMoved }) => {
  const checkProperty =
    Object.prototype.hasOwnProperty.call(data?.node, 'totalEarning') &&
    Object.prototype.hasOwnProperty.call(data?.node, 'baseEarning') &&
    Object.prototype.hasOwnProperty.call(data?.node, 'eliteEarning') &&
    Object.prototype.hasOwnProperty.call(data?.node, 'extraEarning');
  return (
    <div>
      {checkProperty ? (
        <div className="totalEarning">
          {data?.node?.endDate === null ? (
            <div>
              {addSign(
                data?.node?.totalEarning,
                overviewComponentMoved,
                data?.node?.currency?.code === constants.MILES_CURRENCY_CODE
                  ? pointsLabel.milesLabel
                  : pointsLabel?.pointLabel
              )}
            </div>
          ) : (
            <div
              className={clsx(
                overviewComponentMoved ? 'flex-lg-row' : ' flex-lg-column',
                'd-flex flex-row flex-md-row totalEarning d-flex flex-wrap'
              )}
            >
              <div className="text-nowrap">
                {addSign(
                  data?.node?.totalEarning,
                  overviewComponentMoved,
                  data?.node?.currency?.code === constants.MILES_CURRENCY_CODE
                    ? pointsLabel.milesLabel
                    : pointsLabel?.pointLabel
                )}
              </div>
              <div className="fade-txt">
                <Text
                  copyText={`${numberWithCommas(data?.node?.baseEarning)} ${pointsLabel?.basePointLabel}`}
                  fontSize={Types.size.extraLarge}
                  customClass={clsx('icon-dot text-nowrap', !overviewComponentMoved ? 'pl-3 icon-m ' : 'icon-l')}
                  element={Types.tags.span}
                />
              </div>
              <div className="fade-txt">
                <Text
                  copyText={`${numberWithCommas(data?.node?.eliteEarning)} ${pointsLabel?.elitePointLabel}`}
                  fontSize={Types.size.extraLarge}
                  customClass={clsx('icon-dot text-nowrap', !overviewComponentMoved ? 'pl-3 icon-m ' : 'icon-l')}
                  element={Types.tags.span}
                />
              </div>
              <div className="fade-txt">
                <Text
                  copyText={`${numberWithCommas(data?.node?.extraEarning)} ${pointsLabel?.extraPointLabel}`}
                  fontSize={Types.size.extraLarge}
                  customClass={clsx('icon-dot text-nowrap', !overviewComponentMoved ? 'pl-3 icon-m ' : 'icon-l')}
                  element={Types.tags.span}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Icon iconClass={`icon-minus icon-s`}></Icon>
        </div>
      )}
    </div>
  );
};

export function createRows(
  data: any,
  currentLocale: any,
  rewardNumber: any,
  dataLabel: any,
  overviewComponentMoved?: boolean
) {
  const rowData: any[] = [];
  const isRightToLeft = getDirection() === 'rtl';
  data.map((item: any) => {
    rowData.push([
      convertDateToLocaleSpecific(item?.node?.actions?.[0]?.actionDate, currentLocale),
      <div
        className={clsx(
          overviewComponentMoved ? (isRightToLeft ? 'text-left' : ' text-right') : ' text-lg-left text-right'
        )}
      >
        {item?.node?.type?.description}
        {item?.node?.isQualifyingActivity ? <Icon iconClass={`icon-star icon-s ml-2`}></Icon> : ''}
      </div>,
      <CreateDescriptionColumn data={item} currentLocale={currentLocale} model={dataLabel} />,
      <CreateViewBillColumn
        data={item}
        currentLocale={currentLocale}
        rewardNumber={rewardNumber}
        dataLabel={dataLabel}
        viewBillCTALabel={dataLabel?.viewBillCTALabel}
        overviewComponentMoved={overviewComponentMoved}
      />,
      <CreatePointsColumn data={item} pointsLabel={dataLabel} overviewComponentMoved={overviewComponentMoved} />,
    ]);
  });
  return rowData;
}
