// Styles for RoomPreferences go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledRoomPreferences = styled.div`
  ${rtl`
  .modal__container {
    & > div {
      max-width: 100%;
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(584)} !important;
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(800)} !important;
      }
    }
    &-footer {
      background-color: ${baseVariables.color['merch50']};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      .apply-button {
        width:auto;
      }
    }
    &_radio {
      width: 100%;
      label {
        font-size: ${toRem(16)};
        font-weight: ${baseVariables.font.fontWeightMedium};
      }
    }
      &-content{
        :is(${constants.RTL_LANG_LIST}) {
          text-align: right;
      }
    }
  }
  .room_type {
    padding-bottom: ${toRem(46)};
  }

  .room-options {
    padding-left: 0;
    @media ${baseVariables.mediaQuery.md} {
      padding-left: ${toRem(34)};
      &:is(${constants.RTL_LANG_LIST}) {
        padding-right: ${toRem(34)};
      }
    }
  }
  .accessibility-amenities {
    margin-bottom: ${toRem(20)};
    padding-right: 0;
    padding-left: 0;
    @media ${baseVariables.mediaQuery.md} {
      padding-right: ${toRem(34)};
      padding-left: ${toRem(34)};
    }
  }
  .pilllow-type {
    .modal__container_radio {
      padding-right: 0;
      @media ${baseVariables.mediaQuery.lg} {
        padding-right: ${toRem(16)} !important;
      }
    }
  }
 .clear-btn {
    margin-left: ${toRem(28)};
    @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(0)};
    }
    &:is(${constants.RTL_LANG_LIST}){
      margin-right: ${toRem(28)};
      @media ${baseVariables.mediaQuery.md} {
        margin-right: ${toRem(0)};
      }
    }
  }
  .accordion__container:is(${constants.RTL_LANG_LIST}){
    .accordion__icon {
      margin-left: unset !important;
    }
    .accordion__title {
      .icon-decorative {
        margin-left:${toRem(16)} ;
        margin-right: 0 !important
      }
    }
    .accessibility-amenities{
      padding-right:unset;

      label{
        padding-right:${toRem(24)};
          ::before {
          left: unset;
          right: 0;
        }
            
        ::after {
          left: unset;
          right:${toRem(6)};
          transform: rotateZ(45deg) !important;
        }
      }
    }
  }
  .modal__container_radio:is(${constants.RTL_LANG_LIST}) {
    padding-right: 0 !important;
    margin-right: 0 !important;
        
    label {
      ::before {
        left: unset;
      }
      ::after {
        left: ${toRem(45)};
        right: ${toRem(6)};
      }
      span {
        margin-right: ${toRem(28)};
      }
    }
  }
`}
`;
