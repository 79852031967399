import { useState, useEffect, useContext, useMemo } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { inspect } from 'util';
import clsx from 'clsx';
import { useLazyQuery } from '@apollo/client';
import { Modal } from '@marriott/mi-ui-library';
import { phoenixAccountGetAmbassadorInfo } from '../../modules/graph';
import { AMBASSADOR_SERVICE, apiLogger, generateApolloClientHeaders, getCurrentUrlParams } from '../../modules/utils';
import { useProfileStore } from '../../modules/store/profileStore';
import { PageContext } from '../../modules/context/PageContext';
import { AmbassadorServiceDetailsProps, AmbassadorInfoProps } from './AmbassadorServiceDetails.types';
import { StyledAmbassadorService } from './AmbassadorServiceDetails.styles';
import mockData from './__mock__/AmbassadorServiceDetails.model.json';
import { AmbassadorInfo } from './AmbassadorInfo';

export const AmbassadorServiceDetails = ({ isAuthorMode, model }: AmbassadorServiceDetailsProps) => {
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { profileModalId, setProfileModalId, profileDetails } = useProfileStore(state => state);
  const [ambassadorDetails, setAmbassadorDetails] = useState<AmbassadorInfoProps | null>(null);
  const countryCode = profileDetails?.profileAddress
    ?.filter(address => address?.primary === true)?.[0]
    ?.address?.country?.code?.toLocaleUpperCase();
  const tollFreeKey = AMBASSADOR_SERVICE?.REWARDS_MAIN_TOLL_FREE_AMBASSADOR?.concat('_', countryCode);
  const directKey = AMBASSADOR_SERVICE?.REWARDS_MAIN_DIRECT_AMBASSADOR?.concat('_', countryCode);

  const checkNumberNotNull = (obj?: { value?: string; id?: string }): string | undefined | null => {
    return obj?.id === '--' ? null : obj?.id; //since phone number is coming in id from AEM< it cannot be null, hence added this check
  };
  const phoneNumber = useMemo(() => {
    return (
      checkNumberNotNull(model?.supportPhoneNumbers?.find(item => item?.value === tollFreeKey)) ??
      checkNumberNotNull(model?.supportPhoneNumbers?.find(item => item?.value === directKey)) ??
      checkNumberNotNull(
        model?.supportPhoneNumbers?.find(item => item?.value === AMBASSADOR_SERVICE?.REWARDS_MAIN_TOLL_FREE_AMBASSADOR)
      )
    );
  }, [directKey, model?.supportPhoneNumbers, tollFreeKey]);

  useEffect(() => {
    if (isAuthorMode) {
      setAmbassadorDetails(mockData.data);
    }
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isEditAmbassadorService = param.get('editAmbassadorService');
    if (isEditAmbassadorService) {
      setProfileModalId(model?.modalId);
    }
  }, []);

  useEffect(() => {
    if (profileModalId === model?.modalId) {
      generateAmbassadorInfo();
    }
  }, [profileModalId]);

  const [generateAmbassadorInfo, { loading: ambassadorDetailsLoading }] = useLazyQuery(
    phoenixAccountGetAmbassadorInfo,
    {
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: data => {
        setAmbassadorDetails(data);
        apiLogger(
          `[AmbassadorInfo] AccountGetAmbassadorInfo - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: error => {
        apiLogger(
          `[AmbassadorInfo] AccountGetAmbassadorInfo - sessionId: ${sessionData?.sessionToken} - error: ${inspect(
            error
          )}`
        );
      },
    }
  );

  const personalAmbassadorSections = [
    {
      icon: 'icon-ambassador',
      text: ambassadorDetails?.customer?.loyaltyInformation?.rewards?.ambassadorInfo?.name,
    },
    {
      icon: 'icon-clock',
      text: ambassadorDetails?.customer?.loyaltyInformation?.rewards?.ambassadorInfo?.workHours
        ?.map(workDetails => workDetails?.schedule)
        .join(', '),
    },
    {
      icon: 'icon-mail',
      text: ambassadorDetails?.customer?.loyaltyInformation?.rewards?.ambassadorInfo?.email,
    },
    {
      icon: 'icon-phone',
      text: ambassadorDetails?.customer?.loyaltyInformation?.rewards?.ambassadorInfo?.phone,
    },
  ];

  const anyTimeAmbassadorSections = [
    {
      icon: 'icon-clock',
      text: model?.anyTimeAmbassadorTime,
    },
    {
      icon: 'icon-mail',
      text: model?.anyTimeAmbassadorMail,
    },
    {
      icon: 'icon-phone',
      text: phoneNumber,
    },
  ];

  return (
    <StyledAmbassadorService
      data-testid="ambassadorservicedetails"
      data-component-name="o-account-ambassadorservicedetails"
    >
      <Modal
        show={profileModalId === model?.modalId}
        modalId={model?.modalId + 'modal'}
        labelledBy={model?.modalId}
        onClose={(): void => {}}
        secondaryClassName="modal__container"
        disableScrollOnBody={true}
        aria-modal="true"
        handleBlur={true}
        popupOpenState={profileModalId === model?.modalId}
        setPopupOpenState={(): void => {
          setProfileModalId('');
        }}
        role="dialog"
      >
        <Modal.Header
          className="ambassador-service-header t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
          labelText={model?.header}
          popupHeaderOnCLoseFunc={(): void => {
            setProfileModalId('');
          }}
          data-testid="ambassador-service-header"
        />
        <Modal.Body className="modal__container-content px-4 px-md-5 py-4 mt-md-2 mb-2 mx-2">
          {ambassadorDetailsLoading && !isAuthorMode ? (
            <div className="d-flex flex-column p-5">
              <div className="skeleton-loader my-3 skeleton-style"></div>
              <div className="skeleton-loader my-3 skeleton-style"></div>
              <div className="skeleton-loader my-3 skeleton-style"></div>
            </div>
          ) : (
            <div className="row">
              <div className={clsx('t-font-m col-12 m-modal-content mt-2 mt-md-0 mb-0 p-0')}>
                <div className="personal-ambassador-container mb-4">
                  <div className="personal-ambassador-container__title t-subtitle-l pb-5">
                    {model?.personalAmbassadorHeader}
                  </div>
                  {ambassadorDetails?.customer?.loyaltyInformation?.rewards?.ambassadorInfo ? (
                    <div>
                      {personalAmbassadorSections.map((section, index) => (
                        <AmbassadorInfo key={index} icon={section?.icon} text={section?.text} />
                      ))}
                    </div>
                  ) : (
                    <div className="mb-3">
                      <AmbassadorInfo icon={'icon-ambassador'} text={model?.personalAmbassadorNotAvailable} />
                    </div>
                  )}
                </div>
                <div className="anytime-ambassador-container">
                  <div className="anytime-ambassador-container__title t-subtitle-l pb-5">
                    {model?.anyTimeAmbassadorHeader}
                  </div>
                  <div>
                    {anyTimeAmbassadorSections.map((section, index) => (
                      <AmbassadorInfo key={index} icon={section.icon} text={section?.text ?? ''} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          className="modal__container-footer m-auto pt-5 pb-md-5 align-items-baseline justify-content-end"
          applyLabel={model?.closeButton}
          applyBtnClickHandler={(): void => {
            setProfileModalId('');
          }}
          data-testid={model?.closeButton}
        ></Modal.Footer>
      </Modal>
    </StyledAmbassadorService>
  );
};

export const AmbassadorServiceConfig = {
  emptyLabel: 'ambassadorservicedetails',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/ambassadorservicedetails`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AmbassadorServiceDetailsEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <AmbassadorServiceDetails {...props} />
  ) : (
    <EditableComponent config={AmbassadorServiceConfig} {...props}>
      <AmbassadorServiceDetails {...props} />
    </EditableComponent>
  );
};
