/* eslint-disable valid-typeof */
import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Icon, Link, Heading, Types } from '@marriott/mi-ui-library';
import { ItemGroupStyle, FooterStyle, FooterMobileDropDownStyle } from './linksGroup.styles';
import { ItemGroupProp, FooterMobileDropDownProp, ExtendedFooterData } from './linksGroup.types';

export const ItemGroup: React.FC<ItemGroupProp> = ({ entry }: ItemGroupProp) => {
  const { tags, headingType } = Types;
  return (
    <ItemGroupStyle>
      <div className="footer-box d-flex flex-column mb-md-5 pb-md-2">
        <div className="footer-box--header">
          <Heading
            customClass="pb-1 t-subtitle-l"
            element={tags.h2}
            titleText={entry?.sectionName}
            data-testid="section-header"
            variation={headingType.title}
          />
        </div>
        {entry?.links?.length !== 0 &&
          entry?.links?.map((linkdata, index) => (
            <div data-testid="footer-link" className="t-font-s py-2" key={index}>
              <Link
                target="_self"
                linkType="internal"
                linkHref={linkdata?.ctaPath}
                linkClassName="custom_click_track analytics-click"
                text=""
              >
                <span className="m-nav-link ml-0">{linkdata?.ctaLabel}</span>
                <Icon iconClass="icon-arrow-right link-arrow align-middle pl-2"></Icon>
              </Link>
            </div>
          ))}
      </div>
    </ItemGroupStyle>
  );
};

export const FooterMobileDropdown: React.FC<FooterMobileDropDownProp> = ({ data }: FooterMobileDropDownProp) => {
  const { tags, headingType } = Types;
  const [openLink, setOpenLink] = useState(false);

  const toggleHandler = () => {
    setOpenLink(!openLink);
  };
  return (
    <FooterMobileDropDownStyle>
      <div className="footermobile d-flex flex-column" data-testid="footer-mobile-dropdown">
        <Button
          className={clsx(
            `footermobile__button pb-2 d-flex justify-content-between pl-0 ${openLink ? 'pt-5' : 'pt-3'}`
          )}
          callback={toggleHandler}
        >
          <Heading
            customClass="t-subtitle-l"
            element={tags.h2}
            titleText={data?.sectionName}
            variation={headingType.title}
          />
          <div>
            <Icon iconClass={clsx(openLink ? 'icon-arrow-up' : 'icon-arrow-down')}></Icon>
          </div>
        </Button>
        <div className={clsx(`${!openLink && 'd-none'}`)}>
          <div className={`pt-2 pb-5 d-flex flex-column`}>
            {data?.links?.map((list, index) => (
              <div className="py-2 t-font-s" key={index}>
                <Link
                  target="_self"
                  linkType="internal"
                  linkHref={list?.ctaPath}
                  linkClassName="custom_click_track"
                  text={list?.ctaLabel}
                >
                  <Icon iconClass="icon-arrow-right pl-2 align-middle"></Icon>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </FooterMobileDropDownStyle>
  );
};

export const ActivityFooter: React.FC<ExtendedFooterData> = ({ data }: ExtendedFooterData) => {
  return (
    <FooterStyle className="py-2">
      <div className="footer-container container py-5" data-testid="activity-footer">
        <div className="d-flex justify-content-evenly footer-container__wrapper">
          <div className="col flex-fill pe-5 footer-container__border">
            <div className="">
              {data?.footerItems[0] !== null &&
                data?.footerItems[0]
                  .filter(entry => typeof entry?.links !== undefined)
                  .map((entry, index) => (
                    <div key={index}>
                      <ItemGroup entry={entry} />
                    </div>
                  ))}
            </div>
          </div>
          <div className="col flex-fill p-0 pe-5 footer-container__border">
            <div className="pr-md-5 pr-lg-5">
              {data?.footerItems[1] !== null &&
                data?.footerItems[1]
                  .filter(entry => typeof entry?.links !== undefined)
                  .map((entry, index) => (
                    <div key={index}>
                      <ItemGroup entry={entry} />
                    </div>
                  ))}
            </div>
          </div>
          <div className="col flex-fill p-0">
            <div className="pr-md-5 pr-lg-5">
              {data?.footerItems[2] !== null &&
                data?.footerItems[2]
                  .filter(entry => typeof entry?.links !== undefined)
                  .map((entry, index) => (
                    <div key={index}>
                      <ItemGroup entry={entry} />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </FooterStyle>
  );
};

export const FooterMobile: React.FC<ExtendedFooterData> = ({ data }: ExtendedFooterData) => {
  return (
    <div data-testid="footer-mobile">
      <div className="mb-3 mt-3 mx-3">
        {data?.footerItems?.map((entry, index) => (
          <div key={index}>
            {entry
              .filter(entry => typeof entry?.links !== undefined)
              .map((entry, index) => (
                <div data-testid="footer-mobile-dropdown" key={index}>
                  <FooterMobileDropdown data={entry} />
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
