import moment, { Moment } from 'moment';
import { LoyaltyPromotionEdge, RootPromotions } from '../../organisms/NewPromotions/NewPromotions.types';
import { OVERVIEW_REGISTERED_PROMO_SORT_ORDER, PROMOTIONS_CONSTANT, constants } from './constants';

export const offerUnavailableForExistingMembers = (id: string | undefined) => {
  return PROMOTIONS_CONSTANT.UNAVAILABLE_PROMOTIONAL_OFFERS_LIST_CONFIG_KEY.some(
    offerPrefix =>
      id?.toUpperCase().startsWith(offerPrefix) &&
      !PROMOTIONS_CONSTANT.AVAILABLE_PROMOTIONAL_OFFERS_LIST_CONFIG_KEY.includes(id)
  );
};
export const isBetweenDateRange = (startDt: string | undefined, endDt: string | undefined) => {
  let isValid = false;
  if (startDt && endDt && moment().isBetween(startDt, endDt, 'days', '[]')) {
    isValid = true;
  }
  return isValid;
};
export const replaceText = (template: string, replacements: string[]) => {
  if (template) {
    return template.replace(/\{(\d+)\}/g, (match, index) => replacements[index] || match);
  }
  return '';
};
export const getImgProcQueryString = (
  croppingRatio: number,
  outputQuality: number,
  outputInterpolation: string,
  imageDimensions: {
    width: number;
    height: number;
  }
) => {
  return (
    `?output-quality=${outputQuality}&interpolation=${outputInterpolation}&crop=${
      imageDimensions.width * croppingRatio
    }:${imageDimensions.height * croppingRatio};*,*&downsize=` +
    imageDimensions.width +
    'px:*'
  );
};

export const getNewPromotions = (promotions: RootPromotions | null) => {
  const today = moment().format(constants.STANDARD_DATE_FORMAT);
  return (
    promotions?.loyaltyPromotions?.edges?.filter(
      promo =>
        PROMOTIONS_CONSTANT.NEW_PROMOTIONS_STATE_CODES.includes(promo.node?.state?.code) &&
        isBetweenDateRange(today, promo.node?.offers?.[0]?.hurdle?.endDate) &&
        !offerUnavailableForExistingMembers(promo.node?.offers?.[0]?.id)
    ) ?? []
  );
};

export const isPresentPromotion = (promo: LoyaltyPromotionEdge, currentDate: Moment) =>
  PROMOTIONS_CONSTANT.REGISTERED_PROMOTIONS_STATE_CODES.includes(promo?.node?.state?.code) &&
  promo?.node?.offers?.[0].hurdle?.endDate &&
  moment(promo?.node?.offers?.[0].hurdle?.endDate).isSameOrAfter(currentDate, 'day');

export const sortOverviewPromotions = (promotionsList: LoyaltyPromotionEdge[]) => {
  const sortedList = [...promotionsList].sort((a: LoyaltyPromotionEdge, b: LoyaltyPromotionEdge) => {
    const endDateA = a.node.offers?.[0]?.hurdle?.endDate;
    const endDateB = b.node.offers?.[0]?.hurdle?.endDate;
    if (endDateA && endDateB) {
      const dateComparison = new Date(endDateA).getTime() - new Date(endDateB).getTime();
      if (dateComparison !== 0) {
        return dateComparison;
      }
    }
    return (
      OVERVIEW_REGISTERED_PROMO_SORT_ORDER[a.node?.state?.code] -
      OVERVIEW_REGISTERED_PROMO_SORT_ORDER[b.node?.state?.code]
    );
  });
  return sortedList;
};
