// Styles for TwoStepVerification go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledTwoStepVerification = styled.div`
  ${rtl`
    .modal__container {
      & > div {
        max-width: 100%;
        @media ${baseVariables.mediaQuery.md} {
          max-width: ${toRem(584)} !important;
        }
        @media ${baseVariables.mediaQuery.lg} {
          max-width: ${toRem(800)} !important;
        }
      }
      &-footer {
        background-color: ${baseVariables.color['merch50']};
        border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
        .apply-button {
          width:auto;
        }
      }
      &-content:is(${constants.RTL_LANG_LIST}) {
        text-align: right;
      }
    }
    .clear-btn {
      margin-left: ${toRem(28)};
      @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(0)};
      }
      :is(${constants.RTL_LANG_LIST}){
        margin-left: 0;
        @media ${baseVariables.mediaQuery.md} {
         margin-left: ${toRem(28)};
        }
      }
    }
    
    .two-step-loader {
      .skeleton-loader {
        width: 100%;
        height: ${toRem(72)};
      }
    }

    .uxl-error-msg{
      width:100%;
    }
  `}
`;
