// Styles for SweepstakesEnrollment go here.
import { toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledSweepstakesEnrollment = styled.div`
  .confirmation-container {
    max-width: ${toRem(850)};
  }
  .m-link-tertiary-button-external:hover,
  .m-link-tertiary-button-external:focus {
    border-bottom: none;
  }
`;
