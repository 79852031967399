// Styles for AccountOverviewNotification go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledAccountOverviewNotification = styled.div<{ iconTheme?: string }>`
  .card {
    min-height: ${toRem(136)};
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      min-height: ${toRem(116)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      min-height: ${toRem(80)};
    }
    border-radius: ${toRem(14)};
    background-color: ${baseVariables.color['base20']};
    box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
  }
  .outer {
    padding-top: ${toRem(4)};
    padding-bottom: ${toRem(4)};
    padding-left: ${toRem(4)};
    padding-right: ${toRem(4)};
    border-radius: ${toRem(50)};
    box-shadow: 0 ${toRem(8)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
  }
  .icon {
    ${({ iconTheme }) =>
      iconTheme
        ? `background-color: ${baseVariables.color[iconTheme]};`
        : `background-color: ${baseVariables.color['bvyBase30']};`}
    height: ${toRem(40)};
    width: ${toRem(40)};
    text-align: center;
    justify-content: center;
    border-radius: ${toRem(50)};
  }
  .icon-alert {
    font-size: ${toRem(20)};
  }
  .navheader {
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    @media ${baseVariables.mediaQuery.lg} {
      flex-direction: row;
    }
  }
`;
