import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../../modules';

export const StyledPersonalInfoSection = styled.div`
  ${rtl`
  .elite_modal-container {
    .elite_modal__personal-info {
      .dropdown-value {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .dropdown__container {
        z-index: 2;
      }
      &__self {
        &__dropdown {
          width: unset;
          @media ${baseVariables.mediaQuery.lg} {
            min-width: ${toRem(340)};
          }
          .m-dropdown-container {
            top: ${toRem(4)};
          }
          .m-dropdown-children {
            height: ${toRem(200)};
          }
        }
        &__inputLabel:is(${constants.RTL_LANG_LIST}) {
          padding-left: 0 !important;
          @media ${baseVariables.mediaQuery.lg} {
            padding-left: ${toRem(16)} !important;
          }
        }
      }
      &__spouse {
        &__input {
          min-width: auto;
          @media ${baseVariables.mediaQuery.lg} {
            max-width: ${toRem(340)} !important;
          }
        }
        &__inputLabel:is(${constants.RTL_LANG_LIST}) {
          padding-left: 0 !important;
          @media ${baseVariables.mediaQuery.lg} {
            padding-left: ${toRem(16)} !important;
          }
        }
      }
      .elite_modal__children {
        &__divbox {
          border: 1px solid ${baseVariables.color['neutral20']};
          border-radius: ${toRem(14)};
          &__name {
            max-width: ${toRem(396)};
            @media ${baseVariables.mediaQuery.md} {
              width: 70%;
            }
          }
          :is(${constants.RTL_LANG_LIST}) {
            .icon-delete {
              padding-left: ${toRem(24)} !important;
              @media ${baseVariables.mediaQuery.lg} {
                padding-left: 0 !important;
              }
            }
            &__age {
              margin-right: 0 !important;
              @media ${baseVariables.mediaQuery.md} {
                margin-right: ${toRem(16)} !important;
              }
            }
          }
        }
      }

      .elite_modal__hrLine {
        box-sizing: border-box;
        border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
        margin: 0;
      }
      :is(${constants.RTL_LANG_LIST}) {
        text-align: right !important;
      }
    }
    .dropdown_input {
      border-radius: ${toRem(4)} !important;
      height: ${toRem(44)};

      :focus {
        color: ${baseVariables.color.base10};
        border: ${toRem(2)} solid ${baseVariables.color.base10} !important;
        background-color: ${baseVariables.color.base20};
      }

      :hover {
        border: ${toRem(1)} solid ${baseVariables.color.neutral30};
        border-radius: ${toRem(4)} !important;
        background-color: ${baseVariables.color.base20};
      }
    }
    .elite_modal__pet {
      &__divbox {
        border: 1px solid ${baseVariables.color['neutral20']};
        border-radius: ${toRem(14)};
        .dropdown__petType-label {
          z-index: 1;
          margin-left: ${toRem(26)};
          @media ${baseVariables.mediaQuery.lg} {
            margin-left: 0;
          }
        }
        .dropdown__petTravel-label {
          z-index: 1;
          margin-left: ${toRem(26)};
          @media ${baseVariables.mediaQuery.lg} {
            margin-left: 0;
          }
        }
      }
      &__inputbox {
        width: auto;
        @media ${baseVariables.mediaQuery.lg} {
          min-width: ${toRem(180)} !important;
        }
      }
      &__dropdown {
        &__petType {
          width: auto;
          @media ${baseVariables.mediaQuery.lg} {
            min-width: ${toRem(180)} !important;
          }
          :is(${constants.RTL_LANG_LIST}) {
            margin-left: ${toRem(24)} !important;

            @media ${baseVariables.mediaQuery.lg} {
              margin-left: ${toRem(16)} !important;
            }
          }
        }
        &__petTravel {
          width: auto;
          @media ${baseVariables.mediaQuery.lg} {
            min-width: ${toRem(180)} !important;
          }
        }
      }
    }
    .icon-dropdown-down:before {
      font-size: ${toRem(20)};
    }
  }
  .dropdown__occupation-label {
    z-index: 1;
  }
  input[type='text'] {
    caret-color: ${baseVariables.color['base10']};
  }
  .m-input-field {
    :is(${constants.RTL_LANG_LIST}) {
      label {
        left: unset;
        right: ${toRem(8)};
      }
      input[type='text'] {
        padding-top: ${toRem(8)} !important;
        padding-bottom: ${toRem(8)} !important;
      }
    }
  }
`}
`;
