import React, { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { RichText } from '@marriott/mi-ui-library';

import { constants } from '../../modules';
import { addClassToBlankTargetLinks, canUseDOM } from '../../modules/utils/helper';
import { CustomTableCell } from './CustomTableCell/CustomTableCell';
import { AccountTableProps } from './AccountTable.types';
import { StyledAccountTable } from './AccountTable.styles';

export const AccountTable: FC<AccountTableProps> = props => {
  const authorModelData = props?.model;
  const theadRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    canUseDOM && window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let lastScrollY = 0;

  const handleScroll = (): void => {
    const thead = theadRef.current as HTMLElement | null;
    const table = tableRef.current as HTMLElement | null;

    if (table && thead) {
      const tableRect = table.getBoundingClientRect();
      const stickyOffset = tableRect.top + window.scrollY;
      const isMobile = window.innerWidth < 768;

      if (window.scrollY > stickyOffset) {
        thead.classList.add('sticky');
        if (window.scrollY > lastScrollY) {
          // Scrolling down
          thead.style.top = isMobile ? '180px' : '88px';
        } else {
          // Scrolling up
          thead.style.top = isMobile ? '125px' : '198px';
        }
      } else {
        thead.classList.remove('sticky');
      }

      lastScrollY = window.scrollY;
    }
  };

  return (
    <div data-testid="account-AccountTable" data-component-name="o-account-AccountTable" className="py-3 py-md-4">
      <StyledAccountTable className="container-md px-0 px-md-2">
        <div className="color-scheme1 table-container">
          <table ref={tableRef}>
            {/* header row */}
            {authorModelData?.headerTitleItems?.map((headerData, rowIndex) => (
              <thead ref={theadRef} className="table-header" data-testid="table-header">
                {/* header variation inline */}
                {authorModelData?.variation === constants?.DEFAULT && (
                  <tr className={clsx('row-header-inline', rowIndex !== 0 && 'top-border')}>
                    <CustomTableCell
                      text={headerData?.mainTitle}
                      classname="header-cell row-header"
                      customTextClass="t-subtitle-m right-border__column row-header px-3 px-md-4 py-4"
                      id="account-table-main-title-inline"
                      colSpan={headerData?.headerSubItems?.length}
                    />
                  </tr>
                )}
                <tr className={clsx(rowIndex !== 0 ? 'top-border' : '', 'elite-header')}>
                  {/* header variation default */}
                  <CustomTableCell
                    rowSpan={2}
                    text={headerData?.mainTitle}
                    classname={clsx(
                      'header-cell row-header',
                      authorModelData?.variation === constants?.DEFAULT
                        ? 'row-header-default right-border__column'
                        : authorModelData?.variation === constants?.HIDE_ROW_HEADER
                        ? 'row-header-hidden right-border__column'
                        : 'right-border'
                    )}
                    customTextClass="t-subtitle-m row-header px-3 px-md-4 py-4"
                    id="account-table-main-title-default"
                  />
                  {/* header row items - title */}
                  {headerData?.headerSubItems?.map(
                    (headerRowData, colIndex) =>
                      (!!headerRowData?.subtitle || !!headerRowData?.title) && (
                        <CustomTableCell
                          text={headerRowData?.title || headerRowData?.subtitle}
                          fontIconClass={`${
                            headerRowData?.title
                              ? headerRowData?.titleBackGroundColor
                                ? 't-label-inverse-xs'
                                : 't-label-xs'
                              : 't-label-alt-xs'
                          }`}
                          classname={`${
                            colIndex + 1 !== headerData?.headerSubItems?.length ? 'right-border' : ''
                          } header-cell`}
                          colIndex={colIndex}
                          rowIndex={rowIndex}
                          backGroundColor={headerRowData?.title ? headerRowData?.titleBackGroundColor : ''}
                          rowSpan={headerRowData?.subtitle && headerRowData?.title ? 1 : 2}
                        />
                      )
                  )}
                </tr>
                {/* header row items - subtitle */}
                <tr>
                  {headerData?.headerSubItems?.map(
                    (headerRowData, subRowIndex) =>
                      headerRowData?.subtitle &&
                      headerRowData?.title && (
                        <CustomTableCell
                          text={headerRowData?.subtitle}
                          classname={clsx(
                            't-label-alt-xs p-2',
                            subRowIndex + 1 !== headerData?.headerSubItems?.length && 'right-border',
                            headerRowData?.title && 'top-border'
                          )}
                          customTextClass="header-cell align-items-center justify-content-flex-start justify-content-md-center text-center d-flex"
                          id="member-benefit-header"
                        />
                      )
                  )}
                </tr>
              </thead>
            ))}

            {/* Body row */}
            {authorModelData?.rowItems?.map((rowData, rowIndex) => (
              <tbody>
                {authorModelData?.variation === constants?.DEFAULT && (
                  <tr className="row-header-inline top-border right-border__column">
                    <CustomTableCell colSpan={rowData?.rowSubItems?.length}>
                      <div className="py-3 px-3 px-md-4">
                        <RichText
                          text={addClassToBlankTargetLinks(rowData?.rowTitle || '')}
                          customClass="header-cell mb-2 t-subtitle-m"
                          componentId="body-row-header"
                        />
                        <RichText
                          text={rowData?.rowSubTitle || ''}
                          customClass="header-cell t-font-alt-s"
                          componentId="body-row-sub-header"
                        />
                      </div>
                    </CustomTableCell>
                  </tr>
                )}

                <tr className="top-border">
                  <CustomTableCell
                    classname={clsx(
                      authorModelData?.variation === constants?.DEFAULT
                        ? 'row-header-default right-border__column'
                        : authorModelData?.variation === constants?.HIDE_ROW_HEADER
                        ? 'row-header-hidden right-border__column'
                        : 'right-border',
                      'row-header'
                    )}
                  >
                    <div className="row-header py-3 px-3 px-md-4">
                      <RichText
                        text={addClassToBlankTargetLinks(rowData?.rowTitle || '')}
                        customClass="mb-2 t-subtitle-m"
                        componentId="body-row-header"
                      />
                      <RichText
                        text={rowData?.rowSubTitle || ''}
                        customClass="t-font-alt-s"
                        componentId="body-row-sub-header"
                      />
                    </div>
                  </CustomTableCell>

                  {/* Body row sub rows */}
                  {rowData?.rowSubItems?.map((rowSubItems, colIndex) => (
                    <>
                      {/* text */}
                      {rowSubItems?.variation === constants?.TEXT ? (
                        <CustomTableCell
                          text={rowSubItems?.subRowText}
                          classname={colIndex + 1 !== rowData?.rowSubItems?.length ? 'right-border' : ''}
                          customTextClass="t-font-xs d-flex align-items-center justify-content-center text-center px-2 py-3"
                          id={`body-row-header-${colIndex}${rowIndex}`}
                        />
                      ) : rowSubItems?.variation === constants?.FONTICON ? (
                        <td className={colIndex + 1 !== rowData?.rowSubItems?.length ? 'right-border' : ''}>
                          {rowSubItems?.fontIconSubRowComplex && (
                            <CustomTableCell
                              fontIconClass={rowSubItems?.fontIconSubRowComplex}
                              backGroundColor={rowSubItems?.fontIconColor}
                              classname="table-cell subRow-cell d-flex align-items-center justify-content-center text-center px-2 py-3"
                              id={`chart-icon-${colIndex}${rowIndex}`}
                              isIcon={rowSubItems?.fontIconSubRowComplex}
                            />
                          )}
                        </td>
                      ) : null}
                    </>
                  ))}
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <RichText
          text={addClassToBlankTargetLinks(authorModelData?.disclaimerText || '')}
          customClass="t-font-alt-s mt-4 px-3 px-md-0"
          componentId="disclaimer"
        />
      </StyledAccountTable>
    </div>
  );
};

export const AccountTableConfig = {
  emptyLabel: 'AccountTable',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/accounttable`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AccountTableEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <AccountTable {...props} />
  ) : (
    <EditableComponent config={AccountTableConfig} {...props}>
      <AccountTable {...props} />
    </EditableComponent>
  );
};

export default AccountTableEditable;
