export interface AccountModalEvent {
  modalType: string;
  randParameter?: string;
  enrollmentSourceCode?: string;
  signInReturnUrl?: string;
  successEvent?: string;
  ocjCallbackEvent?: string;
  subDirectoryPrefix?: string;
}

export const ACCOUNT_MODAL_OPEN_EVENT = 'accountModalOpen';
export const ACCOUNT_MODAL_CLOSE_EVENT = 'accountModalClose';

export enum ACCOUNT_MODAL_VARIATION {
  DEFAULT = 'DEFAULT',
  RESERVATION = 'RESERVATION',
}
