/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { AccountNavbarStyle } from './AccountNavbar.styles';
import { CurrentTab } from './AccountNavbar.types';
import { Heading, Types } from '@marriott/mi-ui-library';

const NavItems = dynamic(() => import('./NavItems/NavItems').then(mod => mod.NavItems));

export const AccountNavbar = (pageProps: any) => {
  const tablist = pageProps?.model?.ctaLabels;
  const memberLevelData = useAccountPersistentStore(state => state.memberLevel);

  const [currentTab, setCurrentTab] = useState<CurrentTab | null>(null);
  const [selectedNav, setSelectedNav] = useState<string>('');
  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    const pagePath = window && window.location.pathname;

    // condition for finding the active tab for missngStayRequest pages
    if (pagePath?.toLowerCase()?.split('.')[0]?.includes('missingstayrequest')) {
      setCurrentTab(
        tablist?.find((tab: CurrentTab) => tab?.ctaPath?.toLowerCase()?.toString()?.split('.')[0].includes('activity'))
      );
    } else if (pagePath?.toLowerCase()?.split('.')[0]?.includes('promotioncentral')) {
      // condition for handling promotionCentral page path for both aries and phoenix. To be removed once Promotion central page is 100% live in production
      setCurrentTab(
        tablist?.find((tab: CurrentTab) =>
          tab?.ctaPath?.toLowerCase()?.toString()?.split('.')[0].includes('promotioncentral')
        )
      );
    } else {
      setCurrentTab(
        tablist?.find(
          (tab: CurrentTab) =>
            tab?.ctaPath?.toLowerCase()?.toString()?.split('.')[0].includes(pagePath?.toLowerCase()?.split('.')[0]) ||
            pagePath?.toLowerCase()?.split('.')[0].includes(tab?.ctaPath?.toLowerCase()?.toString()?.split('.')[0])
        )
      );
    }
  }, [tablist]);

  useEffect(() => {
    setSelectedNav(currentTab?.ctaLabel || '');
    setPageTitle(currentTab?.pageTitle || '');
  }, [currentTab]);

  return (
    <AccountNavbarStyle>
      <div
        data-testid="account-navbar"
        id="accountNavbarContainer"
        className="account-navbar-container"
        data-component-name="o-account-accountNavbar"
      >
        {tablist && <NavItems memberLevel={memberLevelData} activeNavTab={selectedNav} {...pageProps.model} />}
      </div>
      {pageTitle ? (
        <div className="pagetitle container my-4" data-testid="page-title">
          <Heading
            variation={Types.headingType.title}
            titleText={pageTitle}
            customClass="t-title-m mb-0"
            element={Types.tags.h1}
            disableCustomClickTrack={true}
          ></Heading>
        </div>
      ) : (
        <div className="pagetitle container my-4" data-testid="page-title">
          <div className="skeleton-loader"></div>
        </div>
      )}
    </AccountNavbarStyle>
  );
};

export const AccountNavbarConfig = {
  emptyLabel: 'accountNavbar',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/accountNavbar`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AccountNavbarEditable = (props: any) => {
  return props.cqPath.includes('datapagecomponent') ? (
    <AccountNavbar {...props} />
  ) : (
    <EditableComponent config={AccountNavbarConfig} {...props}>
      <AccountNavbar {...props} />
    </EditableComponent>
  );
};

// export default AccountNavbarEditable;
