// Styles for AmbassadorElitePreferences go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';

export const StyledAmbassadorElitePreferences = styled.div`
  ${rtl`
  .modal__container {
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(616)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(800)};
    }
    &-footer {
      background-color: ${baseVariables.color['merch50']};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
    }
    &_radio:is(${constants.RTL_LANG_LIST}) {
      padding-right: 0 !important;
      margin-right: 0 !important;
      label {
        ::before {
          left: unset;
        }
        ::after {
          left: ${toRem(45)};
          right: ${toRem(6)};
        }
        span {
          margin-right: ${toRem(28)};
        }
      }
    }
  }
  .modal-main-container {
    &__content__modalFooter {
      background-color: ${baseVariables.color.base20};
      height: ${toRem(134)} !important;
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      @media ${baseVariables.mediaQuery.md} {
        height: ${toRem(84)} !important;
        justify-content: unset;
      }

      &__clear-btn {
        margin-left: ${toRem(28)};
        @media ${baseVariables.mediaQuery.md} {
          margin-left: ${toRem(0)};
        }
        :is(${constants.RTL_LANG_LIST}){
          margin-right: ${toRem(28)};
          @media ${baseVariables.mediaQuery.md} {
            margin-right: ${toRem(0)};
          }
        }
      }
    }
  }
  .apply-button {
    margin-left: ${toRem(60)};
    width: auto;
    @media ${baseVariables.mediaQuery.md} {
      margin-left: ${toRem(40)};
      margin-right: ${toRem(8)};
    }
    :is(${constants.RTL_LANG_LIST}) {
      margin-right: ${toRem(32)} !important;
    }
  }

  .brand-logo-container:hover {
    background-color: ${baseVariables.color.neutral10};
  }

  .m-standard-tab-list-item:first-child:is(${constants.RTL_LANG_LIST}) {
    margin-left: ${toRem(16)};
  }
  .consent-model_checkbox:is(${constants.RTL_LANG_LIST}) {
    label {
      ::before {
        left: unset;
        display: flex;
      }
      ::after {
        left: unset;
        right: ${toRem(6)};
      }
      h6 {
        padding-right: ${toRem(32)};
      }
    }
  }

  .accordion__container:is(${constants.RTL_LANG_LIST}) {
    .accordion__title {
      .icon-decorative {
        margin-right: unset !important;
        margin-left: ${toRem(16)};
      }
    }
    .accordion__icon {
      margin-left: unset !important;
    }
  }

  .m-input-field:is(${constants.RTL_LANG_LIST}) {
    input[type='text'] {
      padding-right: ${toRem(16)} !important;
    }
  }
  .experience-section:is(${constants.RTL_LANG_LIST}),
  .checkbox-feature_items:is(${constants.RTL_LANG_LIST}) {
    text-align: right !important;
    label {
      padding-right: ${toRem(24)};
      ::before {
        left: unset;
        right: 0;
        display: flex;
      }
      ::after {
        left: unset;
        right: ${toRem(6)};
        transform: rotateZ(45deg) !important;
      }
      h6 {
        padding-right: ${toRem(32)};
      }
    }
  }
  .experience-section:is(${constants.RTL_LANG_LIST}){
    label {
      padding-right: ${toRem(24)};
    }
  }
  .text_modal-align:is(${constants.RTL_LANG_LIST}) {
    text-align: right;
  }
`}
`;
