// Imports for external libraries go here.
import React, { FC, lazy, Suspense, useCallback } from 'react';
import clsx from 'clsx';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
import { AEMReactCompMap } from '@marriott/mi-ui-library';
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { usePageContext, USER_STATE_AUTHENTICATED } from '../../modules';
import { AccountStaticWrapperProps } from './AccountStaticWrapper.types';
import {
  StyledAccountStaticWrapper,
  StyledAccountWrapperChildContainer,
  StyledAccountWrapperParentContainer,
} from './AccountStaticWrapper.styles';

// Use named rather than default exports.
export const AccountStaticWrapper: FC<AccountStaticWrapperProps> = props => {
  /** break props */
  const {
    isAuthorMode,
    totalNumberOfCards,
    itemPath,
    pagePath,
    model,
    useVerticalStacking,
    allowedComponents,
    wrapperId,
    hideComponentOnLogin,
  } = props;

  const { userState } = usePageContext();
  // get the  mapping from allowed components in model json
  const mapper = AEMReactCompMap(allowedComponents);

  // not able to define types becuase of multiple component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updtePropsToHideTheButton = (itemProps: any) => {
    const itemObj = { ...itemProps };
    if (userState === USER_STATE_AUTHENTICATED) {
      /**
       * delete some keys fro model.json
       */
      delete itemObj?.primaryctalabel;
      delete itemObj?.primaryctalink;
      delete itemObj?.secondaryctalink;
      delete itemObj?.secondaryctalabel;

      return { ...itemObj };
    }
    return itemObj;
  };

  const authorComponentWrapper = (index: number) => {
    /** create parsys container in author mode
     * used to render the container inside the author mode
     */
    return (
      <div className={clsx('col-12')}>
        <ResponsiveGrid
          key={`reponsivegrid-account-static-${index}`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pagePath}
          itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
          gridClassNames={'col-12'}
          config={{
            isEmpty: () => true,
            resourceType: `mi-aem-common-spa/components/container`,
          }}
        />
      </div>
    );
  };
  const getItemsFromParsys = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (compItems: any) => {
      const parsys = compItems?.parsys;
      // eslint-disable-next-line no-prototype-builtins
      if (parsys?.hasOwnProperty(':items')) {
        /**
         * check if items is present
         */
        const parsysItems = parsys[':items'];
        const parsysItemsOrder = parsys[':itemsOrder']; // we might need to adjust order of display
        return parsysItemsOrder?.map((key: string) => {
          /**
           * loop on all the items present in the container
           */
          if (Object.prototype.hasOwnProperty.call(parsysItems, key)) {
            let itemProps = parsysItems[key];
            const itemName = itemProps[':type']?.split('/').pop();
            if (Object.prototype.hasOwnProperty.call(mapper, itemName)) {
              /** impor the dynamic common component */
              const innerComp = mapper[itemName];
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              let Component: any = <></>;
              if (itemName === 'herobanner') {
                // remove the keys to hide the button
                itemProps = {
                  /** reading props data as model.key so we are updating this for particular component */
                  model: updtePropsToHideTheButton(itemProps),
                };
                Component = lazy(() =>
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  import(`@marriott/mi-homepage-components`).then((mod: any) => ({
                    /**
                     * lazy load the dynamic components
                     */
                    default: mod.HeroBanner,
                  }))
                );
              } else {
                Component = lazy(() =>
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  import(`@marriott/mi-common-static-components`).then((mod: any) => ({
                    /**
                     * lazy load the dynamic components
                     */
                    default: mod[`${innerComp}`],
                  }))
                );
              }

              return (
                <StyledAccountWrapperChildContainer
                  key={`child-common-${key}`}
                  className={clsx('col-12', useVerticalStacking && 'col-sm-6')}
                >
                  {/* add error / loader */}
                  <Suspense fallback={<></>}>
                    {hideComponentOnLogin && userState === USER_STATE_AUTHENTICATED ? (
                      <></>
                    ) : (
                      <Component key={`child-comp-common-${key}`} {...itemProps} />
                    )}
                  </Suspense>
                </StyledAccountWrapperChildContainer>
              );
            }
            /**log error at this place */
            return null;
          }
          /** logs error at this place */
          return null;
        });
      }
      /**log error at this place */
      return null;
    },
    [userState]
  );

  const renderPublishedContent = () => {
    /**
     * render publish content on the basis of props passed to the wrapper
     */
    return (
      <StyledAccountWrapperParentContainer className={clsx(model?.styleClass)} data-test-id="publish-content-parent">
        <section className="row">{getItemsFromParsys(model?.cqItems)}</section>
      </StyledAccountWrapperParentContainer>
    );
  };
  return (
    <StyledAccountStaticWrapper
      data-testid="account-StaticWrapper"
      data-component-name="o-account-AccountStaticWrapper"
      key={`accounstatic-wrapper-${wrapperId}`}
      id={wrapperId}
      className={
        userState === USER_STATE_AUTHENTICATED && hideComponentOnLogin
          ? ''
          : model?.styleClass?.includes('container')
          ? 'container'
          : ''
      }
    >
      {/* render publish content for component */}
      {!isAuthorMode && renderPublishedContent()}
      {/* render authorable component */}
      {isAuthorMode && Array.from({ length: totalNumberOfCards?.length }, (_, i) => authorComponentWrapper(i))}
    </StyledAccountStaticWrapper>
  );
};
