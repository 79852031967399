/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

interface combineAccountsState {
  combineAccountFormFields: any;
  setCombineAccountFormFields: (data: any) => void;
}
const initialState: combineAccountsState = {
  combineAccountFormFields: {
    formFields: {
      firstName: '',
      lastName: '',
      bonvoyNumber: '',
      phoneNumber: '',
      second_firstName: '',
      second_lastName: '',
      second_bonvoyNumber: '',
      second_email: null,
      second_country: { id: '', value: '' },
      accountSelected: true,
      consentChecked: false,
    },
    errorState: {
      matchDetailsError: false,
      emailSent: false,
      isAllEmpty: false,
      firstName: false,
      firstNameRegExp: false,
      lastName: false,
      lastNameRegExp: false,
      memberNumber: false,
      email: false,
      phone: false,
      submitted: false,
    },
  },
  setCombineAccountFormFields: () => {},
};

const combineAccountsStore: StateCreator<combineAccountsState> = set => {
  return {
    ...initialState,
    setCombineAccountFormFields: (data: any) => {
      set({ combineAccountFormFields: data });
    },
  };
};

export const usecombineAccountsStore = createAppStore(combineAccountsStore, {
  usePersistentStore: false,
});
