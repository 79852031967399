import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledAccountTable = styled.div`
  .table-container {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      border-radius: ${toRem(14)};
    }
    table {
      table-layout: fixed;
      width: 100%;
      thead {
        background-color: ${baseVariables.color['merch50']};
        position: sticky;
        z-index: 1;
        .elite-header td {
          &:first-child {
            border-radius: ${toRem(14)};
          }
          &:last-child {
            border-radius: 0 ${toRem(14)} 0 0;
          }
        }
      }
    }
  }
  .table-header.sticky {
    box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
    background-color: ${baseVariables.color['merch50']};
    .elite-header td:last-child {
      border-radius: 0;
    }
  }
  .row-header-inline {
    display: table-row !important;
    @media ${baseVariables.mediaQuery.md} {
      display: none !important;
    }
  }
  .row-header-default {
    display: none !important;
    @media ${baseVariables.mediaQuery.md} {
      display: table-cell !important;
    }
  }
  .row-header-hidden {
    display: none !important;
    @media ${baseVariables.mediaQuery.md} {
      display: table-cell !important;
    }
  }
  td {
    word-wrap: break-word;
  }
  .row-header {
    @media ${baseVariables.mediaQuery.md} {
      width: ${toRem(240)};
    }
  }
  .header-cell {
    min-height: 100%;
    @media ${baseVariables.mediaQuery.md} {
      min-height: ${toRem(62)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      min-height: ${toRem(52)};
    }
  }
  .right-border {
    border-right: ${toRem(1)} solid ${baseVariables.color['neutral20']};
    &__column {
      @media ${baseVariables.mediaQuery.md} {
        border-right: ${toRem(1)} solid ${baseVariables.color['neutral20']};
      }
    }
  }
  .top-border {
    border-top: ${toRem(1)} solid ${baseVariables.color['neutral20']};
  }
  p {
    margin-bottom: 0;
  }
`;
