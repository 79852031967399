import { baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import { constants } from '../../modules';

export const StyledConsentForm = styled.div`
  ${rtl`
      .custom-scrollbar {
        //need this to access internal div of modal
        padding: 0;
      }
      .consent-form-footer {
        background-color: ${baseVariables.color['base20']};
        border-top-color: ${baseVariables.color['neutral30']};
      }

      &:is(${constants.RTL_LANG_LIST}) {
        text-align: right !important;
      }
  `}
`;
