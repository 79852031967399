// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const CancelUpgradeRequest = dynamic(() => import('./CancelUpgradeRequest').then(mod => mod.CancelUpgradeRequest));
const CancelUpgradeRequestV2 = dynamic(() =>
  import('./CancelUpgradeRequestV2').then(mod => mod.CancelUpgradeRequestV2)
);

const CancelUpgradeRequestConfig = {
  emptyLabel: 'cancelupgraderequest',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/cancelupgraderequest`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CancelUpgradeRequestEditable = (props: any) => {
  return (
    <EditableComponent config={CancelUpgradeRequestConfig} {...props}>
      {props?.isDTT ? <CancelUpgradeRequestV2 {...props} /> : <CancelUpgradeRequest {...props} />}
    </EditableComponent>
  );
};
