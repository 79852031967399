import { PointsDetailsStateDtt } from './CancelReservation.types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mapper = require('object-mapper');

const transformTemplate = {
  orderById: {
    key: 'orderById',
  },
};

export const getOrderLineItemTransform = (data: { orderById: PointsDetailsStateDtt }) => {
  return mapper(data, transformTemplate);
};
