// Imports for external libraries go here.
import React, { FC, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { Heading, Types, Text, RichText, Button, Link } from '@marriott/mi-ui-library';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { addClassToBlankTargetLinks, addSubDirectoryPrefix, scrollToClass } from '../../modules/utils/helper';
import { numberWithCommas } from '../../modules/utils/numericalFormatsHelper';
import { PageContext, verifyPointsUrl, handleRedirect } from '../../modules';
import { ENCRYPTED_VERIFY_DATA, POINTS_TRANSFER_ID } from '../../modules/utils/constants';
import { usePointsTransferStore } from '../../modules/store/pointsTransferStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { updatePointsTransferDataLayer } from '../../organisms/PointsTransfer/PointsTransferUtils/PointsTransferHelper';
import { CustomError } from '../../organisms/PointsTransfer/PointsToMiles/PointsToMiles.types';
import { VerifyOrSuccessTransferProps, TransferPointsStateDetails } from './VerifyOrSuccessTransferPoints.types';
import { StyledVerifyTransferPoints } from './VerifyOrSuccessTransferPoints.styles';

// Use named rather than default exports.
export const VerifyOrSuccessTransferPoints: FC<VerifyOrSuccessTransferProps> = props => {
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { setBannerId } = useBannerMessagesStore();
  const {
    transferPointsDetails,
    setVerificationPointsFailed,
    setVerificationPointsSuccess,
    setTransferPointsDetails,
    setIsResetState,
    setErrorKey,
  } = usePointsTransferStore(store => store);
  const {
    currentPointBalance: currentPointsBalance,
    setCurrentPointsBalance,
    setMemberData,
  } = useAccountPersistentStore(state => state);
  const [isLoading, setIsLoading] = useState(false);
  const { partnerName, partnerNumber, pointsToTransfer, partnerConfig, isVerifyConvertPoints } =
    props?.transferPointsInfo || {};
  const [transferPoints, setTransferPoints] = useState<TransferPointsStateDetails>();

  useEffect(() => {
    if (props?.isVerifySuccess) {
      if (currentPointsBalance) {
        setCurrentPointsBalance?.(currentPointsBalance - Number(transferPointsDetails?.pointsToTransfer));
        setMemberData?.(null);
      }
      updatePointsTransferDataLayer(transferPointsDetails?.partnerConfig?.dataLayerName ?? '', true);
      sessionStorage.removeItem('pointsTransferState');
    }
    scrollToClass();
  }, []);

  useEffect(() => {
    setTransferPoints({
      transferPointsDetails: transferPointsDetails,
      currentPointsBalance: currentPointsBalance,
      isVerificationPointsSuccess: props?.isVerifySuccess || false,
    });
  }, [transferPointsDetails, currentPointsBalance, props?.isVerifySuccess]);

  const resetStates = (
    isVerifyConvertPoints: boolean = false,
    isVerifyPointsSuccess: boolean = false,
    isVerifyPointsFailed: boolean = false,
    isRedirect: boolean = false
  ) => {
    if (!isRedirect) {
      props?.setTransferPointsInfo({
        partnerId: '',
        partnerName: '',
        partnerNumber: '',
        pointsToTransfer: '',
        isVerifyConvertPoints: isVerifyConvertPoints,
        partnerConfig: undefined,
      });
    }
    setVerificationPointsSuccess(isVerifyPointsSuccess);
    setVerificationPointsFailed(isVerifyPointsFailed);
  };

  const handleReturnToManagePoints = () => {
    if (props?.isVerifySuccess && props?.returnToManagePointsCtaPath) {
      sessionStorage.removeItem('pointsTransferState');
      handleRedirect(props?.returnToManagePointsCtaPath);
    } else {
      resetStates(false, false, false);
      setIsResetState(true);
      setTransferPointsDetails({
        partnerId: '',
        partnerName: '',
        partnerNumber: '',
        pointsToTransfer: '',
        typeOfPointsTransfer: '',
        partnerConfig: undefined,
      });
      setErrorKey('');
    }
  };

  const handlePointsSubmission = async () => {
    const pointsDetails = {
      partnerId: props?.transferPointsInfo?.partnerId,
      partnerName: props?.transferPointsInfo?.partnerName,
      partnerNumber: props?.transferPointsInfo?.partnerNumber,
      pointsToTransfer: props?.transferPointsInfo?.pointsToTransfer,
      typeOfPointsTransfer: props?.typeOfPointsTransfer,
      partnerConfig: props?.transferPointsInfo?.partnerConfig,
    };
    const payload = {
      partnerCode: props?.transferPointsInfo?.partnerId,
      partnerCurrency: props?.transferPointsInfo?.partnerConfig?.partnerCurrency,
      pointsToTransfer: Number(props?.transferPointsInfo?.pointsToTransfer),
      partnerAccountNumber: props?.transferPointsInfo?.partnerNumber,
    };
    try {
      setErrorKey('');
      setIsLoading(true);
      const response = await axios.post(addSubDirectoryPrefix(verifyPointsUrl), payload, {
        headers: {
          'Content-Type': 'application/json',
          Cookie: 'sessionID=' + sessionData?.sessionToken,
        },
      });
      if (response?.data?.isRedirect) {
        setBannerId(POINTS_TRANSFER_ID);
        setTransferPointsDetails(pointsDetails);
        sessionStorage.setItem(ENCRYPTED_VERIFY_DATA, response?.data?.encodePayload);
        const { nextStateURI } = response.data;
        handleRedirect(
          nextStateURI + `${nextStateURI?.includes('?') ? '&' : '?'}returnTo=${window?.location?.pathname}` // to redirect user back to respective points transfer page
        );
      } else {
        setTransferPointsDetails(pointsDetails);
        resetStates(false, true, false, true);
        window.location.reload(); // adding page reload to show updated points in the Global header for WEB-89313
      }
    } catch (error) {
      setTransferPointsDetails(pointsDetails);
      const errorMessage = (error as CustomError)?.response?.data?.phoenixErrorMessages?.errorMessages?.[0];
      if (errorMessage) {
        setErrorKey(errorMessage);
        resetStates(false, false, false);
      } else {
        resetStates(false, false, true);
      }
      setIsLoading(false);
    }
  };

  return (
    <StyledVerifyTransferPoints
      data-component-name="m-account-VerifyOrSuccessTransferPoints"
      data-testid="account-VerifyOrSuccessTransferPoints"
    >
      <Heading
        customClass={'pb-3 pb-lg-4'}
        titleText={props?.headerLabel}
        variation={Types.headingType.title}
        fontSize={Types.size.small}
      />
      {props?.isVerifySuccess ? (
        <RichText text={props?.subHeaderLabel} customClass={'pb-4 t-font-m'} componentId={'success-subheader'} />
      ) : (
        <Text
          customClass={'pb-5 pb-md-4 t-font-m'}
          copyText={props?.subHeaderLabel}
          fontSize={Types.size.medium}
          element={Types.tags.paragraph}
        />
      )}
      <div className="table-container pt-0 pt-lg-4 pb-0 pb-lg-2">
        <Heading
          customClass={'table-container__header d-flex py-4 px-4 px-lg-5'}
          element={Types.tags.span}
          titleText={props?.sectionHeaderLabel}
          variation={Types.headingType.subtitle}
          fontSize={Types.size.extraLarge}
        />
        <div className="table-container__section d-flex flex-column px-3 p-md-5">
          <div className="row">
            <div className="col-6">
              <Heading
                customClass={'table-container__section__label'}
                titleText={props?.accountNameLabel}
                fontSize={Types.size.medium}
                element={Types.tags.span}
                variation={Types.headingType.subtitle}
              />
            </div>
            <div className="col-6">
              <Text
                copyText={transferPoints?.transferPointsDetails?.partnerName || partnerName}
                fontSize={Types.size.medium}
                element={Types.tags.paragraph}
                customClass={'t-subtitle-m table-container__section__value'}
              />
            </div>
          </div>
          {!(isVerifyConvertPoints
            ? partnerConfig?.accountLinkingCheck
            : transferPoints?.transferPointsDetails?.partnerConfig?.accountLinkingCheck) && (
            <div className="row">
              <div className="col-6">
                <Heading
                  customClass={'table-container__section__label'}
                  titleText={props?.accountNumberLabel}
                  fontSize={Types.size.medium}
                  element={Types.tags.span}
                  variation={Types.headingType.subtitle}
                />
              </div>
              <div className="col-6">
                <Text
                  copyText={transferPoints?.transferPointsDetails?.partnerNumber || partnerNumber}
                  fontSize={Types.size.medium}
                  element={Types.tags.paragraph}
                  customClass={'t-subtitle-m table-container__section__value'}
                />
              </div>
            </div>
          )}
          <div className="row table-container__section__amount-section pt-4">
            <div className="col-6">
              <Text
                copyText={props?.isVerifySuccess ? props?.amountTransferred : props?.amountToTransferLabel}
                fontSize={Types.size.extraLarge}
                element={Types.tags.paragraph}
                customClass={'t-subtitle-xl table-container__section__label'}
              />
            </div>
            <div className="col-6">
              <Text
                copyText={
                  transferPoints?.transferPointsDetails?.pointsToTransfer || pointsToTransfer
                    ? `${numberWithCommas(
                        transferPoints?.transferPointsDetails?.pointsToTransfer || pointsToTransfer
                      )} ${props?.pointsLabel}`
                    : ''
                }
                fontSize={Types.size.extraLarge}
                element={Types.tags.paragraph}
                customClass={'t-subtitle-l table-container__section__value'}
              />
            </div>
          </div>
          {props?.isVerifySuccess && (
            <div className="row table-container__section__amount-section pt-4">
              <div className="col-6">
                <Heading
                  customClass={'table-container__section__label'}
                  titleText={props?.newBalanceLabel}
                  fontSize={Types.size.medium}
                  element={Types.tags.span}
                  variation={Types.headingType.subtitle}
                />
              </div>
              <div className="col-6">
                <Text
                  copyText={
                    transferPoints?.currentPointsBalance
                      ? `${numberWithCommas(transferPoints?.currentPointsBalance)} ${props?.pointsLabel}`
                      : ''
                  }
                  fontSize={Types.size.extraLarge}
                  element={Types.tags.paragraph}
                  customClass={'t-subtitle-m table-container__section__value'}
                />
              </div>
            </div>
          )}
        </div>
        {props?.termsDescription && (
          <RichText
            text={addClassToBlankTargetLinks(props?.termsDescription)}
            customClass="pt-5 t-font-s pb-2"
            componentId={'terms-and-description'}
          />
        )}
      </div>
      <div
        className={clsx('col-12 d-flex align-items-center flex-column flex-md-row mt-5 px-0 space-bottom', {
          'pt-3': props?.isVerifySuccess,
        })}
      >
        {props?.isVerifySuccess ? (
          <Button
            ariaLabel="gotoaccount_btn"
            testId="gotoaccount_btn"
            className="m-button-m m-button-secondary transfernow_btn d-flex justify-content-center"
            buttonCopy={props?.goToAccountLabel}
            href={props?.goToMyAccountCtaPath}
            isLink={true}
          />
        ) : (
          <Button
            ariaLabel="transfernow_btn"
            testId="transfernow_btn"
            className={'m-button-m m-button-primary transfernow_btn'}
            isDisabled={isLoading}
            type={Types.ButtonTypeVariation.Button}
            callback={handlePointsSubmission}
          >
            {isLoading ? <div className="m-spinner-s" data-testid="verify-spinner"></div> : props?.buttonLabel}
          </Button>
        )}
        <Link
          callback={handleReturnToManagePoints}
          text={props?.linkLabel}
          linkClassName="m-link-action mt-4 mt-md-0 ml-md-5 go-back"
          linkHref={'#'}
          target="_self"
          linkType="internal"
        />
      </div>
    </StyledVerifyTransferPoints>
  );
};
