import { FC, useContext } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { CobrandCardHorizontalFlexible, Types } from '@marriott/mi-ui-library';
import { TargetBannerProps } from './TargetBanner.types';
import { StyledTargetBanner } from './TargetBanner.styles';
import { PageContext } from '../../modules';

export const TargetBanner: FC<TargetBannerProps> = props => {
  const pageContext = useContext(PageContext);
  const currentLocale = pageContext?.currentLocale ?? 'en_US';
  const serverENV = pageContext?.serverENV ? JSON.parse(pageContext?.serverENV) : {};
  const isLocaleInList =
    serverENV?.COBRAND_LOCALE?.replace(/-/g, '_').includes(currentLocale) ||
    serverENV?.COBRAND_LOCALE?.replace(/_/g, '-').includes(currentLocale) ||
    currentLocale === 'en_US';
  const bannerStyleClass = `${
    props?.model?.bannerType === 'non-math' ? 'for-target-non-math' : 'for-target-evergreen cobrand-evergreen'
  } ${(props?.model?.hideBanner || !isLocaleInList) && ' hide-for-target'} cobrand-banner color-scheme7'`;
  return (
    <StyledTargetBanner
      className="container py-0"
      data-testid="cobrandcardhorizontalflexible"
      data-component-name="o-account-cobrandcardhorizontalflexible"
    >
      <CobrandCardHorizontalFlexible
        openInNewTab={props?.model?.openInNewTab}
        openInNewWindowAriaLabel={props?.model?.openInNewTab ? 'open in new window' : 'open in same window'}
        ctaLink={props?.model?.ctaLink}
        ctaLinkText={props?.model?.ctaLinkText}
        header={props?.model?.header}
        headerTag={Types.tags.h2}
        descriptionText={props?.model?.descriptionText}
        descriptionTextMobile={props?.model?.descriptionTextMobile}
        trackingProperties={props?.model?.trackingProperties}
        styleclass={bannerStyleClass}
        dynamicMedia={props?.model?.dynamicMedia}
        componentId={props?.model?.componentId}
        ctaType={props?.model?.ctaType}
        customImageClass="target-banner-image"
      />
    </StyledTargetBanner>
  );
};

export const TargetBannerConfig = {
  emptyLabel: 'savedHotelsDetails',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/savedHotelsDetails`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TargetBannerEditable = (props: any) => (
  <EditableComponent config={TargetBannerConfig} {...props}>
    <TargetBanner {...props} />
  </EditableComponent>
);
