/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

export interface RandData {
  firstName: string;
  lastName: string;
  orderId: string;
  checkInDate: string;
}

interface AccountCommonState {
  randData?: RandData;
  setRandData: (data: RandData) => void;
  showReportMissingStay: boolean;
  setShowReportMissingStay: (data: boolean) => void;
}
const initialState: AccountCommonState = {
  randData: undefined,
  setRandData: () => {},
  showReportMissingStay: true,
  setShowReportMissingStay: () => {},
};

const accountCommonStore: StateCreator<AccountCommonState> = set => {
  return {
    ...initialState,
    setRandData: (data: RandData) => {
      set({ randData: { ...data } });
    },
    setShowReportMissingStay: (data: any) => {
      set({ showReportMissingStay: data });
    },
  };
};

export const useAccountCommonStore = createAppStore(accountCommonStore, {
  usePersistentStore: false,
});
