/* eslint-disable */

// Queries
export const phoenixAccountGetSavedPropertiesByCustomerId = require('./queries/phoenixAccountGetSavedPropertiesByCustomerId.graphql');
export const phoenixAccountGetOrdersByCustomerId = require('./queries/phoenixAccountGetOrdersByCustomerId.graphql');
export const phoenixAccountGetMemberStatusDetails = require('./queries/phoenixAccountGetMemberStatusDetails.graphql');
export const phoenixAccountGetMyActivityTable = require('./queries/phoenixAccountGetMyActivityTable.graphql');
export const phoenixAccountGetMyActivityRewardsEarned = require('./queries/phoenixAccountGetMyActivityRewardsEarned.graphql');
export const phoenixAccountSuggestedPlacesBySearchStringProperty = require('./queries/phoenixAccountSuggestedPlacesBySearchStringProperty.graphql');
export const phoenixAccountGetOrderDetailsByOrderId = require('./queries/phoenixAccountGetOrderDetailsByOrderId.graphql');
export const phoenixAccountGetUpcomingTripsOfCustomer = require('./queries/phoenixAccountGetUpcomingTripsOfCustomer.graphql');
export const phoenixAccountGetUpcomingTripsBasicInfo = require('./queries/phoenixAccountGetUpcomingTripsBasicInfo.graphql');
export const phoenixAccountCancelledTripsBasicInfo = require('./queries/phoenixAccountCancelledTripsBasicInfo.graphql');
export const phoenixAccountGetAllPromotionsByCustomerId = require('./queries/phoenixAccountGetAllPromotionsByCustomerId.graphql');
export const phoenixAccountGetGlobalPromotionDetail = require('./queries/phoenixAccountGetGlobalPromotionDetailForGuest.graphql');
export const phoenixAccountGetLoyaltyPromotionDetail = require('./queries/phoenixAccountGetLoyaltyPromotionDetail.graphql');
export const phoenixAccountGetConsentsByCustomerId = require('./queries/phoenixAccountGetConsentsByCustomerId.graphql');
export const phoenixAccountGetAddressByCustomerId = require('./queries/phoenixAccountGetAddressByCustomerId.graphql');
export const phoenixAccountGetMemberNightDetails = require('./queries/phoenixAccountGetMemberNightDetails.graphql');
export const phoenixAccountOrderLineItem = require('./queries/phoenixAccountOrderLineItem.graphql');
export const phoenixAccountGetMemberAddressAndCommunicationOptions = require('./queries/phoenixAccountGetMemberAddressAndCommunicationOptions.graphql');
export const phoenixAccountGetCustomerPreferenceFeatures = require('./queries/phoenixAccountGetCustomerPreferenceFeatures.graphql');
export const phoenixAccountGetMFAAuthentication = require('./queries/phoenixAccountGetMFAAuthentication.graphql');
export const phoenixAccountGetPersonalInformation = require('./queries/phoenixAccountGetPersonalInformation.graphql');
export const phoenixAccountGetAmbassadorInfo = require('./queries/phoenixAccountGetAmbassadorInfo.graphql');
export const phoenixAccountGetSuiteNightAwardsInfo = require('./queries/phoenixAccountGetSuiteNightAwardsInfo.graphql');
export const phoenixAccountGetWalletPaymentInfo = require('./queries/phoenixAccountGetWalletPaymentInfo.graphql');
export const phoenixAccountGetCustomerCountryCode = require('./queries/phoenixAccountGetCustomerCountryCode.graphql');
export const phoenixAccountGetCustomerAmbassadorElitePreferences = require('./queries/phoenixAccountGetCustomerAmbassadorElitePreferences.graphql');
export const phoenixAccountTransliterate = require('./queries/phoenixAccountTransliterate.graphql');
export const phoenixAccountGetAwardsRequests = require('./queries/phoenixAccountGetAwardsRequests.graphql');
export const phoenixAccountGetuserCurrentPointBalance = require('./queries/phoenixAccountGetuserCurrentPointBalance.graphql');
export const phoenixAccountGetRoomPreferences = require('./queries/phoenixAccountGetRoomPreferences.graphql');
export const phoenixAccountGetEarningsAndRewards = require('./queries/phoenixAccountGetEarningsAndRewards.graphql');
export const phoenixAccountAwardsRequestsCount = require('./queries/phoenixAccountAwardsRequestsCount.graphql');
export const phoenixAccountLookupCustomerProfiles = require('./queries/phoenixAccountLookupCustomerProfiles.graphql');
export const phoenixAccountGetAirlineTransactionDetail = require('./queries/phoenixAccountGetAirlineTransactionDetail.graphql');
export const phoenixAccountGetRelationshipList = require('./queries/phoenixAccountGetRelationshipList.graphql');
export const phoenixAccountGetCustomerPreferenceFeaturesAndCommunicationOptions = require('./queries/phoenixAccountGetCustomerPreferenceFeaturesAndCommunicationOptions.graphql');

// Mutations
export const phoenixAccountUpdateCustomerSavedProperties = require('./mutations/phoenixAccountUpdateCustomerSavedProperties.graphql');
export const phoenixAccountRegisterPromotion = require('./mutations/phoenixAccountRegisterPromotion.graphql');
export const phoenixAccountUpdateCustomerConsents = require('./mutations/phoenixAccountUpdateCustomerConsents.graphql');
export const phoenixAccountAddCustomerFeaturesConsent = require('./mutations/phoenixAccountAddCustomerFeaturesConsent.graphql');
export const phoenixAccountGetProfileDetails = require('./queries/phoenixAccountGetProfileDetails.graphql');
export const phoenixAccountAddCustomerPreferences = require('./mutations/phoenixAccountAddCustomerPreferences.graphql');
export const phoenixAccountAddCustomerPreferredLanguage = require('./mutations/phoenixAccountAddCustomerPreferredLanguage.graphql');
export const phoenixAccountDeleteCustomerPaymentMethod = require('./mutations/phoenixAccountDeleteCustomerPaymentMethod.graphql');
export const phoenixAccountAddAmbassadorElitePreferences = require('./mutations/phoenixAccountAddAmbassadorElitePreferences.graphql');
export const phoenixAccountUpdateCustomerPaymentMethod = require('./mutations/phoenixAccountUpdateCustomerPaymentMethod.graphql');
export const phoenixAccountAddRoomPreferences = require('./mutations/phoenixAccountAddRoomPreferences.graphql');
export const phoenixAccountUpdateEarningAndRewardsMethod = require('./mutations/phoenixAccountUpdateEarningAndRewardsMethod.graphql');
export const phoenixAccountUpdateEmailSubscription = require('./mutations/phoenixAccountUpdateEmailSubscription.graphql');
export const phoenixAccountUpdateCustomerPreferenceFeaturesAndCommunicationOptions = require('./mutations/phoenixAccountUpdateCustomerPreferenceFeaturesAndCommunicationOptions.graphql');
