import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

export const ExtendedFooterStyle = styled.div`
  ${rtl`
  .extended-footer {
    background-color: ${baseVariables.color['neutral10']};
  }
  `}
`;
