// Imports for external libraries go here.
import React, { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { Icon, DropDownModal, getEventKeyTypes, Types, Label } from '@marriott/mi-ui-library';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { DropdownOption, DropdownProps } from './Dropdown.types';
import { StyledDropdown } from './Dropdown.styles';

let searchString = '';
let searchIndex = 0;

// Use named rather than default exports.
export const Dropdown: FC<DropdownProps> = ({
  defaultOption,
  dropdownOptions,
  onChange,
  customClassName,
  dropdownId,
  labelSize = Types.size.small,
  valueCustomClass,
  isIconChange = true,
  hasLabel = '',
  id,
  isDisabled = false,
  showIdandValueinOptions = false,
  hasError = false,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [mouseClickEvent, setMouseClickEvent] = useState(true); // to hide border on option when click is triggered using mouse
  const [activeIndex, setActiveIndex] = useState(() => {
    const index =
      dropdownOptions && dropdownOptions.length
        ? dropdownOptions.findIndex(option => option.value?.toLowerCase() === defaultOption?.toLowerCase())
        : 0;
    return index > -1 ? index : 0;
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownOptionsRef = useRef<HTMLUListElement>(null);
  const [focusedId, setFocusedId] = useState(activeIndex > -1 ? dropdownOptions[activeIndex]?.id : '');

  const getSearchString = function (char: string) {
    // reset typing timeout and start new timeout
    // this allows us to make multiple-letter matches, like a native select

    debounce(() => {
      searchString = '';
    }, 500)();
    // add most recent letter to saved search string
    searchString += char;
    return searchString;
  };

  // filter an array of options against an input string
  // returns an array of options that begin with the filter string, case-independent
  const filterOptions = (
    options: Array<DropdownOption> = [],
    filter: string,
    exclude: Array<string> = []
  ): DropdownOption[] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return options.filter((option: any) => {
      const matches = option.value.toLowerCase().indexOf(filter.toLowerCase()) === 0;
      return matches && exclude.indexOf(option?.value) < 0;
    });
  };

  const getIndexByLetter = (options: DropdownOption[], filter: string, startIndex: number): number => {
    const orderedOptions: DropdownOption[] = [...options.slice(startIndex), ...options.slice(0, startIndex)];
    const firstMatch: DropdownOption = filterOptions(orderedOptions, filter)[0];
    const allSameLetter = (array: string[]) => array.every((letter: string) => letter === array[0]);
    // first check if there is an exact match for the typed string
    if (firstMatch) {
      return options.indexOf(firstMatch);
    }

    // if the same letter is being repeated, cycle through first-letter matches
    else if (allSameLetter(filter.split(''))) {
      const matches: DropdownOption[] = filterOptions(orderedOptions, filter[0]);
      return options.indexOf(matches[0]);
    }

    // if no matches, return -1
    else {
      return -1;
    }
  };

  const onComboType = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    const searchString: string = getSearchString(e.key);
    const currentIndex =
      dropdownOptions && dropdownOptions.length
        ? dropdownOptions.findIndex(option => option.id?.toLowerCase() === focusedId?.toLowerCase())
        : 0;
    if (searchIndex > 0) {
      searchIndex = getIndexByLetter(dropdownOptions, searchString, searchIndex + 1);
    }
    if (currentIndex > 0) {
      searchIndex = getIndexByLetter(dropdownOptions, searchString, currentIndex + 1);
    } else {
      if (focusedId) {
        searchIndex = getIndexByLetter(dropdownOptions, searchString, searchIndex + 1);
      }
      searchIndex = getIndexByLetter(dropdownOptions, searchString, searchIndex);
    }
    if (searchIndex >= 0) {
      onOptionChange(searchIndex, e);
    }
  };

  const isScrollable = (element: { clientHeight: number; scrollHeight: number }): boolean => {
    return element && element.clientHeight <= element.scrollHeight;
  };
  // ensure a given child element is within the parent's visible scroll area
  // if the child is not visible, scroll the parent
  const maintainScrollVisibility = (activeElement: HTMLElement, scrollParent: HTMLElement): void => {
    const { offsetHeight, offsetTop } = activeElement;
    const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;
    const isAbove = offsetTop < scrollTop;
    const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;
    if (isAbove) {
      scrollParent.scrollTo(0, offsetTop);
    } else if (isBelow) {
      scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
    }
  };

  const onOptionChange = function (index: number, e: React.KeyboardEvent<HTMLDivElement>): void {
    searchIndex = index;
    const listbox: HTMLElement | null = e.currentTarget.querySelector('[aria-labelledby=container]');
    // update aria-activedescendant
    dropdownOptionsRef?.current?.setAttribute('aria-activedescendant', `${searchIndex}`);

    // update active option styles
    const options: NodeListOf<HTMLUListElement> = e.currentTarget.querySelectorAll('[role=option]');
    options.forEach(optionEl => {
      optionEl.classList.remove('option-current');
    });
    options[searchIndex].classList.add('option-current');

    // ensure the new option is in view
    if (isScrollable(listbox as HTMLElement)) {
      maintainScrollVisibility(options[searchIndex], listbox as HTMLElement);
    }

    options[searchIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    setFocusedId(dropdownOptions[searchIndex]?.id);
    setActiveIndex(searchIndex);
  };

  useEffect(() => {
    if (openDropdown) {
      dropdownOptionsRef.current && dropdownOptionsRef?.current?.focus();
      const currentIndex =
        dropdownOptions && dropdownOptions.length
          ? defaultOption
            ? dropdownOptions.findIndex(option => option.value?.toLowerCase() === defaultOption?.toLowerCase())
            : dropdownOptions.findIndex(option => option.id?.toLowerCase() === focusedId?.toLowerCase())
          : 0;
      setActiveIndex(currentIndex); // to set active index and scroll to the selected option
      dropdownRef?.current?.setAttribute('aria-controls', 'listbox1');
      dropdownRef?.current?.setAttribute('aria-activedescendant', `${defaultOption}`);
    } else {
      setActiveIndex(0); // reset active index on close
      dropdownRef?.current?.setAttribute('aria-controls', 'dropdown-selected-value' + dropdownId);
      dropdownRef?.current?.removeAttribute('aria-activedescendant');
    }
  }, [openDropdown]);

  const handleButtonClick = () => {
    setMouseClickEvent(true);
    setOpenDropdown(!openDropdown);
  };

  // handle click or enter event on the option
  const handleOptionSelect = (selectedOption: DropdownOption, isMouseClick: boolean) => {
    setOpenDropdown(false);
    onChange(selectedOption);
    setFocusedId(selectedOption.id);
    setMouseClickEvent(isMouseClick);
  };
  const handleFocusOut = (event: React.FocusEvent<HTMLElement>) => {
    if (dropdownRef.current && !dropdownRef?.current?.contains(event.relatedTarget)) {
      setOpenDropdown(false);
    }
  };

  //keydown events on dropdown
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (getEventKeyTypes(e)?.isEnterKey) {
      setMouseClickEvent(false);
      setOpenDropdown(!openDropdown); // Toggle the dropdown when Enter is pressed
    } else if (getEventKeyTypes(e)?.isTabKey || getEventKeyTypes(e)?.isEscapseKey) {
      setOpenDropdown(false); // Close the dropdown when Tab is pressed
    } else if (getEventKeyTypes(e)?.isDownArrowKey) {
      dropdownOptionsRef.current && dropdownOptionsRef?.current?.focus();
    } else {
      onComboType(e);
    }
  };

  // Scroll the selected option into view when it changes
  useEffect(() => {
    if (activeIndex !== null) {
      const optionElement = dropdownOptionsRef?.current?.children[activeIndex];
      if (optionElement) {
        optionElement.scrollIntoView &&
          optionElement.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
      }
    }
  }, [activeIndex]);

  // ul li list on keydown event
  const handleOptionKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    const currentIndex = activeIndex;
    setMouseClickEvent(false);
    if (getEventKeyTypes(e)?.isDownArrowKey) {
      e.preventDefault();
      if (currentIndex === dropdownOptions.length - 1) {
        setActiveIndex(1);
        setFocusedId(dropdownOptions[0].id);
      } else {
        setActiveIndex(currentIndex + 1);
        setFocusedId(dropdownOptions[currentIndex + 1].id);
      }
    } else if (getEventKeyTypes(e)?.isUpArrowKey) {
      e.preventDefault();
      if (currentIndex <= 0) {
        setActiveIndex(dropdownOptions.length - 1);
        setFocusedId(dropdownOptions[dropdownOptions.length - 1].id);
      } else {
        setActiveIndex(currentIndex - 1);
        setFocusedId(dropdownOptions[currentIndex - 1].id);
      }
    } else if (getEventKeyTypes(e)?.isEnterKey) {
      setActiveIndex(currentIndex);
      if (currentIndex < 0) {
        setOpenDropdown(false);
      } else {
        handleOptionSelect(dropdownOptions[currentIndex], false);
      }
      dropdownRef.current && dropdownRef.current.focus(); // shifts focus from options to div on collapse
    }
  };

  const renderDropdownOptions = () => {
    return (
      <ul
        className="dropdown__container--list d-flex flex-column p-0 pt-2 pb-2 my-2"
        ref={dropdownOptionsRef}
        role="listbox"
        tabIndex={-1}
        onKeyDown={handleOptionKeyDown}
        aria-expanded={openDropdown}
        aria-activedescendant={`option-${activeIndex}`}
        id={`listbox1`}
      >
        {dropdownOptions?.map((option, index) => (
          <li
            className={clsx(
              'dropdown__container--list--option text-left py-2 ',
              defaultOption?.toLowerCase() === option?.value?.toLowerCase() ? 'selected' : '',
              focusedId === option.id && !mouseClickEvent ? 'focused' : '',
              showIdandValueinOptions ? ' pl-1 pr-3 d-flex justify-content-between ' : 'px-4 '
            )}
            role="option"
            key={index}
            onClick={() => handleOptionSelect(option, true)}
            aria-selected={defaultOption === option.value || 'false'}
            id={`option-${index}`}
            tabIndex={focusedId && focusedId === option.id ? 0 : 1}
          >
            {showIdandValueinOptions && <span className="dropdown-option-value"> {option.name}</span>}
            {option.value}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <StyledDropdown id={id} data-component-name="m-account-Dropdown" data-testid="account-Dropdown">
      {hasLabel && <Label labelText={hasLabel} size={Types.size.extraSmall} customClass="dropdown__label" />}
      <div
        className={clsx(
          'dropdown text-left px-3 py-2',
          isDisabled ? 'dropdown__disable' : 'dropdown__enable',
          hasError && 'dropdown__error',
          hasLabel ? 'dropdown-labeled' : `t-font-${labelSize}`,
          customClassName ?? ''
        )}
        ref={dropdownRef}
        onBlur={handleFocusOut}
        onKeyDown={handleKeyDown}
        aria-expanded={openDropdown}
        aria-haspopup="listbox"
        aria-label={dropdownId ? 'dropdown ' + dropdownId : 'dropdown-label'}
        id={dropdownId ? 'dropdown' + dropdownId : 'dropdown'}
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="combobox"
        tabIndex={0}
        onClick={handleButtonClick}
      >
        <div
          aria-label={`${defaultOption} selected`}
          role="term"
          className={clsx(
            'dropdown-value text-nowrap',
            isDisabled && 'option-disabled',
            valueCustomClass,
            hasLabel && 'dropdown__label--text'
          )}
          id={'dropdown-selected-value' + dropdownId}
          data-testid={`${defaultOption}`}
        >
          <span className={clsx(valueCustomClass && 'dropdown-option-value')}>{defaultOption}</span>
          <Icon
            aria-label="dropdown-icon"
            iconClass={clsx(isIconChange && openDropdown ? 'icon-dropdown-up' : 'icon-dropdown-down')}
          />
        </div>
        {openDropdown && (
          <DropDownModal
            children={renderDropdownOptions()}
            className={clsx('dropdown__container', showIdandValueinOptions && 'dropdown-showBothOptions')}
            show={openDropdown}
            labelledBy={'container'}
            mobileTakeOver={false}
            handleBlur={false} // Prevent the modal from closing on blur, we handle blur ourselves
            scrollDownModalCloseState={false}
            setDropdownModalOpenState={setOpenDropdown}
            dropdownModalOpenState={openDropdown}
            dropdownModalOnCLoseFunc={() => {
              setOpenDropdown(false);
            }}
          />
        )}
      </div>
    </StyledDropdown>
  );
};
