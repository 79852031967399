import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledNightsProgress = styled.div<{
  awardvalue: boolean;
  isNightsModelOpen: boolean;
  isNonMember: boolean;
}>`
  .meterText {
    position: relative;
    bottom: ${toRem(1)};
    top: ${props =>
      props?.isNightsModelOpen
        ? toRem(-16)
        : props?.awardvalue
        ? props?.isNonMember
          ? toRem(-15)
          : toRem(-30)
        : toRem(-25)};

    width: 100%;
    text-align: center;
    margin-top: -23%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__icon {
      font-size: ${toRem(72)} !important;
      color: ${baseVariables.color.base20};
      &-member {
        font-size: ${toRem(72)} !important;
        color: ${baseVariables.color['bvyAccent10']};
      }
    }
    &__inverse {
      color: ${baseVariables.color.base20};
    }

    @media ${baseVariables.mediaQuery.md} {
      top: ${props =>
        props?.isNightsModelOpen
          ? toRem(-6)
          : props?.awardvalue
          ? props?.isNonMember
            ? toRem(-8)
            : toRem(-18)
          : toRem(4)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      top: ${props =>
        props?.isNightsModelOpen
          ? toRem(-10)
          : props?.awardvalue
          ? props?.isNonMember
            ? toRem(-12)
            : toRem(-24)
          : toRem(-1)};

      margin-top: -23%;
    }
  }
  .night-progress-height {
    height: ${toRem(230)};
    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(190)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      height: ${toRem(195)};
    }
  }
  .number-height {
    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(100)};
    }
  }

  .btn {
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .transparent-button {
    background-color: transparent;
    cursor: pointer;
  }
  .night-cloud {
    height: ${toRem(60)};
  }
`;
