import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Button, Icon, TabComponent } from '@marriott/mi-ui-library';
import { useGetBreakpoint } from '../../modules/utils/helper';
import { BREAKPOINT_MOBILE_TEXT } from '../../modules/utils/constants/application-breakpoints';
import { constants } from '../../modules/utils/constants/constants';
import { useStore } from '../../modules/store/memberLevelStore';
import { ToggleSwitchProps } from './ToggleSwitch.types';
import { StyledToggleSwitch } from './ToggleSwitch.styles';

export const ToggleSwitch: FC<ToggleSwitchProps> = ({ tabList, lookUpbtn, onTabSelect }) => {
  const [activeTab, _setActiveTab] = useState(lookUpbtn ? constants.TAB_UPCOMING : 'tabBusiness');
  const [isMobileViewPort, setIsMobileViewPort] = useState(false);
  const setShowFindReservationModal = useStore(state => state.setShowFindReservationModal);

  function handleResize() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setIsMobileViewPort(useGetBreakpoint() === BREAKPOINT_MOBILE_TEXT);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledToggleSwitch data-component-name="m-account-ToggleSwitch" data-testid="account-ToggleSwitch">
      <div className={clsx(lookUpbtn ? 'container' : 'pb-3', 'item-tabs pb-md-4')}>
        <div className="item-tabs__button pr-3 pt-md-0 pt-4 pb-3">
          {lookUpbtn && (
            <Button
              className="look-up m-button-s m-button-secondary-icon t-label-alt-xs d-flex align-items-center"
              callback={() => setShowFindReservationModal(true)}
            >
              <Icon iconClass="icon-search" />
              {lookUpbtn}
            </Button>
          )}
        </div>
        <TabComponent
          tabList={tabList}
          hidetabsAndContent={false}
          customClass={clsx(`m-standard-tab-list d-flex ${isMobileViewPort && 'justify-content-around'} ml-2 `)}
          hideTabs={false}
          isTablink={false}
          selectedTab={activeTab}
          clickTrackingLoc="parentComponent"
          disableCustomClickTrack={true}
          setSelectedTab={(selectedTab: string) => {
            onTabSelect(selectedTab);
          }}
        ></TabComponent>
      </div>
    </StyledToggleSwitch>
  );
};
