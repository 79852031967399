// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

export const EarnedRewardsConfig = {
  emptyLabel: 'EarnedRewards',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/earnedRewards`,
};

const EarnedRewards = dynamic(() => import('./EarnedRewards').then(mod => mod.EarnedRewards));
const EarnedRewardsV2 = dynamic(() => import('./EarnedRewardsV2').then(mod => mod.EarnedRewardsV2));

export const EarnedRewardsEditable = (props: any) => {
  return (
    <EditableComponent config={EarnedRewardsConfig} {...props}>
      {props?.isDTT ? <EarnedRewardsV2 {...props} /> : <EarnedRewards {...props} />}
    </EditableComponent>
  );
};
