import { useMemo } from 'react';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';
import { getCoordTypeFromCountryCode } from '@marriott/mi-ui-library';
import { URL_CONSTANTS } from '../constants';
import { replaceSpacesWithTabs } from './helper';

const { GOOGLE_MAP_URL, BAIDU_MAP_API_URL, BAIDU_MAP_URL } = URL_CONSTANTS;

interface UseHotelMapUrlParams {
  locale?: string;
  latitude?: string;
  longitude?: string;
  currency?: string;
  hotelName?: string;
  hotelAddress?: string;
}

type UseHotelMapUrl = (params: UseHotelMapUrlParams) => string;

/**
 * Hook to generate a hotel map URL based on locale and other parameters.
 *
 * This hook determines the appropriate map URL to use (Google Maps or Baidu Maps)
 * depending on the locale. If the locale is identified as CN, it uses
 * Baidu Maps, otherwise, it defaults to Google Maps.
 *
 * @param {UseHotelMapUrlParams} params - Parameters for generating the map URL.
 * @param {string} [params.locale] - The locale of the user.
 * @param {string} [params.latitude] - The latitude of the hotel's location.
 * @param {string} [params.longitude] - The longitude of the hotel's location.
 * @param {string} [params.currency] - The currency code to determine the coordinate type for Baidu Maps.
 * @param {string} [params.hotelName] - The name of the hotel, used as the marker title on Baidu Maps.
 * @param {string} [params.hotelAddress] - The address of the hotel, used as the marker content on Baidu Maps.
 *
 * @returns {string} - The generated map URL (Google Maps or Baidu Maps) based on the input parameters.
 */
export const useHotelMapUrl: UseHotelMapUrl = ({ locale, latitude, longitude, currency, hotelName, hotelAddress }) => {
  return useMemo(() => {
    if (!isCNLocale(locale)) return `${GOOGLE_MAP_URL}${latitude},${longitude}`;
    // If locale is CN, Baidu api is used
    const coordType = getCoordTypeFromCountryCode(currency);
    const markertitle = replaceSpacesWithTabs(hotelName);
    const markerContent = replaceSpacesWithTabs(hotelAddress);
    // if currency is CNY, HKD, MOP or TWD, the coord type would have a value of corresponding coord type
    if (coordType)
      return `${BAIDU_MAP_API_URL}${latitude},${longitude}&output=html&title=${markertitle}&content=${markerContent}&coord_type=${coordType}`;
    // in case coord type is null, using already converted url to skip the baidu map api convertor
    return `${BAIDU_MAP_URL}${latitude},${longitude}&title=${markertitle}&content=${markerContent}`;
  }, [locale, latitude, longitude, currency, hotelName, hotelAddress]);
};
