import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const AccountNavbarStyle = styled.div`
  ${rtl`
    .pagetitle:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }

    .skeleton-loader {
      width: ${toRem(280)};
      height: ${toRem(36)};

      @media ${baseVariables.mediaQuery.desktop} {
        height: ${toRem(44)};
      }
    }
  `}
`;
