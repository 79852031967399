// Styles for FindReservation go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledFindReservation = styled.div`
  ${rtl`
    .width-mobile-100 {
      width: 100%;
      height: auto;
      @media ${baseVariables.mediaQuery['sm']} {
        width: auto;
      }
    }
    .calendar-wrapper {
      border-radius: ${toRem(12)};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 ${constants.BORDER10};
      z-index: 2;
      position: absolute;
      @media ${baseVariables.mediaQuery.md} {
        position: fixed;
      }
      
      &-position {
        position: absolute;
      }

      .DayPicker {
        &-Day {
          &:hover,
          &:focus {
            background-color: ${
              baseVariables.color['neutral10']
            } !important; //important is neccesaary since we are overriding library class
            border-radius: 50% !important;
          }
        }
      }
    }

    .icon-calendar {
      position: absolute;
      right: ${toRem(16)};
      top: ${toRem(12)}; // to align icon center to input
      cursor: pointer;
    }

    .overlay-footer {
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
    }

    .cancel-button {
      background: none;
      border-bottom: ${toRem(1)} solid ${baseVariables.color.merch30};
      height: fit-content;
      &::after,
      &::before {
        display: none;
      }
    }

    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }

    .icon-calendar:is(${constants.RTL_LANG_LIST}) {
      right: auto !important;
      left: ${toRem(16)} !important;
      top: ${toRem(16)} !important;
    }

    .m-input-field input:is(${constants.RTL_LANG_LIST}) {
      padding-left: 0 !important;
      padding-right: ${toRem(16)} !important;
    }

    .m-input-field label:is(${constants.RTL_LANG_LIST}) {
      left: auto !important;
      right: ${toRem(11)} !important;
    }
    .m-message-content-wrap:is(${constants.RTL_LANG_LIST}){
      &:before{
        margin-right:0 !important;
        margin-left:${toRem(8)} !important;
      }
    }
`}
`;
