import React from 'react';
import { ProfileInfoStyle } from './MemberProfileDetails.styles';

interface ProfileInfoProps {
  color?: string;
  givenName?: string;
  description?: string;
}

// TODO - removing this member image for now - it will be enable in future

// interface ProfileImageProps {
//   bordercolor: string;
//   eliteMemberStatus: any;
// }

// const ProfileImage: React.FC<ProfileImageProps> = ({ bordercolor, eliteMemberStatus }) => {
//   return (
//     <ProfileImageStyle colorCode={bordercolor}>
//       <div className="profile-picture">
//         {eliteMemberStatus && <span className="icon-lifetime"></span>}
//         <span className="icon-nav---account-alt-active"></span>
//       </div>
//     </ProfileImageStyle>
//   );
// };

export const MemberProfileDetails: React.FC<ProfileInfoProps> = ({ color, givenName, description }) => {
  const memberCode = color;
  return (
    <ProfileInfoStyle className="d-flex align-items-center flex-row" colorCode={memberCode}>
      {/* TODO - removing this member image for now - it will be enable in future */}
      {/* <ProfileImage bordercolor={memberCode} eliteMemberStatus={eliteMember}></ProfileImage> */}
      <div className="profileinfo">
        {description ? (
          <div className="profileinfo__category t-category-custom mb-0" data-testid="category">
            {description?.toUpperCase()}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center justify-content-md-start">
            <div className="skeleton-loader member-status__skeleton__level mb-2"></div>
          </div>
        )}
        {givenName ? (
          <div className="profileinfo__name t-numbers-l t-numbers-l-custom" data-testid="name">
            {givenName?.toLowerCase()}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center justify-content-md-start">
            <div className="skeleton-loader member-status__skeleton__name"></div>
          </div>
        )}
      </div>
    </ProfileInfoStyle>
  );
};
