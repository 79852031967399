import React, { FC, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { inspect } from 'util';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import {
  Button,
  CheckBox,
  Heading,
  Icon,
  InputTextField,
  Link,
  Messages,
  RichText,
  Types,
} from '@marriott/mi-ui-library';
import { PageContext, useCheckBreakpoint } from '../../../modules';
import { useAccountPersistentStore } from '../../../modules/store/accountPersistentStore';
import {
  addSubDirectoryPrefix,
  alphabetRegex,
  apiLogger,
  ENCRYPTED_VERIFY_DATA,
  handleRedirect,
  nonDigitRegex,
  numberWithCommas,
  POINTS_TRANSFER_ID,
  verifyMemberToMemberUrl,
} from '../../../modules/utils';
import { PartnerDetails } from '../TransferPartnerPoints/TransferPartnerPoints.types';
import memberToMemberMock from './__mock__/MemberToMemberMock.json';
import { MemberToMemberProps } from './MemberToMember.types';
import { StyledMemberToMember } from './MemberToMember.styles';
import {
  isMultipleOf,
  updatePointsTransferDataLayer,
  errorKeyHandler,
} from '../PointsTransferUtils/PointsTransferHelper';
import { replaceText } from '../../../modules/utils/promotionHelper';
import { useBannerMessagesStore } from '../../../modules/store/bannerMessagesStore';
import { usePointsTransferStore } from '../../../modules/store/pointsTransferStore';
import { CustomError } from '../PointsToMiles/PointsToMiles.types';
import { PointsTransferErrors } from '../PointsTransfer.types';

// Use named rather than default exports.
export const MemberToMember: FC<MemberToMemberProps> = props => {
  const isTabletViewPort = useCheckBreakpoint('viewportM');
  const defaultErrorsList = props?.errorList?.['default']?.[0];
  const [isUXLError, setIsUXLError] = useState(false);
  const { setBannerId } = useBannerMessagesStore();
  const {
    errorKey,
    transferPointsDetails,
    setVerificationPointsFailed,
    setVerificationPointsSuccess,
    setTransferPointsDetails,
    setErrorKey,
  } = usePointsTransferStore(store => store);

  const formInitialState = {
    firstName: '',
    lastName: '',
    memberNumber: '',
    confirmMemberNumber: '',
    amountToTransfer: '',
    consentChecked: false,
  };

  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [partnerDetails, setPartnerDetails] = useState<PartnerDetails | undefined>();
  const [formData, setFormData] = useState<{
    firstName?: string;
    lastName?: string;
    memberNumber?: string;
    confirmMemberNumber?: string;
    amountToTransfer?: string;
    consentChecked?: boolean;
  }>(formInitialState);
  const [inlineError, setInlineError] = useState<{
    firstName?: string;
    lastName?: string;
    memberNumber?: string;
    confirmMemberNumber?: string;
    amountToTransfer?: string;
  }>({});
  const [formErrors, setFormErrors] = useState<PointsTransferErrors>({});
  const { currentPointBalance } = useAccountPersistentStore(store => store);
  const [pointsBalance, setPointsBalance] = useState<number | undefined>();

  const isValidFormData = Object.values(formData).every((value: string | boolean) => {
    if (typeof value === 'string') {
      return value?.trim().length !== 0;
    } else return formData?.consentChecked === true;
  });
  const formErrorsList = Object.values(formErrors).filter((error: string) => error !== undefined);
  const isBtnDisabled =
    !isValidFormData ||
    !!inlineError?.firstName ||
    !!inlineError?.lastName ||
    !!inlineError?.memberNumber ||
    !!inlineError.confirmMemberNumber ||
    !!inlineError?.amountToTransfer;

  useEffect(() => {
    const getPartnersDetails = async () => {
      try {
        const response = await axios.get(props?.convertPointsMappingUrl);
        const partnersListReponse = response.data.pointsTransferData;
        setPartnerDetails(partnersListReponse?.['member-to-member']);
        setIsUXLError(false);
      } catch (error) {
        setIsUXLError(true);
        apiLogger(
          `[TransferPartnerPoints] getPartnersDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(error)}`
        );
      }
    };
    if (props?.isAuthorMode) {
      setPartnerDetails(memberToMemberMock?.pointsTransferData?.['member-to-member']);
    } else {
      getPartnersDetails();
    }
  }, []);

  useEffect(() => {
    if (currentPointBalance !== undefined) {
      setPointsBalance(currentPointBalance);
    }
  }, [currentPointBalance]);

  // We will be getting error key from BE as maxGiveAmt to which respective AEM error label will be maxGiveAmtErrorMessage.
  // If BE error key i.e maxGiveAmt for now, is present in content fragment config, then we will replace the placeholder in AEM label with config value.
  // Else the AEM label will be assigned to the errors directly.
  useEffect(() => {
    if (errorKey) {
      errorKeyHandler(
        errorKey,
        setFormErrors,
        defaultErrorsList as { [key: string]: string },
        transferPointsDetails?.partnerConfig || partnerDetails,
        () => {
          setFormErrors({ genericErrorMessage: defaultErrorsList?.genericErrorMessage });
        }
      );
      updatePointsTransferDataLayer(transferPointsDetails?.partnerConfig?.dataLayerName ?? '');
      setTransferPointsDetails({
        partnerId: '',
        partnerName: '',
        partnerNumber: '',
        pointsToTransfer: '',
        typeOfPointsTransfer: '',
        partnerConfig: undefined,
      });
      setErrorKey('');
    }
  }, [errorKey]);

  const resetStates = (isVerifyPointsSuccess: boolean = false, isVerifyPointsFailed: boolean = false) => {
    setVerificationPointsSuccess(isVerifyPointsSuccess);
    setVerificationPointsFailed(isVerifyPointsFailed);
  };

  const handleFirstNameChange = (firstName: string) => {
    let isFirstNameInValid;
    if (firstName && !alphabetRegex?.test(firstName)) {
      isFirstNameInValid = defaultErrorsList?.invalidFirstNameErrorMessage;
    }
    setInlineError({ ...inlineError, firstName: isFirstNameInValid });
    setFormData({ ...formData, firstName: firstName });
  };

  const handleLastNameChange = (lastName: string) => {
    let isLastNameInvalid;
    if (lastName && !alphabetRegex?.test(lastName)) {
      isLastNameInvalid = defaultErrorsList?.invalidLastNameErrorMessage;
    }
    setInlineError({ ...inlineError, lastName: isLastNameInvalid });
    setFormData({ ...formData, lastName: lastName });
  };

  const handleMemberNumberChange = (memberNumber: string) => {
    let isMemberNumberInvalid;
    let isConfirmMemberNumberSame = inlineError?.confirmMemberNumber;
    if (memberNumber && nonDigitRegex?.test(memberNumber)) {
      isMemberNumberInvalid = defaultErrorsList?.invalidCharOrSpaceErrorMessage;
    }
    if (
      memberNumber &&
      formData?.confirmMemberNumber &&
      !nonDigitRegex?.test(formData?.confirmMemberNumber) &&
      memberNumber !== formData?.confirmMemberNumber
    ) {
      isConfirmMemberNumberSame = defaultErrorsList?.memberNumberMismatchErrorMessage;
    } else if (
      memberNumber &&
      formData?.confirmMemberNumber &&
      !nonDigitRegex?.test(formData?.confirmMemberNumber) &&
      memberNumber === formData?.confirmMemberNumber
    ) {
      isConfirmMemberNumberSame = '';
    }
    setInlineError({
      ...inlineError,
      memberNumber: isMemberNumberInvalid,
      confirmMemberNumber: isConfirmMemberNumberSame,
    });
    setFormData({ ...formData, memberNumber: memberNumber });
  };

  const handleConfirmMemberNumberChange = (confirmMemberNumber: string) => {
    let isConfirmMemberNumberInvalid;
    if (confirmMemberNumber && nonDigitRegex?.test(confirmMemberNumber)) {
      isConfirmMemberNumberInvalid = defaultErrorsList?.invalidCharOrSpaceErrorMessage;
    } else if (formData?.memberNumber && confirmMemberNumber && formData?.memberNumber !== confirmMemberNumber) {
      isConfirmMemberNumberInvalid = defaultErrorsList?.memberNumberMismatchErrorMessage;
    }
    setInlineError({ ...inlineError, confirmMemberNumber: isConfirmMemberNumberInvalid });
    setFormData({ ...formData, confirmMemberNumber: confirmMemberNumber });
  };

  const handleAmountToTranferChange = (points: string) => {
    let isAmountToTransferError;
    let isValid = true;

    if (points && nonDigitRegex?.test(points)) {
      isAmountToTransferError = defaultErrorsList?.invalidPointsFieldErrorMessage;
      isValid = false;
    } else if (points && Number(points) > (pointsBalance || 0)) {
      isAmountToTransferError = defaultErrorsList?.notEnoughErrorMessage;
      isValid = false;
    } else if (points && partnerDetails?.maxGiveAmt && Number(points) > Number(partnerDetails?.maxGiveAmt)) {
      isAmountToTransferError = replaceText(defaultErrorsList?.upperThreshPointErrorMessage || '', [
        numberWithCommas(partnerDetails?.maxGiveAmt),
      ]);
      isValid = false;
    } else if (
      points &&
      partnerDetails?.minPointsToTransfer &&
      Number(points) < Number(partnerDetails?.minPointsToTransfer)
    ) {
      isAmountToTransferError = replaceText(defaultErrorsList?.lowerThreshPointErrorMessage || '', [
        numberWithCommas(partnerDetails?.minPointsToTransfer),
      ]);
      isValid = false;
    } else if (points && partnerDetails && !nonDigitRegex?.test(points)) {
      const isValidMultiple = isMultipleOf(Number(points), partnerDetails?.pointsTransferMultiplier || '1');
      isAmountToTransferError = !isValidMultiple
        ? replaceText(defaultErrorsList?.multiplierErrorMessage || '', [
            numberWithCommas(partnerDetails?.pointsTransferMultiplier || '1'),
          ])
        : '';

      isValid = isValidMultiple ? true : false;
    }
    setInlineError({ ...inlineError, amountToTransfer: isValid ? '' : isAmountToTransferError });
    setFormData({ ...formData, amountToTransfer: points });
  };

  const handleCancelButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorKey('');
    sessionStorage.removeItem('pointsTransferState');
    handleRedirect(props?.cancelCtaPath);
  };

  const handlePointsSubmission = async () => {
    setErrorKey(''); // initializing page level error to false on submit
    setisLoading(true);
    setIsUXLError(false); // initializing UXL error to false on submit
    setFormErrors({});
    try {
      setisLoading(true);
      const pointsDetails = {
        partnerId: '',
        partnerName: `${formData?.firstName} ${formData?.lastName}`,
        partnerNumber: formData?.memberNumber || '',
        pointsToTransfer: formData?.amountToTransfer || '',
        typeOfPointsTransfer: props?.typeOfPointsTransfer || '',
        partnerConfig: partnerDetails,
      };
      const payload = {
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        numberOfPoints: Number(formData?.amountToTransfer),
        receipientAccountId: formData?.memberNumber,
        returnUrl: window?.location?.pathname,
      };
      const response = await axios.post(addSubDirectoryPrefix(verifyMemberToMemberUrl), payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      props?.setTransferPointsDetails({
        ...pointsDetails,
        isVerifyConvertPoints: false,
        partnerConfig: partnerDetails,
      });
      if (response?.data?.isRedirect) {
        setBannerId(POINTS_TRANSFER_ID);
        setTransferPointsDetails(pointsDetails);
        sessionStorage.setItem(ENCRYPTED_VERIFY_DATA, response?.data?.encodePayload);
        const { nextStateURI } = response.data;
        handleRedirect(
          nextStateURI +
            `${nextStateURI?.includes('?') ? '&' : '?'}returnTo=${addSubDirectoryPrefix(window?.location?.pathname)}` // to add current page path as return to url
        );
      } else {
        setTransferPointsDetails(pointsDetails);
        resetStates(true, false);
        window.location.reload(); // adding page reload to show updated points in the Global header for WEB-89313
      }
    } catch (error) {
      setisLoading(false);
      const errorMessage = (error as CustomError)?.response?.data?.phoenixErrorMessages?.errorMessages?.[0];
      if (errorMessage) {
        setErrorKey(errorMessage);
        resetStates(false, false);
      } else {
        resetStates(false, true);
      }
    }
  };

  return (
    <StyledMemberToMember>
      <Heading
        customClass={'mb-3 mt-5 pt-0 pt-md-3'}
        titleText={props?.transferHeader}
        variation={Types.headingType.title}
        fontSize={Types.size.small}
      />
      {partnerDetails?.minPointsToTransfer ? (
        <Heading
          customClass="subheader mb-4 mb-md-5"
          titleText={replaceText(props?.transferSubHeader || '', [
            numberWithCommas(partnerDetails?.minPointsToTransfer ?? ''),
          ])}
          variation={Types.headingType.body}
          fontSize={Types.size.medium}
        />
      ) : (
        <div className="skeleton-loader mb-4 mb-md-5 text-loader" />
      )}
      {isUXLError ? (
        <Messages messageType="error-sev1" className="mt-2 mt-md-0 mb-5 px-2 points-page-error-msg t-font-s">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      ) : (
        !!formErrorsList?.length && (
          <Messages messageType="error-sev1" className="mt-2 mt-md-0 mb-5 px-2 points-page-error-msg t-font-s">
            <RichText text={formErrorsList?.[0]} componentId="uxl-error-msg" />
          </Messages>
        )
      )}
      <div
        className={clsx(
          'current-balance p-5 d-flex justify-content-start mb-4 mb-md-5',
          isTabletViewPort && 'align-items-center'
        )}
      >
        <div>
          <Icon iconClass="icon-buy-points icon-l mi-icon icon-decorative"></Icon>
        </div>
        <div
          className={clsx(
            'd-flex current-balance__points justify-content-start align-items-center',
            isTabletViewPort ? 't-subtitle-xl' : 't-subtitle-l'
          )}
        >
          {pointsBalance !== undefined ? (
            <div className="current-balance__label">
              {replaceText(props?.currentBalText || '', [numberWithCommas(pointsBalance)])}
            </div>
          ) : (
            <div className="skeleton-loader current-balance__loader" />
          )}
        </div>
      </div>
      <div className="d-flex flex-column transfer-details-container">
        <div className="transfer-details-container__header p-4 p-md-5">
          <Heading
            element={Types.tags.span}
            titleText={props?.transferSectionHeader}
            variation={Types.headingType.subtitle}
            fontSize={Types.size.extraLarge}
          />
        </div>
        <div className="transfer-details-container__form px-4 px-md-5 py-4 py-md-5">
          <div className="d-md-flex custom-gap">
            <div className="custom-width">
              <InputTextField
                testId="first-name"
                iconClass="icon icon-clear icon-m input-valid-check"
                label={props?.firstName}
                inputValue={formData?.firstName}
                showErrorMessage={!!inlineError?.firstName}
                setErrorHtml={true}
                messageClass="error-label"
                inputMaxLength={20}
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'first-name',
                })}
                getLabelProps={() => ({
                  htmlFor: 'first-name',
                })}
                className="m-input-text-field mt-4 mt-md-0"
                getInputValue={(val: string) => {
                  handleFirstNameChange(val);
                }}
              />
              {inlineError?.firstName && (
                <div className="t-font-xs pt-1 transfer-details-container__form__validation-msg">
                  {inlineError?.firstName}
                </div>
              )}
            </div>
            <div className="custom-width">
              <InputTextField
                testId="last-name"
                iconClass="icon icon-clear icon-m input-valid-check"
                label={props?.lastName}
                inputValue={formData?.lastName}
                showErrorMessage={!!inlineError?.lastName}
                setErrorHtml={true}
                messageClass="error-label"
                inputMaxLength={25}
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'last-name',
                })}
                getLabelProps={() => ({
                  htmlFor: 'last-name',
                })}
                className="m-input-text-field mt-4 mt-md-0"
                getInputValue={(val: string) => {
                  handleLastNameChange(val);
                }}
              />
              {inlineError?.lastName && (
                <div className="t-font-xs pt-1 transfer-details-container__form__validation-msg">
                  {inlineError?.lastName}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4 mt-md-5 mb-3 transfer-details-container__form__sectionSubHeader">
              <Heading
                element={Types.tags.span}
                titleText={props?.recipientMemberNumber}
                variation={Types.headingType.subtitle}
                fontSize={Types.size.medium}
              />
            </div>
          </div>
          <div className="d-md-flex custom-gap">
            <div className="custom-width">
              <InputTextField
                testId="member-number"
                iconClass="icon icon-clear icon-m input-valid-check"
                label={props?.memberNumber}
                inputValue={formData?.memberNumber}
                showErrorMessage={!!inlineError?.memberNumber}
                setErrorHtml={true}
                messageClass="error-label"
                inputMaxLength={12}
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'member-number',
                })}
                getLabelProps={() => ({
                  htmlFor: 'member-number',
                })}
                className="m-input-text-field mt-4 mt-md-0"
                getInputValue={(val: string) => {
                  handleMemberNumberChange(val);
                }}
              />
              {inlineError?.memberNumber && (
                <div className="t-font-xs pt-1 transfer-details-container__form__validation-msg">
                  {inlineError?.memberNumber}
                </div>
              )}
            </div>
            <div className="custom-width">
              <InputTextField
                testId="confirm-member-number"
                iconClass="icon icon-clear icon-m input-valid-check"
                label={props?.confirmMemberNumber}
                inputValue={formData?.confirmMemberNumber}
                showErrorMessage={!!inlineError?.confirmMemberNumber}
                setErrorHtml={true}
                messageClass="error-label"
                inputMaxLength={12}
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'confirm-member-number',
                })}
                getLabelProps={() => ({
                  htmlFor: 'confirm-member-number',
                })}
                className="m-input-text-field mt-4 mt-md-0"
                getInputValue={(val: string) => {
                  handleConfirmMemberNumberChange(val);
                }}
              />
              {inlineError?.confirmMemberNumber && (
                <div className="t-font-xs pt-1 transfer-details-container__form__validation-msg">
                  {inlineError?.confirmMemberNumber}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4 mt-md-5">
              <InputTextField
                testId="amount-to-transfer"
                iconClass="icon icon-clear icon-m input-valid-check"
                label={props?.amountToTransfer}
                inputValue={formData?.amountToTransfer}
                showErrorMessage={!!inlineError?.amountToTransfer}
                setErrorHtml={true}
                messageClass="error-label"
                getInputProps={() => ({
                  autoComplete: 'off',
                  id: 'amount-to-transfer',
                })}
                getLabelProps={() => ({
                  htmlFor: 'amount-to-transfer',
                })}
                className="m-input-text-field"
                getInputValue={(val: string) => {
                  handleAmountToTranferChange(val);
                }}
              />
              {!!inlineError?.amountToTransfer && (
                <div className="t-font-xs pt-1 transfer-details-container__form__validation-msg">
                  {inlineError?.amountToTransfer}
                </div>
              )}
            </div>
          </div>
          {partnerDetails?.maxGiveAmt && partnerDetails?.minPointsToTransfer ? (
            <RichText
              text={replaceText(props?.inlineMessage || '', [
                numberWithCommas(partnerDetails?.maxGiveAmt || ''),
                numberWithCommas(partnerDetails?.minPointsToTransfer || ''),
              ])}
              customClass="t-font-s user-instruction mt-4 mt-md-5"
              componentId="user-instruction"
            />
          ) : (
            <div className="skeleton-loader mt-4 mt-md-5 text-loader" />
          )}
        </div>
      </div>
      <CheckBox
        key={'checkbox'}
        data-testid="consent"
        checkboxName='"accountSelection"'
        checkboxId="consent"
        className="notification-option"
        checked={formData?.consentChecked}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFormData({ ...formData, consentChecked: event.target.checked });
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();
            setFormData({ ...formData, consentChecked: !formData?.consentChecked });
          }
        }}
      >
        <RichText
          text={props?.consentDetails || ''}
          customClass="t-font-s user-instruction mt-4 mt-md-5"
          componentId="consent-text"
        />
      </CheckBox>
      <div className="col-12 d-flex align-items-center flex-column flex-md-row mt-4 mt-md-5 px-0 space-bottom">
        <Button
          ariaLabel={props?.transferPointsNow}
          testId="transfer-points"
          isDisabled={isLoading || isBtnDisabled}
          className={clsx('m-button-m m-button-primary continue_btn', isBtnDisabled ? 'disabled' : '')}
          type={Types.ButtonTypeVariation.Button}
          custom_click_track_value={props?.transferPointsNowClickTrackValue}
          callback={handlePointsSubmission}
        >
          {isLoading ? <div className="m-spinner-s"></div> : props?.transferPointsNow}
        </Button>
        <Link
          text={props?.cancel}
          callback={(event: React.ChangeEvent<HTMLInputElement>) => handleCancelButton(event)}
          linkClassName="m-link-action mt-5 mt-md-0 ml-md-5 cancel"
          linkHref={props?.cancelCtaPath || ''}
          target="_self"
          linkType="internal"
        />
      </div>
    </StyledMemberToMember>
  );
};
