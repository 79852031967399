// Imports for external libraries go here.
import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Heading, Types, InputTextField, Icon, Label } from '@marriott/mi-ui-library';
import {
  AMBASSADOR_PERSONAL_INFO_CATEGORY,
  PERSONAL_INFO_OCCUPATION_DEFAULT,
  PERSONAL_INFO_PETSTYPE_DEFAULT,
} from '../../../modules/utils/constants';
import { nameFieldRegex } from '../../../modules/utils/regexUtils';
import { Dropdown } from '../../../molecules/Dropdown/Dropdown';
import { StyledPersonalInfoSection } from './PersonalInfoSection.styles';
import { PersonalType, DropdownMethodType, ChildType, Pet, Props } from './PersonalInfoSection.types';

export const PersonalInfoSection: FC<Props> = ({
  personalInfo,
  modalProps,
  onSubmitFlag,
  onHandleFlag,
  setIsPersonalError,
  setFlagforSaveBtn,
}) => {
  const [personalData, setPersonalData] = useState({
    preferredName: '',
    partnerName: '',
  });
  const [industryOccupation, setIndustryOccupation] = useState<DropdownMethodType>({ id: '', value: '' });
  const [childrenData, setChildrenData] = useState<{ age: null | number; name: string }[]>([]);
  const [petsData, setPetsData] = useState<Pet[]>([]);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  //useMemo
  const filteredOccupations = useMemo(() => {
    return modalProps?.informationList?.[0]?.occupationList?.map((occupation: DropdownMethodType) => ({
      id: occupation.id,
      value: occupation.value,
    }));
  }, [modalProps?.informationList]);

  const formattedPetCategoryOptions = useMemo(() => {
    // Use optional chaining to access properties safely
    const petsSection = modalProps?.informationList?.find(
      section => section.infoType === AMBASSADOR_PERSONAL_INFO_CATEGORY.PET_CATEGORY
    );

    if (petsSection?.typeList) {
      return petsSection?.typeList?.map((item: DropdownMethodType) => ({
        id: item.id,
        value: item.value || '', // Use empty string if value is nullish
      }));
    } else {
      return [];
    }
  }, [modalProps?.informationList]);

  const travelPetOptions = useMemo(() => {
    const petsSection = modalProps?.informationList?.find(
      section => section.infoType === AMBASSADOR_PERSONAL_INFO_CATEGORY.PET_CATEGORY
    );
    if (petsSection?.travelList) {
      return petsSection?.travelList?.map((item: DropdownMethodType) => ({
        id: item.id,
        value: item.value || '',
      }));
    } else {
      return [];
    }
  }, [modalProps?.informationList]);

  //sent flag to enable save btn
  useEffect(() => {
    if (isSaveButtonEnabled) {
      setFlagforSaveBtn(isSaveButtonEnabled);
    }
  }, [isSaveButtonEnabled, setFlagforSaveBtn]);

  //usestate
  useEffect(() => {
    const preferredNameValue = personalInfo?.preferredName?.value || '';
    const partnerValue = personalInfo?.partner?.value || '';
    setPersonalData({
      preferredName: preferredNameValue,
      partnerName: partnerValue,
    });
  }, [personalInfo]);

  // save button disable logic
  const saveButtonDisableHandler = (value: string) => {
    setIsPersonalError && setIsPersonalError(!isNameFieldRegex(value));
  };
  useEffect(() => {
    //default occupation
    const foundOccupation = filteredOccupations?.find(
      (occupation: DropdownMethodType) => occupation?.id === personalInfo?.industryOccupation.code
    );

    if (foundOccupation) {
      setIndustryOccupation({ id: foundOccupation?.id ?? '', value: foundOccupation?.value ?? '' });
    } else {
      const defaultOccupation = filteredOccupations?.find(
        (occupation: DropdownMethodType) => occupation?.id === PERSONAL_INFO_OCCUPATION_DEFAULT
      );
      if (defaultOccupation) {
        setIndustryOccupation({ id: defaultOccupation?.id ?? '', value: defaultOccupation?.value ?? '' });
      }
    }
    //default petType and
    const updatedPetsData = personalInfo?.pets?.value?.map((pet: Pet) => {
      const foundPetType = formattedPetCategoryOptions?.find(
        (option: DropdownMethodType) => option?.id === pet?.petType?.code
      );

      const foundTravelOption = travelPetOptions?.find(
        (option: DropdownMethodType) => option?.id === pet?.travelsWithPet?.code
      );

      const defaultPetType = formattedPetCategoryOptions?.find(
        (option: DropdownMethodType) => option.id === PERSONAL_INFO_PETSTYPE_DEFAULT
      );
      const defaultTravelPet = travelPetOptions?.find((option: DropdownMethodType) => option.id === 'NA');
      const petType = foundPetType || defaultPetType!;
      const travelsWithPet = foundTravelOption || defaultTravelPet!;

      return {
        ...pet,
        petType: {
          code: petType?.id,
          value: petType?.value,
        },
        travelsWithPet: {
          code: travelsWithPet?.id,
          value: travelsWithPet?.value,
        },
      };
    });

    setPetsData(
      updatedPetsData || [
        {
          petName: '',
          petType: { code: '', value: '' },
          travelsWithPet: { code: '', value: '' },
        },
      ]
    );

    // default children
    const formmattedChild = personalInfo?.children?.value?.map((child: ChildType) => ({
      name: child.name,
      age: child.age || null,
    }));
    setChildrenData(formmattedChild || [{ name: '', age: null }]);
  }, [personalData, filteredOccupations, personalInfo, formattedPetCategoryOptions, travelPetOptions]);

  //handle click pet
  const handleAddPet = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    // Check if petsData length is less than 10
    if (petsData.length < 10) {
      const defaultPetType = formattedPetCategoryOptions.find(option => option.id === '') || {
        code: '',
      };
      const defaultTravelsWithPet = travelPetOptions.find(option => option.id === '') || {
        code: '',
      };

      setPetsData([
        ...petsData,
        {
          petName: '',
          petType: defaultPetType,
          travelsWithPet: defaultTravelsWithPet,
        },
      ]);
    }
    setIsSaveButtonEnabled(true);
  };
  const handleClickPetDelete = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
    petIndex: number
  ) => {
    e.stopPropagation();
    const updatedPets = [...petsData];
    updatedPets.splice(petIndex, 1);

    if (updatedPets.length === 0) {
      const defaultPetType = formattedPetCategoryOptions.find(option => option.id === '') || {
        code: '',
      };
      const defaultTravelsWithPet = travelPetOptions.find(option => option.id === '') || {
        code: '',
      };
      updatedPets.push({
        petName: ' ',
        petType: defaultPetType,
        travelsWithPet: defaultTravelsWithPet,
      });
    }
    setPetsData(updatedPets);
    setIsSaveButtonEnabled(true);
  };
  const handlePetNameUpdate = (name: string, index: number) => {
    setPetsData(prevState => {
      const updatedPetsData = [...prevState];
      updatedPetsData[index] = { ...updatedPetsData[index], petName: name }; // Update the pet name at the specified index
      return updatedPetsData;
    });
  };

  //child click function
  const handleAddChild = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (childrenData.length < 10) {
      setChildrenData([...childrenData, { age: null, name: '' }]);
    }
    setIsSaveButtonEnabled(true);
  };

  const handleClickChildDelete = (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    e.stopPropagation();
    const updatedChildrenData = [...childrenData];
    updatedChildrenData.splice(index, 1);

    setChildrenData(updatedChildrenData);

    if (updatedChildrenData.length === 0) {
      setChildrenData([{ age: null, name: '' }]);
    }
    setIsSaveButtonEnabled(true);
  };

  //dropdown handle
  const handleDropdownOccupationChange = (selectedOption: { value: string }) => {
    const matchedOccupation = filteredOccupations?.find(
      (option: { value: string }) => option.value === selectedOption.value
    );
    if (matchedOccupation) {
      setIndustryOccupation({ id: matchedOccupation.id, value: matchedOccupation.value });
    }
    setIsSaveButtonEnabled(true);
  };

  // check if value have only alphabets, space or blank
  const isNameFieldRegex = (value: string) => {
    if (value) {
      return nameFieldRegex.test(value);
    } else {
      return true;
    }
  };

  const handleDropdownPetTypeChange = (selectedPetType: DropdownMethodType | string, petIndex: number) => {
    setPetsData(prevPetsData => {
      const updatedPetsData = prevPetsData?.map((pet, index) => {
        if (index === petIndex) {
          // Check if the input value is a custom string entered by the user
          if (typeof selectedPetType === 'string') {
            return {
              ...pet,
              petType: {
                id: 'OTHER',
                countryDialCode: null,
                value: selectedPetType, // Store the custom string entered by the user
              },
              isInput: true,
            };
          } else {
            // Handle the case when selectedPetType is of type DropdownMethodType
            return {
              ...pet,
              petType: {
                code: selectedPetType.id,
                value: selectedPetType.value,
              },
              isInput: selectedPetType.id === 'ENTER_NAME',
            };
          }
        }
        return pet;
      });

      return updatedPetsData;
    });
  };

  const handleTravelPetDropdownChange = (selectedTravelsWithPet: DropdownMethodType, petIndex: number) => {
    setPetsData(prevPetsData => {
      const updatedPetsData = prevPetsData?.map((pet, index) => {
        if (index === petIndex) {
          return {
            ...pet,
            travelsWithPet: {
              code: selectedTravelsWithPet?.id,
              value: selectedTravelsWithPet?.value,
            },
          };
        }
        return pet;
      });

      return updatedPetsData;
    });
  };

  //handle labels
  const handleFirstLabel = (infoType: string) => {
    switch (infoType) {
      case AMBASSADOR_PERSONAL_INFO_CATEGORY.SELF_CATEGORY:
        return modalProps?.preferredName;
      case AMBASSADOR_PERSONAL_INFO_CATEGORY.SPOUSE_CATEGORY:
        return modalProps?.partnerName;
      case AMBASSADOR_PERSONAL_INFO_CATEGORY.CHILDREN_CATEGORY:
        return modalProps?.name;
      case AMBASSADOR_PERSONAL_INFO_CATEGORY.PET_CATEGORY:
        return modalProps?.name;
      default:
        return undefined; // Handle unknown infoType case
    }
  };

  const isDataChanged = () => {
    const preferedNameChanged = personalData?.preferredName !== personalInfo?.preferredName?.value;
    const occupationChanged = industryOccupation?.id !== personalInfo?.industryOccupation?.code;
    const partnerNameChanged = personalData?.partnerName
      ? personalData?.partnerName !== personalInfo?.partner?.value
      : personalInfo?.partner?.value !== null;

    const isChilddataNotChanged =
      childrenData.every((child, index) => {
        const childFromChildren = personalInfo?.children?.value[index];
        if (childFromChildren) {
          return child.age === childFromChildren?.age && child.name === childFromChildren?.name;
        }
        return false;
      }) && childrenData?.length === personalInfo?.children?.value?.length;
    const petsDataNotChanged =
      petsData.every((pet, index) => {
        const petFromPets = personalInfo?.pets.value[index];
        if (petFromPets) {
          return (
            pet.petType.code === petFromPets.petType.code &&
            pet.petName === petFromPets.petName &&
            pet.travelsWithPet.code === petFromPets.travelsWithPet.code
          );
        }
        return false;
      }) && petsData?.length === personalInfo?.pets?.value?.length;

    return (
      preferedNameChanged || occupationChanged || partnerNameChanged || !isChilddataNotChanged || !petsDataNotChanged
    );
  };

  //mutation payload fetch

  useEffect(() => {
    if (onSubmitFlag) {
      const personalInfodataChanged = isDataChanged();
      const personalInfoPayload = {
        preferredName: personalData?.preferredName,
        partner: personalData?.partnerName ? personalData?.partnerName : null,
        industryOccupation: industryOccupation?.id ?? PERSONAL_INFO_OCCUPATION_DEFAULT,
        children:
          childrenData?.map(child => ({
            age: child.name ? child.age ?? null : null,
            name: child.name ?? '',
            lastModifiedDate: ' ',
          })) || [],
        pets:
          petsData?.map(pet => ({
            petName: pet?.petName ?? '',
            petType: pet?.petType?.code ?? PERSONAL_INFO_PETSTYPE_DEFAULT,
            travelsWithPet: pet?.travelsWithPet?.code ?? 'NA',
          })) || [],
      };

      onHandleFlag(personalInfoPayload, personalInfodataChanged); // Call callback with data if flag is true
    }
  }, [onSubmitFlag]);

  const childInfoSection = (index: number, child: ChildType) => (
    <div className="show-again-click d-md-flex py-5 pl-md-4 color-scheme1" data-testid="child-section">
      <InputTextField
        label={modalProps?.name}
        testId={`Child-Name`}
        showErrorMessage={!isNameFieldRegex(child?.name)}
        messageToShow={modalProps.nameInlineErrorMsg}
        inputValue={child?.name}
        messageClass="error-label"
        inputMaxLength={25}
        className="elite_modal__children__divbox__name m-input-field flex-fill mr-md-3 mx-4 mx-md-0 pb-4 pb-md-0 px-0"
        getInputValue={(val: string) => {
          setChildrenData(prevState => [
            ...prevState.map((child, i) => (i === index ? { ...child, name: val } : child)),
          ]);
          saveButtonDisableHandler(val);
          setIsSaveButtonEnabled(true);
        }}
      />
      <InputTextField
        label={modalProps?.age}
        testId={'Child-Age'}
        inputValue={child?.age?.toString() || ''}
        messageClass="error-label"
        inputMaxLength={3}
        className="elite_modal__children__divbox__age m-input-field flex-fill mr-lg-3 mx-4 mx-md-0 pb-5 pb-md-0"
        getInputValue={(val: string) => {
          setChildrenData(prevState => [
            ...prevState.map((child, i) => (i === index ? { ...child, age: val ? parseInt(val) : null } : child)),
          ]);
          setIsSaveButtonEnabled(true);
        }}
      />
      {childrenData.length !== 1 && (
        <div
          onClick={e => {
            handleClickChildDelete(e, index);
            setIsSaveButtonEnabled(true);
          }}
          className="d-flex justify-content-end"
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleClickChildDelete(e, index);
              setIsSaveButtonEnabled(true);
            }
          }}
          data-testid="delete-child"
        >
          <Icon iconClass="icon-delete icon-l elite_modal__pet-info__deleteicon d-flex px-4 align-items-center" />
        </div>
      )}
    </div>
  );

  const petInfoSection = (index: number, pet: Pet) => (
    <div key={index} className="d-lg-flex py-5">
      <div className="d-lg-flex pl-lg-4 pl-0 color-scheme1">
        <InputTextField
          label={modalProps?.name}
          testId="Pet-Name"
          inputValue={pet?.petName}
          showErrorMessage={!isNameFieldRegex(pet?.petName)}
          messageToShow={modalProps.nameInlineErrorMsg}
          messageClass="error-label"
          inputMaxLength={25}
          className="elite_modal__pet__inputbox m-input-field flex-fill mr-lg-3 mx-4 mx-lg-0"
          getInputValue={(val: string) => {
            handlePetNameUpdate(val, index);
            saveButtonDisableHandler(val);
            setIsSaveButtonEnabled(true);
          }}
        />

        <div className="m-input-field is-active item">
          {!petsData[index]?.isInput ? (
            <div onClick={e => e.stopPropagation()}>
              <label className="dropdown__petType-label label" htmlFor="petType" id="dropdown-typeOfPet">
                {modalProps?.typeOfPet}
              </label>
              <Dropdown
                dropdownOptions={formattedPetCategoryOptions}
                defaultOption={pet?.petType?.value ?? ''}
                customClassName="elite_modal__pet__dropdown__petType dropdown_input m-input-field pr-3 flex-fill mr-lg-3 mx-4 mx-lg-0 mt-4 mt-lg-0"
                dropdownId="pet-type"
                isIconChange={false}
                onChange={val => {
                  handleDropdownPetTypeChange(val, index);
                  setIsSaveButtonEnabled(true);
                }}
              />
            </div>
          ) : (
            <InputTextField
              label={modalProps?.typeOfPet}
              testId="pet-type"
              inputValue={petsData[index]?.petType?.value}
              messageClass="error-label"
              inputMaxLength={25}
              className="elite_modal__pet__dropdown__petType m-input-field flex-fill mr-lg-3 mx-4 mx-lg-0"
              getInputValue={(val: string) => {
                handleDropdownPetTypeChange(val, index);
                setIsSaveButtonEnabled(true);
              }}
            />
          )}
        </div>
        <div className="m-input-field is-active item" onClick={e => e.stopPropagation()}>
          <label className="dropdown__petTravel-label label" htmlFor="petTravel" id="dropdown-petTravel t">
            {modalProps?.travelsWithYou}
          </label>
          <Dropdown
            dropdownOptions={travelPetOptions}
            defaultOption={pet?.travelsWithPet?.value ?? ''}
            customClassName="elite_modal__pet__dropdown__petTravel dropdown_input  m-input-field  flex-fill mx-4 mx-lg-0 mt-4  mt-lg-0"
            dropdownId="pet-travel"
            isIconChange={false}
            onChange={val => {
              handleTravelPetDropdownChange(val, index);
              setIsSaveButtonEnabled(true);
            }}
          />
        </div>
      </div>
      {petsData.length !== 1 && (
        <div
          onClick={e => {
            handleClickPetDelete(e, index);
            setIsSaveButtonEnabled(true);
          }}
          className="d-flex justify-content-end "
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleClickPetDelete(e, index);
              setIsSaveButtonEnabled(true);
            }
          }}
          data-testid="delete-pet"
        >
          <Icon
            iconClass={clsx(
              'icon-delete icon-l elite_modal__pet-info__deleteicon d-flex pl-lg-5 px-4 pt-lg-0 pt-3 align-items-center',
              childrenData.length !== 1 && 'd-none'
            )}
          />
        </div>
      )}
    </div>
  );
  return (
    <StyledPersonalInfoSection>
      {modalProps?.informationList?.map((infoSection: { subTitle: string | undefined; infoType: string }) => (
        <div className="elite_modal-container" key={infoSection?.infoType}>
          <div className="elite_modal__personal-info pl-0 pl-md-5 pr-md-2">
            <Heading
              fontSize={Types?.size?.medium}
              titleText={infoSection?.subTitle}
              customClass={clsx(
                ' pb-3 ',
                `elite_modal__personal-info__${infoSection?.infoType}__heading`,
                infoSection?.infoType === 'pets' ? 'pt-3' : ''
              )}
              variation={Types?.headingType?.subtitle}
            />
            {(infoSection?.infoType === 'self' || infoSection?.infoType === 'spouse') && (
              <div
                className={clsx(
                  `color-scheme1 elite_modal__personal-info__${infoSection?.infoType}__input`,
                  'd-lg-flex flex-row mb-5 pb-4 '
                )}
              >
                <InputTextField
                  label={handleFirstLabel(infoSection?.infoType) ?? ''}
                  testId={`${infoSection?.infoType}-name`}
                  inputValue={
                    infoSection?.infoType === 'self' ? personalData?.preferredName : personalData?.partnerName
                  }
                  showErrorMessage={
                    !isNameFieldRegex(
                      infoSection?.infoType === 'self' ? personalData?.preferredName : personalData?.partnerName
                    )
                  }
                  messageToShow={modalProps.nameInlineErrorMsg}
                  messageClass="error-label"
                  inputMaxLength={25}
                  className={clsx(
                    'm-input-field flex-fill pr-lg-3 pr-0',
                    `elite_modal__personal-info__${infoSection?.infoType}__inputLabel `
                  )}
                  getInputValue={(val: string) => {
                    if (infoSection.infoType === AMBASSADOR_PERSONAL_INFO_CATEGORY.SELF_CATEGORY) {
                      setPersonalData(prevState => ({
                        ...prevState,
                        preferredName: val,
                      }));
                    } else {
                      setPersonalData(prevState => ({
                        ...prevState,
                        partnerName: val,
                      }));
                    }
                    setIsSaveButtonEnabled(true);
                    saveButtonDisableHandler(val);
                  }}
                />
                {infoSection?.infoType === AMBASSADOR_PERSONAL_INFO_CATEGORY.SELF_CATEGORY && (
                  <div className="m-input-field" onClick={e => e.stopPropagation()}>
                    <label className="dropdown__occupation-label label" htmlFor="petType" id="dropdown-typeOfPet">
                      {modalProps?.occupation}
                    </label>
                    <Dropdown
                      dropdownOptions={filteredOccupations}
                      defaultOption={industryOccupation?.value}
                      customClassName={clsx(
                        `elite_modal__personal-info__${infoSection?.infoType}__dropdown`,
                        ' d-flex align-items-center dropdown_input m-button-filter text-nowrap flex-fill mt-5  mt-lg-0 '
                      )}
                      dropdownId="occupation-filter"
                      isIconChange={false}
                      onChange={handleDropdownOccupationChange}
                    />
                  </div>
                )}
              </div>
            )}
            {infoSection?.infoType === AMBASSADOR_PERSONAL_INFO_CATEGORY.CHILDREN_CATEGORY && (
              <div>
                <div className="elite_modal__children__divbox">
                  {childrenData?.map((child, index) => (
                    <div key={index}>
                      {childInfoSection(index, child)}
                      {index < childrenData.length - 1 && <hr className="elite_modal__hrLine" />}
                    </div>
                  ))}
                </div>
                {childrenData?.length < 10 && (
                  <div
                    className="elite_modal__children-info__addExtra d-flex justify-content-end pt-4"
                    onClick={e => handleAddChild(e)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleAddChild(e);
                      }
                    }}
                    data-testid="add-child"
                  >
                    <Icon iconClass={'icon-add elite_modal__children-info__addicon'} />
                    <Label
                      labelText={modalProps?.addChild}
                      size={Types.size.small}
                      customClass="px-2 align-content-center"
                    />
                  </div>
                )}
              </div>
            )}
            {infoSection?.infoType === AMBASSADOR_PERSONAL_INFO_CATEGORY.PET_CATEGORY && (
              <div>
                <div className="elite_modal__pet__divbox">
                  {petsData?.map((pet, index) => (
                    <div key={index}>
                      {petInfoSection(index, pet)}
                      {index < petsData.length - 1 && <hr className="elite_modal__hrLine mx-4" />}
                    </div>
                  ))}
                </div>
                {petsData?.length < 10 && (
                  <div
                    className="elite_modal__pet-info__addExtra d-flex justify-content-end pt-3 mb-5 pb-2"
                    onClick={e => handleAddPet(e)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleAddPet(e);
                      }
                    }}
                    data-testid="add-pet"
                  >
                    <Icon iconClass="icon-add elite_modal__pet-info__addicon" />
                    <Label
                      labelText={modalProps?.addPet}
                      size={Types.size.small}
                      customClass="px-2 align-content-center"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </StyledPersonalInfoSection>
  );
};
