// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const CancelledTrips = dynamic(() => import('./CancelledTrips').then(mod => mod.CancelledTrips));
const CancelledTripsV2 = dynamic(() => import('./CancelledTripsV2').then(mod => mod.CancelledTripsV2));

export const CancelledTripsConfig = {
  emptyLabel: 'CancelledTrips',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/cancelledtrips`,
};

export const CancelledTripsEditable = (props: any) => {
  return (
    <EditableComponent config={CancelledTripsConfig} {...props}>
      {props?.isDTT ? <CancelledTripsV2 {...props} /> : <CancelledTrips {...props} />}
    </EditableComponent>
  );
};
