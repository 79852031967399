import React, { FC, useState, useEffect, useContext } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
import {
  constants,
  PageContext,
  PROFILE_REDIRECTION_URL,
  JOIN_NOW_CANCEL_URL,
  COMPLETE_PROFILE_URL,
  CUSTOM_PROFILE_TRACK_Val,
} from '../../modules';
import { OnboardingSectionProps } from './OnboardingSection.types';
import { StyledOnboardingSection } from './OnboardingSection.styles';
import { OnboardingComponent } from '../../molecules/OnboardingComponent';
import { useStore } from '../../modules/store/memberLevelStore';

export const OnboardingSection: FC<OnboardingSectionProps> = ({ header, onboardingDataObject, isHorizontal }) => {
  const [newUser, setNewUser] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isMytripsPage, setIsMytripsPage] = useState(false);
  const pageContext = useContext(PageContext);
  const isOverViewPage = pageContext?.isOverViewPage;
  const { showOnboardingSection } = useStore(state => state);

  useEffect(() => {
    const profilePageCheck =
      typeof window !== 'undefined' && window.location?.pathname
        ? PROFILE_REDIRECTION_URL.includes(window.location.pathname)
        : false;

    setIsProfilePage(profilePageCheck);
    const isNewUser = sessionStorage?.getItem(constants.MR_ENROLLED) === 'true';
    setNewUser(isNewUser);
    const currentPageUrl = window?.location?.href;
    const isMytripPage = currentPageUrl.includes(constants.FIND_RESERVATION_LIST_PAGE_URL) ? true : false;
    setIsMytripsPage(isMytripPage);
    return () => {
      sessionStorage?.removeItem(constants.MR_ENROLLED);
    };
  }, []);

  return isOverViewPage || (newUser && isProfilePage) || (isMytripsPage && showOnboardingSection) ? (
    <StyledOnboardingSection
      data-testid="onboarding-section"
      className={clsx('container mt-5 mb-4 mb-md-5')}
      data-component-name="o-account-onboardingsection"
    >
      <div className={clsx('new-member-header mb-5 t-title-s', isHorizontal ? 'text-left' : 'text-center')}>
        {header}
      </div>
      <div
        className={clsx(
          'd-flex new-member mx-lg-auto ',
          isHorizontal ? 'flex-lg-nowrap flex-wrap' : 'mx-4 align-items-center justify-content-center flex-column'
        )}
      >
        {onboardingDataObject.map((item, index) => (
          <OnboardingComponent
            key={index}
            isProfilePage={isProfilePage}
            iconClass={item.iconClass}
            text={item.text}
            ctaLabel={item.ctaLabel}
            ctaPath={item.ctaPath}
            textClass={index === onboardingDataObject.length - 1 && !isHorizontal ? '' : 'mb-5 mr-md-4'}
            target={item.target}
            buttonClass={index === onboardingDataObject.length - 1 && !isHorizontal ? 'px-4' : 'px-5'}
            clickTrack={
              item.ctaPath?.includes(COMPLETE_PROFILE_URL)
                ? CUSTOM_PROFILE_TRACK_Val.COMPLETE_PROFILE_TRACK_VAL
                : item.ctaPath?.includes(JOIN_NOW_CANCEL_URL)
                ? CUSTOM_PROFILE_TRACK_Val.BOOK_HOTEL_TRACK_VAL
                : CUSTOM_PROFILE_TRACK_Val.VIEW_BENEFITS_TRACK_VAL
            }
            isHorizontal={isHorizontal}
          />
        ))}
      </div>
    </StyledOnboardingSection>
  ) : null;
};

export const OnboardingSectionConfig = {
  emptyLabel: 'OnboardingSection',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/onboardingsection`,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const OnboardingSectionEditable = (props: any) => {
  return (
    <EditableComponent config={OnboardingSectionConfig} {...props}>
      <OnboardingSection {...props} />
    </EditableComponent>
  );
};
