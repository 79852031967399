import { FC } from 'react';
import { Heading, Types } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { OverviewSectionHeading } from '../../../molecules/OverviewSectionHeading';
import { TotalPointsProps } from './TotalPoints.types';
import { StyledTotalPoints } from './TotalPoints.styles';
import { numberWithCommas } from '../../../modules/utils/numericalFormatsHelper';

export const TotalPoints: FC<TotalPointsProps> = ({ model, points, customClass }) => {
  return (
    <StyledTotalPoints>
      <div className={clsx('card', customClass)}>
        <OverviewSectionHeading
          sectionHeadingClass="container card__header align-items-center px-5 px-sm-4 px-lg-5 pt-3 pb-2 pt-sm-0 pb-sm-0"
          title={model?.totalPointsSectionTitle}
          ctaLabel={model?.viewActivityCTAButtonLabel}
          ctaPath={model?.viewActivityPath}
          isLinkOpenNewTab={model?.openInANewTab}
        />
        <div className="card__divider"></div>
        <div className="mt-sm-3 pb-3">
          <Heading
            fontSize={Types.size.large}
            variation={Types.headingType.title}
            titleText={points ? numberWithCommas(points) : '0'}
            element={Types.tags.span}
            customClass="px-5"
          />
        </div>
      </div>
    </StyledTotalPoints>
  );
};
