// Styles for EditChangePassword go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledEditChangePassword = styled.div`
  ${rtl`
  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      &::-ms-reveal {
        display: none;
      }
    }
  }
  .modal__container {
    max-width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(584)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(902)} !important;
    }
    &-content {
      :is(${constants.RTL_LANG_LIST}){
        text-align: right;
      } 

     .m-input-field:is(${constants.RTL_LANG_LIST})  {
        label {
          left:0;
          right :${toRem(8)};
        }
        input{
          padding-right: ${toRem(16)};
        } 
      }
    }

    &-footer {
      background-color: ${baseVariables.color['merch50']};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      .apply-button {
        width:auto;
      }
    }
    .clear-btn {
      margin-left: ${toRem(28)};
      @media ${baseVariables.mediaQuery.md} {
        margin-left: 0;
      }
      :is(${constants.RTL_LANG_LIST}) {
        margin-right: ${toRem(28)};
        @media ${baseVariables.mediaQuery.md} {
          margin-right: 0;
        }
      }
    }
    .error-label,
    .error-label * {
      font-size: ${baseVariables.font.fontXs};
      color: ${baseVariables.color.alert50} !important;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .custom-value-color {
      color: ${baseVariables.color.neutral40} !important;
    }
    .custom-error {
      color: red !important;
    }
  }
    .edit-password {
      &__email ,
      &__password,
      &__newPassword,
      &__confirmPassword {
        max-width: unset;
        @media ${baseVariables.mediaQuery.md} {
          max-width: ${toRem(464)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          max-width: ${toRem(460)};
        }
      }
    }
    .checkbox-remember:is(${constants.RTL_LANG_LIST})  {
      padding-right: unset;

      label {
        padding-right: ${toRem(24)};
        ::before {
          left: unset;
          right: 0;
        }

        ::after {
          left: unset;
          right: ${toRem(6)};
          transform: rotateZ(45deg) !important;
        }
      }
    
    }
`}
`;
