import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';

export const StyledCombineAccountsConsent = styled.div`
  ${rtl`
  .form-bar {
    left: 0;
    border-bottom: ${toRem(1)} solid ${baseVariables.color.alert20};
  }
  .consent-button {
    .btn {
      width: 100%;
      @media ${baseVariables.mediaQuery.md} {
        width: auto;
      }
    }
  }

  .container:is(${constants.RTL_LANG_LIST}) {
    .account-selection {
      .m-radio-button-sm {
        label {
          padding-right: ${toRem(24)} !important;
          ::before {
            left: unset;
            right: ${toRem(1)};
            display: flex;
          }
          ::after {
            left: unset;
            right: ${toRem(6)};
          }
        }
      }
    }
    .consent-checkbox {
      label {
        padding-right: ${toRem(24)};
        ::before {
          left: unset;
          right: 0px;
        }

        ::after {
          left: unset;
          right: ${toRem(6)};
          transform: rotateZ(45deg) !important;
        }
      }
    }
  }
`}
`;
